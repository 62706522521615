import Styles from "./styles";
import ui from "components/ui";
import { Link } from "react-router-dom";

// img
import ImgDummyList from "resources/image/img_dummy_list.png";

function ContentsList({ data, big, url, urlCallback }) {
  return (
    <Styles.Container className={big ? "big" : ""} onClick={urlCallback}>
      <Link to={url} className="content">
        <div className="imgWrap">
          {data.soldout_yn == "Y" && <div className="closed">마감</div>}

          <div className="img">
            <img
              src={data?.thumbnail ? data?.thumbnail : ImgDummyList}
              alt="listImg"
            />
          </div>
        </div>

        <p className="title">{data?.title}</p>
      </Link>
      <ul className="keyword">
        {(data?.keyword_list || data?.keyword?.split(",") || [])
          .map((item, index) => {
            
            if(index > 1)return null;

            return (
              <li className="keywordList" key={"keywordList" + index}>
                <ui.button.Keyword
                  data={item}
                />
              </li>
            );
          })}
      </ul>
    </Styles.Container>
  );
}

export default ContentsList;
