import Styles from "./styles";
import ui from "components/ui";

// img
import IconClose from "resources/image/icon/icon_close.svg";

function DefaultModal({ modalOption, modalClose }) {
  return (
    <Styles.Container>
      <div className={"modalContainer"}>
        {modalOption.closeButton && (
          <button
            type="button"
            className="closeButton"
            onClick={() => {
              modalClose();
            }}
          >
            <img src={IconClose} alt="IconClose" />
          </button>
        )}

        {modalOption.icon && (
          <div className="icon">
            <img src={modalOption.icon} alt="modalIcon" />
          </div>
        )}

        <p className="title">{modalOption.title}</p>

        {modalOption.text && <p className="text">{modalOption.text}</p>}

        <div className="button">
          <ui.button.BasicButton
            buttonText={modalOption.confirmButtonText}
            onClick={() => {
              modalClose();
              {
                modalOption.confirmButtonClick &&
                  modalOption.confirmButtonClick();
              }
            }}
          />
        </div>
      </div>
    </Styles.Container>
  );
}

export default DefaultModal;
