import connector from "service/connector";
import utils from "utils";

const getList = async (data) => {
  return await connector.authConnector(`category?${utils.etc.getFilterStr(data)}`,'GET');
}

const getCategoryBannerList = async (id) => {
  return await connector.authConnector(`category/banner?id=${id}`,'GET');
}

export {
  getList,
  getCategoryBannerList,
};


export default {
  getList,
  getCategoryBannerList,
}