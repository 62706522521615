import styled from "styled-components";

const Container = styled.main`
  min-height: 100vh;
  min-height: 100dvh;
  padding-bottom: 100px;

  & .mainBannerSection {
    margin-bottom: 20px;
  }

  & .programSection {
    & .buttonWrap {
      display: flex;
      gap: 11px;
      margin-bottom: 40px;

      & .buttonList {
        flex: 1;

        &:last-child button {
          background-color: #f27d92;
        }

        & button {
          gap: 5px;
        }
      }
    }

    & .programContainer {
      &:not(:last-child) {
        margin-bottom: 40px;
        padding-bottom: 40px;
        border-bottom: 10px solid var(--gray03);
      }
    }

    & .categoryName {
      color: var(--green);
    }
  }

  @media (max-width: 520px) {
    padding-bottom: calc(100vw * (100 / 500));

    & .mainBannerSection {
      margin-bottom: calc(100vw * (20 / 500));
    }

    & .programSection {
      & .buttonWrap {
        gap: calc(100vw * (11 / 500));
        margin-bottom: calc(100vw * (40 / 500));

        & button {
          gap: calc(100vw * (5 / 500));
        }
      }

      & .programContainer {
        &:not(:last-child) {
          margin-bottom: calc(100vw * (40 / 500));
          padding-bottom: calc(100vw * (40 / 500));
          border-bottom: calc(100vw * (10 / 500)) solid var(--gray03);
        }
      }
    }
  }
`;

export { Container };

export default { Container };
