import Styles from "./styles";
import { useNavigate } from "react-router-dom";

// img
import IconArrowLeft from "resources/image/icon/icon_arrow_left.svg";

export default function SubHeader({ noPrevButton, pageName, backEvent }) {
  const navigate = useNavigate();

  return (
    <Styles.Container>
      <div className="inner">
        {!noPrevButton && (
          <button
            type="button"
            className="prevButton"
            onClick={() => {
              backEvent
                ? backEvent()
                : navigate(-1);
            }}
          >
            <img src={IconArrowLeft} alt="IconSearch" />
          </button>
        )}

        <p className="pageName">{pageName}</p>
      </div>
    </Styles.Container>
  );
}
