import styled from "styled-components";

const Container = styled.main`
  position: relative;
  min-height: calc(100vh - 61px);
  min-height: calc(100dvh - 61px);
  padding-bottom: 100px;

  & .tab {
    margin-bottom: 40px;
  }

  & .likeListWrap {
    display: flex;
    flex-wrap: wrap;
    gap: 40px 16px;

    & .likeList {
      position: relative;
    }

    & .deleteButton {
      position: absolute;
      top: 10px;
      left: 10px;
      padding: 5px;
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      background-color: var(--black);
      border-radius: 3px;
      z-index: 1;
    }
  }

  & .emptyText {
    padding-top: 310px;
    font-weight: 400;
    color: var(--gray01);
    text-align: center;
  }

  @media (max-width: 520px) {
    min-height: calc(100vh - calc(100vw * (61 / 500)));
    min-height: calc(100dvh - calc(100vw * (61 / 500)));
    padding-bottom: calc(100vw * (100 / 500));

    & .tab {
      margin-bottom: calc(100vw * (40 / 500));
    }

    & .likeListWrap {
      gap: calc(100vw * (40 / 500)) calc(100vw * (16 / 500));

      & .deleteButton {
        top: calc(100vw * (10 / 500));
        left: calc(100vw * (10 / 500));
        padding: calc(100vw * (5 / 500));
        font-size: calc(100vw * (16 / 500));
        border-radius: calc(100vw * (3 / 500));
      }
    }

    & .emptyText {
      padding-top: calc(100vw * (310 / 500));
    }
  }
`;

export { Container };

export default { Container };
