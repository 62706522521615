import styled from "styled-components";

const Container = styled.main`
  min-height: 100vh;
  min-height: 100dvh;
  padding-bottom: 100px;

  & .mainBannerSection {
    margin-bottom: 40px;
  }

  & .magazineSection {
    & .magazineContainer {
      &:not(:last-child) {
        margin-bottom: 40px;
        padding-bottom: 40px;
        border-bottom: 10px solid var(--gray03);
      }
    }

    & .categoryName {
      color: var(--green);
    }
  }

  @media (max-width: 520px) {
    padding-bottom: calc(100vw * (100 / 500));

    & .mainBannerSection {
      margin-bottom: calc(100vw * (40 / 500));
    }

    & .magazineSection {
      & .magazineContainer {
        &:not(:last-child) {
          margin-bottom: calc(100vw * (40 / 500));
          padding-bottom: calc(100vw * (40 / 500));
          border-bottom: calc(100vw * (10 / 500)) solid var(--gray03);
        }
      }
    }
  }
`;

export { Container };

export default { Container };
