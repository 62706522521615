import Styles from "./styles";
import { common, layout, ui } from "components";
import { api } from "service";
import { useEffect, useState } from "react";
import { useModals, useCalendar, useProduct, useTransaction, useAuth } from "hooks";
import { useNavigate,useParams } from "react-router-dom";
import utils from "utils";
import components from "./components";

// img
import IconLocation from "resources/image/icon/icon_location.svg";
import IconPeople from "resources/image/icon/icon_people_black.svg";
import IconMinus from "resources/image/icon/icon_minus.svg";
import IconPlus from "resources/image/icon/icon_plus.svg";

function ApplyPage() {
  const authInfo = useAuth({})
  const calendar = useCalendar();
  const navigate = useNavigate();
  const params = useParams();


  const [people, setPeople] = useState(1);
  const productInfo = useProduct({
    transactionDetailOption:{
      id : params?.id,
    },
    optionListOption: {
      id:params?.id,
      date: utils.date.getDateType(calendar.value,'-'),
      amount : people ? people : 1
    }
  });
  const detailQuery = productInfo.transactionDetailQuery?.data;
  const optionList = (productInfo.optionListQuery.data?.data || []);
  
  const transactionInfo = useTransaction({
    transactionList : [
      {
        optionList : productInfo?.optionGroupList,
        detail : productInfo?.transactionDetailQuery?.data?.data,
        amount : people,
        date : detailQuery?.data?.type == "2" ? optionList[0]?.started_at : calendar.value,
      }
    ]
  });
  
  useEffect(()=>{
    calendar.onClickDate(null);
    calendar.updateValidMap(detailQuery?.data?.option_group_list?.filter((value)=> parseInt(value.amount) >= people).map((value)=>{ return value.started_at; }));
  },[detailQuery, people])



  return (
    <div className="wrap">
      <common.SubHeader pageName={"결제하기"} />

      <Styles.Container>
        <section className="infoSection">
          <div className="inner">
            <div className="programInfo">
              <p className="containerTitle">프로그램 정보</p>

              <div className="infoWrap">
                <div className="img">
                  <img src={detailQuery?.data?.thumbnail} alt="programImg" />
                </div>

                <div className="textWrap">
                  <div className="top">
                    <layout.Tag type={detailQuery?.data?.type} />

                    <div className="location">
                      <div className="icon">
                        <img src={IconLocation} alt="IconLocation" />
                      </div>
                      {detailQuery?.data?.location}
                    </div>
                  </div>

                  <p className="programTitle">{detailQuery?.data?.title}</p>

                  <p className="programDescription">{detailQuery?.data?.subtitle}</p>
                </div>
              </div>
            </div>

            <div className="people">
              <div className="containerTitle">
                <div className="icon">
                  <img src={IconPeople} alt="IconPeople" />
                </div>
                신청인원
              </div>

              <div className="selectPeople">
                <button
                  type="button"
                  className="minusButton"
                  onClick={() => {
                    if (people > 1) {
                      setPeople(people - 1);
                    }
                  }}
                >
                  <img src={IconMinus} alt="IconMinus" />
                </button>

                <div className="peopleNumber">{people}</div>

                <button
                  type="button"
                  className="plusButton"
                  onClick={() => {
                    let totalAmount = 0;
                    detailQuery?.data?.option_group_list.map(v=>{ totalAmount = Math.max(totalAmount,v.amount); });
                    if(people + 1 <= totalAmount){
                      setPeople(e => e + 1);
                    }
                  }}
                >
                  <img src={IconPlus} alt="IconPlus" />
                </button>
              </div>
            </div>
            {
              detailQuery?.data?.banner_title && 
              <layout.ProgramBanner 
                type={detailQuery?.data?.type} 
                title={detailQuery?.data?.banner_title} 
                subtitle={detailQuery?.data?.banner_subtitle} 
              />
            }
          </div>
        </section>

        <section className="reservationSection">
          <div className="inner">
            <layout.Calendar 
              calendar={calendar} 
              activeAll={detailQuery?.data?.type == "2"}
            />
          </div>
        </section>

        <section className="timeSection">
          <div className="inner">
            {detailQuery?.data?.type != "2" ? (
              productInfo.optionGroupList?.map((item, index) => {
                return (
                  <div className="timeContainer" key={"timeContainer" + index}>
                    <p className="containerTitle">{item.title}</p>
                    <components.TimeList 
                      data={item} 
                      amount={people}
                      onChangeOption={productInfo.onChangeOption}
                    />
                  </div>
                );
              })
            ) : optionList[0] && (
              <>
                {/* name, id 바꿔서 사용해주세요 */}
                <ui.input.FirstOptionCheckbox
                  id={optionList[0].id}
                  name={"firstOption"}
                  data={optionList[0]}
                  amount={people}
                  checked={true}
                  // onChange={()=>{
                  //   productInfo.onChangeOption(optionList[0]);
                  // }}
                />
              </>
            )}
          </div>
        </section>

        <div className="application">
          <div className="inner">
            <div className="price">
              {utils.etc.numberWithCommas(transactionInfo.getTotalPrice())}원
            </div>

            <div className="applicationButton">
              <ui.button.BasicButton
                disabled={!transactionInfo.isValidate()}
                buttonText={"신청하기"}
                onClick={() => {
                  if (authInfo.certification()) {
                    navigate("/payment", { 
                      state : {
                        transactionList : transactionInfo.getGenerateTransactionList(),
                        productList : transactionInfo.transactionList,
                      }
                    });
                  }

                  // modalOption.setToastModal((e) => {
                  //   e.show = true;
                  //   e.text = "날짜를 선택해 주세요.";
                  //   // 시간을 선택해 주세요.
                  //   return { ...e };
                  // });
                }}
              />
            </div>
          </div>
        </div>
      </Styles.Container>
    </div>
  );
}

export default ApplyPage;
