import styled from "styled-components";

const Container = styled.main`
  min-height: 100vh;
  min-height: 100dvh;
  padding: 60px 0 50px;
  text-align: center;

  & .logo {
    width: 111px;
    height: 32px;
    margin: 0 auto 30px;
  }

  & .title {
    margin-bottom: 81px;
    font-size: 30px;
    font-weight: 600;
  }

  & .pointWrap {
    width: 420px;
    margin: 0 auto 82px;
    padding-top: 52px;
    background-color: var(--gray03);
    border-radius: 10px;

    & p {
      margin-bottom: 52px;
      font-weight: 400;
      color: var(--main);
      line-height: 1.5;

      & b {
        font-size: 30px;
        font-weight: 800;
      }
    }

    & .img {
      height: 218px;
    }
  }

  & .text {
    margin-bottom: 30px;
    font-size: 22px;
    color: var(--gray01);
  }

  & .buttonList:not(:last-child) {
    margin-bottom: 20px;
  }

  @media (max-width: 520px) {
    padding: calc(100vw * (60 / 500)) 0 calc(100vw * (50 / 500));

    & .logo {
      width: calc(100vw * (111 / 500));
      height: calc(100vw * (32 / 500));
      margin: 0 auto calc(100vw * (30 / 500));
    }

    & .title {
      margin-bottom: calc(100vw * (81 / 500));
      font-size: calc(100vw * (30 / 500));
    }

    & .pointWrap {
      width: calc(100vw * (420 / 500));
      margin: 0 auto calc(100vw * (82 / 500));
      padding-top: calc(100vw * (52 / 500));
      border-radius: calc(100vw * (10 / 500));

      & p {
        margin-bottom: calc(100vw * (52 / 500));

        & b {
          font-size: calc(100vw * (30 / 500));
        }
      }

      & .img {
        height: calc(100vw * (218 / 500));
      }
    }

    & .text {
      margin-bottom: calc(100vw * (30 / 500));
      font-size: calc(100vw * (22 / 500));
    }

    & .buttonList:not(:last-child) {
      margin-bottom: calc(100vw * (20 / 500));
    }
  }
`;

export { Container };

export default { Container };
