import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid var(--gray02);

  & .starText {
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
  }

  & .starPointText {
    margin-bottom: 10px;
    font-size: 30px;
    font-weight: bold;
  }

  & .inputWrap {
    position: relative;

    & .star {
      display: flex;
      gap: 5px;

      & li {
        width: 44px;
        height: 44px;
        cursor: pointer;
      }
    }

    & input {
      position: absolute;
      top: 0;
      left: -15px;
      width: 100%;
      height: 100%;
      -webkit-appearance: none;
      opacity: 0;
      cursor: pointer;

      &[type="range"]::-webkit-slider-thumb,
      &[type="range"]::-moz-range-thumb,
      &[type="range"]::-ms-thumb {
        width: 44px;
        height: 44px;
      }

      &[type="range"]::-webkit-slider-runnable-track,
      &[type="range"]::-moz-range-track,
      &[type="range"]::-ms-track {
        width: 100%;
        height: 44px;
      }
    }
  }

  @media (max-width: 520px) {
    margin-bottom: calc(100vw * (40 / 500));
    padding-bottom: calc(100vw * (40 / 500));

    & .starText {
      margin-bottom: calc(100vw * (20 / 500));
      font-size: calc(100vw * (30 / 500));
    }

    & .starPointText {
      margin-bottom: calc(100vw * (10 / 500));
      font-size: calc(100vw * (30 / 500));
    }

    & .inputWrap {
      & .star {
        gap: calc(100vw * (5 / 500));

        & li {
          width: calc(100vw * (44 / 500));
          height: calc(100vw * (44 / 500));
        }
      }

      & input {
        left: calc(100vw * (-15 / 500));

        &[type="range"]::-webkit-slider-thumb,
        &[type="range"]::-moz-range-thumb,
        &[type="range"]::-ms-thumb {
          width: calc(100vw * (44 / 500));
          height: calc(100vw * (44 / 500));
        }

        &[type="range"]::-webkit-slider-runnable-track,
        &[type="range"]::-moz-range-track,
        &[type="range"]::-ms-track {
          height: calc(100vw * (44 / 500));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
