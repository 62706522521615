import styled from "styled-components";

const Container = styled.main`
  min-height: 100vh;
  min-height: 100dvh;
  padding-bottom: 100px;

  & .mainBannerSection {
    margin-bottom: 40px;
  }

  & .programSection {
    margin-bottom: 60px;
  }

  & .subBannerSection {
    margin-bottom: 60px;
  }

  @media (max-width: 520px) {
    padding-bottom: calc(100vw * (100 / 500));

    & .mainBannerSection {
      margin-bottom: calc(100vw * (40 / 500));
    }

    & .programSection {
      margin-bottom: calc(100vw * (60 / 500));
    }

    & .subBannerSection {
      margin-bottom: calc(100vw * (60 / 500));
    }
  }
`;

export { Container };

export default { Container };
