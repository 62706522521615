import Styles from "./styles";
import { useAuth, useModals, useSupport } from "hooks";
import { common, ui } from "components";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

// img
import IconArrowRight from "resources/image/icon/icon_arrow_right_mini.svg";
import { DOMAIN } from "service/connector";

function SignupStepAPage() {
  const navigate = useNavigate();
  const modalOption = useModals();
  const authInfo = useAuth({});
  const supportInfo = useSupport({
    options: { policy: true },
  });

  const [check, setCheck] = useState([]);
  const [allCheck, setAllCheck] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    let resultMap = {};
    authInfo.signInfo.checkList.map((v) => {
      resultMap[v] = v;
    });
    setAllCheck(
      essentialCheckList.filter((v) => resultMap[v.id]).length == 3 &&
        optionalCheckList.filter((v) => resultMap[v.id]).length == 3
    );
  }, [authInfo.signInfo.checkList]);

  useEffect(() => {
    document.cookie = `jwt=${searchParams.get(
      "token"
    )};path=/;domain=${DOMAIN}`;
    searchParams.get("token") &&
      navigate("/signup/stepA", { state: true, replace: true });

    if (sessionStorage.getItem("signUp")) {
      const dataInfo = {
        "push_yn": "optional01",
        "message_yn": "optional02",
        "mail_yn": "optional03",
      }
      const checkData = []
      for (let key in dataInfo) {
        checkData.push(JSON.parse(sessionStorage.getItem("signUp"))[key] === "Y" ? dataInfo[key] : "")
      }
      console.log(checkData.filter(v => { return v }));
      setCheck(["essential01", "essential02", "essential03", ...checkData.filter(v => { return v })])
    }
  }, [])

  const handleAllCheck = (checked) => {
    if (checked) {
      const idArray = [];
      essentialCheckList.forEach((el) => idArray.push(el.id));
      optionalCheckList.forEach((el) => idArray.push(el.id));
      authInfo.setSignInfo({ checkList: [...idArray] });
      setAllCheck(true);
    } else {
      authInfo.setSignInfo({ checkList: [] });
      setAllCheck(false);
    }
  };

  // 단일 체크 함수
  const handleSingleCheck = (checked, id) => {
    if (checked) {
      authInfo.setSignInfo({
        checkList: [...authInfo.signInfo.checkList.concat(id)],
      });
    } else {
      authInfo.setSignInfo({
        checkList: [...authInfo.signInfo.checkList.filter((el) => el !== id)],
      });
    }
  };

  const validate = () => {
    let resultMap = {};
    authInfo.signInfo.checkList.map((v) => {
      resultMap[v] = v;
    });
    if (essentialCheckList.filter((v) => resultMap[v.id]).length != 3) {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "필수 약관에 동의해 주세요.";
        return { ...e };
      });
      return false;
    }
    return true;
  };

  const essentialCheckList = [
    {
      id: "essential01",
      labelText: "만 14세 이상입니다.",
    },
    {
      id: "essential02",
      labelText: "서비스 이용약관에 동의합니다.",
      modal: () => {
        supportInfo.getModal(0);
      },
    },
    {
      id: "essential03",
      labelText: "개인정보수집이용 및 처리 방침에 동의합니다.",
      modal: () => {
        supportInfo.getModal(2);
      },
    },
  ];

  const optionalCheckList = [
    {
      id: "optional01",
      labelText: "앱푸시",
    },
    {
      id: "optional02",
      labelText: "문자",
    },
    {
      id: "optional03",
      labelText: "이메일",
    },
  ];

  return (
    // location.state &&
    <div className="wrap">
      <common.SubHeader pageName={"회원가입"} />

      <Styles.Container>
        <div className="inner">
          <h2>
            오뉴 이용약관에 <br />
            동의해 주세요.
          </h2>

          <div className="allCheck">
            <ui.input.BasicCheckbox
              id={"all"}
              labelText={"전체 동의"}
              checked={allCheck}
              onChange={(e) => {
                handleAllCheck(e.target.checked);
              }}
            />
          </div>

          <div className="essentialCheck">
            <p className="title">필수 이용 약관</p>
            <ul>
              {essentialCheckList?.map((item, index) => {
                return (
                  <li
                    className="essentialCheckList"
                    key={"essentialCheckList" + index}
                  >
                    <ui.input.BasicCheckbox
                      id={item.id}
                      labelText={item.labelText}
                      checked={
                        authInfo.signInfo.checkList.includes(item.id)
                          ? true
                          : false
                      }
                      onChange={(e) =>
                        handleSingleCheck(e.target.checked, e.target.id)
                      }
                    />

                    {/* //* 퍼블 디자인 깨짐 우려 주석. 원본 코드 */}
                    {/* <Link to={item.link} className="icon">
                      <img src={IconArrowRight} alt="IconArrowRight" />
                    </Link> */}

                    {item.modal && (
                      <button
                        className="icon"
                        type="button"
                        onClick={item.modal}
                      >
                        <img src={IconArrowRight} alt="IconArrowRight" />
                      </button>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="optionalCheck">
            <p className="title">
              선택 이용 약관
              {/* //* 퍼블 디자인 깨짐 우려 주석. 원본 코드 */}
              {/* <Link to={"/contact/terms"} className="icon">
                <img src={IconArrowRight} alt="IconArrowRight" />
              </Link> */}
              <button
                className="icon"
                type="button"
                onClick={() => {
                  supportInfo.getModal(0);
                }}
              >
                <img src={IconArrowRight} alt="IconArrowRight" />
              </button>
            </p>

            <p className="guideText">
              할인 소식 혜택 등 마케팅 수신에 동의합니다.
            </p>

            <ul>
              {optionalCheckList?.map((item, index) => {
                return (
                  <li
                    className="optionalCheckList"
                    key={"optionalCheckList" + index}
                  >
                    <ui.input.BasicCheckbox
                      id={item.id}
                      labelText={item.labelText}
                      checked={
                        authInfo.signInfo.checkList.includes(item.id)
                          ? true
                          : false
                      }
                      onChange={(e) =>
                        handleSingleCheck(e.target.checked, e.target.id)
                      }
                    />
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="nextButton">
            <ui.button.BasicButton
              buttonText={"다음으로"}
              onClick={() => {
                if (!validate()) return;

                navigate("/signup/stepB")
                sessionStorage.setItem("signUp", JSON.stringify({
                  policy_yn: "Y",
                  marketing_yn: "Y",
                  personal_policy_yn: "Y",
                  mail_yn: authInfo.signInfo.checkList.filter((v) => v == "optional03")[0]
                    ? "Y"
                    : "N",
                  message_yn: authInfo.signInfo.checkList.filter((v) => v == "optional02")[0]
                    ? "Y"
                    : "N",
                  push_yn: authInfo.signInfo.checkList.filter((v) => v == "optional01")[0]
                    ? "Y"
                    : "N",
                },
                ));
              }}
            />
          </div>
        </div>
      </Styles.Container>
    </div>
  );
}

export default SignupStepAPage;
