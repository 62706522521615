import utils from "utils";
import Styles from "./styles";
import { common, layout, ui } from "components";
import { useEffect, useState } from "react";
import { useBanner, useSearch } from "hooks";
import { useLocation, useNavigate } from "react-router-dom";
import dummy from "service/api/dummy";

// img
import IconSearch from "resources/image/icon/icon_search.svg";
import LogoGray from "resources/image/icon/logo_gray.svg";

function SearchPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const param = utils.etc.getSearchParam();

  const bannerInfo = useBanner({ type: 2 });
  const [inputs, setInputs] = useState({ search: "" });
  const searchInfo = useSearch({
    listOption: {
      keyword: inputs.search,
    },
  });

  const data = searchInfo.listQuery?.data?.data;
  const banner = bannerInfo.getBanner?.data?.data;

  const inputData = {
    name: "search",
    value: inputs.search,
    placeholder: "예시 : 시간권, 미술, 요가, 미식",
  };

  useEffect(() => {
    setInputs({ search: param.keyword?.replace(/\+/g, " ") });
  }, [location]);

  return (
    <div className="wrap">
      <common.SubHeader
        pageName={"검색하기"}
        backEvent={() => {
          navigate("/");
        }}
      />

      <Styles.Container>
        <section className="section">
          <div className="searchContainer inner">
            <ui.input.BasicInput
              data={inputData}
              setInputs={setInputs}
              onKeyUp={(e) => {
                if (e.key == "Enter") {
                  navigate(
                    utils.etc.getQueryParamUrl(
                      { keyword: inputs.search?.replace(/ /g, "%20") },
                      false
                    )
                  );
                }
              }}
            />

            <button
              type="button"
              className="searchButton"
              onClick={() => {
                navigate(
                  utils.etc.getQueryParamUrl(
                    { keyword: inputs.search?.replace(/ /g, "%20") },
                    false
                  )
                );
              }}
            >
              <img src={IconSearch} alt="IconSearch" />
            </button>
          </div>

          {param.keyword === inputs.search && param.keyword !== undefined ? (
            <>
              <div className="program">
                <layout.SectionTitle
                  title={
                    <>
                      프로그램 <span>({data?.total_product_count})</span>
                    </>
                  }
                  url={"/search/detail/program?keyword=" + inputs.search}
                />

                {data?.product_list?.length > 0 ? (
                  <layout.ContentsSlider
                    slider={"program"}
                    data={data.product_list}
                    urlCallback={(e) => {
                      navigate(`/program/detail/${e.id}`, {
                        replace: true,
                      });
                    }}
                    url={"/search/detail/program?keyword=" + inputs.search}
                  />
                ) : (
                  <p className="emptyText inner">검색 결과가 없습니다.</p>
                )}
              </div>

              <div className="magazine">
                <layout.SectionTitle
                  title={
                    <>
                      매거진 <span>({data?.total_board_count})</span>
                    </>
                  }
                  url={"/search/detail/magazine?keyword=" + inputs.search}
                />

                {data?.board_list?.length > 0 ? (
                  <layout.ContentsSlider
                    slider={"magazine"}
                    data={data.board_list}
                    urlCallback={(e) => {
                      navigate(`/magazine/detail/${e.id}`);
                    }}
                    url={"/search/detail/magazine?keyword=" + inputs.search}
                  />
                ) : (
                  <p className="emptyText inner">검색 결과가 없습니다.</p>
                )}
              </div>
            </>
          ) : (
            <div className="subBanner">
              <layout.SubBanner data={banner?.length > 0 && banner} />

              {/* <ul className="keywordWrap">
                {dummy.keywordList.map((item, index) => {
                  return (
                    <li className="keywordList" key={"keywordList" + index}>
                      <ui.button.Keyword data={item} />
                    </li>
                  );
                })}
              </ul> */}

              <div className="logo">
                <img src={LogoGray} alt="LogoGray" />
              </div>
            </div>
          )}
        </section>
      </Styles.Container>

      <common.QuickMenu />
      <common.NavigationBar />
    </div>
  );
}

export default SearchPage;
