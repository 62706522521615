import Styles from "./styles";
import { common, ui } from "components";
import { useState } from "react";
import { useInterest, useModals } from "hooks";
import { useNavigate } from "react-router-dom";

// img
import ImgInterestBanner from "resources/image/img_interest_banner.jpg";

function InterestPage() {
  
  const interestInfo = useInterest({ listOption:{} });
  const modalOption = useModals();
  const navigate = useNavigate();

  return (
    <div className="wrap">
      <common.SubHeader pageName={"관심사 등록"} />

      <Styles.Container>
        <div className="inner">
          <section className="bannerSection">
            <div className="bannerImg">
              <img src={ImgInterestBanner} alt="ImgInterestBanner" />
            </div>
          </section>

          <section className="checkSection">
            {interestInfo?.list?.map((item, index) => {

              return (
                <div className={`inputContainer ${item.type == "0" && 'checkSubActive'}`} key={"inputContainer" + index}>
                  <p className="title">
                    <span>{item.required_yn == "Y" && '[필수]'}</span>
                    {item.title}
                  </p>
                  {item.description && (
                    <p className="guideText">{item.description}</p>
                  )}
                  {
                    item?.group_list &&
                    item?.group_list?.map((group)=>{
                      return group?.item_list && 
                      (
                        <>
                          <p className="subTitle">{group.group_title}</p>
                          <ul className={`checkListWrap`}>
                            {group.item_list?.map((current, idx) => {
                              return (
                                <>
                                  <li className="checkList" key={"checkList" + idx}>
                                    {
                                      current.title &&
                                      <ui.input.BasicCheckbox
                                        id={current.id}
                                        labelText={current.title}
                                        checked={current.checked}
                                        onChange={(e) => {
                                          current.checked = !current.checked;
                                          interestInfo.setList(e => [...e]);
                                        }}
                                      />
                                    }
                                  </li>
                                </>
                              );
                            })}
                          </ul>
                        </>
                      )
                    })
                  }
                  {
                    item.write_relate_id ?
                    item.type == "3" ? 
                    <ui.input.BasicCustomInput
                      value={item.content}
                      setValue={(e)=>{
                        item.content = e.target.value;
                        item.write_obj.checked = e.target.value != "";
                        interestInfo.setList(e => [...e]);
                      }}
                      placeholder={item.placeholder || "직접 입력"}
                    />
                    :
                    <div className="inputWrap">
                      <ui.input.BasicCustomInput
                        value={item.content}
                        setValue={(e)=>{
                          item.content = e.target.value;
                          item.write_obj.checked = e.target.value != "";
                          interestInfo.setList(e => [...e]);
                        }}
                        placeholder={item.placeholder || "직접 입력"}
                      />
                    </div>
                    :
                    null
                  }

                </div>
              );
            })}

            <div className="buttonWrap">
              <ui.button.BasicButton
                buttonText={"등록하기"}
                onClick={async () => {
                  let result = await interestInfo.updateInterestInfo();
                  if(result?.success == true){
                     window.location.replace('/interest/complete');
                  }
                }}
              />
            </div>
          </section>
        </div>
      </Styles.Container>
    </div>
  );
}

export default InterestPage;
