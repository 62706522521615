import Styles from "./styles";
import { useSupport } from "hooks";

function Terms() {
  const supportInfo = useSupport({
    options: { policy: true }
  });
  const data = supportInfo.getPolicy.data || {};
  const total = data.data?.length

  return (
    <Styles.Container>
      <ul>
        {data.data?.map((item, index) => {
          return (
            <li
              className="termsList"
              key={"termsList" + index}
              onClick={() => {
                supportInfo.getModalDetail(item)
              }}
            >
              <p className="title">{item.title}</p>
              <p className="bottom">
                <span className="number">{total - index}</span>
                <span className="date">{item.created_format}</span>
              </p>
            </li>
          );
        })}
      </ul>
    </Styles.Container>
  );
}

export default Terms;
