import { useQuery } from "react-query";
import { api } from "service";

export default function usePopup({
  type
}) {

  const popupQuery = useQuery(["popup", type], () => {
    return api.popup.getPopup(type);
  }, {
    refetchOnWindowFocus: false,
  });

  return { 
    popupQuery,
    list : popupQuery.data?.data || []
  }
}