import Styles from "./styles";
import { useNavigate } from "react-router-dom";
import utils from "utils";

// img
import IconArrowLeft from "resources/image/icon/icon_arrow_left_box.svg";
import IconArrowRight from "resources/image/icon/icon_arrow_right_box.svg";

function Pagination({ list, page, maxPage, disableScrollTop }) {
  const navigate = useNavigate();
  const onClickPage = (value) => {
    navigate(utils.etc.getQueryParamUrl({ page: value, disableScrollTop : disableScrollTop }));
  };

  const onClickPrev = () => {
    if (page == 1) return;
    navigate(utils.etc.getQueryParamUrl({ page: page - 1, disableScrollTop : disableScrollTop }));
  };

  const onClickNext = () => {
    if (page == maxPage) return;
    navigate(utils.etc.getQueryParamUrl({ page: page + 1, disableScrollTop : disableScrollTop }));
  };

  return (
    <Styles.Container>
      <button
        type="button"
        className="iconArrow"
        onClick={(e) => {
          onClickPrev(e);
        }}
      >
        <img src={IconArrowLeft} alt="IconArrowLeft" />
      </button>

      <div className="paginationList">
        {(list ? list : []).map((item, index) => {
          return (
            <button
              type="button"
              value={item}
              className={page == item ? "on" : ""}
              onClick={() => {
                onClickPage(item);
              }}
              key={index}
            >
              {item}
            </button>
          );
        })}
      </div>

      <button
        type="button"
        className="iconArrow"
        onClick={(e) => {
          onClickNext(e);
        }}
      >
        <img src={IconArrowRight} alt="IconArrowRight" />
      </button>
    </Styles.Container>
  );
}

export { Pagination };

export default Pagination;
