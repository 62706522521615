import Styles from "./styles";
import { common, layout } from "components";
import { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

// img
import IconHeadset from "resources/image/icon/icon_headset.svg";
import IconMail from "resources/image/icon/icon_mail.svg";
import IconArrowRight from "resources/image/icon/icon_arrow_right_mini.svg";

function ContactPage() {
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState(location.pathname.split("/")[2]);

  const contactList = [
    {
      icon: IconHeadset,
      text: "고객센터",
      color: (
        <a href="tel:02-2038-2636">
          <span>02-2038-2636</span>
          <div className="arrowIcon">
            <img src={IconArrowRight} alt="IconArrowRight" />
          </div>
        </a>
      ),
    },
    {
      icon: IconMail,
      text: "문의하기",
      color: "hello@losh.co.kr",
    },
  ];
  const tabList = [
    {
      id: "notice",
      url: "/contact/notice",
      text: "공지사항",
    },
    {
      id: "faq",
      url: "/contact/faq",
      text: "자주 묻는 질문",
    },
    {
      id: "terms",
      url: "/contact/terms",
      text: "이용약관",
    },
  ];

  return (
    <div className="wrap">
      <common.SubHeader pageName={"고객센터"} />

      <Styles.Container>
        <section className="contactSection">
          <ul className="contactWrap">
            {contactList?.map((item, index) => {
              return (
                <li className="contactList" key={"contactList" + index}>
                  <div className="icon">
                    <img src={item.icon} alt="contactIcon" />
                  </div>
                  <p className="text">{item.text}</p>
                  <div className="colorText">{item.color}</div>
                </li>
              );
            })}
          </ul>

          <p className="time">영업시간 : 월~토요일 오전 9시 - 오후 6시</p>
        </section>

        <section className="contentsSection">
          <div className="tab">
            <layout.Tab
              tabList={tabList}
              tabIndex={tabIndex}
              setTabIndex={setTabIndex}
            />
          </div>

          <div className="inner">
            <Outlet />
          </div>
        </section>
      </Styles.Container>

      <common.QuickMenu />
      <common.Footer />
      <common.NavigationBar />
    </div>
  );
}

export default ContactPage;
