import connector from "service/connector";

const getBanner = async (path) => {
  return await connector.connectFetchController("banner?type=" + path, "GET");
}
const clickBanner = async (id, type) => {
  return await connector.connectFetchController("banner/click?id=" + id + "&type=" + (type ? type : 1), "POST");
}
const getIntroduceBanner = async () => {
  return await connector.connectFetchController("board/introduce/banner", "GET");
}

export { getBanner, clickBanner, getIntroduceBanner };
export default { getBanner, clickBanner, getIntroduceBanner };