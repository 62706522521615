import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 460px;
  height: 75px;
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  background-color: rgba(99, 5, 238, 0.5);
  border-radius: 500px;
  backdrop-filter: blur(7.5px);
  z-index: 5;

  & .icon {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }

  @media (max-width: 520px) {
    top: calc(100vw * (80 / 500));
    width: calc(100vw * (460 / 500));
    height: calc(100vw * (75 / 500));
    font-size: calc(100vw * (22 / 500));
    border-radius: calc(100vw * (500 / 500));
    backdrop-filter: blur(calc(100vw * (8 / 500)));

    & .icon {
      width: calc(100vw * (24 / 500));
      height: calc(100vw * (24 / 500));
      margin-right: calc(100vw * (5 / 500));
    }
  }
`;

export { Container };

export default { Container };
