import styled from "styled-components";

const Container = styled.main`
  position: relative;
  min-height: calc(100vh - 61px);
  min-height: calc(100dvh - 61px);
  padding: 40px 0 100px;
  border-top: 10px solid var(--gray03);

  & .containerTitle {
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: bold;
  }

  & .infoSection {
    padding-bottom: 30px;
    border-bottom: 10px solid var(--gray03);

    & .programInfo {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid var(--gray02);

      & .infoWrap,
      & .top,
      & .location {
        display: flex;
      }

      & .infoWrap {
        gap: 26px;

        & .img {
          flex-shrink: 0;
          width: 112px;
          height: 140px;
          border-radius: 5px;
          overflow: hidden;
        }

        & .textWrap {
          flex: 1 0;
          max-width: calc(100% - 138px);

          & .top,
          & .location {
            align-items: center;
          }

          & .top {
            gap: 10px;
            margin-bottom: 15px;
          }

          & .location {
            font-weight: 600;
            color: var(--main);
          }

          & .programTitle {
            margin-bottom: 10px;
            font-size: 22px;
            font-weight: 600;
            line-height: 1.36;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          & .programDescription {
            display: -webkit-box;
            font-size: 18px;
            font-weight: 400;
            color: var(--gray01);
            line-height: 1.33;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }

    & .people,
    & .containerTitle,
    & .selectPeople {
      display: flex;
      align-items: center;
    }

    & .people {
      justify-content: space-between;
      margin-bottom: 30px;

      & .containerTitle {
        gap: 12px;
        margin-bottom: 0;

        & .icon {
          width: 34px;
          height: 34px;
        }
      }

      & .selectPeople {
        gap: 10px;
        font-size: 30px;
        font-weight: 600;

        & button {
          width: 34px;
          height: 34px;
        }

        & .peopleNumber {
          width: 60px;
          text-align: center;
        }
      }
    }
  }

  & .reservationSection {
    padding-top: 40px;

    & .inner {
      border-bottom: 1px solid var(--gray02);
    }
  }

  & .timeSection {
    padding: 40px 0 100px;

    & .timeContainer:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  & .application {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 500px;
    padding: 12px 0;
    background-color: #fff;
    border-top: 1px solid var(--gray02);
    z-index: 5;

    & .inner {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & .price {
        font-size: 30px;
        font-weight: bold;
      }

      & .applicationButton {
        width: 225px;
      }
    }
  }

  @media (max-width: 520px) {
    min-height: calc(100vh - calc(100vw * (61 / 500)));
    min-height: calc(100dvh - calc(100vw * (61 / 500)));
    padding: calc(100vw * (40 / 500)) 0 calc(100vw * (100 / 500));
    border-top: calc(100vw * (10 / 500)) solid var(--gray03);

    & .containerTitle {
      margin-bottom: calc(100vw * (20 / 500));
      font-size: calc(100vw * (22 / 500));
    }

    & .infoSection {
      padding-bottom: calc(100vw * (30 / 500));
      border-bottom: calc(100vw * (10 / 500)) solid var(--gray03);

      & .programInfo {
        margin-bottom: calc(100vw * (20 / 500));
        padding-bottom: calc(100vw * (20 / 500));

        & .infoWrap {
          gap: calc(100vw * (26 / 500));

          & .img {
            width: calc(100vw * (112 / 500));
            height: calc(100vw * (140 / 500));
            border-radius: calc(100vw * (5 / 500));
          }

          & .textWrap {
            max-width: calc(100% - calc(100vw * (138 / 500)));

            & .top {
              gap: calc(100vw * (10 / 500));
              margin-bottom: calc(100vw * (15 / 500));
            }

            & .programTitle {
              margin-bottom: calc(100vw * (10 / 500));
              font-size: calc(100vw * (22 / 500));
            }

            & .programDescription {
              font-size: calc(100vw * (18 / 500));
            }
          }
        }
      }

      & .people {
        margin-bottom: calc(100vw * (30 / 500));

        & .containerTitle {
          gap: calc(100vw * (12 / 500));

          & .icon {
            width: calc(100vw * (34 / 500));
            height: calc(100vw * (34 / 500));
          }
        }

        & .selectPeople {
          gap: calc(100vw * (10 / 500));
          font-size: calc(100vw * (30 / 500));

          & button {
            width: calc(100vw * (34 / 500));
            height: calc(100vw * (34 / 500));
          }

          & .peopleNumber {
            width: calc(100vw * (60 / 500));
          }
        }
      }
    }

    & .reservationSection {
      padding-top: calc(100vw * (40 / 500));
    }

    & .timeSection {
      padding: calc(100vw * (40 / 500)) 0 calc(100vw * (100 / 500));

      & .timeContainer:not(:last-child) {
        margin-bottom: calc(100vw * (30 / 500));
      }
    }

    & .application {
      width: 100%;
      padding: calc(100vw * (12 / 500)) 0;

      & .inner {
        & .price {
          font-size: calc(100vw * (30 / 500));
        }

        & .applicationButton {
          width: calc(100vw * (225 / 500));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
