import Styles from "./styles";

function BasicCustomInput({
  value,
  setValue,
  children,
  readOnly,
  name,
  id,
  essential,
  placeholder,
  labelText,
  errorText,
  type,
}) {
  return (
    <>
      {labelText && (
        <Styles.Label htmlFor={id ? id : name}>
          {essential && <span>[필수]</span>} {labelText}
        </Styles.Label>
      )}

      <Styles.Container>
        <input
          className={errorText ? "error" : ""}
          type={type ? type : "text"}
          value={value}
          onChange={setValue}
          readOnly={readOnly}
          placeholder={placeholder}
        />

        {children}
      </Styles.Container>
    </>
  );
}

export default BasicCustomInput;
