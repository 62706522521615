import Styles from "./styles";

function BasicRadio({ id, name, labelText, checked, error, setInputs }) {
  const onChange = (e) => {
    const { name, id } = e.target;
    setInputs((e) => {
      return { ...e, [name]: id };
    });
  };

  return (
    <>
      <Styles.Input
        id={id}
        name={name}
        type="radio"
        checked={checked === id}
        onChange={onChange}
      />

      <Styles.Label htmlFor={id} className={error ? "error" : ""}>
        {labelText}
      </Styles.Label>
    </>
  );
}

export default BasicRadio;
