import styled from "styled-components";

const Container = styled.main`
  position: relative;

  & section:not(:first-of-type) {
    padding: 40px 0;
    border-bottom: 10px solid var(--gray03);
  }

  & .title {
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: bold;
  }

  & .completeText {
    padding: 40px 0;
    font-size: 30px;
    font-weight: 600;
    text-align: center;

    & .icon {
      width: 80px;
      height: 80px;
      margin: 0 auto 15px;
    }

    & span {
      color: var(--main);
    }
  }

  & .methodSection .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .title {
      margin: 0;
    }

    & .method {
      font-size: 22px;
      font-weight: 600;
      color: var(--gray01);
    }
  }

  & .buttonContainer .inner {
    display: flex;
    gap: 10px;
    padding: 50px 0;

    & .buttonWrap {
      flex: 1;
    }
  }

  @media (max-width: 520px) {
    & section:not(:first-of-type) {
      padding: calc(100vw * (40 / 500)) 0;
      border-bottom: calc(100vw * (10 / 500)) solid var(--gray03);
    }

    & .title {
      margin-bottom: calc(100vw * (20 / 500));
      font-size: calc(100vw * (22 / 500));
    }

    & .completeText {
      padding: calc(100vw * (40 / 500)) 0;
      font-size: calc(100vw * (30 / 500));

      & .icon {
        width: calc(100vw * (80 / 500));
        height: calc(100vw * (80 / 500));
        margin: 0 auto calc(100vw * (15 / 500));
      }
    }

    & .methodSection .inner {
      & .method {
        font-size: calc(100vw * (22 / 500));
      }
    }

    & .buttonContainer .inner {
      gap: calc(100vw * (10 / 500));
      padding: calc(100vw * (50 / 500)) 0;
    }
  }
`;

export { Container };

export default { Container };
