import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 100vh;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10;

  & .modalContainer {
    position: relative;
    width: 420px;
    padding: 70px 40px 30px;
    background-color: #fff;
    border-radius: 10px;

    & .closeButton {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 34px;
      height: 34px;
    }

    & .title {
      margin-bottom: 19px;
      font-size: 22px;
      font-weight: bold;
    }

    & .editorWrap {
      max-height: 60vh;
      max-height: 60dvh;
      word-break: break-all;
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  @media (max-width: 520px) {
    width: 100%;

    & .modalContainer {
      width: calc(100vw * (420 / 500));
      padding: calc(100vw * (70 / 500)) calc(100vw * (40 / 500))
        calc(100vw * (30 / 500));
      border-radius: calc(100vw * (10 / 500));

      & .closeButton {
        top: calc(100vw * (20 / 500));
        right: calc(100vw * (20 / 500));
        width: calc(100vw * (34 / 500));
        height: calc(100vw * (34 / 500));
      }

      & .title {
        margin-bottom: calc(100vw * (19 / 500));
        font-size: calc(100vw * (22 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
