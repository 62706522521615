import BasicCheckbox from "./BasicCheckbox";
import BasicInput from "./BasicInput";
import BasicCustomInput from "./BasicCustomInput";
import BasicRadio from "./BasicRadio";
import DateInput from "./DateInput";
import FirstOptionCheckbox from "./FirstOptionCheckbox";
import SecondOptionCheckbox from "./SecondOptionCheckbox";
import ProgramRadio from "./ProgramRadio";
import BasicSelect from "./BasicSelect";
import BasicTextarea from "./BasicTextarea";
import ReasonRadio from "./ReasonRadio";
import CommentInput from "./CommentInput";

export {
  BasicCheckbox,
  BasicInput,
  BasicCustomInput,
  BasicRadio,
  DateInput,
  FirstOptionCheckbox,
  SecondOptionCheckbox,
  ProgramRadio,
  BasicSelect,
  BasicTextarea,
  ReasonRadio,
  CommentInput,
};

export default {
  BasicCheckbox,
  BasicInput,
  BasicCustomInput,
  BasicRadio,
  DateInput,
  FirstOptionCheckbox,
  SecondOptionCheckbox,
  ProgramRadio,
  BasicSelect,
  BasicTextarea,
  ReasonRadio,
  CommentInput,
};
