import styled from "styled-components";

const Container = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 5;

  & .inner {
    display: flex;
    align-items: center;
    gap: 20px;
    height: 70px;

    & .logo a {
      width: 111px;
      height: 32px;
    }

    & .search {
      display: flex;
      align-items: center;
      width: 177px;
      height: 40px;
      padding: 0 10px;
      background-color: var(--gray03);
      border-radius: 500px;

      & .icon {
        width: 26px;
        height: 26px;
        filter: var(--gray01-filter);
      }
    }

    & .location {
      display: flex;
      align-items: center;
      font-weight: 600;
      color: var(--main);

      & .icon {
        width: 30px;
        height: 30px;
      }
    }

    & .userButton {
      width: 30px;
      height: 30px;
    }
  }

  @media (max-width: 520px) {
    & .inner {
      gap: calc(100vw * (20 / 500));
      height: calc(100vw * (70 / 500));

      & .logo a {
        width: calc(100vw * (111 / 500));
        height: calc(100vw * (32 / 500));
      }

      & .search {
        width: calc(100vw * (177 / 500));
        height: calc(100vw * (40 / 500));
        padding: 0 calc(100vw * (10 / 500));
        border-radius: calc(100vw * (500 / 500));

        & .icon {
          width: calc(100vw * (26 / 500));
          height: calc(100vw * (26 / 500));
        }
      }

      & .location {
        & .icon {
          width: calc(100vw * (30 / 500));
          height: calc(100vw * (30 / 500));
        }
      }

      & .userButton {
        width: calc(100vw * (30 / 500));
        height: calc(100vw * (30 / 500));
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
