import Styles from "./styles";
import { common } from "components";
import { useSupport } from "hooks";
import utils from "utils";

function NoticeDetailPage() {
  const supportInfo = useSupport({
    options: { noticeDetail: true }
  });
  const data = supportInfo.getNoticeDetail.data || {};

  return (
    <div className="wrap">
      <common.SubHeader pageName={"공지사항"} />

      <Styles.Container>
        <div className="inner">
          <div className="titleWrap">
            <p className="date">{utils.date.getDateType(data.created_at,'.')}</p>

            <p className="title">{data.title}</p>
          </div>

          <div className="editor ck-content"
            dangerouslySetInnerHTML={{__html: data?.description}}
          ></div>
        </div>
      </Styles.Container>

      <common.QuickMenu />
      <common.Footer />
      <common.NavigationBar />
    </div>
  );
}

export default NoticeDetailPage;
