import Styles from "./styles";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "hooks";

// img
import IconCalendar01 from "resources/image/icon/icon_calendar_circle01.svg";
import IconCalendar02 from "resources/image/icon/icon_calendar_circle02.svg";
import IconClose from "resources/image/icon/icon_close_circle.svg";
import IconReservation from "resources/image/icon/icon_reservation_circle.svg";
import IconAsk from "resources/image/icon/icon_ask_circle.svg";
import IconStamp from "resources/image/icon/icon_stamp.svg";

function QuickMenu() {
  const authInfo = useAuth({});
  const navigate = useNavigate();

  const [buttonModalOpen, setButtonModalOpen] = useState(false);
  // map
  const list = [
    {
      class: "calendar",
      url: "/reservation/date",
      icon: IconCalendar02,
      text: "캘린더",
    },
    {
      class: "reservation",
      url: "/reservation/quick",
      icon: IconReservation,
      text: "빠른예약",
    },
    {
      class: "attendance",
      url: "/attendance",
      icon: IconStamp,
      text: "출석체크",
    },
    {
      class: "ask",
      url: "/ask",
      icon: IconAsk,
      text: "문의하기",
    },
  ];

  return (
    <Styles.Container>
      <ul className={"buttonListWrap" + (buttonModalOpen ? " on" : "")}>
        {buttonModalOpen &&
          list?.map((item, index) => {
            return (
              <li className="buttonList" key={"buttonList" + index}>
                <a
                  onClick={() => {
                    if (index != 2) {
                      navigate(item.url);
                      // if (authInfo.certification()) navigate(item.url);
                    } else {
                      navigate(item.url);
                    }
                    setButtonModalOpen(!buttonModalOpen);
                  }}
                >
                  <img src={item.icon} alt={item.icon} />
                  <p className="buttonText">{item.text}</p>
                </a>
              </li>
            );
          })}

        <li className="buttonList">
          <button
            type="button"
            onClick={() => {
              setButtonModalOpen(!buttonModalOpen);
            }}
          >
            <img
              src={buttonModalOpen ? IconClose : IconCalendar01}
              alt="IconPen"
            />
          </button>
        </li>
      </ul>

      {buttonModalOpen && <div className="overlay"></div>}
    </Styles.Container>
  );
}

export default QuickMenu;
