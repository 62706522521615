// <path-to-your-build>/src/ckeditor.ts or file containing editor configuration if you are integrating an editor from source.
import connector from "service/connector";
import "./styles.css"

// The editor creator to use.
import  Editor  from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

const CustomCkEditor = ({ text, setText }) => {
	const readOnly = setText === "";

	const customUploadAdapter = (loader) => {
		return {
			upload() {
				return new Promise((resolve) => {
					const data = new FormData();
					loader.file.then((file) => {
						data.append("name", file.name);
						data.append("files[0]", file);
						connector.connectFetchController("upload/image/drag", "POST", data).then((e) => {
							if (e.success == true) { resolve({ default: e.data.files[0] }) }
						})
					})
				})
			}
		}
	}

	function uploadPlugin(editor) {
		editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
			return customUploadAdapter(loader);
		}
	}

	const edrtorConfiguration = {
		extraPlugins: [uploadPlugin],
		language: "ko",
		toolbar: {
			items: [
				"heading",
				"|",
				"bold",
				"italic",
				"bulletedlist",
				"numberedlist",
				"|",
				"undo",
				"redo",

				"blockQuote",
				"selectAll",
				"insertTable",
				"|",
				"link",
				"imageUpload",
				"mediaEmbed",
			]
		}
	};	

	return (
		<div id="ckeditor-body" >
			<CKEditor
				editor={Editor}
				config={!readOnly && edrtorConfiguration}
				disabled={readOnly}
				data={text ? text : ""}
				onBlur={(e, editor) => {
					!readOnly && setText(editor.getData()); 
				}}
			/>
		</div>
	)
}

export default CustomCkEditor;