import styled from "styled-components";

const Container = styled.div`
  & .title {
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: bold;
  }

  & .prcieListWrap {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--gray02);

    & .priceList {
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      color: var(--gray01);

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  & .totalPriceWrap,
  .cancelPriceWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .title {
      margin-bottom: 0;
    }

    & .totalPrice,
    .cancelPrice {
      font-size: 30px;
      font-weight: 600;
      color: var(--main);
    }
  }

  & .cancelPriceWrap {
    margin-top: 25px;
    padding-top: 25px;
    border-top: 1px solid var(--gray02);

    & .cancelPrice {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    & .fee {
      font-size: 18px;
      font-weight: 500;
      color: var(--green);
    }
  }

  @media (max-width: 520px) {
    & .title {
      margin-bottom: calc(100vw * (20 / 500));
      font-size: calc(100vw * (22 / 500));
    }

    & .prcieListWrap {
      margin-bottom: calc(100vw * (20 / 500));
      padding-bottom: calc(100vw * (20 / 500));

      & .priceList {
        font-size: calc(100vw * (18 / 500));

        &:not(:last-child) {
          margin-bottom: calc(100vw * (10 / 500));
        }
      }
    }

    & .totalPriceWrap,
    .cancelPriceWrap {
      & .totalPrice,
      .cancelPrice {
        font-size: calc(100vw * (30 / 500));
      }
    }

    & .cancelPriceWrap {
      margin-top: calc(100vw * (25 / 500));
      padding-top: calc(100vw * (25 / 500));

      & .cancelPrice {
        gap: calc(100vw * (10 / 500));
      }

      & .fee {
        font-size: calc(100vw * (18 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
