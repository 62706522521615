import styled from "styled-components";

const Container = styled.div`
  & .subBannerList {
    width: 460px;

    & div {
      height: 130px;
      border-radius: 10px;
      overflow: hidden;
      cursor: pointer;
    }
  }

  @media (max-width: 520px) {
    & .subBannerList {
      width: calc(100vw * (460 / 500));

      & div {
        height: calc(100vw * (130 / 500));
        border-radius: calc(100vw * (10 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
