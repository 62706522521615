import MainBanner from "./MainBanner";
import Menu from "./Menu";
import SectionTitle from "./SectionTitle";
import ContentsList from "./ContentsList";
import ContentsSlider from "./ContentsSlider";
import CategoryNavigation from "./CategoryNavigation";
import Tag from "./Tag";
import ProgramBanner from "./ProgramBanner";
import Calendar from "./Calendar";
import ProgramInfoSection from "./ProgramInfoSection";
import ProgramJungiOptionSection from "./ProgramJungiOptionSection";
import ProgramDetailSection from "./ProgramDetailSection";
import ProgramOptionSection from "./ProgramOptionSection";
import ProgramPriceSection from "./ProgramPriceSection";
import Tab from "./Tab";
import MyProgram from "./MyProgram";
import ReviewStarPoint from "./ReviewStarPoint";
import ReviewImageUpload from "./ReviewImageUpload";
import SubBanner from "./SubBanner";
import Comment from "./Comment";

export {
  MainBanner,
  Menu,
  SectionTitle,
  ContentsList,
  ContentsSlider,
  CategoryNavigation,
  Tag,
  ProgramBanner,
  Calendar,
  ProgramInfoSection,
  ProgramJungiOptionSection,
  ProgramDetailSection,
  ProgramOptionSection,
  ProgramPriceSection,
  Tab,
  MyProgram,
  ReviewStarPoint,
  ReviewImageUpload,
  SubBanner,
  Comment,
};

export default {
  MainBanner,
  Menu,
  SectionTitle,
  ContentsList,
  ContentsSlider,
  CategoryNavigation,
  Tag,
  ProgramBanner,
  Calendar,
  ProgramInfoSection,
  ProgramDetailSection,
  ProgramJungiOptionSection,
  ProgramOptionSection,
  ProgramPriceSection,
  Tab,
  MyProgram,
  ReviewStarPoint,
  ReviewImageUpload,
  SubBanner,
  Comment,
};
