import styled from "styled-components";

const Container = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 92px;
  height: 35px;
  font-weight: 600;
  color: #fff;
  background-color: var(--green);
  border-radius: 500px;

  &.time {
    background-color: var(--main);
  }

  @media (max-width: 520px) {
    width: calc(100vw * (92 / 500));
    height: calc(100vw * (35 / 500));
    border-radius: calc(100vw * (500 / 500));
  }
`;

export { Container };

export default { Container };
