import styled from "styled-components";

const Container = styled.ul`
  position: sticky;
  bottom: 0;
  left: 0;
  display: flex;
  border-top: 1px solid var(--gray02);
  background-color: #fff;
  z-index: 5;

  & .navList {
    width: calc(100% / 4);

    & a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 95px;

      & .iconWrap {
        width: 34px;
        height: 34px;
        margin-bottom: 5px;
        filter: var(--gray01-filter);
      }

      & .navText {
        font-size: 16px;
        font-weight: 600;
        color: var(--gray01);
      }

      &.active {
        position: relative;

        &::before {
          position: absolute;
          top: -1px;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: var(--main);
          content: "";
        }

        & .iconWrap {
          filter: var(--main-filter);
        }

        & .navText {
          color: var(--main);
        }
      }
    }
  }

  @media (max-width: 520px) {
    & .navList {
      & a {
        height: calc(100vw * (95 / 500));

        & .iconWrap {
          width: calc(100vw * (34 / 500));
          height: calc(100vw * (34 / 500));
          margin-bottom: calc(100vw * (5 / 500));
        }

        & .navText {
          font-size: calc(100vw * (16 / 500));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
