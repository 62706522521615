import styled from "styled-components";

const Container = styled.main`
  position: relative;
  min-height: calc(100vh - 61px);
  min-height: calc(100dvh - 61px);
  padding: 40px 0 100px;

  & .input {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid var(--gray02);

    & .inputList:not(:last-child) {
      margin-bottom: 40px;
    }

    & .label {
      margin-bottom: 20px;
      font-size: 22px;
      font-weight: 600;

      & span {
        color: var(--main);
      }
    }

    & .guideText {
      display: flex;
      align-items: center;
      gap: 7px;
      margin-top: 15px;
      font-size: 18px;
      color: var(--gray01);

      & .icon {
        display: inline-block;
        width: 28px;
        height: 28px;
        filter: var(--gray01-filter);
      }
    }
  }

  & .terms {
    margin-bottom: 40px;

    & .label {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    & .check {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & .icon {
        width: 16px;
        height: 16px;
        filter: var(--gray01-filter);
      }
    }
  }

  @media (max-width: 520px) {
    min-height: calc(100vh - calc(100vw * (61 / 500)));
    min-height: calc(100dvh - calc(100vw * (61 / 500)));
    padding: calc(100vw * (40 / 500)) 0 calc(100vw * (100 / 500));

    & .input {
      margin-bottom: calc(100vw * (40 / 500));
      padding-bottom: calc(100vw * (40 / 500));

      & .inputList:not(:last-child) {
        margin-bottom: calc(100vw * (40 / 500));
      }

      & .label {
        margin-bottom: calc(100vw * (20 / 500));
        font-size: calc(100vw * (22 / 500));
      }

      & .guideText {
        gap: calc(100vw * (7 / 500));
        margin-top: calc(100vw * (15 / 500));
        font-size: calc(100vw * (18 / 500));

        & .icon {
          width: calc(100vw * (28 / 500));
          height: calc(100vw * (28 / 500));
        }
      }
    }

    & .terms {
      margin-bottom: calc(100vw * (40 / 500));

      & .label {
        font-size: calc(100vw * (22 / 500));
        margin-bottom: calc(100vw * (20 / 500));
      }

      & .check {
        & .icon {
          width: calc(100vw * (16 / 500));
          height: calc(100vw * (16 / 500));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
