import useAuth from 'hooks/useAuth';
import React, { useState } from 'react';
import { api } from 'service';
import { ip } from 'service/connector';

export default function useIamport() {
  const authInfo = useAuth({});

  async function onClickPayment(payData) {

    let result = await api.transaction.doTransaction(JSON.stringify(payData));
    if(result.success == false){
      return result;
    }
    if(result.success && result.data?.total_price == 0){
      window.location.replace('/payment/complete?id=' + result?.data?.id);
      return true;
    }

    /* 1. 가맹점 식별하기 */
    const { IMP } = window;
    // IMP.init('imp48174694');
    IMP.init('imp48174694');
    /* 2. 결제 데이터 정의하기 */
    const data = {
      // pg: 'html5_inicis',                           // PG사
      pg: 'uplus',                           // PG사
      pay_method: payData.method,                           // 결제수단
      merchant_uid: result.data.merchant_uid, //`mid_${new Date().getTime()}`,   // 주문번호
      amount: result.data.total_price,                                // 결제금액
      name: payData?.productTitle,                  // 주문명
      buyer_name: authInfo.userData?.name || payData.transaction_member_list[0].name ,                           // 구매자 이름
      // buyer_tel: '01012341234',                     // 구매자 전화번호
      buyer_email: authInfo.userData?.email || '',               // 구매자 이메일
      // buyer_addr: '신사동 661-16',                    // 구매자 주소
      // buyer_postcode: '06018',                      // 구매자 우편번호
      m_redirect_url : ip + "iamport"
    };
    /* 4. 결제 창 호출하기 */
    IMP.request_pay(data, callback);

    return false;
  }

  /* 3. 콜백 함수 정의하기 */
  async function callback(response) { 
    const {
      success,
      merchant_uid,
      error_msg,
    } = response;


    console.log(response)
    if (success) {
      
      var form = document.createElement("form");
      form.setAttribute("charset", "UTF-8");
      form.setAttribute("method", "POST");  //Post 방식
      form.setAttribute("action", ip + "iamport"); //요청 보낼 주소
  
      for(let key in response){
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("name", key); 
        hiddenField.setAttribute("value", response[key]);
        form.appendChild(hiddenField);
      }
  
      document.body.appendChild(form);
      form.submit();
    } else {
      alert(`결제 실패: ${error_msg}`);
    }
  }

  return {
    onClickPayment,
  }
}