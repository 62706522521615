import styled from "styled-components";

const Container = styled.main`
  min-height: calc(100vh - 61px);
  min-height: calc(100dvh - 61px);
  padding: 40px 0 50px;

  & .select {
    display: flex;
    gap: 5px;
    margin-bottom: 40px;

    & .selectList {
      flex: 1;
    }
  }

  & .emptyText {
    padding: 270px 0;
    text-align: center;
    font-weight: 400;
    color: var(--gray01);
  }

  & .programList {
    cursor: pointer;
  }

  & .programList:not(:last-child) {
    margin-bottom: 20px;
  }

  & .pagination {
    margin-top: 40px;
  }

  @media (max-width: 520px) {
    min-height: calc(100vh - calc(100vw * (61 / 500)));
    min-height: calc(100dvh - calc(100vw * (61 / 500)));
    padding: calc(100vw * (40 / 500)) 0 calc(100vw * (50 / 500));

    & .select {
      gap: calc(100vw * (5 / 500));
      margin-bottom: calc(100vw * (40 / 500));
    }

    & .emptyText {
      padding: calc(100vw * (270 / 500)) 0;
    }

    & .programList:not(:last-child) {
      margin-bottom: calc(100vw * (20 / 500));
    }

    & .pagination {
      margin-top: calc(100vw * (40 / 500));
    }
  }
`;

export { Container };

export default { Container };
