import Styles from "./styles";
import FaqList from "../FaqList";
import { ui } from "components";
import { useSupport } from "hooks";

function Faq() {
  const supportInfo = useSupport({
    options: { faqList: true }
  });
  const data = supportInfo.getFaqList.data || {};

  return (
    <Styles.Container>
      {data.list?.length > 0 ? (
        <>
          <ul>
            {data.list.map((item, index) => {
              return (
                <li className="faqList" key={"faqList" + index}>
                  <FaqList data={item} />
                </li>
              );
            })}
          </ul>

          <div className="pagination">
            <ui.Pagination
              page={data.pagination?.page}
              list={data.pagination?.page_list}
              maxPage={data.pagination?.totalPageCount}
            />
          </div>
        </>
      ) : (
        <p className="emptyText">등록된 게시글이 없습니다.</p>
      )}
    </Styles.Container>
  );
}

export default Faq;
