import styled from "styled-components";

const Container = styled.div`
  & .noticeList {
    padding: 20px 0;
    border-bottom: 1px solid var(--gray03);

    & .title {
      margin-bottom: 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    & .bottom {
      font-size: 18px;
      font-weight: 400;
      color: var(--gray01);

      & .number {
        position: relative;
        padding-right: 10px;
        margin-right: 10px;

        &::after {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          width: 1px;
          height: 12px;
          background-color: var(--gray01);
          content: "";
        }
      }
    }
  }

  & .pagination {
    margin-top: 40px;
  }

  & .emptyText {
    padding: 180px 0 105px;
    color: var(--gray01);
    text-align: center;
  }

  @media (max-width: 520px) {
    & .noticeList {
      padding: calc(100vw * (20 / 500)) 0;

      & .title {
        margin-bottom: calc(100vw * (10 / 500));
      }

      & .bottom {
        font-size: calc(100vw * (18 / 500));

        & .number {
          padding-right: calc(100vw * (10 / 500));
          margin-right: calc(100vw * (10 / 500));

          &::after {
            height: calc(100vw * (12 / 500));
          }
        }
      }
    }

    & .pagination {
      margin-top: calc(100vw * (40 / 500));
    }

    & .emptyText {
      padding: calc(100vw * (180 / 500)) 0 calc(100vw * (105 / 500));
    }
  }
`;

export { Container };

export default { Container };
