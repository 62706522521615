import styled from "styled-components";

const Container = styled.main`
  position: relative;
  min-height: calc(100vh - 61px);
  min-height: calc(100dvh - 61px);
  padding-bottom: 155px;

  & section {
    padding: 40px 0;
  }

  & section:not(:last-of-type) {
    border-bottom: 10px solid var(--gray03);
  }

  & .contentsTitle {
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: bold;
  }

  & .priceWrap {
    & .percent {
      margin-right: 10px;
      color: var(--green);
    }

    & del {
      font-weight: 400;
      color: var(--gray01);
    }

    & .price {
      font-weight: bold;
    }
  }

  & .slideSection {
    & .nowProgramDate {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70px;
      margin: -20px auto 20px;
      font-size: 22px;
      font-weight: 600;
      background-color: var(--gray03);
      border: 1px solid var(--gray02);
      border-radius: 10px;
    }

    & .slider {
      margin-bottom: 20px;
      padding-bottom: 20px;
    }

    & .swiper-wrapper {
      align-items: flex-end;
    }

    & .slideList {
      width: 118px;
      height: 116px;
      padding: 0 12px;
      cursor: pointer;

      & .img {
        height: 100%;
        border-radius: 5px;
        overflow: hidden;
      }

      &.active {
        height: 147px;
        padding: 0;
        margin: 0 0 0 12px !important;
        box-shadow: 6px 8px 10px 0px rgba(68, 74, 102, 0.25);
      }
    }
  }

  & .priceSection {
    & .top,
    & .bottom,
    & .location,
    & .priceText {
      display: flex;
      align-items: center;
    }

    & .top {
      gap: 10px;
      margin-bottom: 15px;

      & .location {
        font-weight: 600;
        color: var(--main);

        & .icon {
          width: 30px;
          height: 30px;
        }
      }
    }

    & .title {
      margin-bottom: 20px;
      padding-bottom: 20px;
      font-size: 30px;
      font-weight: 600;
      border-bottom: 1px solid var(--gray02);
    }

    & .bottom {
      align-items: flex-start;
      justify-content: space-between;
      font-size: 22px;

      & .priceText {
        gap: 10px;
        color: var(--gray01);

        & .icon {
          width: 24px;
          height: 24px;
        }
      }

      & .priceWrap {
        text-align: right;

        & .discount {
          margin-bottom: 10px;
        }

        & .price {
          font-size: 36px;
        }
      }
    }
  }

  & .infoSection {
    & .listTitle,
    & .keyword {
      display: flex;
      align-items: center;
    }

    & .infoWrap {
      margin-bottom: 20px;
    }

    & .infoList {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      font-size: 22px;

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      & .listTitle {
        color: var(--gray01);

        & .icon {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
      }

      & .text {
        width: 282px;
      }
    }

    & .keyword {
      flex-wrap: wrap;
      gap: 8px;
      margin-bottom: 40px;
    }
  }

  & .application {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 500px;
    padding: 12px 0;
    background-color: #fff;
    border-top: 1px solid var(--gray02);
    z-index: 5;

    & .inner {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & .discount {
        margin-bottom: 5px;
        font-size: 18px;
      }

      & .price {
        font-size: 30px;
      }

      & .applicationButton {
        width: 225px;
      }
    }
  }

  @media (max-width: 520px) {
    min-height: calc(100vh - calc(100vw * (61 / 500)));
    min-height: calc(100dvh - calc(100vw * (61 / 500)));
    padding-bottom: calc(100vw * (155 / 500));

    & section {
      padding: calc(100vw * (40 / 500)) 0;
    }

    & section:not(:last-of-type) {
      border-bottom: calc(100vw * (10 / 500)) solid var(--gray03);
    }

    & .contentsTitle {
      margin-bottom: calc(100vw * (20 / 500));
      font-size: calc(100vw * (22 / 500));
    }

    & .priceWrap {
      & .percent {
        margin-right: calc(100vw * (10 / 500));
      }
    }

    & .slideSection {
      & .nowProgramDate {
        height: calc(100vw * (70 / 500));
        font-size: calc(100vw * (22 / 500));
        border-radius: calc(100vw * (10 / 500));
      }

      & .slideList {
        width: calc(100vw * (118 / 500));
        height: calc(100vw * (116 / 500));
        padding: 0 calc(100vw * (12 / 500));
        border-radius: calc(100vw * (5 / 500));

        &.active {
          height: calc(100vw * (147 / 500));
          margin: 0 0 0 calc(100vw * (12 / 500));
          box-shadow: calc(100vw * (6 / 500)) calc(100vw * (8 / 500))
            calc(100vw * (10 / 500)) 0 rgba(68, 74, 102, 0.25);
        }
      }
    }

    & .priceSection {
      & .top {
        gap: calc(100vw * (10 / 500));
        margin-bottom: calc(100vw * (15 / 500));

        & .location {
          & .icon {
            width: calc(100vw * (30 / 500));
            height: calc(100vw * (30 / 500));
          }
        }
      }

      & .title {
        margin-bottom: calc(100vw * (20 / 500));
        padding-bottom: calc(100vw * (20 / 500));
        font-size: calc(100vw * (30 / 500));
      }

      & .bottom {
        font-size: calc(100vw * (22 / 500));

        & .priceText {
          gap: calc(100vw * (10 / 500));

          & .icon {
            width: calc(100vw * (24 / 500));
            height: calc(100vw * (24 / 500));
          }
        }

        & .priceWrap {
          & .discount {
            margin-bottom: calc(100vw * (10 / 500));
          }

          & .price {
            font-size: calc(100vw * (36 / 500));
          }
        }
      }
    }

    & .infoSection {
      & .infoWrap {
        margin-bottom: calc(100vw * (20 / 500));
      }

      & .infoList {
        font-size: calc(100vw * (22 / 500));

        &:not(:last-child) {
          margin-bottom: calc(100vw * (15 / 500));
        }

        & .listTitle {
          & .icon {
            width: calc(100vw * (24 / 500));
            height: calc(100vw * (24 / 500));
            margin-right: calc(100vw * (10 / 500));
          }
        }

        & .text {
          width: calc(100vw * (282 / 500));
        }
      }

      & .keyword {
        gap: calc(100vw * (8 / 500));
        margin-bottom: calc(100vw * (40 / 500));
      }
    }

    & .application {
      width: 100%;
      padding: calc(100vw * (12 / 500)) 0;

      & .inner {
        & .discount {
          margin-bottom: calc(100vw * (5 / 500));
          font-size: calc(100vw * (18 / 500));
        }

        & .price {
          font-size: calc(100vw * (30 / 500));
        }

        & .applicationButton {
          width: calc(100vw * (225 / 500));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
