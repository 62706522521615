import { api } from "service";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

export default function useDirection({
  locationList,
  locationDetail,
}) {
  const { id } = useParams();


  const getLocation = useQuery(["location_list"], () => {
    return api.direction.getLocationList();
  }, {
    refetchOnWindowFocus: false,
    enabled: !!locationList
  });
  const getLocationDetail = useQuery(["location_detail", id], () => {
    return api.direction.getLocationDetail(id);
  }, {
    refetchOnWindowFocus: false, 
    enabled: !!locationDetail
  });

  
  return {
    getLocation,
    getLocationDetail
  };
}
