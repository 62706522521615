import product from "./product";
import productQuick from "./productQuick";
import dummy from "./dummy";
import auth from "./auth";
import sms from "./sms";
import transaction from "./transaction";
import point from "./point";
import order from "./order";
import category from "./category";
import support from "./support";
import inquiry from "./inquiry";
import review from "./review";
import interest from "./interest";
import like from "./like";
import direction from "./direction";
import banner from "./banner";
import board from "./board";
import search from "./search";
import reply from "./reply";
import upload from "./upload";
import codeCheck from "./codeCheck";
import report from "./report";
import popup from "./popup";

export default {
  product,
  productQuick,
  dummy,
  auth,
  sms,
  transaction,
  point,
  order,
  category,
  support,
  inquiry,
  review,
  interest,
  like,
  direction,
  banner,
  board,
  search,
  reply,
  upload,
  codeCheck,
  report,
  popup,
};

export { 
  auth,
  sms,
  transaction,
  point,
  order,
  category,
  support,
  inquiry,
  review,
  interest,
  like,
  direction,
  banner,
  board,
  search,
  reply,
  upload,
  codeCheck,
  report,
  popup,
};
