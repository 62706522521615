import { useAtom } from "jotai";
import { useState } from "react";
import { useQuery } from "react-query";
import { api } from "service";
import { modalAtom } from "store/atoms";

export default function useReview({
  infoOption,
  listOption,
  detailOption,
}) {

  const listQuery = useQuery(["review",listOption], 
    ()=>{
      return api.review.getList(listOption);
    }, {
    enabled:!!listOption,
    refetchOnWindowFocus: false,
  });

  const infoQuery = useQuery(["reviewInfo",infoOption], 
    ()=>{
      return api.review.getInfo(infoOption);
    }, {
    enabled:!!infoOption,
    refetchOnWindowFocus: false,
  });

  const detailQuery = useQuery(["reviewDetail",detailOption], 
    ()=>{
      return api.review.getDetail(detailOption?.id);
    }, {
    enabled:!!detailOption,
    refetchOnWindowFocus: false,
  });


  return {
    infoQuery,
    listQuery,
    detailQuery,
  };
}
