import styled from "styled-components";

const Container = styled.div`
  & .uploadTitle {
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: 600;
  }

  & .uploadButton {
    & input {
      display: none;
    }

    & label {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70px;
      font-size: 22px;
      font-weight: 600;
      color: #fff;
      background-color: var(--main);
      border-radius: 5px;
      cursor: pointer;
    }
  }

  & .guideText {
    display: flex;
    gap: 7px;
    margin-top: 15px;
    padding-bottom: 20px;
    font-size: 18px;
    color: var(--gray01);
    line-height: 1.33;
    border-bottom: 1px solid var(--gray02);

    & .icon {
      flex-shrink: 0;
      display: inline-block;
      width: 28px;
      height: 28px;
      filter: var(--gray01-filter);
    }
  }

  & .imgListWrap {
    display: flex;
    flex-wrap: wrap;
    gap: 30px 20px;
    margin-top: 40px;

    & .imgList {
      position: relative;
      background-color: var(--gray03);

      & .img {
        width: 140px;
        height: 140px;
      }

      & .delButton {
        position: absolute;
        top: 10px;
        left: 10px;
        padding: 5px;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        background-color: var(--black);
        border-radius: 3px;
      }
    }
  }

  @media (max-width: 520px) {
    & .uploadTitle {
      margin-bottom: calc(100vw * (20 / 500));
      font-size: calc(100vw * (22 / 500));
    }

    & .uploadButton {
      & label {
        height: calc(100vw * (70 / 500));
        font-size: calc(100vw * (22 / 500));
        border-radius: calc(100vw * (5 / 500));
      }
    }

    & .guideText {
      gap: calc(100vw * (7 / 500));
      margin-top: calc(100vw * (15 / 500));
      padding-bottom: calc(100vw * (20 / 500));
      font-size: calc(100vw * (18 / 500));

      & .icon {
        width: calc(100vw * (28 / 500));
        height: calc(100vw * (28 / 500));
      }
    }

    & .imgListWrap {
      gap: calc(100vw * (30 / 500)) calc(100vw * (20 / 500));
      margin-top: calc(100vw * (40 / 500));

      & .imgList {
        & .img {
          width: calc(100vw * (140 / 500));
          height: calc(100vw * (140 / 500));
        }

        & .delButton {
          top: calc(100vw * (10 / 500));
          left: calc(100vw * (10 / 500));
          padding: calc(100vw * (5 / 500));
          font-size: calc(100vw * (16 / 500));
          border-radius: calc(100vw * (3 / 500));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
