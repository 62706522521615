import Styles from "./styles";
import { useState } from "react";
import { useReport } from "hooks";
import { common, ui } from "components";
import { useLocation } from "react-router-dom";

function ReportPage() {
  const reportInfo = useReport();
  const location = useLocation();

  const [inputs, setInputs] = useState({
    reason: "",
    textarea: "",
  });

  const onRadioChange = (e) => {
    const { name, id } = e.target;
    setInputs((e) => {
      return { ...e, [name]: id, textarea: "" };
    });
  };
  const onTextareaChange = (e) => {
    const { name, value } = e.target;
    setInputs((e) => {
      return { ...e, [name]: value };
    });
  };

  const reasonList = [
    "스팸홍보 / 도배글",
    "음란성 / 선정성",
    "불법정보",
    "개인, 타인정보 노출",
    "욕설 / 음해 / 인신공격",
    "폭력 / 범죄 행위 / 사행심 조장",
    "기타",
  ];
  const textareaData = {
    id: "textarea",
    value: inputs.textarea,
    name: "textarea",
    placeholder: "직접 입력",
    errorText: false,
  };

  return (
    <div className="wrap">
      <common.SubHeader pageName={"신고하기"} />

      <Styles.Container>
        <section className="section">
          <div className="inner">
            <h2>신고 사유를 선택해 주세요.</h2>

            <ul>
              {reasonList.map((item, index) => {
                return (
                  <li className="reasonList" key={"reasonList" + index}>
                    <ui.input.ReasonRadio
                      id={item}
                      labelText={item}
                      checked={inputs.reason}
                      name={"reason"}
                      onChange={onRadioChange}
                    />
                  </li>
                );
              })}
            </ul>

            <div className="textarea">
              <ui.input.BasicTextarea
                data={textareaData}
                disabled={inputs.reason !== "기타"}
                onChange={onTextareaChange}
              />
            </div>

            <div className="buttonWrap">
              <ui.button.BasicButton
                buttonText={"신고하기"}
                onClick={() => {
                  reportInfo.doReport({
                    reason: inputs.reason === "기타" ? inputs.textarea : inputs.reason,
                    relate_info_id: location.state.id,
                    type: location.state.type,
                  })
                }}
              />
            </div>
          </div>
        </section>
      </Styles.Container>
    </div>
  );
}

export default ReportPage;
