import Styles from "./styles";
import layout from "..";
import { api } from "service";

// img
import IconLocation from "resources/image/icon/icon_location.svg";

function ProgramDetailSection({ productInfo, transactionList, detail, hideState }) {
  const optionList = detail?.group_list[0]?.option_list?.filter(
    (value) => value.type == "0"
  );

  // 수강일 기준 기획(내부용 최종 12-5-1, 12-5-2 사이 상태값 시안) 참고 부탁드립니다!
  const renderState = (state) => {
    switch (String(state)) {
      case "-1":
        return "결제 취소";
      case "10":
        return "결제 대기";
      case "80":
        return "환불 신청";
      case "83":
        return "환불 완료";
      case "81":
        return "환불 반려";
      default:
        return "결제 완료";
    }
  };

  return (
    <Styles.Container>
      <div className="inner">
        <div className="top">
          <div className="tag">
            {
              (productInfo?.detail || detail) ? 
              <layout.Tag type={(productInfo?.detail || detail)?.type} />
              :
              null
            }
          </div>

          <div className="location">
            <div className="icon">
              <img src={IconLocation} alt="IconLocation" />
            </div>
            {productInfo?.detail?.location}
            {detail?.group_list[0]?.location}
          </div>

          {!hideState && (productInfo?.detail || detail)?.state && (
            <div className="state">{renderState((productInfo?.detail || detail)?.state)}</div>
          )}
        </div>

        <div className="programTitle">{productInfo?.detail?.title || detail?.group_list[0]?.title}</div>

        <ul className="time">
          {transactionList &&
            transactionList[0]?.option_list?.map((item, index) => {
              if((productInfo?.detail || detail)?.type == "2" && index != 0) return null;
              
              return (
                <li className="timeList" key={"timeList" + index}>
                  {item.title}
                </li>
              );
            })}
          {optionList &&
            optionList?.map((item, index) => {

              if((productInfo?.detail || detail)?.type == "2" && index != 0)
                return null;

              return (
                <li className="timeList" key={"timeList" + index}>
                  {item.title}
                </li>
              );
            })}
        </ul>
      </div>
    </Styles.Container>
  );
}

export default ProgramDetailSection;
