import styled from "styled-components";

const Container = styled.div`
  & .dateTopContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding-bottom: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid var(--gray02);

    & .dateWrap {
      font-size: 30px;
      font-weight: 600;
    }

    & button {
      width: 20px;
      height: 20px;
    }
  }

  & .dateBottomContainer {
    padding-bottom: 30px;

    & .dayContainer {
      display: flex;
      justify-content: center;
      gap: 34px;
      margin-bottom: 30px;

      & .day {
        width: 28px;
        font-size: 22px;
        text-align: center;

        &.sunday {
          color: var(--red);
        }

        &.saturday {
          color: #2e83e7;
        }
      }
    }

    & .row {
      display: flex;
      justify-content: center;
      gap: 34px;

      &:not(:last-child) {
        margin-bottom: 34px;
      }
    }

    & .date {
      position: relative;
      width: 28px;
      color: var(--gray02);
      text-align: center;
      pointer-events: none;

      & p {
        position: relative;
        z-index: 1;
      }

      & .emptyDate {
        display: none;
      }

      &.schedule {
        color: var(--black);
        pointer-events: initial;
        cursor: pointer;
      }

      &.active {
        &::after {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 46px;
          height: 46px;
          background-color: var(--main);
          border-radius: 50%;
          content: "";
        }

        & p {
          color: #fff;
        }
      }
    }
  }

  @media (max-width: 520px) {
    & .dateTopContainer {
      gap: calc(100vw * (30 / 500));
      padding-bottom: calc(100vw * (20 / 500));
      margin-bottom: calc(100vw * (30 / 500));

      & .dateWrap {
        font-size: calc(100vw * (30 / 500));
      }

      & button {
        width: calc(100vw * (20 / 500));
        height: calc(100vw * (20 / 500));
      }
    }

    & .dateBottomContainer {
      padding-bottom: calc(100vw * (30 / 500));

      & .dayContainer {
        gap: calc(100vw * (34 / 500));
        margin-bottom: calc(100vw * (30 / 500));

        & .day {
          width: calc(100vw * (28 / 500));
          font-size: calc(100vw * (22 / 500));
        }
      }

      & .row {
        gap: calc(100vw * (34 / 500));

        &:not(:last-child) {
          margin-bottom: calc(100vw * (34 / 500));
        }
      }

      & .date {
        width: calc(100vw * (28 / 500));

        &.active {
          &::after {
            width: calc(100vw * (46 / 500));
            height: calc(100vw * (46 / 500));
          }
        }
      }
    }
  }
`;

export { Container };

export default { Container };
