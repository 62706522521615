import Styles from "./styles";
import { useNavigate } from "react-router-dom";
import { common, layout } from "components";
import { useBanner, useBoard, useModals, useProduct } from "hooks";
import { useEffect } from "react";
import usePopup from "hooks/usePopup";

function MainPage() {
  const navigate = useNavigate();

  const popupInfo = usePopup({type:1});
  const modalOption = useModals({});
  const mainBannerInfo = useBanner({ type: 1 });
  const subBannerInfo = useBanner({ type: 2 });
  const productInfo = useProduct({
    listOption: {
      page: 1,
      recordSize: 5,
      pageSize: 1,
      hold_yn: "Y",
    },
  });
  const boardInfo = useBoard({
    listOption: {
      page: 1,
      recordSize: 5,
      pageSize: 1,
      type: 1,
      hold_yn: "Y",
    },
  });

  const mainBanner = mainBannerInfo.getBanner?.data?.data;
  const subBanner = subBannerInfo.getBanner?.data?.data;

  useEffect(()=>{
    if(popupInfo.list && popupInfo.list.length > 0){
      popupInfo.list?.map((value)=>{
        modalOption.setEventModal({
          show: true,
          description:value.description
        })
      })
    }
  },[popupInfo.list])

  return (
    <div className="wrap">
      <common.Header />

      <Styles.Container>
        <layout.Menu />

        {mainBanner && mainBanner.length > 0 && (
          <section className="mainBannerSection">
            <layout.MainBanner data={mainBanner} />
          </section>
        )}

        <section className="programSection">
          <layout.SectionTitle title={"인기 프로그램"} url={"/program"} />

          <layout.ContentsSlider
            slider={"program"}
            data={productInfo.listQuery.data?.list || []}
            urlCallback={(e) => {
              navigate("/program/detail/" + e.id);
            }}
            url={"/program"}
          />
        </section>

        {subBanner && subBanner.length > 0 && (
          <section className="subBannerSection">
            <layout.SubBanner data={subBanner} />
          </section>
        )}

        <section className="magazineSection">
          <layout.SectionTitle title={"인기 매거진"} url={"/magazine"} />

          <layout.ContentsSlider
            slider={"magazine"}
            data={boardInfo.listQuery.data?.list || []}
            urlCallback={(e) => {
              navigate("/magazine/detail/" + e.id);
            }}
            url={"/magazine"}
          />
        </section>
      </Styles.Container>

      <common.Footer />

      <common.QuickMenu />
      <common.NavigationBar />
    </div>
  );
}

export default MainPage;
