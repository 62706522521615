import Styles from "./styles";
import { ui } from "components";
import { useNavigate } from "react-router-dom";

// img
import ImgComplete from "resources/image/img_interest_complete.png";
import Logo from "resources/image/icon/logo.svg";

function InterestCompletePage() {
  const navigate = useNavigate();

  return (
    <div className="wrap">
      <Styles.Container>
        <div className="inner">
          <div className="logo">
            <img src={Logo} alt="logo" />
          </div>

          <h2>관심사가 등록되었습니다!</h2>

          <div className="img">
            <img src={ImgComplete} alt="ImgComplete" />
          </div>

          <div className="text">
            <p>관심사를 등록해주셔서 감사합니다!</p>
            <p>1,000 포인트가 적립 되었습니다.</p>
          </div>

          <div className="button">
            <ui.button.BasicButton
              buttonText={"확인"}
              onClick={() => {
                navigate("/mypage");
              }}
            />
          </div>
        </div>
      </Styles.Container>
    </div>
  );
}

export default InterestCompletePage;
