import styled from "styled-components";

const Container = styled.main`
  position: relative;
  min-height: calc(100vh - 672px);
  min-height: calc(100dvh - 672px);
  padding: 40px 0 100px;

  & h2 {
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: bold;
  }

  & .editor {
    margin-bottom: 40px;
    word-break: break-all;
  }

  & .location {
    display: flex;
    gap: 40px 0;
    flex-wrap: wrap;

    & .locaitonList {
      flex-shrink: 0;
      width: 50%;
      font-size: 22px;
      word-break: break-all;

      &:first-child {
        width: 100%;
        line-height: 1.45;

        & .icon {
          filter: var(--main-filter);
        }
      }
    }

    & .icon {
      width: 34px;
      height: 34px;
      margin-bottom: 10px;
    }

    & a {
      width: fit-content;
      color: var(--main);
      text-decoration: underline;
      text-underline-offset: 3px;
    }
  }

  @media (max-width: 520px) {
    min-height: calc(100vh - calc(100vw * (672 / 500)));
    min-height: calc(100dvh - calc(100vw * (672 / 500)));
    padding-bottom: calc(100vw * (40 / 500)) 0 calc(100vw * (100 / 500));

    & h2 {
      margin-bottom: calc(100vw * (20 / 500));
      font-size: calc(100vw * (30 / 500));
    }

    & .editor {
      margin-bottom: calc(100vw * (40 / 500));
    }

    & .location {
      gap: calc(100vw * (40 / 500)) 0;

      & .locaitonList {
        font-size: calc(100vw * (22 / 500));
      }

      & .icon {
        width: calc(100vw * (34 / 500));
        height: calc(100vw * (34 / 500));
        margin-bottom: calc(100vw * (10 / 500));
      }

      & a {
        text-underline-offset: calc(100vw * (3 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
