import Styles from "./styles";
import { common, layout, ui } from "components";
import { api } from "service";
import { useNavigate, useParams } from "react-router-dom";
import { useCategory, useProduct } from "hooks";

// img
import IconCalendar from "resources/image/icon/icon_calendar_white.svg";
import IconList from "resources/image/icon/icon_list_white.svg";

function ProgramCategoryPage() {
  const navigate = useNavigate();

  const params = useParams();
  const categoryInfo = useCategory({
    bannerOption: {
      id : params?.id ? params.id : 0,
    },
  });
  const productInfo = useProduct({
    listOption: {
      page: 1,
      category_info_id: params?.id ? params.id : 0,
      recordSize: 60,
      pageSize: 1,
    },
  });

  const buttonList = [
    {
      icon: IconCalendar,
      text: "빠른예약 하기",
      url: "/quick",
    },
    {
      icon: IconList,
      text: "일자별 예약 하기",
      url: "/date",
      type: "green",
    },
  ];

  return (
    <div className="wrap">
      <common.Header />

      <Styles.Container>
        <layout.CategoryNavigation
          mainHomeText={"프로그램 홈"}
          url={"/program"}
        />
        {
          categoryInfo?.bannerListQuery?.data?.data && 
          <section className="mainBannerSection">
            <layout.MainBanner 
              data={categoryInfo?.bannerListQuery?.data?.data}
              type={2}
            />
          </section>
        }

        <section className="programSection">
          <div className="inner">
            <ul className="buttonWrap">
              {buttonList.map((item, index) => {
                return (
                  <li className="buttonList" key={"buttonList" + index}>
                    <ui.button.BasicButton
                      icon={item.icon}
                      size={"small"}
                      buttonText={item.text}
                      buttonType={item.type}
                      onClick={() => {
                        navigate("/reservation" + item.url);
                      }}
                    />
                  </li>
                );
              })}
            </ul>

            <ul className="program">
              {productInfo.listQuery?.data?.list?.map((item, index) => {
                return (
                  <li className="programList" key={"programList" + index}>
                    <layout.ContentsList
                      data={item}
                      big
                      url={"/program/detail/" + item.id}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </section>
      </Styles.Container>

      <common.QuickMenu />
      <common.NavigationBar />
    </div>
  );
}

export default ProgramCategoryPage;
