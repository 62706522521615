import Styles from "./styles";
import { common } from "components";
import { useAuth, useModals } from "hooks";
import { useEffect, useState } from "react";
import utils from "utils";

// img
import Logo from "resources/image/icon/logo.svg";
import IconGoogle from "resources/image/icon/icon_google.svg";
import IconKakao from "resources/image/icon/icon_kakao.svg";
import IconNaver from "resources/image/icon/icon_naver.svg";
import IconApple from "resources/image/icon/icon_apple.svg";

function LoginPage() {
  const authInfo = useAuth({});
  const modalOption = useModals({});
  const [pageInfo, setPageInfo] = useState();
  const pageUrl = async (loginType) => {
    const result = await authInfo.socialLogin(loginType);
    setPageInfo(result?.data);
  };
  const [anotherWay, setAnotherWay] = useState(false);

  useEffect(() => {
    pageInfo && window.location.replace(pageInfo);
  }, [pageInfo]);

  const buttonList = [
    {
      icon: IconKakao,
      onClick: () => {
        pageUrl("KAKAO");
      },
      text: "kakao",
    },
    {
      icon: IconNaver,
      onClick: () => {
        pageUrl("NAVER");
      },
      text: "naver",
    },
    {
      icon: IconApple,
      onClick: () => {
        pageUrl("APPLE");
      },
      text: "apple",
    },
    {
      icon: IconGoogle,
      onClick: async () => {
        if(checkValidateBrowser() == false){
          return;
        }
        let result = await authInfo.socialLogin("GOOGLE");
        utils.etc.callNative("GOOGLELOGIN", result.data);
      },
      text: "google",
    },
  ];

  function checkValidateBrowser() {
    if(navigator.userAgent.indexOf("KAKAOTALK") != -1 || navigator.userAgent.indexOf("NAVER")  != -1 || navigator.userAgent.indexOf("Instagram")  != -1 || navigator.userAgent.indexOf("Facebook") != -1){
      modalOption.setGuideModal({
        show : true
      });
      return false;
    }
    return true;
  }

  return (
    <div className="wrap">
      <common.SubHeader pageName={"로그인/회원가입"} />

      <Styles.Container>
        <div className="inner">
          <h2>
            <b>간편로그인</b> 후 <br /> 오뉴를 이용해 보세요.
          </h2>

          <div className="logo">
            <img src={Logo} alt="logo" />
          </div>

          {anotherWay ? (
            <ul>
              {buttonList.map((item, index) => {
                return (
                  <li className="buttonList" key={"buttonList" + index}>
                    <button
                      type="button"
                      className={item.text}
                      onClick={item.onClick}
                    >
                      <span className="icon">
                        <img src={item.icon} alt="loginIcon" />
                      </span>

                      <span className="text">{item.text}로 시작하기</span>
                    </button>
                  </li>
                );
              })}
            </ul>
          ) : (
            <ul>
              <li className="buttonList">
                <button
                  type="button"
                  className="kakao"
                  onClick={() => {
                    pageUrl("KAKAO");
                  }}
                >
                  <span className="icon">
                    <img src={IconKakao} alt="loginIcon" />
                  </span>

                  <span className="text">Kakao로 시작하기</span>
                </button>
              </li>

              <li className="buttonList">
                <button
                  type="button"
                  className="anotherButton"
                  onClick={() => {
                    setAnotherWay(true);
                  }}
                >
                  <span className="text">다른 방법으로 로그인</span>
                </button>
              </li>
            </ul>
          )}
        </div>
      </Styles.Container>
    </div>
  );
}

export default LoginPage;
