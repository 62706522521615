import utils from "utils";
import Styles from "./styles";
import { api } from "service";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper";

function SubBanner({ data }) {
  const banner = data ? data : api.dummy.subBannerList;

  return (
      <Styles.Container>
        <Swiper
          className="subBannerSlider"
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          slidesPerView={"auto"}
          centeredSlides={true}
          spaceBetween={10}
          modules={[Autoplay]}
        >
          {banner?.map((item, index) => {
            return (
              <SwiperSlide
                className="subBannerList"
                key={"subBannerList" + index}
              >
                <div
                  onClick={async () => {
                    await api.banner.clickBanner(item.id,item.type);
                    utils.etc.openUrl(item?.url) 
                  }}
                >
                  <img src={item.path ? item.path : item} alt="subBannerImg" />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Styles.Container>
  );
}

export default SubBanner;
