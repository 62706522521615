import { useEffect } from "react";
import Styles from "./styles";

// img
import IconClose from "resources/image/icon/icon_close.svg";
import utils from "utils";

function EventModal({ modalOption, modalClose }) {


  const updateSevenCookie = (day) => {
    var now = new Date();
    var time = now.getTime();
    var expireTime = time + 1000*60*60*24*day;
    now.setTime(expireTime);

    document.cookie = 'mdeivce=true; expires=' + now.toUTCString()+ '; path=/;';
  }

  useEffect(()=>{
    setTimeout(()=>{
      let isMobileDevice = utils.etc.getCookie('mdeivce');
      if(isMobileDevice){
        modalClose();
      }
    },250)
  },[])

  
  let isMobileDevice = utils.etc.getCookie('mdeivce');
  if(isMobileDevice)return null


  return (
    <Styles.Container>
      <div className="modalContainer">
        <button
          type="button"
          className="closeButton"
          onClick={() => {
            modalClose();
          }}
        >
          <img src={IconClose} alt="IconClose" />
        </button>

        <div className="modalInner">
          <div className="editor ck-content" dangerouslySetInnerHTML={{ __html: modalOption.description }} ></div>
        </div>

        <button
          type="button"
          className="todayButton"
          onClick={() => {
            updateSevenCookie(1);
            modalClose();
          }}
        >
          오늘 하루 그만보기
        </button>
      </div>
    </Styles.Container>
  );
}

export default EventModal;
