import styled from "styled-components";

const Container = styled.div`
  padding-top: 20px;

  & .pagination {
    margin-top: 40px;
  }

  & .emptyText {
    padding: 180px 0 105px;
    color: var(--gray01);
    text-align: center;
  }

  @media (max-width: 520px) {
    padding-top: calc(100vw * (20 / 500));

    & .pagination {
      margin-top: calc(100vw * (40 / 500));
    }

    & .emptyText {
      padding: calc(100vw * (180 / 500)) 0 calc(100vw * (105 / 500));
    }
  }
`;

export { Container };

export default { Container };
