import Styles from "./styles";
import { useBanner } from "hooks";
import { useProduct } from "hooks";
import { useNavigate } from "react-router-dom";
import { common, layout, ui } from "components";

// img
import IconCalendar from "resources/image/icon/icon_calendar_white.svg";
import IconList from "resources/image/icon/icon_list_white.svg";

function ProgramPage() {
  const bannerInfo = useBanner({ type: 3 });
  const navigate = useNavigate();

  const productInfo = useProduct({
    groupListOption: {
      page: 1,
      recordSize: 20,
      pageSize: 1,
    },
  });

  const banner = bannerInfo.getBanner?.data?.data;

  const buttonList = [
    // {
    //   icon: IconCalendar,
    //   text: "시간권 보기",
    //   url: "시간권",
    // },
    // {
    //   icon: IconCalendar,
    //   text: "모임 보기",
    //   url: "모임",
    // },
    {
      icon: IconList,
      text: "정기권 보기",
      url: "정기권",
      type: "green",
    },

    {
      icon: IconList,
      text: "원데이 보기",
      url: "원데이",
    },
  ];

  return (
    <div className="wrap">
      <common.Header />

      <Styles.Container>
        <layout.Menu />

        <section className="mainBannerSection">
          <layout.MainBanner data={banner} />
        </section>

        <section className="programSection">
          <ul className="buttonWrap inner">
            {buttonList.map((item, index) => {
              return (
                <li className="buttonList" key={"buttonList" + index}>
                  <ui.button.BasicButton
                    icon={item.icon}
                    size={"small"}
                    buttonText={item.text}
                    buttonType={item.type}
                    onClick={() => {
                      navigate("/search/detail/program?keyword=" + item.url);
                    }}
                  />
                </li>
              );
            })}
          </ul>

          {productInfo.groupListQuery.data?.data?.map((item, index) => {
            return (
              <div
                className="programContainer"
                key={"programContainer" + index}
              >
                <layout.SectionTitle
                  title={
                    <>
                      {item.subtitle}{" "}
                      {/* <span
                        className="categoryName"
                        onClick={() => {
                          navigate(`/program/category/${item.id}`);
                        }}
                      >
                        {item.title}
                      </span> */}
                    </>
                  }
                  url={`/program/category/${item.id}`}
                />

                <layout.ContentsSlider
                  slider={"program"}
                  data={item?.product_list}
                  urlCallback={(e) => {
                    navigate("/program/detail/" + e.id);
                  }}
                  url={`/program/category/${item.id}`}
                />
              </div>
            );
          })}
        </section>
      </Styles.Container>

      <common.QuickMenu />
      <common.NavigationBar />
    </div>
  );
}

export default ProgramPage;
