import Styles from "./styles";

// img
import ImgAndroid from "resources/image/img_android.png";
import ImgIos01 from "resources/image/img_ios01.png";
import ImgIos02 from "resources/image/img_ios02.png";
import IconClose from "resources/image/icon/icon_close.svg";

function GuideModal({ modalOption, modalClose }) {
  const imgList = [ImgAndroid, ImgIos01, ImgIos02];

  return (
    <Styles.Container>
      <div className="modalContainer">
        <button
          type="button"
          className="closeButton"
          onClick={() => {
            modalClose();
          }}
        >
          <img src={IconClose} alt="IconClose" />
        </button>

        <p className="title">
          다른 브라우저를 이용해 <br />
          로그인해 주세요.
        </p>

        <p className="text">
          이 브라우저에서는 해당 로그인 수단을 지원하지 않아요. 더보기/공유
          버튼을 통해 다른 브라우저로 링크를 연 후, 로그인을 시도해 주세요.
        </p>

        <ul className="imgListWrap">
          {imgList.map((item, index) => {
            return (
              <li className="imgList" key={"imgList" + index}>
                <img src={item} alt="guideImg" />
              </li>
            );
          })}
        </ul>
      </div>
    </Styles.Container>
  );
}

export default GuideModal;
