import Styles from "./styles";
import { useBoard, useReply } from "hooks";
import { common, layout, ui } from "components";
import { Link, useParams, useLocation } from "react-router-dom";

//img
import ImgDummy from "resources/image/img_dummy_list.png";
import { useEffect, useState } from "react";
import utils from "utils";

function IntroductionDetailPage() {
  const location = useLocation();
  const pathname = location.pathname.split("/")[3];
  const [filter, setFilter] = useState(null);
  const params = useParams();
  const boardInfo = useBoard({
    detailOption: {
      id: params?.id || 1,
    },
  });
  const replyInfo = useReply({
    listOption: filter,
  });
  useEffect(() => {
    let param = utils.etc.getSearchParam();
    setFilter({
      page: param.page || 1,
      recordSize: param.recordSize || 5,
      pageSize: param.pageSize || 5,
      relate_info_id: params?.id.replace(/[^0-9]/gi, ""),
      type: param.type || 1,
    });
  }, [location]);

  const detail = boardInfo.detailQuery.data?.data || {};

  return (
    <div className="wrap">
      <common.SubHeader pageName={detail?.category_title} />

      <Styles.Container>
        <section className="programSection">
          <div className="thumbnail">
            <div className="img">
              <img
                src={detail.image ? detail.image : ImgDummy}
                alt="programImg"
              />
            </div>

            <div className="textWrap">
              <div className="inner">
                <Link
                  to={`/introduction/category/${pathname}`}
                  className="storyName"
                >
                  {detail?.category_title}
                </Link>

                <p className="mainTitle">{detail.title}</p>

                <p className="description">{detail.subtitle}</p>
              </div>
            </div>
          </div>

          <div className="editorWrap">
            <div className="inner">
              <div
                className="editor ck-content"
                dangerouslySetInnerHTML={{ __html: detail.description }}
              ></div>

              <ul className="keyword">
                {detail.keyword?.split(",")?.map((item, index) => {
                  return (
                    <li className="keywordList" key={"keywordList" + index}>
                      <ui.button.Keyword data={item} />
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          <div className="commentWrap">
            <div className="inner">
              <layout.Comment replyInfo={replyInfo} />
            </div>
          </div>
        </section>
      </Styles.Container>

      <common.QuickMenu />
      <common.NavigationBar />
    </div>
  );
}

export default IntroductionDetailPage;
