import { useAuth } from "hooks";
import Styles from "./styles";
import TextareaAutosize from "react-textarea-autosize";

function CommentInput({ value, setValue, onChange, onClick, edit }) {
  const authInfo = useAuth({});

  return (
    <Styles.Container>
      <TextareaAutosize 
        value={value}
        onChange={onChange}
        maxRows={4} 
        placeholder={"댓글을 남겨주세요."}
        onFocus={() => {
          if (authInfo.certification() === false) {
            setValue("")
            document.activeElement.blur();
          }
        }}
      />

      <button type="button" className="submitButton" onClick={onClick}>
        {edit ? "수정" : "등록"}
      </button>
    </Styles.Container>
  );
}

export default CommentInput;
