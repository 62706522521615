import Styles from "./styles";

function BasicTextarea({ data, onChange, disabled }) {
  const { id, labelText, essential, value, name, placeholder, errorText } =
    data;

  return (
    <>
      {labelText && (
        <Styles.Label htmlFor={id}>
          {essential && <span>[필수]</span>} {labelText}
        </Styles.Label>
      )}

      <Styles.Textarea
        className={errorText ? "error" : ""}
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
      ></Styles.Textarea>
    </>
  );
}

export default BasicTextarea;
