import Styles from "./styles";
import { common, layout } from "components";
import { useBanner, useBoard } from "hooks";
import { useNavigate, Link } from "react-router-dom";

function IntroductionPage() {
  const navigate = useNavigate();
  const bannerInfo = useBanner({
    type: 5,
    introduce: true
  });
  const boardInfo = useBoard({
    groupListOption: {
      page: 1,
      recordSize: 5,
      pageSize: 1,
      type: 3,
    },
  });
  
  const banner = bannerInfo.getBanner?.data?.data
  const introduce = bannerInfo.getIntroduceBanner?.data?.data || []

  return (
    <div className="wrap">
      <common.Header />

      <Styles.Container>
        <layout.Menu />

        <section className="mainBannerSection">
          {/* //*1 banner */}
          <layout.MainBanner data={banner} />
        </section>

        <section className="storySection">
          <Link to={"/introduction/category/how-to-use"} className="useLink inner">
            <p dangerouslySetInnerHTML={{ __html: introduce.title }}></p>
            <p dangerouslySetInnerHTML={{ __html: introduce.subtitle }}></p>
          </Link>

          {boardInfo.groupListQuery?.data?.data?.map((item, index) => {
            return (
              <div className="storyContainer" key={"storyContainer" + index}>
                <layout.SectionTitle
                  title={item.title}
                  url={`/introduction/category/${item.id}`}
                />

                {item?.board_list?.length > 0 ? (
                  <layout.ContentsSlider
                    slider={item.id}
                    data={item.board_list}
                    urlCallback={(e) => {
                      navigate(
                        `/introduction/detail/${e.id}`
                      );
                    }}
                  />
                ) : (
                  <p className="emptyText">등록된 스토리가 없습니다.</p>
                )}
              </div>
            );
          })}
        </section>
      </Styles.Container>

      <common.QuickMenu />
      <common.NavigationBar />
    </div>
  );
}

export default IntroductionPage;
