import styled from "styled-components";

const Container = styled.main`
  & .inner {
    min-height: calc(100vh - 60px);
    min-height: calc(100dvh - 60px);
    padding: 60px 0 50px;

    & h2 {
      margin-bottom: 40px;
      font-size: 30px;
      font-weight: bold;
      line-height: 1.4;
    }

    & .title {
      font-size: 22px;
      font-weight: bold;
    }

    & .icon {
      width: 16px;
      height: 16px;
      filter: var(--gray01-filter);
    }

    & .allCheck,
    .essentialCheck {
      margin-bottom: 40px;
      padding-bottom: 40px;
      border-bottom: 1px solid var(--gray02);
    }

    & .essentialCheck {
      & .title {
        margin-bottom: 20px;
      }

      & li {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }

    & .optionalCheck {
      & .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
      }

      & .guideText {
        margin-bottom: 20px;
        font-weight: 600;
        color: var(--gray01);
      }

      & ul {
        display: flex;
        gap: 40px;
      }
    }

    & .nextButton {
      margin-top: 164px;
    }
  }

  @media (max-width: 520px) {
    & .inner {
      min-height: calc(100vh - calc(100vw * (60 / 500)));
      min-height: calc(100dvh - calc(100vw * (60 / 500)));
      padding: calc(100vw * (60 / 500)) 0 calc(100vw * (50 / 500));

      & h2 {
        margin-bottom: calc(100vw * (40 / 500));
        font-size: calc(100vw * (30 / 500));
      }

      & .title {
        font-size: calc(100vw * (22 / 500));
      }

      & .icon {
        width: calc(100vw * (16 / 500));
        height: calc(100vw * (16 / 500));
      }

      & .allCheck,
      .essentialCheck {
        margin-bottom: calc(100vw * (40 / 500));
        padding-bottom: calc(100vw * (40 / 500));
      }

      & .essentialCheck {
        & .title {
          margin-bottom: calc(100vw * (20 / 500));
        }

        & li {
          &:not(:last-child) {
            margin-bottom: calc(100vw * (20 / 500));
          }
        }
      }

      & .optionalCheck {
        & .title {
          margin-bottom: calc(100vw * (10 / 500));
        }

        & .guideText {
          margin-bottom: calc(100vw * (20 / 500));
        }

        & ul {
          gap: calc(100vw * (40 / 500));
        }
      }

      & .nextButton {
        margin-top: calc(100vw * (164 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
