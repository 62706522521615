import Styles from "./styles";
import { common, ui } from "components";
import { useAuth, useModals } from "hooks";
import { useNavigate } from "react-router-dom";

// img
import Logo from "resources/image/icon/logo.svg";
import IconCheck from "resources/image/icon/icon_check_gray.svg";
import ImgAttendance from "resources/image/img_attendance.png";
import { useEffect, useState } from "react";
import { api } from "service";
import utils from "utils";

function AttendancePage() {
  const authInfo = useAuth({});
  const modalOption = useModals();
  const navigate = useNavigate();

  const [attendYn, setAttendYn] = useState(false);

  useEffect(()=>{
    updateAttend();
  },[])

  const updateAttend = async () => {
    let result = await api.point.getAttendInfo();
    setAttendYn(result.success);
  }

  const doAttend = async () => {
    let result = await api.point.doAttend();
    if(result.success){
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.closeButton = false;
        e.icon = IconCheck;
        e.title = (
          <>
            <span className="color">10 포인트</span>가 적립되었습니다.
          </>
        );
        e.text = "내일 또 오뉴에서 만나요!";
        e.confirmButtonText = "확인";
        e.confirmButtonClick = () => {
          navigate("/");
        };
  
        return { ...e };
      });
    }else{
      return;
    }
  }

  return (
    <div className="wrap">
      <common.SubHeader pageName={"출석체크"} />

      <Styles.Container>
        <div className="inner">
          <div className="logo">
            <img src={Logo} alt="logo" />
          </div>

          <p className="title">오뉴에 오신것을 환영합니다!</p>

          <div className="pointWrap">
            <p>
              매일매일 출석체크를 통해 <br />
              <b>10 포인트를 적립해보세요</b>
            </p>

            <div className="img">
              <img src={ImgAttendance} alt="ImgAttendance" />
            </div>
          </div>

          <p className="date">{utils.date.getDateType(new Date())}</p>

          <div className="attendanceButton">
            <ui.button.BasicButton
              buttonText={!authInfo.isLogin || attendYn ? "출석체크 하기" : "출석 완료"}
              onClick={() => {
                if(authInfo.certification()){
                  if(attendYn)
                    doAttend();
                }

                // modalOption.setDefaultModal((e) => {
                //   e.show = true;
                //   e.closeButton = true;
                //   e.icon = false;
                //   e.title = (
                //     <>
                //       오뉴의 <span className="color">멤버</span>가 되어보세요!
                //     </>
                //   );
                //   e.text = "로그인/회원가입 후 이용하실 수 있습니다.";
                //   e.confirmButtonText = "로그인/회원가입";
                //   e.confirmButtonClick = () => {
                //     navigate("/login");
                //     modalOption.clearModals();
                //   };

                //   return { ...e };
                // });

                
              }}
            />
          </div>
        </div>
      </Styles.Container>
    </div>
  );
}

export default AttendancePage;
