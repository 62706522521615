import connector from "service/connector";
import utils from "utils";

const getList = async (data) => {
  return await connector.authConnector(`product?${utils.etc.getFilterStr(data)}`,'GET');
}

const getInitinteList = async (data) => {
  let response =  await connector.authConnector(`product?${utils.etc.getFilterStr(data)}`, "GET");
  let result = {
    list :  response?.list,
    page :  data.page,
    totalCount : response?.pagination?.totalRecordCountAll
  }
  return result;
}


const getGroupList = async (data) => {
  return await connector.authConnector(`product/group_category_list?${utils.etc.getFilterStr(data)}`,'GET');
}

const getDetail = async (id) => {
  return await connector.authConnector(`product/${id}`,'GET');
}

const getTransactionDetail = async (id) => {
  return await connector.authConnector(`product/transaction/${id}`,'GET');
}

const getOptionList = async (data) => {
  return await connector.authConnector(`product/option?${utils.etc.getFilterStr(data)}`,'GET');
}


export {
  getDetail,
  getGroupList,
  getList,
  getTransactionDetail,
  getOptionList,
  getInitinteList,
};


export default {
  getDetail,
  getGroupList,
  getList,
  getTransactionDetail,
  getOptionList,
  getInitinteList,
}