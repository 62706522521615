import utils from "utils";
import Styles from "./styles";
import { ui } from "components";
import { useAuth, usePoint } from "hooks";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function Point() {
  const location = useLocation();
  const navigate = useNavigate();
  const authInfo = useAuth({});

  const [filter, setFilter] = useState()
  const pointInfo = usePoint({ listOption: filter });
  const state = (data) => {
    return utils.date.compareNowDate(new Date(data));
  }

  useEffect(() => {
    let param = utils.etc.getSearchParam();
    let f = {
      page: param.page ? param.page : 1,
      recordSize: param.recordSize ? param.recordSize : 8,
      type: param.type ? param.type : 0,
    };
    if (param.location) {
      f.location = param.location;
    }
    if (param.category_info_id) {
      f.category_info_id = param.category_info_id;
    }
    setFilter({ ...f });
  }, [location]);

  const data = pointInfo.getPointList.data
  const optionList = ["전체", "적립", "사용", "만료"];

  return (
    <Styles.Container>
      <div className="totalPointWrap">
        <div className="inner">
          <span className="text">보유 포인트</span>
          <span className="totalPoint">
            {utils.etc.numberWithCommas(authInfo.userData.total_point)} P
          </span>
        </div>
      </div>

      <div className="inner">
        <div className="select">
          <ui.input.BasicSelect
            optionList={optionList}
            select={
              optionList[filter?.type]
            }
            setSelect={(e) => {
              console.log(e);
              navigate(
                utils.etc.getQueryParamUrl(
                  { type: optionList.indexOf(e), page: 1 },
                  false
                )
              );
            }}
          />
        </div>

        {data?.list?.length > 0 ? (
          <>
            <ul className="pointListWrap">
              {data.list.map((item, index) => {
                return (
                  <li className="pointList" key={"pointList" + index}>
                    <div className="top">
                      <p className="title">{item.title}</p>

                      <p className={"point" + (item.point > 0 ? " on" : "")}>
                        {(item.point > 0 ? "+" : "") + utils.etc.numberWithCommas(item.point)}
                      </p>
                    </div>
                    <div className="bottom">
                      <span className="date">{utils.date.getDateType(item.created_at)}</span>
                      <span className="type">{state(item.expired_at) === -1 ? "만료" : item.point > 0 ? "적립" : "사용"}</span>
                    </div>
                  </li>
                );
              })}
            </ul>

            {data.list.length > 8 && (
              <div className="pagination">
                <ui.Pagination
                  page={data.pagination?.page}
                  list={data.pagination?.page_list}
                  maxPage={data.pagination?.totalPageCount}
                />
              </div>
            )}
          </>
        ) : (
          <p className="emptyText">포인트 적립 내역이 없습니다.</p>
        )}
      </div>
    </Styles.Container>
  );
}

export default Point;
