import styled from "styled-components";

// img
import IconArrowRight from "resources/image/icon/icon_arrow_right.svg";

const Container = styled.main`
  position: relative;
  min-height: calc(100vh - 672px);
  min-height: calc(100dvh - 672px);
  padding: 40px 0 100px;

  & .logo {
    width: 111px;
    height: 32px;
    margin: 0 auto 40px;
  }

  & .locaitonList {
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    & a {
      display: flex;
      align-items: center;
      height: 110px;
      padding: 0 20px;
      border-radius: 10px;
      background: url(${IconArrowRight}) no-repeat calc(100% - 20px) center /
        20px var(--gray03);
    }

    & .icon {
      flex-shrink: 0;
      width: 34px;
      height: 34px;
      margin-right: 30px;
      filter: var(--main-filter);
    }

    & .text {
      width: 290px;

      & .location {
        margin-bottom: 5px;
        font-size: 22px;
        font-weight: bold;
      }

      & .address {
        font-size: 16px;
        font-weight: 400;
        color: var(--gray01);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  @media (max-width: 520px) {
    min-height: calc(100vh - calc(100vw * (672 / 500)));
    min-height: calc(100dvh - calc(100vw * (672 / 500)));
    padding-bottom: calc(100vw * (40 / 500)) 0 calc(100vw * (100 / 500));

    & .logo {
      width: calc(100vw * (111 / 500));
      height: calc(100vw * (32 / 500));
      margin: 0 auto calc(100vw * (40 / 500));
    }

    & .locaitonList {
      &:not(:last-child) {
        margin-bottom: calc(100vw * (20 / 500));
      }

      & a {
        height: calc(100vw * (110 / 500));
        padding: 0 calc(100vw * (20 / 500));
        border-radius: calc(100vw * (10 / 500));
        background: url(${IconArrowRight}) no-repeat
          calc(100% - calc(100vw * (20 / 500))) center /
          calc(100vw * (20 / 500)) var(--gray03);
      }

      & .icon {
        width: calc(100vw * (34 / 500));
        height: calc(100vw * (34 / 500));
        margin-right: calc(100vw * (30 / 500));
      }

      & .text {
        width: calc(100vw * (290 / 500));

        & .location {
          margin-bottom: calc(100vw * (5 / 500));
          font-size: calc(100vw * (22 / 500));
        }

        & .address {
          font-size: calc(100vw * (16 / 500));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
