import styled from "styled-components";

const Container = styled.div`
  & .title {
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: bold;
  }

  & .timeList {
    display: flex;
    align-items: center;
    padding: 13px 0;
    font-weight: 600;

    & .number,
    & .optionTime {
      font-size: 18px;
    }

    & .number {
      width: 20px;
      margin-right: 10px;
      color: var(--gray02);
    }

    & .optionTitle {
      max-width: 310px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    & .optionTime {
      margin-left: auto;
      color: var(--gray01);
    }
  }

  @media (max-width: 520px) {
    & .title {
      margin-bottom: calc(100vw * (20 / 500));
      font-size: calc(100vw * (22 / 500));
    }

    & .timeList {
      padding: calc(100vw * (13 / 500)) 0;

      & .number,
      & .optionTime {
        font-size: calc(100vw * (18 / 500));
      }

      & .number {
        width: calc(100vw * (20 / 500));
        margin-right: calc(100vw * (10 / 500));
      }

      & .optionTitle {
        max-width: calc(100vw * (310 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
