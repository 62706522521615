import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  & p {
    font-size: 30px;
    font-weight: bold;

    & span {
      color: var(--main);
    }
  }

  & a {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 18px;
    color: var(--main);

    & .icon {
      width: 12px;
      height: 12px;
      filter: var(--main-filter);
    }
  }

  @media (max-width: 520px) {
    margin-bottom: calc(100vw * (24 / 500));

    & p {
      font-size: calc(100vw * (30 / 500));
    }

    & a {
      gap: calc(100vw * (6 / 500));
      font-size: calc(100vw * (18 / 500));

      & .icon {
        width: calc(100vw * (12 / 500));
        height: calc(100vw * (12 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
