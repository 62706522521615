import Styles from "./styles";

// img
import IconLiketOn from "resources/image/icon/icon_like_on.svg";
import IconLiketOff from "resources/image/icon/icon_like_off.svg";

export default function LikeButton({ like, likeNumber, likeInfo }) {
  return (
    <Styles.Container
      onClick={() => {
        likeInfo.page.toggleLike(likeInfo.id, likeInfo.type);
      }}
    >
      <div className="likeIcon">
        <img src={like ? IconLiketOn : IconLiketOff} alt="IconLike" />
      </div>
      {likeNumber}
    </Styles.Container>
  );
}
