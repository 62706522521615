import styled from "styled-components";

const Container = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  background-color: #fff;
  border-bottom: 1px solid var(--gray02);
  z-index: 5;

  & .inner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;

    & .prevButton {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
    }
  }

  @media (max-width: 520px) {
    & .inner {
      height: calc(100vw * (60 / 500));

      & .prevButton {
        width: calc(100vw * (20 / 500));
        height: calc(100vw * (20 / 500));
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
