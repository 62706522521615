import styled from "styled-components";

const Container = styled.main`
  position: relative;
  min-height: calc(100vh - 672px);
  min-height: calc(100dvh - 672px);
  padding: 40px 0 100px;

  & .titleWrap {
    padding-bottom: 20px;
    margin-bottom: 40px;
    border-bottom: 1px solid var(--gray02);

    & .date {
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 400;
      color: var(--gray01);
    }
  }

  & .editor {
    word-break: break-all;
  }

  @media (max-width: 520px) {
    min-height: calc(100vh - calc(100vw * (672 / 500)));
    min-height: calc(100dvh - calc(100vw * (672 / 500)));
    padding: calc(100vw * (40 / 500)) 0 calc(100vw * (100 / 500));

    & .titleWrap {
      padding-bottom: calc(100vw * (20 / 500));
      margin-bottom: calc(100vw * (40 / 500));

      & .date {
        margin-bottom: calc(100vw * (10 / 500));
        font-size: calc(100vw * (18 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
