import { api } from "service";

export default function useUpload() {

  const uploadImage = async (file) => {
    const postData = new FormData();
    postData.append("file", file)
    return await api.upload.postImage(postData);
  }

  const uploadReviewImageList = async (fileList) => {
    const postData = new FormData();
    for(let i =0;i<fileList.length;i++){
      postData.append("files", fileList[i]);
    }
    return await api.upload.postReviewImage(postData);
  }

  return {
    uploadImage,
    uploadReviewImageList,
  };
}
