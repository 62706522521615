import { useEffect } from "react";
import Styles from "./styles";

// img
import IconWarning from "resources/image/icon/icon_warning02.svg";

function ToastModal({ modalOption, modalClose }) {
  useEffect(() => {
    setTimeout(() => {
      modalClose();
    }, 2000);
  }, []);

  return (
    <Styles.Container>
      <div className="icon">
        <img src={IconWarning} alt="IconWarning" />
      </div>

      {modalOption.text}
    </Styles.Container>
  );
}

export { ToastModal };

export default ToastModal;
