import styled from "styled-components";

const Container = styled.div`
  width: 200px;

  &.big {
    width: 222px;

    & .content {
      & .imgWrap {
        height: 277px;
      }
    }
  }

  & .closed {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    font-size: 22px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    background-color: rgba(24, 26, 42, 0.7);
  }

  & .imgWrap {
    position: relative;
    width: 100%;
    height: 250px;
    margin-bottom: 14px;
    border-radius: 5px;
    overflow: hidden;

    & .img {
      height: 100%;
    }
  }

  & .title {
    display: -webkit-box;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.54;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  & ul {
    display: flex;
    gap: 8px;
    margin-top: 14px;
  }

  @media (max-width: 520px) {
    width: calc(100vw * (200 / 500));

    & .content {
      /* height: calc(100vw * (375 / 500)); */
    }

    &.big {
      width: calc(100vw * (222 / 500));

      & .content {
        /* height: calc(100vw * (402 / 500)); */

        & .imgWrap {
          height: calc(100vw * (277 / 500));
        }
      }
    }

    & .closed {
      padding: calc(100vw * (10 / 500));
      font-size: calc(100vw * (22 / 500));
    }

    & .imgWrap {
      height: calc(100vw * (250 / 500));
      margin-bottom: calc(100vw * (14 / 500));
      border-radius: calc(100vw * (5 / 500));
    }

    & .title {
      margin-bottom: calc(100vw * (14 / 500));
      font-size: calc(100vw * (22 / 500));
    }

    & ul {
      gap: calc(100vw * (8 / 500));
      margin-top: calc(100vw * (14 / 500));
    }
  }
`;

export { Container };

export default { Container };
