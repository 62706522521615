import Styles from "./styles";
import utils from "utils";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper";
import { api } from "service";

function MainBanner({ data, type }) {

  return (
    <Styles.Container>
      <Swiper
        pagination={{
          type: "fraction",
          formatFractionCurrent: function (number) {
            return number < 10 ? "0" + number : number;
          },
          formatFractionTotal: function (number) {
            return number < 10 ? "0" + number : number;
          },
        }}
        autoplay={{
          delay: 4000,
          speed: 500,
          disableOnInteraction: false,
        }}
        loop={true}
        modules={[Pagination, Autoplay]}
        className="mainBannerSlider"
      >
        {data?.map((item, index) => {
          return (
            <SwiperSlide
              className="mainBannerSlideList"
              key={"mainBannerSlideList" + index}
            >
              <div
                onClick={async () => {
                  await api.banner.clickBanner(item.id, type);
                  utils.etc.openUrl(item?.url);
                }}
              >
                {/* 새창으로 */}
                {/* //* 1 banner */}
                {/* <img src={item} alt="mainBanner" /> */}
                <img src={item.path} alt="mainBanner" />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Styles.Container>
  );
}

export default MainBanner;
