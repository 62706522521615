import styled from "styled-components";

const Container = styled.main`
  position: relative;
  min-height: calc(100vh - 157px);
  min-height: calc(100dvh - 157px);
  padding-bottom: 60px;

  & .thumbnail {
    position: relative;

    & .img {
      height: 625px;
    }

    & .textWrap {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;
      height: 312px;
      padding: 40px 0;
      color: #fff;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 135.68%);

      & .storyName {
        display: inline-block;
        font-size: 22px;
        font-weight: 600;
        color: #00ffac;
      }

      & .mainTitle {
        margin: 12px 0 15px;
        font-size: 32px;
        font-weight: 600;
        line-height: 1.37;
      }

      & .description {
        font-size: 18px;
        font-weight: 400;
        line-height: 1.66;
      }
    }
  }

  & .editorWrap {
    padding: 40px 0;
    word-break: break-all;
    border-bottom: 10px solid var(--gray03);

    & .keyword {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      margin-top: 40px;
      padding-top: 24px;
      border-top: 1px solid var(--gray02);
    }
  }

  & .commentWrap {
    padding: 40px 0;
  }

  @media (max-width: 520px) {
    min-height: calc(100vh - calc(100vw * (157 / 500)));
    min-height: calc(100dvh - calc(100vw * (157 / 500)));
    padding-bottom: calc(100vw * (60 / 500));

    & .thumbnail {
      & .img {
        height: calc(100vw * (625 / 500));
      }

      & .textWrap {
        height: calc(100vw * (312 / 500));
        padding: calc(100vw * (40 / 500)) 0;

        & .storyName {
          font-size: calc(100vw * (22 / 500));
        }

        & .mainTitle {
          margin: calc(100vw * (12 / 500)) 0 calc(100vw * (15 / 500));
          font-size: calc(100vw * (32 / 500));
        }

        & .description {
          font-size: calc(100vw * (18 / 500));
        }
      }
    }

    & .editorWrap {
      padding: calc(100vw * (40 / 500)) 0;
      border-bottom: calc(100vw * (10 / 500)) solid var(--gray03);

      & .keyword {
        gap: calc(100vw * (12 / 500));
        margin-top: calc(100vw * (40 / 500));
        padding-top: calc(100vw * (24 / 500));
      }
    }

    & .commentWrap {
      padding: calc(100vw * (40 / 500)) 0;
    }
  }
`;

export { Container };

export default { Container };
