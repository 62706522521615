import styled from "styled-components";

const Container = styled.main`
  position: relative;
  min-height: calc(100vh - 61px);
  min-height: calc(100dvh - 61px);
  padding: 60px 0 50px;
  text-align: center;

  & .logo {
    width: 111px;
    height: 32px;
    margin: 0 auto 30px;
  }

  & h2 {
    margin-bottom: 134px;
    font-size: 30px;
    font-weight: 600;
  }

  & .img {
    height: 385px;
    margin-bottom: 120px;
  }

  & .text {
    margin-bottom: 30px;
    letter-spacing: -0.03em;

    & p:first-child {
      font-weight: 400;
      color: var(--gray01);
      line-height: 2;
    }

    & p:last-child {
      font-size: 30px;
      font-weight: 800;
      color: var(--main);
    }
  }

  @media (max-width: 520px) {
    min-height: calc(100vh - calc(100vw * (61 / 500)));
    min-height: calc(100dvh - calc(100vw * (61 / 500)));
    padding: calc(100vw * (60 / 500)) 0 calc(100vw * (50 / 500));

    & .logo {
      width: calc(100vw * (111 / 500));
      height: calc(100vw * (32 / 500));
      margin: 0 auto calc(100vw * (30 / 500));
    }

    & h2 {
      margin-bottom: calc(100vw * (134 / 500));
      font-size: calc(100vw * (30 / 500));
    }

    & .img {
      height: calc(100vw * (385 / 500));
      margin-bottom: calc(100vw * (120 / 500));
    }

    & .text {
      margin-bottom: calc(100vw * (30 / 500));

      & p:last-child {
        font-size: calc(100vw * (30 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
