import styled from "styled-components";

const Container = styled.div`
  & .total {
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: bold;

    & span {
      color: var(--main);
    }
  }

  & .commentInputContainer {
    margin-bottom: 20px;
  }

  & .pagination {
    margin-top: 40px;
  }

  & .emptyText {
    padding: 60px 0 40px;
    font-weight: 400;
    color: var(--gray01);
    text-align: center;
    line-height: 1.4;
  }

  @media (max-width: 499px) {
    & .total {
      margin-bottom: calc(100vw * (18 / 500));
      font-size: calc(100vw * (22 / 500));
    }

    & .commentInputContainer {
      margin-bottom: calc(100vw * (20 / 500));
    }

    & .pagination {
      margin-top: calc(100vw * (40 / 500));
    }

    & .emptyText {
      padding: calc(100vw * (60 / 500)) 0 calc(100vw * (40 / 500));
    }
  }
`;

export { Container };

export default { Container };
