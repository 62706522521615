import styled from "styled-components";

const Container = styled.main`
  position: relative;
  padding-bottom: 50px;

  & section {
    padding: 40px 0;
    border-bottom: 10px solid var(--gray03);
  }

  & .reasonSection {
    & h2 {
      margin-bottom: 25px;
      font-size: 30px;
      font-weight: bold;
    }

    & .reasonList {
      padding: 15px 0;
      border-bottom: 1px solid var(--gray03);
    }

    & .textarea {
      margin-top: 15px;

      & textarea {
        height: 170px;
      }
    }
  }

  & .guideSection {
    & .title {
      margin-bottom: 20px;
      font-size: 22px;
      font-weight: bold;
    }

    & .guideList {
      position: relative;
      padding-left: 12px;
      font-size: 18px;
      font-weight: 400;
      line-height: 1.55;
      word-break: keep-all;

      &::before {
        position: absolute;
        left: 0;
        content: "-";
      }

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  & .check {
    width: fit-content;
    margin: 40px auto;
  }

  @media (max-width: 520px) {
    padding-bottom: calc(100vw * (50 / 500));

    & section {
      padding: calc(100vw * (40 / 500)) 0;
      border-bottom: calc(100vw * (10 / 500)) solid var(--gray03);
    }

    & .reasonSection {
      & h2 {
        margin-bottom: calc(100vw * (25 / 500));
        font-size: calc(100vw * (30 / 500));
      }

      & .reasonList {
        padding: calc(100vw * (15 / 500)) 0;
      }

      & textarea {
        height: calc(100vw * (170 / 500));
      }
    }

    & .guideSection {
      & .title {
        margin-bottom: calc(100vw * (20 / 500));
        font-size: calc(100vw * (22 / 500));
      }

      & .guideList {
        padding-left: calc(100vw * (12 / 500));
        font-size: calc(100vw * (18 / 500));

        &:not(:last-child) {
          margin-bottom: calc(100vw * (20 / 500));
        }
      }
    }

    & .check {
      margin: calc(100vw * (40 / 500)) auto;
    }
  }
`;

export { Container };

export default { Container };
