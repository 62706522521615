import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 100vh;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10;

  & .modalContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 420px;
    padding: 70px 0 30px;
    text-align: center;
    background-color: #fff;
    border-radius: 10px;

    & .closeButton {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 34px;
      height: 34px;
    }

    & .icon {
      width: 82px;
      height: 82px;
      margin-bottom: 20px;
      filter: var(--gray02-filter);
    }

    & .title {
      font-size: 30px;
      font-weight: bold;
    }

    & .text {
      margin-top: 14px;
      font-size: 20px;
      color: var(--gray01);
    }

    & .color {
      color: var(--main);
    }

    & .button {
      width: 340px;
      margin-top: 60px;
    }
  }

  @media (max-width: 520px) {
    width: 100%;

    & .modalContainer {
      width: calc(100vw * (420 / 500));
      padding: calc(100vw * (70 / 500)) 0 calc(100vw * (30 / 500));
      border-radius: calc(100vw * (10 / 500));

      & .closeButton {
        top: calc(100vw * (20 / 500));
        right: calc(100vw * (20 / 500));
        width: calc(100vw * (34 / 500));
        height: calc(100vw * (34 / 500));
      }

      & .icon {
        width: calc(100vw * (82 / 500));
        height: calc(100vw * (82 / 500));
        margin-bottom: calc(100vw * (20 / 500));
      }

      & .title {
        font-size: calc(100vw * (30 / 500));
      }

      & .text {
        margin-top: calc(100vw * (14 / 500));
        font-size: calc(100vw * (20 / 500));
      }

      & .button {
        width: calc(100vw * (340 / 500));
        margin-top: calc(100vw * (60 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
