import styled from "styled-components";

const Container = styled.main`
  min-height: calc(100vh - 61px);
  min-height: calc(100dvh - 61px);
  padding-bottom: 100px;

  & .buttonContainer {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 500px;
    padding: 12px 0;
    border-top: 1px solid var(--gray02);
    background-color: #fff;
    z-index: 3;

    & .inner {
      display: flex;
      gap: 10px;

      & .buttonWrap {
        flex: 1;
      }
    }
  }

  @media (max-width: 520px) {
    min-height: calc(100vh - calc(100vw * (61 / 500)));
    min-height: calc(100dvh - calc(100vw * (61 / 500)));
    padding-bottom: calc(100vw * (100 / 500));

    & .buttonContainer {
      width: 100%;
      padding: calc(100vw * (12 / 500)) 0;

      & .inner {
        gap: calc(100vw * (10 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
