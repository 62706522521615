import styled from "styled-components";

const Container = styled.main`
  min-height: 100vh;
  min-height: 100dvh;
  padding-bottom: 100px;

  & .mainBannerSection {
    margin-bottom: 20px;
  }

  & .programSection {
    & .buttonWrap {
      display: flex;
      gap: 12px;
      margin-bottom: 40px;

      & .buttonList {
        flex: 1;
      }
    }

    & .program {
      display: flex;
      flex-wrap: wrap;
      gap: 40px 16px;
    }
  }

  @media (max-width: 520px) {
    padding-bottom: calc(100vw * (100 / 500));

    & .mainBannerSection {
      margin-bottom: calc(100vw * (20 / 500));
    }

    & .programSection {
      & .buttonWrap {
        gap: calc(100vw * (12 / 500));
        margin-bottom: calc(100vw * (40 / 500));
      }

      & .program {
        gap: calc(100vw * (40 / 500)) calc(100vw * (16 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
