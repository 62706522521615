import styled from "styled-components";

const Container = styled.footer`
  background-color: var(--gray03);

  & .top {
    border-bottom: 1px solid var(--gray02);

    & .inner,
    & .inner ul,
    & .inner a {
      display: flex;
      align-items: center;
    }

    & .inner {
      height: 64px;
      justify-content: space-between;

      & .linkWrap {
        gap: 30px;
      }

      & a {
        gap: 5px;
        font-size: 18px;
        font-weight: 600;

        & .icon {
          width: 12px;
          height: 12px;
          filter: var(--black-filter);
        }
      }

      & .snsWrap {
        gap: 20px;

        & button {
          display: block;
        }
      }
    }
  }

  & .bottom {
    padding: 27px 0;

    & .logo {
      width: 111px;
      height: 32px;
      margin-bottom: 30px;
    }

    & ul,
    & .copyright {
      font-weight: 400;
      color: var(--gray01);
    }

    & ul {
      margin-bottom: 30px;

      & li:not(:last-child) {
        margin-bottom: 10px;
      }

      & li a,
      & li button {
        display: inline-block;
        font-size: 20px;
        font-weight: 500;
        color: var(--main);
        text-decoration: underline;
        text-underline-offset: 2px;
      }
    }
  }

  @media (max-width: 520px) {
    & .top {
      & .inner {
        height: calc(100vw * (64 / 500));

        & .linkWrap {
          gap: calc(100vw * (30 / 500));
        }

        & a {
          gap: calc(100vw * (5 / 500));
          font-size: calc(100vw * (18 / 500));

          & .icon {
            width: calc(100vw * (12 / 500));
            height: calc(100vw * (12 / 500));
          }
        }

        & .snsWrap {
          gap: calc(100vw * (20 / 500));
        }
      }
    }

    & .bottom {
      padding: calc(100vw * (27 / 500)) 0;

      & .logo {
        width: calc(100vw * (111 / 500));
        height: calc(100vw * (32 / 500));
        margin-bottom: calc(100vw * (30 / 500));
      }

      & ul {
        margin-bottom: calc(100vw * (30 / 500));

        & li:not(:last-child) {
          margin-bottom: calc(100vw * (10 / 500));
        }

        & li a,
        & li button {
          font-size: calc(100vw * (20 / 500));
          text-underline-offset: calc(100vw * (2 / 500));
        }
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
