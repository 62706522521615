import Styles from "./styles";
import { ui } from "components";

// img
import IconWarning from "resources/image/icon/icon_warning.svg";

function TimeList({ data, onChangeOption, amount }) {
  const subList = data.list?.filter((v) => v.checked)[0]?.option_relate_list;

  return (
    <Styles.Container>
      <ul className="firstOption">
        {data.list?.map((item, index) => {
          return (
            <li className="firstOptionList" key={"firstOptionList" + index}>
              <ui.input.FirstOptionCheckbox
                id={`${data.title + item.id}`}
                name={data.title}
                data={item}
                amount={amount}
                checked={item.checked}
                onChange={(e) => {
                  onChangeOption(item, null);
                }}
              />
            </li>
          );
        })}
      </ul>

      {subList?.length > 0 && (
        <>
          <ul className="secondOption">
            {subList?.map((item, index) => {
              return (
                <li
                  className="secondOptionList"
                  key={"secondOptionList" + index}
                >
                  <ui.input.SecondOptionCheckbox
                    id={`${data.title}` + "_secondOption" + index}
                    name={data.title + "_secondOption"}
                    data={item}
                    amount={amount}
                    checked={item.checked}
                    onChange={(e) => {
                      onChangeOption(item, data.list);
                    }}
                  />
                </li>
              );
            })}
          </ul>

          <div className="guideText">
            <div className="icon">
              <img src={IconWarning} alt="IconWarning" />
            </div>
            한 번 더 선택해 주세요, 선택하지 않으셔도 괜찮아요!
          </div>
        </>
      )}
    </Styles.Container>
  );
}

export default TimeList;
