import connector from "service/connector";


const recommend = async (data) => {
  return await connector.connectFetchController("check/recommend?recommend_code=" + data, "GET");
}
const promotion = async (data) => {
  return await connector.connectFetchController("promotion?code=" + data + "&type=1", "GET");
}


export {
  recommend,
  promotion,
};

export default {
  recommend,
  promotion,
}