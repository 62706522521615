import { useAuth } from "hooks";

const AuthRoute = ({ children }) => {
  let authInfo = useAuth({});

  if (authInfo.isLoad === false) {
    if (authInfo.isLogin === true) {
      return children;
    } else {
      window.location.replace("/");
      return <></>;
    }
  }
}

export default AuthRoute