import Styles from "./styles";

function ProgramBanner({ type, title, subtitle }) {
  const renderBanner = (type) => {
    switch (type) {
      case "1":
        return [
          "3시간 동안의 무제한 프로그램 체험!",
          "시간대에 진행되는 프로그램을 다양하게 들을 수 있어요.",
        ];

      case "2":
        return [
          "몰입과 집중을 위한 정규 과정!",
          "한 달, 4회의 커리큘럼으로 탄탄히 과정을 배워볼 수 있어요.",
        ];

      case "3":
        return [
          "가벼운 마음으로 편하게 여가 콘텐츠를!",
          "다양한 스팟성 프로그램과 커뮤니티를 경험해 볼 수 있어요.",
        ];
      default:
        return ["", ""];
    }
  };

  return (
    <Styles.Container
      className={String(type) == "1" || String(type) == "4" ? "time" : ""}
    >
      <p>{title ? title : renderBanner(type)[0]}</p>
      <p>{subtitle ? subtitle : renderBanner(type)[1]}</p>
    </Styles.Container>
  );
}

export default ProgramBanner;
