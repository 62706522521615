import Styles from "./styles";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { common, ui } from "components";
import { useModals, useInquiry, useAuth, useSupport } from "hooks";

// img
import IconWarning from "resources/image/icon/icon_warning.svg";
import IconArrowRight from "resources/image/icon/icon_arrow_right_mini.svg";
import utils from "utils";

function AskPage() {
  const modalOption = useModals();
  const inquiryInfo = useInquiry();
  const authInfo = useAuth({});
  const supportInfo = useSupport({
    options: { policy: true },
  });

  const [select, setSelect] = useState("선택하세요.");
  const [inputs, setInputs] = useState({
    phone: "",
    program_title: "",
    content: "",
  });
  const [check, setCheck] = useState(false);

  useEffect(() => {
    let param = utils.etc.getSearchParam();
    if (param?.type == 2) {
      setSelect("프로그램 문의");
      setInputs((e) => {
        return { ...e, program_title: param.title };
      });
    }
  }, []);

  useEffect(() => {
    authInfo.isLogin &&
      setInputs((e) => {
        return { ...e, phone: authInfo.userData?.phone || "" };
      });
  }, [authInfo.userData]);

  // data
  const optionList = ["광고 문의", "협업 문의", "채용 문의", "프로그램 문의"];
  const inputList = [
    {
      essential: true,
      value: inputs.phone,
      name: "phone",
      type: "phone",
      labelText: "휴대폰",
      placeholder: "숫자만 입력",
    },
    {
      name: "program_title",
      value: inputs.program_title,
      labelText: "프로그램명",
      placeholder: "프로그램명",
    },
    {
      essential: true,
      value: inputs.content,
      name: "content",
      labelText: "문의 내용",
      placeholder: "문의 내용",
    },
  ];

  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs((e) => {
      return { ...e, [name]: value };
    });
  };

  const validate = () => {
    if (select === "선택하세요.") {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "문의 분류를 선택해 주세요.";
        return { ...e };
      });
      return false;
    }
    if (inputs.phone === "") {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "휴대폰 번호를 입력해 주세요.";
        return { ...e };
      });
      return false;
    }
    if (inputs.content === "") {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "문의 내용을 입력해 주세요.";
        return { ...e };
      });
      return false;
    }
    if (!check) {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "필수 약관에 동의해 주세요.";
        return { ...e };
      });
      return false;
    }
    return true;
  };

  const onNext = () => {
    if (validate()) {
      inquiryInfo.postInquiry({
        type: optionList.indexOf(select) + 1,
        ...inputs,
      });
    }
  };

  return (
    <div className="wrap">
      <common.SubHeader pageName={"문의하기"} />

      <Styles.Container>
        <section className="section">
          <div className="inner">
            <ul className="input">
              <li className="inputList">
                <p className="label">
                  <span>[필수]</span> 문의 분류
                </p>
                <ui.input.BasicSelect
                  optionList={optionList}
                  select={select}
                  setSelect={setSelect}
                />
              </li>

              <li className="inputList">
                <ui.input.BasicInput
                  data={{
                    ...inputList[0],
                    value:
                      inputs.phone?.substr(0, 3) +
                      (inputs.phone?.length > 3
                        ? "-" +
                          inputs.phone?.substr(3, 4) +
                          (inputs.phone?.length > 7
                            ? "-" + inputs.phone?.substr(7, 4)
                            : "")
                        : ""),
                  }}
                  setValue={(e) => {
                    setInputs((input) => {
                      return {
                        ...input,
                        [inputList[0].name]: e.target.value
                          .replace(/-/gi, "")
                          .replace(/[^0-9]/gi, "")
                          .slice(0, 11),
                      };
                    });
                  }}
                />
                <p className="guideText">
                  <span className="icon">
                    <img src={IconWarning} alt="IconWarning" />
                  </span>
                  답변 받으실 연락처를 입력해 주세요.
                </p>
              </li>

              <li className="inputList">
                <ui.input.BasicInput
                  data={inputList[1]}
                  setInputs={setInputs}
                />
              </li>

              <li className="inputList">
                <ui.input.BasicTextarea
                  data={inputList[2]}
                  onChange={onChange}
                />
              </li>
            </ul>

            <div className="terms">
              <p className="label">필수 이용 약관</p>
              <div className="check">
                <ui.input.BasicCheckbox
                  id={"check"}
                  labelText={"개인정보수집이용 및 처리 방침에 동의합니다."}
                  checked={check}
                  onChange={(e) => {
                    setCheck(e.target.checked);
                  }}
                />

                {/* //* 퍼블 깨짐 우려 주석. 원본 코드 */}
                {/* <Link to={"/contact/terms"} className="icon">
                  <img src={IconArrowRight} alt="IconArrowRight" />
                </Link> */}

                <button
                  className="icon"
                  type="button"
                  onClick={() => {
                    supportInfo.getModal(2);
                  }}
                >
                  <img src={IconArrowRight} alt="IconArrowRight" />
                </button>
              </div>
            </div>

            <ui.button.BasicButton buttonText={"문의하기"} onClick={onNext} />
          </div>
        </section>
      </Styles.Container>

      <common.QuickMenu />
      <common.NavigationBar />
    </div>
  );
}

export default AskPage;
