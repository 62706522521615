import styled from "styled-components";

const Container = styled.div`
  & .firstOption {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 15px;

    & .firstOptionList {
      width: fit-content;
    }
  }

  & .secondOption {
    margin-bottom: 15px;

    & .secondOptionList:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  & .guideText {
    display: flex;
    align-items: center;
    gap: 7px;
    font-size: 18px;
    color: var(--gray01);

    & .icon {
      width: 28px;
      height: 28px;
      filter: var(--gray01-filter);
    }
  }

  @media (max-width: 520px) {
    & .firstOption {
      gap: calc(100vw * (12 / 500));
      margin-bottom: calc(100vw * (15 / 500));
    }

    & .secondOption {
      margin-bottom: calc(100vw * (15 / 500));

      & .secondOptionList:not(:last-child) {
        margin-bottom: calc(100vw * (15 / 500));
      }
    }

    & .guideText {
      gap: calc(100vw * (7 / 500));
      font-size: calc(100vw * (18 / 500));

      & .icon {
        width: calc(100vw * (28 / 500));
        height: calc(100vw * (28 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
