import utils from "utils";
import Styles from "./styles";
import useOrder from "hooks/useOrder";
import { common, layout, ui } from "components";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDirection } from "hooks";

function MyProgramPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [filter, setFilter] = useState(null);
  const [typeselect, setTypeSelect] = useState("전체");
  const [stateselect, setStateSelect] = useState("전체");
  const [locationselect, setLocationSelect] = useState("전체");

  const directionInfo = useDirection({ locationList: true });

  const orderInfo = useOrder({
    listOption: filter,
  });

  const list = orderInfo?.listQuery?.data?.list || [];

  useEffect(() => {
    let param = utils.etc.getSearchParam();
    let f = {
      page: param.page ? parseInt(param.page) : 1,
      recordSize: param.recordSize ? param.recordSize : 10,
      pageSize: 5,
    };

    if (param.type) {
      f.type = param.type;
      setTypeSelect({
        title:
          optionList[0]?.list?.filter((v) => v.value == param.type)[0]?.title ||
          "전체",
      });
    } else {
      setTypeSelect({ title: "전체" });
    }
    if (param.state) {
      f.state = param.state;
      setStateSelect({
        title:
          optionList[1]?.list?.filter((v) => v.value == param.state)[0]
            ?.title || "전체",
      });
    } else {
      setStateSelect({ title: "전체" });
    }
    if (param.location) {
      f.location = param.location;
      setLocationSelect({
        title:
          optionList[2]?.list?.filter((v) => v.value == param.location)[0]
            ?.title || "전체",
      });
    } else {
      setLocationSelect({ title: "전체" });
    }

    setFilter({ ...f });
  }, [location]);

  // map
  const optionList = [
    {
      select: typeselect,
      setSelect: setTypeSelect,
      list: [
        { key: "type", title: "전체", value: "" },
        { key: "type", title: "시간권", value: "1" },
        { key: "type", title: "정기권", value: "2" },
        { key: "type", title: "원데이", value: "3" },
        { key: "type", title: "모임", value: "4" },
      ],
    },
    {
      select: stateselect,
      setSelect: setStateSelect,
      list: [
        { key: "state", title: "전체", value: "" },
        { key: "state", title: "결제 완료", value: "20" },
        { key: "state", title: "결제 취소", value: "-1" },
        { key: "state", title: "환불 신청", value: "80" },
        { key: "state", title: "환불 완료", value: "83" },
        { key: "state", title: "환불 반려", value: "81" },
      ],
    },
    {
      select: locationselect,
      setSelect: setLocationSelect,
      list: [{ key: "location", title: "전체", value: "" }].concat(
        directionInfo?.getLocation?.data?.data?.map((value) => {
          return {
            key: "location",
            title: value.title,
            value: value.title,
          };
        })
      ),
      // { key : "location", title : "전체", value : "" },
      // { key : "location", title : "지점명", value : "" },
      // { key : "location", title : "지점명", value : "" }
    },
  ];
  return (
    <div className="wrap">
      <common.SubHeader pageName={"프로그램"} />

      <Styles.Container>
        <div className="inner">
          <ul className="select">
            {optionList.map((item, index) => {
              return (
                <li className="selectList" key={"selectList" + index}>
                  <ui.input.BasicSelect
                    optionList={item.list}
                    select={item.select}
                    setSelect={(e) => {
                      navigate(
                        utils.etc.getQueryParamUrl({
                          [e.key]: e.value,
                          page: 1,
                        })
                      );
                    }}
                  />
                </li>
              );
            })}
          </ul>

          {list?.length > 0 ? (
            <>
              <ul className="program">
                {list.map((item, index) => {
                  return (
                    <li
                      className="programList"
                      key={"programList" + index}
                      onClick={() => {
                        navigate("/mypage/payment-history?id=" + item.id);
                      }}
                    >
                      <layout.MyProgram data={item} button />
                    </li>
                  );
                })}
              </ul>

              <div className="pagination">
                <ui.Pagination
                  page={orderInfo?.listQuery?.data?.pagination?.page}
                  list={orderInfo?.listQuery?.data?.pagination?.page_list}
                  maxPage={
                    orderInfo?.listQuery?.data?.pagination?.totalPageCount
                  }
                />
              </div>
            </>
          ) : (
            <p className="emptyText">프로그램 내역이 없습니다.</p>
          )}
        </div>
      </Styles.Container>
    </div>
  );
}
export default MyProgramPage;
