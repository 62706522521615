import styled from "styled-components";

import IconCheckOff from "resources/image/icon/icon_check_off.svg";
import IconCheckOn from "resources/image/icon/icon_check_on.svg";

const Input = styled.input`
  display: none;

  &:checked + label .checkbox {
    background-image: url(${IconCheckOn});
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: 22px;
  cursor: pointer;

  & .checkbox {
    flex-shrink: 0;
    display: inline-block;
    width: 34px;
    height: 34px;
    margin-right: 5px;
    background: url(${IconCheckOff}) no-repeat center / cover;
  }

  @media (max-width: 520px) {
    font-size: calc(100vw * (22 / 500));

    & .checkbox {
      width: calc(100vw * (34 / 500));
      height: calc(100vw * (34 / 500));
      margin-right: calc(100vw * (5 / 500));
    }
  }
`;

export { Input, Label };

export default { Input, Label };
