import styled from "styled-components";

const Container = styled.div`
  & .top,
  & .location,
  & .time,
  & .timeList {
    display: flex;
  }

  & .top {
    align-items: center;
    gap: 8px;

    & .location {
      align-items: center;
      font-weight: 600;
      color: var(--main);

      & .icon {
        width: 30px;
        height: 30px;
      }
    }

    & .state {
      margin-left: auto;
      font-weight: 600;
      color: var(--gray01);
    }
  }

  & .programTitle {
    margin: 20px 0;
    font-size: 30px;
    font-weight: 600;
  }

  & .time {
    flex-wrap: wrap;
    gap: 10px;

    & .timeList {
      align-items: center;
      height: 31px;
      padding: 0 10px;
      font-size: 18px;
      font-weight: 600;
      color: var(--gray01);
      border: 1px solid var(--gray01);
      border-radius: 500px;
    }
  }

  @media (max-width: 520px) {
    & .top {
      gap: calc(100vw * (8 / 500));

      & .location {
        & .icon {
          width: calc(100vw * (30 / 500));
          height: calc(100vw * (30 / 500));
        }
      }
    }

    & .programTitle {
      margin: calc(100vw * (20 / 500)) 0;
      font-size: calc(100vw * (30 / 500));
    }

    & .time {
      gap: calc(100vw * (10 / 500));

      & .timeList {
        height: calc(100vw * (31 / 500));
        padding: 0 calc(100vw * (10 / 500));
        font-size: calc(100vw * (18 / 500));
        border-radius: calc(100vw * (500 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
