import Styles from "./styles";
import { common, layout } from "components";
import { useParams, useLocation } from "react-router-dom";
import { useBoard, useCategory } from "hooks";

function IntroductionStoryPage() {
  const params = useParams();
  const locaion = useLocation();

  const categoryInfo = useCategory({
    bannerOption: {
      id: params?.id ? params.id : 0,
    },
  });
  const boardInfo = useBoard({
    listOption: {
      page: 1,
      category_info_id: params?.id ? params.id : 0,
      recordSize: 10,
      pageSize: 1,
      type: 3,
    },
    introduce: true
  });
  const pathname = locaion.pathname.split("/")[3];

  const introduction = {
    id: "how-to-use",
    title: "이용방법",
  };

  return (
    <div className="wrap">
      <common.Header />

      <Styles.Container>
        <layout.CategoryNavigation
          mainHomeText={"오뉴소개 홈"}
          url={"/introduction"}
          introduction={introduction}
        />
        {pathname === "how-to-use" ? (
          <section className="howToUseSection">
            <div className="inner">
              <div
                className="editor ck-content"
                dangerouslySetInnerHTML={{__html: boardInfo.introduceQuery?.data?.data?.description}}
              ></div>
            </div>
          </section>
        ) : (
          <>
            {categoryInfo?.bannerListQuery?.data?.data && (
              <section className="mainBannerSection">
                <layout.MainBanner
                  data={categoryInfo?.bannerListQuery?.data?.data}
                  type={2}
                />
              </section>
            )}

            <section className="storySection">
              <div className="inner">
                {boardInfo.listQuery?.data?.list?.length > 0 ? (
                  <ul className="story">
                    {boardInfo.listQuery?.data?.list?.map((item, index) => {
                      return (
                        <li className="storyList" key={"storyList" + index}>
                          <layout.ContentsList
                            data={item}
                            big
                            url={`/introduction/detail/${item.id}`}
                          />
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <p className="emptyText">등록된 스토리가 없습니다.</p>
                )}
              </div>
            </section>
          </>
        )}
      </Styles.Container>

      <common.QuickMenu />
      <common.NavigationBar />
    </div>
  );
}

export default IntroductionStoryPage;
