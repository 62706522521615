import Styles from "./styles";
import components from "./components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { common, layout } from "components";

function MyBenefitPage() {
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState("point");

  const tabList = [
    {
      id: "point",
      text: "포인트",
    },
    {
      id: "coupon",
      text: "쿠폰",
    },
  ];

  return (
    <div className="wrap">
      <common.SubHeader
        pageName={"나의 혜택"}
        backEvent={() => { navigate("/mypage") }}
      />

      <Styles.Container>
        <section className="section">
          <div className="tab">
            <layout.Tab
              tabList={tabList}
              tabIndex={tabIndex}
              setTabIndex={setTabIndex}
            />
          </div>

          {tabIndex === "point" && <components.Point />}

          {tabIndex === "coupon" && (
            <p className="couponEmptyText">쿠폰 준비중 입니다.</p>
          )}
        </section>
      </Styles.Container>
    </div>
  );
}

export default MyBenefitPage;
