import { useAtom } from "jotai";
import { modalAtom } from "store/atoms";

export default function useModals() {
  const [defaultModal, setDefaultModal] = useAtom(modalAtom.defaultModalAtom);
  const [toastModal, setToastModal] = useAtom(modalAtom.toastModalAtom);
  const [couponModal, setCouponModal] = useAtom(modalAtom.couponModalAtom);
  const [termsModal, setTermsModal] = useAtom(modalAtom.termsModalAtom);
  const [addressModal, setAddressModal] = useAtom(modalAtom.addressModalAtom);
  const [guideModal, setGuideModal] = useAtom(modalAtom.guideModalAtom);
  const [eventModal, setEventModal] = useAtom(modalAtom.eventModalAtom);

  const clearModals = () => {
    setDefaultModal({ show: false });
    setToastModal({ show: false });
    setCouponModal({ show: false });
    setTermsModal({ show: false });
    setAddressModal({ show: false });
    setGuideModal({ show: false });
    setEventModal({ show: false });
  };

  const clearSubModals = (callback) => {
    setDefaultModal((e) => !e);
    setTimeout(() => {
      callback();
    }, [0]);
  };

  return {
    clearModals,
    defaultModal,
    setDefaultModal,
    toastModal,
    setToastModal,
    couponModal,
    setCouponModal,
    termsModal,
    setTermsModal,
    addressModal,
    setAddressModal,
    guideModal,
    setGuideModal,
    eventModal,
    setEventModal,
  };
}
