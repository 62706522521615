import Styles from "./styles";

// datepicker
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import utils from "utils";

function DateInput({ labelText, name, startDate, setStartDate, readOnly }) {
  return (
    <Styles.Container>
      <label>
        <span>{utils.etc.iOS() ? "[선택]" : "[필수]"}</span> {labelText}
      </label>

      <div className="inputContainer">
        <ReactDatePicker
          dateFormat="yyyy.MM.dd"
          selected={startDate}
          onChange={(date) => {
            setStartDate((e) => {
              return { ...e, [name]: date };
            });
          }}
          placeholderText="yyyy.mm.dd"
          showMonthDropdown
          showYearDropdown
          name={name}
          maxDate={new Date()}
          dropdownMode="select"
          onKeyDown={(e) => {
            // e.preventDefault();
            // console.log(e);
            // return "3";
          }}
        />
      </div>
    </Styles.Container>
  );
}

export default DateInput;
