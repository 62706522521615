import styled from "styled-components";

const Container = styled.main`
  position: relative;
  min-height: calc(100vh - 61px);
  min-height: calc(100dvh - 61px);
  padding: 20px 0 50px;

  & .bannerSection {
    margin-bottom: 40px;

    & .bannerImg {
      border-radius: 10px;
      overflow: hidden;
    }
  }

  & .checkSection {
    & .inputContainer {
      padding-bottom: 30px;
      margin-bottom: 30px;
      border-bottom: 1px solid var(--gray03);

      &:nth-child(4) .checkList {
        width: 100%;
      }
    }

    & .checkSubActive {
      margin-top: 0px;
      padding-bottom: 0px;
      margin-bottom: 0px;
    }

    & .title,
    .guideText,
    & .subTitle {
      font-weight: 600;
    }

    & .title {
      display: flex;
      gap: 6px;
      font-size: 22px;
      line-height: 1.36;
      word-break: keep-all;

      & span {
        flex-shrink: 0;
        color: var(--main);
      }
    }

    & .guideText {
      margin-top: 10px;
      color: var(--gray01);
    }

    & .subTitle {
      margin-top: 20px;
      color: var(--green);
    }

    & .checkListWrap {
      display: flex;
      flex-wrap: wrap;
      gap: 20px 30px;
      margin-top: 20px;
    }

    & .inputWrap {
      margin-top: 15px;
    }

    & .subCheck:not(:last-child) {
      margin-bottom: 30px;
    }

    & .buttonWrap {
      margin-top: 50px;
    }
  }

  @media (max-width: 520px) {
    min-height: calc(100vh - calc(100vw * (61 / 500)));
    min-height: calc(100dvh - calc(100vw * (61 / 500)));
    padding: calc(100vw * (20 / 500)) 0 calc(100vw * (50 / 500));

    & .bannerSection {
      margin-bottom: calc(100vw * (40 / 500));

      & .bannerImg {
        border-radius: calc(100vw * (10 / 500));
      }
    }

    & .checkSection {
      & .inputContainer {
        padding-bottom: calc(100vw * (30 / 500));
        margin-bottom: calc(100vw * (30 / 500));
      }

      & .checkSubActive {
        margin-top: 0px;
        padding-bottom: 0px;
        margin-bottom: 0px;
      }
      
      & .title {
        gap: calc(100vw * (6 / 500));
        font-size: calc(100vw * (22 / 500));
      }

      & .guideText {
        margin-top: calc(100vw * (10 / 500));
      }

      & .subTitle {
        margin-top: calc(100vw * (20 / 500));
      }

      & .checkListWrap {
        gap: calc(100vw * (20 / 500)) calc(100vw * (30 / 500));
        margin-top: calc(100vw * (20 / 500));
      }

      & .inputWrap {
        margin-top: calc(100vw * (15 / 500));
      }

      & .subCheck:not(:last-child) {
        margin-bottom: calc(100vw * (30 / 500));
      }

      & .buttonWrap {
        margin-top: calc(100vw * (50 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
