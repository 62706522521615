import connector from "service/connector";
import utils from "utils";


const getDetail = async (data) => {
  return await connector.authConnector(`transaction?${utils.etc.getFilterStr(data)}`,'GET');
}

const doTransaction = async (data) => {
  return await connector.authConnector(`transaction`,'POST',data);
}

const cancelTransaction = async (data) => {
  return await connector.authConnector(`transaction/cancel`,'POST',data);
}

const refundTransaction = async (data) => {
  return await connector.authConnector(`transaction/refund`,'POST',data);
}

const iamportCallback = async (data) => {
  return await connector.authConnector(`iamport`,'POST',data);
}


export {
  getDetail,
  doTransaction,
  iamportCallback,
  cancelTransaction,
  refundTransaction,
};


export default {
  getDetail,
  doTransaction,
  iamportCallback,
  cancelTransaction,
  refundTransaction,
}