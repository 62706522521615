import styled from "styled-components";

const Container = styled.main`
  min-height: calc(100vh - 166px);
  min-height: calc(100dvh - 166px);
  padding-bottom: 100px;

  & .mainBannerSection {
    margin-bottom: 40px;
  }

  & .storySection {
    & .story {
      display: flex;
      flex-wrap: wrap;
      gap: 40px 16px;
    }

    & .emptyText {
      padding: 160px 0 198px;
      color: var(--gray01);
      text-align: center;
    }
  }

  & .howToUseSection {
    padding-top: 40px;

    & .editor {
      word-break: break-all;
    }
  }

  @media (max-width: 520px) {
    min-height: calc(100vh - calc(100vw * (166 / 500)));
    min-height: calc(100dvh - calc(100vw * (166 / 500)));
    padding-bottom: calc(100vw * (100 / 500));

    & .mainBannerSection {
      margin-bottom: calc(100vw * (40 / 500));
    }

    & .storySection {
      & .story {
        gap: calc(100vw * (40 / 500)) calc(100vw * (16 / 500));
      }

      & .emptyText {
        padding: calc(100vw * (160 / 500)) 0 calc(100vw * (198 / 500));
      }
    }

    & .howToUseSection {
      padding-top: calc(100vw * (40 / 500));
    }
  }
`;

export { Container };

export default { Container };
