import Styles from "./styles";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useAuth } from "hooks";

// img
import IconHome from "resources/image/icon/icon_home.svg";
import IconMenu from "resources/image/icon/icon_menu.svg";
import IconUser from "resources/image/icon/icon_user.svg";
import IconLocation from "resources/image/icon/icon_location02.svg";

function NavigationBar() {
  const location = useLocation();
  const authInfo = useAuth({});

  const list = [
    {
      url: "/",
      icon: IconHome,
      text: "Home",
    },
    {
      url: "/category",
      icon: IconMenu,
      text: "카테고리",
    },
    {
      url: authInfo.isLogin ? "/mypage" : "/login",
      icon: IconUser,
      text: authInfo.isLogin ? "마이페이지" : "로그인",
    },
    {
      url: "/direction",
      icon: IconLocation,
      text: "오시는길",
    },
  ];

  return (
    <Styles.Container>
      {list.map((item, index) => {
        return (
          <li className="navList" key={"navList" + index}>
            {item.url === "/" ? (
              <Link
                to={item.url}
                className={location.pathname === item.url ? "active" : ""}
              >
                <div className="iconWrap">
                  <img src={item.icon} alt={item.icon} />
                </div>

                <p className="navText">{item.text}</p>
              </Link>
            ) : (
              <NavLink to={item.url}>
                <div className="iconWrap">
                  <img src={item.icon} alt={item.icon} />
                </div>

                <p className="navText">{item.text}</p>
              </NavLink>
            )}
          </li>
        );
      })}
    </Styles.Container>
  );
}

export default NavigationBar;
