import styled, { css } from "styled-components";

const sizes = {
  smallText: {
    fontSize: "20",
  },
  small: {
    height: "60",
    fontSize: "20",
    borderRadius: "3",
  },
};

const buttonTypes = {
  black: {
    backgroundColor: "var(--black)",
    fontColor: "#fff",
  },
  gray: {
    backgroundColor: "var(--gray03)",
    fontColor: "var(--gray01)",
  },
  green: {
    backgroundColor: "var(--green)",
    fontColor: "#fff",
  },
  line: {
    border: "1px solid var(--main)",
    backgroundColor: "#fff",
    fontColor: "var(--main)",
  },
};

//변수를 size 형태로 받아서 분기 처리가 가능합니다.
const sizeStyles = css`
  ${({ size }) => css`
    height: ${sizes[size] && sizes[size].height
      ? sizes[size].height + "px"
      : "70px"};
    font-size: ${sizes[size] && sizes[size].fontSize
      ? sizes[size].fontSize + "px"
      : "22px"};
    border-radius: ${sizes[size] && sizes[size].borderRadius
      ? sizes[size].borderRadius + "px"
      : "5px"};

    @media (max-width: 520px) {
      height: ${sizes[size] && sizes[size].height
        ? "calc(100vw * (" + sizes[size].height + "/ 500))"
        : "calc(100vw * (70 / 500))"};
      font-size: ${sizes[size] && sizes[size].fontSize
        ? "calc(100vw * (" + sizes[size].fontSize + "/ 500))"
        : "calc(100vw * (22 / 500))"};
      border-radius: ${sizes[size] && sizes[size].borderRadius
        ? "calc(100vw * (" + sizes[size].borderRadius + "/ 500))"
        : "calc(100vw * (5 / 500))"};
    }
  `}
`;

const buttonTypeStyles = css`
  ${({ buttonType }) => css`
    border: ${buttonTypes[buttonType] && buttonTypes[buttonType].border};
    background-color: ${buttonTypes[buttonType] &&
    buttonTypes[buttonType].backgroundColor
      ? buttonTypes[buttonType].backgroundColor
      : "var(--main)"};
    color: ${buttonTypes[buttonType] && buttonTypes[buttonType].fontColor
      ? buttonTypes[buttonType].fontColor
      : "#fff"};
  `}
`;

const Container = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  font-weight: 600;

  ${sizeStyles}
  ${buttonTypeStyles}

  &:disabled {
    color: var(--gray01);
    background-color: var(--gray02);
  }

  & .iconWrap {
    display: inline-block;
    width: 24px;
    height: 24px;
  }

  @media (max-width: 520px) {
    gap: calc(100vw * (10 / 500));

    & .iconWrap {
      width: calc(100vw * (24 / 500));
      height: calc(100vw * (24 / 500));
    }
  }
`;

export { Container };

export default {
  Container,
};
