// img
import ImgDummyMainBanner from "resources/image/img_dummy_main_banner.jpg";
import ImgDummySubBanner from "resources/image/img_dummy_sub_banner.jpg";
import ImgDummyList from "resources/image/img_dummy_list.png";
import ImgDummyReview from "resources/image/img_dummy_review.jpg";

const mainBannerList = [
  ImgDummyMainBanner,
  ImgDummyMainBanner,
  ImgDummyMainBanner,
];
const subBannerList = [ImgDummySubBanner, ImgDummySubBanner, ImgDummySubBanner];
const dummyList = [
  {
    id: "dummy1",
    img: ImgDummyList,
    title: "3월, 찾아온 꽃샘추위 시니어 감기 해결책",
    keywordList: [
      { url: "/search", keyword: "정리 컨설턴트" },
      { url: "", keyword: "까사마미 대표" },
    ],
  },
  {
    id: "dummy2",
    img: ImgDummyList,
    title: "3월, 찾아온 꽃샘추위 시니어 감기 해결책",
    keywordList: [
      { url: "", keyword: "정리 컨설턴트" },
      { url: "", keyword: "까사마미 대표" },
    ],
    closed: true,
  },
  {
    id: "dummy3",
    img: ImgDummyList,
    title: "3월, 찾아온 꽃샘추위 시니어 감기 해결책",
    keywordList: [
      { url: "", keyword: "정리 컨설턴트" },
      { url: "", keyword: "까사마미 대표" },
    ],
  },
  {
    id: "dummy4",
    img: ImgDummyList,
    title: "3월, 찾아온 꽃샘추위 시니어 감기 해결책",
    keywordList: [
      { url: "", keyword: "정리 컨설턴트" },
      { url: "", keyword: "까사마미 대표" },
    ],
  },
  {
    id: "dummy5",
    img: ImgDummyList,
    title: "3월, 찾아온 꽃샘추위 시니어 감기 해결책",
    keywordList: [
      { url: "", keyword: "정리 컨설턴트" },
      { url: "", keyword: "까사마미 대표" },
    ],
  },
];
const programList = [
  {
    id: "1",
    title: "배움을 가꾸다,",
    categoryName: "지식의 정원",
    list: dummyList,
  },
  {
    id: "2",
    title: "배움을 가꾸다,",
    categoryName: "지식의 정원",
    list: dummyList,
  },
  {
    id: "3",
    title: "배움을 가꾸다,",
    categoryName: "지식의 정원",
    list: dummyList,
  },
  {
    id: "4",
    title: "배움을 가꾸다,",
    categoryName: "지식의 정원",
    list: dummyList,
  },
];
const categoryList = [
  {
    id: "1",
    text: "category01",
  },
  { id: "2", text: "category02" },
  { id: "3", text: "category03" },
  { id: "4", text: "category04" },
];
const timeList = [
  {
    timeTitle: "오전권",
    list: [
      {
        id: "1",
        startTime: "09:30",
        endTime: "10:30",
        nowPeople: 5,
        maxPeople: 15,
        optionList: [
          {
            title: "반야사 요가, 온전한 몸을 향한 시간",
            startTime: "12:50",
            endTime: "13:20",
            nowPeople: 0,
            maxPeople: 15,
          },
          {
            title: "마루운동, 온전한 몸을 향한 시간",
            startTime: "10:50",
            endTime: "12:20",
            nowPeople: 6,
            maxPeople: 15,
          },
          {
            title: "마루운동, 온전한 몸을 향한 시간",
            startTime: "10:50",
            endTime: "12:20",
            nowPeople: 15,
            maxPeople: 15,
          },
        ],
      },
      {
        id: "2",
        startTime: "09:30",
        endTime: "10:30",
        nowPeople: 15,
        maxPeople: 15,
        optionList: [
          {
            title: "반야사 요가, 온전한 몸을 향한 시간",
            startTime: "12:50",
            endTime: "13:20",
            nowPeople: 0,
            maxPeople: 15,
          },
          {
            title: "마루운동, 온전한 몸을 향한 시간",
            startTime: "10:50",
            endTime: "12:20",
            nowPeople: 6,
            maxPeople: 15,
          },
        ],
      },
    ],
  },
  {
    timeTitle: "오후권",
    list: [
      {
        id: "1",
        startTime: "09:30",
        endTime: "10:30",
        nowPeople: 5,
        maxPeople: 15,
      },
      {
        id: "2",
        startTime: "09:30",
        endTime: "10:30",
        nowPeople: 5,
        maxPeople: 15,
      },
    ],
  },
  {
    timeTitle: "저녁권",
    list: [
      {
        id: "1",
        startTime: "09:30",
        endTime: "10:30",
        nowPeople: 5,
        maxPeople: 15,
      },
      {
        id: "2",
        startTime: "09:30",
        endTime: "10:30",
        nowPeople: 5,
        maxPeople: 15,
      },
    ],
  },
];
const programData = {
  // type: 0 - 시간권 / 1 - 정기권 / 2 - 원데이
  // closed: true,
  location: "북촌점",
  type: "1",
  date: "2024.05.07",
  img: ImgDummyList,
  title: "배움을 가꾸다,",
  categoryName: "지식의 정원",
  mainTitle: "차명상, 온전한 쉼을 향한 시간",
  description:
    "차 마스터가 엄선한 최상급의 차를 음미하고, 나를 돌아보며 스스로 돌보는 방법을 배워봐요.",
  like: true,
  likeNumber: 1,
  totalStarNumber: 3,
  beforePrice: 42000,
  afterPrice: 42000,
  people: "4~6명, 최대 10명",
  room: "3층 A룸",
  time: "90분",
  schedule: "오전반, 오후반, 저녁반",
  keywordList: [
    { url: "", keyword: "정리 컨설턴트" },
    { url: "", keyword: "까사마미 대표" },
    { url: "", keyword: "까사마미 대표" },
    { url: "", keyword: "까사마미 대표" },
    { url: "", keyword: "까사마미 대표" },
  ],
  subProgramList: dummyList,
  recentphotoReviewList: [
    ImgDummyReview,
    ImgDummyReview,
    ImgDummyReview,
    ImgDummyReview,
    ImgDummyReview,
    ImgDummyReview,
  ],
  reviewList: [
    {
      nickname: "닉네임이 들어갑니다",
      review:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua!!",
      starPoint: 4,
      date: "2023.01.01",
    },
    {
      profileImg: ImgDummyReview,
      nickname: "닉네임이 들어갑니다",
      review: "리뷰 입니다리뷰 입니다리뷰 입니다리뷰 입니다",
      photoReviewList: [ImgDummyReview, ImgDummyReview],
      starPoint: 3,
      date: "2023.01.01",
    },
  ],
};
const paymentData = {
  // type: 0 - 시간권 / 1 - 정기권 / 2 - 원데이
  date: "2024.05.04",
  peopleList: [
    {
      name: "김이름",
      phone: "010-1234-1234",
    },
    {
      name: "홍길동",
      phone: "010-1234-1234",
    },
  ],
  type: 0,
  state: 3,
  location: "북촌점",
  title: "차명상, 온전한 쉼을 향한 시간",
  timeList: ["09:00~10:30", "10:50~12:20", "10:50~12:20"],
  optionList: [
    {
      optionTitle: "반야사 요가, 온전한 몸을 향한 시간",
      time: "09:00~10:30",
    },
    {
      optionTitle: "마루운동, 온전한 몸을 향한 시간",
      time: "09:00~10:30",
    },
    {
      optionTitle: "쿠킹클래스, 온전한 충만함을 위한 시간",
      time: "09:00~10:30",
    },
  ],
  price: 82000,
  coupon: 0,
  point: 10000,
  method: "신용카드",
};
const myData = {
  profileImg: ImgDummyReview,
  nickname: "유채꽃킬러",
  point: 0,
  programList: [
    {
      // type: 0 - 시간권 / 1 - 정기권 / 2 - 원데이
      type: 0,
      location: "북촌점",
      date: "2024.05.01",
      people: 2,
      detailList: [
        {
          main: true,
          title: "차명상, 온전한 쉼을 향한 시간",
          time: "09:00~10:30",
          room: "3층 A룸",
        },
        {
          title:
            "반야사 요가, 온전한 몸을 향한 시간반야사 요가, 온전한 몸을 향한 시간",
          time: "10:50~10:30",
          room: "3층 A룸",
        },
        {
          main: true,
          title: "차명상, 온전한 쉼을 향한 시간",
          time: "11:50~12:30",
          room: "3층 A룸",
        },
      ],
    },
    {
      type: 1,
      location: "북촌점",
      date: "2024.05.01",
      people: 2,
      detailList: [
        {
          main: true,
          title:
            "반야사 요가, 온전한 몸을 향한 시간반야사 요가, 온전한 몸을 향한 시간",
          time: "09:00~10:30",
          room: "3층 A룸",
        },
      ],
    },
  ],
  code: "ASD123DFG456",
};
const myProgramData = [
  {
    // type: 0 - 시간권 / 1 - 정기권 / 2 - 원데이
    type: 0,
    location: "북촌점",
    state: 0,
    date: "2024.05.01",
    people: 2,
    title: "차명상, 온전한 쉼을 향한 시간",
    room: "3층 A룸",
  },
  {
    type: 1,
    location: "북촌점",
    state: 3,
    date: "2024.05.01",
    people: 2,
    title: "차명상, 온전한 쉼을 향한 시간",
    room: "3층 A룸",
  },
];
const reviewImgList = [
  {
    img: ImgDummyReview,
  },
  {
    img: ImgDummyReview,
  },
];
const searchList = {
  program: dummyList,
  magazine: dummyList,
};
const noticeList = [
  {
    title: "텍스트텍스트텍스트텍스트텍스트",
    date: "2024.05.01",
  },
  {
    title: "텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트",
    date: "2024.05.01",
  },
];
const faqList = [
  {
    question: "텍스트텍스트텍스트텍스트텍스트텍스트텍스트",
    answer:
      "텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트",
  },
  {
    question: "텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트",
    answer:
      "텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스트",
  },
];
const pointList = [
  // type: 0 - 적립 / 1 - 사용 / 2 - 만료
  {
    type: 0,
    title: "이벤트 적립",
    date: "2024.05.01",
    point: 1000,
  },
  {
    type: 1,
    title: "프로그램 결제",
    date: "2024.05.01",
    point: 48000,
  },
  {
    type: 2,
    title: "프로그램 결제",
    date: "2024.05.01",
    point: 48000,
  },
];
const locationList = [
  {
    locaiton: "오뉴 팔판",
    address: "주소주소주소주소주소주소주소주소주소주소주소주소",
    tel: "02-2038-2636",
    email: "email@email.com",
  },
  {
    locaiton: "오뉴 북촌(오뉴 제과)",
    address: "주소주소주소주소주소주소주소주소주소주소주소주소",
    tel: "02-2038-2636",
    email: "email@email.com",
  },
  {
    locaiton: "오뉴 팔판",
    address: "주소주소주소주소주소주소주소주소주소주소주소주소",
    tel: "02-2038-2636",
    email: "email@email.com",
  },
];
const commentList = [
  {
    userName: "닉네임이 들어갑니다",
    comment:
      "댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다",
    date: "2023.01.01",
  },
  {
    userName: "닉네임이 들어갑니다",
    comment:
      "댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다",
    date: "2023.01.01",
    my: true,
    reply: [
      {
        userName: "닉네임이 들어갑니다",
        comment:
          "리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다",
        date: "2023.01.01",
      },
      {
        my: true,
        userName: "닉네임이 들어갑니다",
        comment:
          "리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다 리댓글입니다",
        date: "2023.01.01",
      },
    ],
  },
  {
    userName: "닉네임이 들어갑니다",
    comment:
      "댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다 댓글입니다",
    date: "2023.01.01",
  },
];
const slideList = [
  ImgDummyList,
  ImgDummyList,
  ImgDummyList,
  ImgDummyList,
  ImgDummyList,
  ImgDummyList,
  ImgDummyList,
  ImgDummyList,
  ImgDummyList,
  ImgDummyList,
  ImgDummyList,
  ImgDummyList,
  ImgDummyList,
  ImgDummyList,
  ImgDummyList,
];
const keywordList = [
  {
    keyword: "태그태그",
  },
  {
    keyword: "태그",
  },
  {
    keyword: "태그태그태그",
  },
  {
    keyword: "태그태그",
  },
  {
    keyword: "태그태그",
  },
  {
    keyword: "태그태그태그태그태그태그",
  },
  {
    keyword: "태그태그태그태그",
  },
];
export {
  mainBannerList,
  subBannerList,
  dummyList,
  programList,
  categoryList,
  timeList,
  programData,
  paymentData,
  myData,
  myProgramData,
  reviewImgList,
  searchList,
  noticeList,
  faqList,
  pointList,
  locationList,
  commentList,
  slideList,
  keywordList,
};

export default {
  mainBannerList,
  subBannerList,
  dummyList,
  programList,
  categoryList,
  timeList,
  programData,
  paymentData,
  myData,
  myProgramData,
  reviewImgList,
  searchList,
  noticeList,
  faqList,
  pointList,
  locationList,
  commentList,
  slideList,
  keywordList,
};
