import Styles from "./styles";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "hooks";

// img
import Logo from "resources/image/icon/logo.svg";
import IconSearch from "resources/image/icon/icon_search.svg";
import IconLocation from "resources/image/icon/icon_location.svg";
import IconUser from "resources/image/icon/icon_user.svg";

export default function Header() {
  const authInfo = useAuth({});
  const navigate = useNavigate();

  return (
    <Styles.Container>
      <div className="inner">
        <h1 className="logo">
          <Link to={"/"}>
            <img src={Logo} alt="logo" />
          </Link>
        </h1>

        <Link className="search" to={"/search"}>
          <div className="icon">
            <img src={IconSearch} alt="IconSearch" />
          </div>
        </Link>

        <Link to={"/direction"} className="location">
          <div className="icon">
            <img src={IconLocation} alt="IconLocation" />
          </div>
          북촌점
        </Link>

        <button
          type="button"
          className="userButton"
          onClick={() => {
            navigate(authInfo.isLogin ? "/mypage" : "/login");
          }}
        >
          <img src={IconUser} alt="IconUser" />
        </button>
      </div>
    </Styles.Container>
  );
}
