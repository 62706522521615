import connector from "service/connector";
import utils from "utils";


const getList = async (data) => {
  return await connector.authConnector(`board?${utils.etc.getFilterStr(data)}`,'GET');
}

const getInitinteList = async (data) => {
  let response =  await connector.authConnector(`board?${utils.etc.getFilterStr(data)}`, "GET");
  let result = {
    list :  response?.list,
    page :  data.page,
    totalCount : response?.pagination?.totalRecordCountAll
  }
  return result;
}

const getGroupList = async (data) => {
  return await connector.authConnector(`board/group_category_list?${utils.etc.getFilterStr(data)}`,'GET');
}

const getDetail = async (id) => {
  return await connector.authConnector(`board/${id}`,'GET');
}

const getIntroduce = async () => {
  return await connector.connectFetchController(`board/introduce`,'GET');
}

export {
  getDetail,
  getGroupList,
  getInitinteList,
  getList,
  getIntroduce,
};


export default {
  getDetail,
  getGroupList,
  getInitinteList,
  getList,
  getIntroduce,
}