import useModals from "hooks/useModals";
import { api } from "service";
import { useNavigate } from "react-router-dom";

export default function useReport() {
  const navigate = useNavigate();
  const modalOption = useModals();

  const validate = (data) => {
    if (!data.reason) {
      modalOption.setToastModal((e) => { e.show = true; e.text = "신고 사유를 선택해 주세요."; return { ...e } });
      return false;              
    }
    if (data.reason === "기타" && !data.textarea) {
      modalOption.setToastModal((e) => { e.show = true; e.text = "기타 사유를 입력해 주세요."; return { ...e } });
      return false;
    }
    return true;
  }

  const doReport = (data) => {
    if (validate(data)) {
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.closeButton = true;
        e.icon = false;
        e.title = "정말 신고하시겠습니까?";
        e.text = false;
        e.confirmButtonText = "신고하기";
        e.confirmButtonClick = async () => {
          const result = await api.report.postReport(JSON.stringify(data));
          if (result.success) {
            modalOption.setDefaultModal((e) => {
              e.show = true;
              e.closeButton = false;
              e.icon = false;
              e.title = "신고가 완료되었습니다.";
              e.text = false;
              e.confirmButtonText = "확인";
              e.confirmButtonClick = () => {
                navigate(-1);
                modalOption.clearModals();
              };
              return { ...e };
            });
          }
        };
        return { ...e };
      });
    }
  }


  return {
    doReport,
  };
}
