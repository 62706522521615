import { atom } from 'jotai'

const userInfo = atom({
  email: null,
  image: null,
  interest_yn: "N",
  my_recommend_code: "",
  name: "",
  nickname: "",
  phone: null,
  social_yn: "N",
  thumbnail: null,
  total_point: "0",
});

const signInfo = atom({
  checkList : []
});

const introInfo = atom({
  auth: false,
});

export default { 
  userInfo, 
  signInfo,
  introInfo 
};