import styled from "styled-components";

const Container = styled.main`
  position: relative;

  & section:not(:first-of-type) {
    padding: 40px 0;
  }

  & section:not(:first-of-type, :last-of-type) {
    border-bottom: 10px solid var(--gray03);
  }

  & .title {
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: bold;
  }

  & .scheduleSection {
    & .timeList {
      display: flex;
      align-items: center;
      padding: 13px 0;
      font-weight: 600;

      & .number,
      & .optionTime {
        font-size: 18px;
      }

      & .number {
        width: 20px;
        margin-right: 10px;
        color: var(--gray02);
      }

      & .optionTitle {
        max-width: 310px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      & .optionTime {
        margin-left: auto;
        color: var(--gray01);
      }
    }
  }

  & .peopleSection {
    & .peopleList {
      display: flex;
      height: 50px;
      align-items: center;
      font-weight: 600;

      & .number {
        width: 21px;
        margin-right: 10px;
        font-size: 18px;
        color: var(--gray02);
        text-align: center;
      }

      & .name {
        font-weight: 600;
      }

      & .phone {
        margin-left: auto;
        font-size: 18px;
        color: var(--gray01);
      }
    }
  }

  & .methodSection .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .title {
      margin: 0;
    }

    & .method {
      display: flex;
      gap: 15px;
      align-items: center;
      font-size: 22px;
      font-weight: 600;
      color: var(--gray01);

      & a {
        height: 29px;
        padding: 5px;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        background-color: var(--black);
        border-radius: 3px;
      }
    }
  }

  & .buttonContainer {
    border-top: 10px solid var(--gray03);

    & .inner {
      display: flex;
      justify-content: center;
      gap: 10px;
      padding: 50px 0;

      & button {
        width: 225px;
      }
    }
  }

  @media (max-width: 520px) {
    & section:not(:first-of-type) {
      padding: calc(100vw * (40 / 500)) 0;
    }

    & section:not(:first-of-type, :last-of-type) {
      border-bottom: calc(100vw * (10 / 500)) solid var(--gray03);
    }

    & .title {
      margin-bottom: calc(100vw * (20 / 500));
      font-size: calc(100vw * (22 / 500));
    }

    & .scheduleSection {
      & .timeList {
        padding: calc(100vw * (13 / 500)) 0;

        & .number,
        & .optionTime {
          font-size: calc(100vw * (18 / 500));
        }

        & .number {
          width: calc(100vw * (20 / 500));
          margin-right: calc(100vw * (10 / 500));
        }

        & .optionTitle {
          max-width: calc(100vw * (310 / 500));
        }
      }
    }

    & .peopleSection {
      & .peopleList {
        height: calc(100vw * (50 / 500));

        & .number {
          width: calc(100vw * (21 / 500));
          margin-right: calc(100vw * (10 / 500));
          font-size: calc(100vw * (18 / 500));
        }

        & .phone {
          font-size: calc(100vw * (18 / 500));
        }
      }
    }

    & .methodSection .inner {
      & .method {
        gap: calc(100vw * (15 / 500));
        font-size: calc(100vw * (22 / 500));

        & a {
          height: calc(100vw * (29 / 500));
          padding: calc(100vw * (5 / 500));
          font-size: calc(100vw * (16 / 500));
          border-radius: calc(100vw * (3 / 500));
        }
      }
    }

    & .buttonContainer {
      border-top: calc(100vw * (10 / 500)) solid var(--gray03);

      & .inner {
        gap: calc(100vw * (10 / 500));
        padding: calc(100vw * (50 / 500)) 0;

        & button {
          width: calc(100vw * (225 / 500));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
