import styled from "styled-components";

// img
// import IconEssential from "resources/image/icon/icon_essential.svg";

const Container = styled.div`
  & label {
    display: inline-block;
    margin-bottom: 15px;
    font-size: 22px;
    font-weight: 600;

    & span {
      color: var(--main);
    }
  }

  & .inputContainer {
    position: relative;
    display: flex;
    gap: 20px;

    & .react-datepicker-wrapper {
      flex-grow: 1;
    }

    & select {
      font-size: 18px;
    }

    & input {
      width: 100%;
      height: 70px;
      padding: 0 15px;
      background-color: var(--gray03);
      border-radius: 5px;
    }

    & .react-datepicker__tab-loop {
      position: absolute;
      top: 0;
    }

    & .react-datepicker__triangle {
      display: none !important;
    }
  }

  @media (max-width: 520px) {
    & label {
      margin-bottom: calc(100vw * (15 / 500));
      font-size: calc(100vw * (22 / 500));
    }

    & .inputContainer {
      gap: calc(100vw * (20 / 500));

      & select {
        font-size: calc(100vw * (18 / 500));
      }

      & input {
        height: calc(100vw * (70 / 500));
        padding: 0 calc(100vw * (15 / 500));
        border-radius: calc(100vw * (5 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
