import utils from "utils";
import Styles from "./styles";
import { useLike } from "hooks";
import { useEffect, useState } from "react";
import { common, layout } from "components";
import { useLocation, useNavigate } from "react-router-dom";

function MyLikePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState("program");
  const likeInfo = useLike({
    programList: tabIndex === "program",
    magazineList: tabIndex === "magazine"
  });

  const data = tabIndex === "program"
    ? likeInfo.likeProgramList?.data?.data
    : likeInfo.likeMagazineList?.data?.data

  const tabList = [
    {
      id: "program",
      text: "프로그램",
    },
    {
      id: "magazine",
      text: "매거진",
    },
  ];

  useEffect(() => {
    let param = utils.etc.getSearchParam();
    setTabIndex(param.tab ? param.tab : "program");
  }, [location]);

  return (
    <div className="wrap">
      <common.SubHeader
        pageName={"나의 찜"}
        backEvent={() => { navigate("/mypage") }}  
      />

      <Styles.Container>
        <section className="section">
          <div className="tab">
            <layout.Tab
              tabList={tabList}
              tabIndex={tabIndex}
              setTabIndex={(e) => {
                navigate(
                  utils.etc.getQueryParamUrl(
                    { tab: e }, false
                  )
                )
              }}
            />
          </div>

          <div className="inner">
            {data?.length > 0 ? (
              <ul className="likeListWrap">
                {data.map((item, index) => {
                  return (
                    <li className="likeList" key={"likeList" + index}>
                      <layout.ContentsList
                        data={item}
                        big
                        url={`/${tabIndex}/detail/${item.id}`}
                      />

                      <button
                        type="button"
                        className="deleteButton"
                        onClick={() => {
                          likeInfo.toggleLike(item.id, tabIndex === "program" ? 2 : 1)
                          window.location.reload();
                        }}
                      >
                        삭제
                      </button>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <p className="emptyText">찜 내역이 없습니다.</p>
            )}
          </div>
        </section>
      </Styles.Container>
    </div>
  );
}

export default MyLikePage;
