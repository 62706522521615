import Styles from "./styles";
import { useState, useEffect } from "react";
import { api } from "service";

// img
import IconWarning from "resources/image/icon/icon_warning.svg";
import { useModals, useUpload } from "hooks";

function ReviewImageUpload({imageList,setImageList, fileList, setFileList}) {
  const [tempImageList, setTempImageList] = useState([]);
  const modalOption = useModals();

  const encodeFileToBase64 = async (files) => {
    let resultImageUrl = [];
    for(let i = 0;i<files.length;i++){
      let imageUrl = "";
      const reader = new FileReader();
      reader.readAsDataURL(files[i]);
      await new Promise((resolve) => {
        reader.onload = () => {
          imageUrl = reader.result;
          resolve();
        };
      });
      resultImageUrl.push(imageUrl);
    }
    setTempImageList(resultImageUrl);
  };

  return (
    <Styles.Container>
      <p className="uploadTitle">사진 첨부</p>

      <div className="uploadButton">
        <input
          type="file"
          id="file"
          max={5}
          maxLength={5}
          onChange={(e) => {
           
            if(imageList.length + e.target.files.length > 5){
              e.target.files = [];
               modalOption.setToastModal((e) => {
                e.show = true;
                e.text = "사진은 최대 5개까지 첨부 가능합니다.";
                return { ...e };
              });
              return;
            }
            
            encodeFileToBase64(e.target.files);
            setFileList([...e.target.files]);
          }}
          multiple
        />
        <label htmlFor="file">파일 업로드</label>
      </div>

      <p className="guideText">
        <span className="icon">
          <img src={IconWarning} alt="IconWarning" />
        </span>
        허위 리뷰, 프로그램과 연관되지 않는 내용 및 욕설, 과도한 <br />
        비방 등 악의적인 내용 작성 시 노출이 제한될 수 있습니다.
      </p>

      {(imageList?.length > 0 || tempImageList?.length > 0) && (
        <ul className="imgListWrap">
          {(imageList || []).concat((tempImageList || []))?.map((item, index) => {
            return (
              <li className="imgList" key={"imgList" + index}>
                <div className="img">
                  <img src={item} alt="reviewImg" />
                </div>

                <button 
                  type="button" 
                  className="delButton"
                  onClick={()=>{

                    if(index >= imageList.length){
                      setTempImageList([...tempImageList.filter((v,i)=> imageList.length + i != index)]);
                      setFileList(e => [...e.filter((v,i)=> imageList.length + i != index)]);
                    }else{
                      setImageList([...imageList.filter((v,i)=> i != index)]);
                    }

                  }}
                >
                  삭제
                </button>
              </li>
            );
          })}
        </ul>
      )}
    </Styles.Container>
  );
}

export default ReviewImageUpload;
