import Styles from "./styles";
import layout from "..";
import { Link } from "react-router-dom";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import { FreeMode } from "swiper";

// img
import IconMore from "resources/image/icon/icon_more.svg";

function ContentsSlider({ data, slider, url, urlCallback, isHideMore }) {
  return (
    <Styles.Container>
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={16}
        freeMode={true}
        modules={[FreeMode]}
        className={slider + "Slider"}
      >
        {data?.map((item, index) => {
          return (
            <SwiperSlide
              className={slider + "SlideList"}
              key={slider + "SlideList" + index}
            >
              {
                <layout.ContentsList
                  data={item}
                  urlCallback={
                    urlCallback
                      ? () => {
                          urlCallback(item);
                        }
                      : null
                  }
                />
              }
            </SwiperSlide>
          );
        })}

        {
          isHideMore ? null
          :
          <SwiperSlide className="moreList">
            <Link to={url}>
              <div className="icon">
                <img src={IconMore} alt="IconMore" />
              </div>
              더보기
            </Link>
          </SwiperSlide>
        }
      </Swiper>
    </Styles.Container>
  );
}

export default ContentsSlider;
