import styled from "styled-components";

const Container = styled.main`
  min-height: calc(100vh - 61px);
  min-height: calc(100dvh - 61px);
  padding: 50px 0 100px;
  text-align: center;

  & .logo {
    width: 111px;
    height: 32px;
    margin: 0 auto 30px;
  }

  & .title {
    margin-bottom: 58px;
    font-size: 30px;
    font-weight: 600;
  }

  & .pointWrap {
    width: 420px;
    margin: 0 auto 57px;
    padding: 64px 43px;
    background-color: var(--gray03);
    border-radius: 10px;

    & p {
      margin-bottom: 50px;
      font-weight: 400;
      color: var(--main);
      line-height: 1.5;

      & b {
        font-size: 30px;
        font-weight: 800;
      }
    }

    & .img {
      height: 146px;
    }
  }

  & .date {
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 600;
    color: var(--main);
  }

  @media (max-width: 520px) {
    min-height: calc(100vh - calc(100vw * (61 / 500)));
    min-height: calc(100dvh - calc(100vw * (61 / 500)));
    padding: calc(100vw * (50 / 500)) 0 calc(100vw * (100 / 500));

    & .logo {
      width: calc(100vw * (111 / 500));
      height: calc(100vw * (32 / 500));
      margin: 0 auto calc(100vw * (30 / 500));
    }

    & .title {
      margin-bottom: calc(100vw * (58 / 500));
      font-size: calc(100vw * (30 / 500));
    }

    & .pointWrap {
      width: calc(100vw * (420 / 500));
      margin: 0 auto calc(100vw * (57 / 500));
      padding: calc(100vw * (64 / 500)) calc(100vw * (43 / 500));
      border-radius: calc(100vw * (10 / 500));

      & p {
        margin-bottom: calc(100vw * (50 / 500));

        & b {
          font-size: calc(100vw * (30 / 500));
        }
      }

      & .img {
        height: calc(100vw * (146 / 500));
      }
    }

    & .date {
      margin-bottom: calc(100vw * (15 / 500));
      font-size: calc(100vw * (18 / 500));
    }
  }
`;

export { Container };

export default { Container };
