import Styles from "./styles";
import { common } from "components";
import { useNavigate, Link } from "react-router-dom";
import { api } from "service";
import { useAuth, useModals } from "hooks";

// img
import IconWarning from "resources/image/icon/icon_warning.svg";
import ImgDefaultProfile from "resources/image/img_default_profile.svg";
import IconHeadset from "resources/image/icon/icon_headset02.svg";
import IconPen from "resources/image/icon/icon_pen.svg";
import IconProgram from "resources/image/icon/icon_program02.svg";
import IconMagazine from "resources/image/icon/icon_magazine.svg";
import IconCommunity from "resources/image/icon/icon_community.svg";
import IconOnew from "resources/image/icon/icon_onew.svg";
import IconLocation from "resources/image/icon/icon_location03.svg";

function CategoryPage() {
  const modalOption = useModals();
  const authInfo = useAuth({});

  const data = authInfo.userData;
  const login = authInfo.isLogin;

  const contactList = [
    {
      icon: IconHeadset,
      text: "고객센터",
      url: "/contact/notice",
    },
    {
      icon: IconPen,
      text: "문의하기",
      url: "/ask",
    },
  ];

  const menuList = [
    {
      icon: IconProgram,
      url: "/program",
      text: "프로그램",
    },
    {
      icon: IconMagazine,
      url: "/magazine",
      text: "매거진",
    },
    {
      icon: IconCommunity,
      url: "/community",
      onClick: (e) => {
        e.preventDefault();
        modalOption.setDefaultModal((e) => {
          e.show = true;
          e.icon = IconWarning;
          e.title = (
            <>
              오뉴 커뮤니티 <span className="color">단장중</span>입니다!
            </>
          );
          e.text = "조금만 기다려 주세요 :)";
          e.confirmButtonText = "확인";

          return { ...e };
        });
      },
      text: "커뮤니티",
    },
    {
      icon: IconOnew,
      url: "/introduction",
      text: "오뉴소개",
    },
    {
      icon: IconLocation,
      url: "/direction",
      text: "오시는 길",
    },
  ];

  return (
    <div className="wrap">
      <Styles.Container>
        <div className="inner">
          <div className="profileContainer">
            <div className="profileImgWrap">
              <img
                src={
                  login && data.image ? data.image : ImgDefaultProfile
                }
                alt="profileImg"
              />
            </div>

            <p className="userName">
              {login ? data.nickname : "로그인 후 이용 가능합니다."}
            </p>

            {login ? (
              <Link to={"/mypage"} className="mypageButton">
                마이페이지
              </Link>
            ) : (
              <Link to={"/login"} className="loginButton">
                로그인
              </Link>
            )}
          </div>

          <ul className="conttact">
            {contactList.map((item, index) => {
              return (
                <li className="contactList" key={"contactList" + index}>
                  <Link to={item.url}>
                    <div className="icon">
                      <img src={item.icon} alt="contactIcon" />
                    </div>
                    {item.text}
                  </Link>
                </li>
              );
            })}
          </ul>

          <ul className="menu">
            {menuList?.map((item, index) => {
              return (
                <li className="menuList" key={"menuList" + index}>
                  <Link to={item.url} onClick={item.onClick && item.onClick}>
                    <div className="icon">
                      <img src={item.icon} alt="menuIcon" />
                    </div>

                    {item.text}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </Styles.Container>

      <common.QuickMenu />
      <common.NavigationBar />
    </div>
  );
}

export default CategoryPage;
