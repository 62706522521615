import styled from "styled-components";

const Container = styled.div`
  & .mainBannerSlideList div {
    height: 375px;
    cursor: pointer;
  }

  & .swiper-pagination {
    top: auto;
    left: auto;
    bottom: 20px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    width: 92px;
    height: 35px;
    font-size: 16px;
    font-weight: 300;
    color: #fff;
    border-radius: 500px;
    background-color: rgba(51, 51, 51, 0.5);
  }

  @media (max-width: 520px) {
    & .mainBannerSlideList div {
      height: calc(100vw * (375 / 500));
    }

    & .swiper-pagination {
      bottom: calc(100vw * (20 / 500));
      right: calc(100vw * (20 / 500));
      gap: calc(100vw * (5 / 500));
      width: calc(100vw * (92 / 500));
      height: calc(100vw * (35 / 500));
      font-size: calc(100vw * (16 / 500));
      border-radius: calc(100vw * (500 / 500));
    }
  }
`;

export { Container };

export default { Container };
