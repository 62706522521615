import styled from "styled-components";

const Container = styled.main`
  & .inner {
    min-height: calc(100vh - 60px);
    min-height: calc(100dvh - 60px);
    padding: 60px 0 50px;

    & h2 {
      margin-bottom: 40px;
      font-size: 30px;
      font-weight: bold;
      line-height: 1.4;
    }

    & .list {
      &:not(:last-child) {
        margin-bottom: 40px;
      }

      & .label {
        margin-bottom: 15px;
        font-size: 22px;
        font-weight: 600;

        & span {
          color: var(--main);
        }
      }

      & .guideText {
        color: var(--gray01);

        & .icon {
          display: inline-block;
          width: 28px;
          height: 28px;
          filter: var(--gray01-filter);
        }
      }

      & button {
        width: 120px;
      }

      & .inputContainer:not(:last-child) {
        margin-bottom: 15px;
      }

      & .inputWrap {
        position: relative;

        & .time {
          position: absolute;
          top: 50%;
          right: 145px;
          transform: translateY(-50%);
          font-size: 22px;
          font-weight: 400;
          color: var(--red);
        }
      }

      &.gender ul {
        display: flex;
        gap: 10px;

        & li {
          flex: 1;
        }
      }

      &.phone .guideText {
        display: flex;
        gap: 7px;
        margin-top: 15px;
        font-size: 18px;
      }

      &.family {
        & .label {
          margin-bottom: 10px;
        }

        & .guideText {
          margin-bottom: 20px;
        }

        & ul {
          display: flex;
          flex-wrap: wrap;
          gap: 20px 40px;
        }
      }
    }

    & .nextButton {
      margin-top: 100px;
    }
  }

  @media (max-width: 520px) {
    & .inner {
      min-height: calc(100vh - calc(100vw * (60 / 500)));
      min-height: calc(100dvh - calc(100vw * (60 / 500)));
      padding: calc(100vw * (60 / 500)) 0 calc(100vw * (50 / 500));

      & h2 {
        margin-bottom: calc(100vw * (40 / 500));
        font-size: calc(100vw * (30 / 500));
      }

      & .list {
        &:not(:last-child) {
          margin-bottom: calc(100vw * (40 / 500));
        }

        & .label {
          margin-bottom: calc(100vw * (15 / 500));
          font-size: calc(100vw * (22 / 500));
        }

        & .guideText {
          & .icon {
            width: calc(100vw * (28 / 500));
            height: calc(100vw * (28 / 500));
          }
        }

        & button {
          width: calc(100vw * (120 / 500));
        }

        & .inputContainer:not(:last-child) {
          margin-bottom: calc(100vw * (15 / 500));
        }

        & .inputWrap {
          & .time {
            right: calc(100vw * (145 / 500));
            font-size: calc(100vw * (22 / 500));
          }
        }

        &.gender ul {
          gap: calc(100vw * (10 / 500));
        }

        &.phone .guideText {
          gap: calc(100vw * (7 / 500));
          margin-top: calc(100vw * (15 / 500));
          font-size: calc(100vw * (18 / 500));
        }

        &.family {
          & .label {
            margin-bottom: calc(100vw * (10 / 500));
          }

          & .guideText {
            margin-bottom: calc(100vw * (20 / 500));
          }

          & ul {
            gap: calc(100vw * (20 / 500)) calc(100vw * (40 / 500));
          }
        }
      }

      & .nextButton {
        margin-top: calc(100vw * (100 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
