import Styles from "./styles";
import { common, layout, ui } from "components";
import { useNavigate, Link } from "react-router-dom";
import { useModals, useTransaction } from "hooks";
import { useRef, useState } from "react";
import { api } from "service";

// img
import IconArrowRight from "resources/image/icon/icon_arrow_right_mini.svg";
import utils from "utils";
import useOrder from "hooks/useOrder";

function MyRefundPage() {
  const navigate = useNavigate();
  const modalOption = useModals();
  const orderInfo = useOrder({
    detailOption: {
      id: utils.etc.getSearchParam().id,
    },
  });

  const data = api.dummy.myProgramData[0];
  const isLoad = useRef(false);

  const [inputs, setInputs] = useState({
    refund: "",
  });
  const [check, setCheck] = useState(false);

  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs((e) => {
      return { ...e, [name]: value };
    });
  };

  // map
  const refundData = {
    essential: true,
    labelText: "환불 사유",
    name: "refund",
    id: "refund",
    value: inputs.refund,
    placeholder: "직접 입력",
  };
  const termsLinkList = [
    {
      text: "수강취소/환불 안내",
      link: "/contact/terms",
    },
    {
      text: "클래스 환불 규정",
      link: "/contact/terms",
    },
    {
      text: "평생 교육법 환불 규정",
      link: "/contact/terms",
    },
  ];

  const doRefund = async () => {
    if (check && inputs.refund.length != 0) {
      if (isLoad.current) return;
      // isLoad.current = true;

      let data = {
        id: utils.etc.getSearchParam().id,
        refund_memo: inputs.refund,
      };
      let result = await api.transaction.refundTransaction(
        JSON.stringify(data)
      );
      if (result.success)
        modalOption.setDefaultModal((e) => {
          e.show = true;
          e.closeButton = false;
          e.icon = false;
          e.title = "환불 신청이 완료되었습니다.";
          e.text = (
            <>
              환불 사유에 대해 확인 후 답변드리도록 <br />
              하겠습니다.
            </>
          );
          e.confirmButtonText = "확인";
          e.confirmButtonClick = () => {
            navigate("/mypage/program");
            modalOption.clearModals();
          };
          return { ...e };
        });
      else {
        modalOption.setToastModal((e) => {
          e.show = true;
          e.text = "환불 신청에 실패하였습니다. 고객센터에 문의해주세요.";
          return { ...e };
        });
      }
    } else {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text =
          inputs.refund.length == 0
            ? "환불 사유를 작성해 주세요."
            : "취소 및 환불 규정을 확인해 주세요.";
        return { ...e };
      });
    }
  };

  return (
    <div className="wrap">
      <common.SubHeader pageName={"환불 신청"} />

      <Styles.Container>
        <section className="refundSection">
          <div className="inner">
            <div className="program">
              {orderInfo?.detailQuery?.data?.data && (
                <layout.MyProgram
                  data={orderInfo?.detailQuery?.data?.data}
                  hideStateBar={true}
                />
              )}
            </div>

            <div className="textarea">
              <ui.input.BasicTextarea data={refundData} onChange={onChange} />
            </div>
          </div>
        </section>

        <section className="termsSection">
          <div className="inner">
            <ul className="termsLink">
              {termsLinkList.map((item, index) => {
                return (
                  <li className="termsLinkList" key={"termsLinkList" + index}>
                    <Link to={item.url}>
                      {item.text}{" "}
                      <div className="icon">
                        <img src={IconArrowRight} alt="IconArrowRight" />
                      </div>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>

        <div className="check">
          <ui.input.BasicCheckbox
            id={"yourselfCheck"}
            labelText={"취소 및 환불 규정을 확인했습니다."}
            checked={check}
            onChange={(e) => {
              setCheck(e.target.checked);
            }}
          />
        </div>

        <div className="buttonContainer">
          <div className="inner">
            <div className="buttonWrap">
              <ui.button.BasicButton
                buttonText={"취소"}
                buttonType={"green"}
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
            <div className="buttonWrap">
              <ui.button.BasicButton
                buttonText={"환불 신청"}
                onClick={doRefund}
              />
            </div>
          </div>
        </div>
      </Styles.Container>
    </div>
  );
}

export default MyRefundPage;
