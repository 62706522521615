import Styles from "./styles";
import { ui } from "components";
import { useNavigate } from "react-router-dom";

// img
import Logo from "resources/image/icon/logo.svg";
import ImgSignupComplete from "resources/image/img_signup_complete.png";

function SignupCompletePage() {
  const navigate = useNavigate();

  return (
    <div className="wrap">
      <Styles.Container>
        <div className="inner">
          <div className="logo">
            <img src={Logo} alt="logo" />
          </div>

          <p className="title">오뉴 멤버가 되신 것을 환영합니다!</p>

          <div className="pointWrap">
            <p>
              관심사 정보를 입력하시면 <br />
              <b>포인트 1,000점을 드립니다!</b>
            </p>

            <div className="img">
              <img src={ImgSignupComplete} alt="ImgSignupComplete" />
            </div>
          </div>

          <p className="text">
            여러분의 가슴 뛰는 일상을 돕기 위해 <br />
            관심사 정보를 입력해 주세요!
          </p>

          <ul className="buttonListWrap">
            <li className="buttonList">
              <ui.button.BasicButton
                buttonText={"관심사 정보 등록하기"}
                onClick={() => {
                  navigate("/interest");
                }}
              />
            </li>

            <li className="buttonList">
              <ui.button.BasicButton
                buttonText={"오뉴 메인으로"}
                buttonType={"gray"}
                onClick={() => {
                  navigate("/");
                }}
              />
            </li>
          </ul>
        </div>
      </Styles.Container>
    </div>
  );
}

export default SignupCompletePage;
