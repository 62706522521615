import Styles from "./styles";
import utils from "utils";
import { useNavigate } from "react-router-dom";
import { useAuth, useModals } from "hooks";

// img
import ImgDefaultProfile from "resources/image/img_default_profile.svg";

function ReviewList({ data }) {
  const authInfo = useAuth({});
  const navigate = useNavigate();

  return (
    <Styles.Container>
      <div className="reviewTop">
        <div className="profileImg">
          <img
            src={data?.profile_image ? data?.profile_image : ImgDefaultProfile}
            alt="profileImg"
          />
        </div>

        <p className="nickname">{data.nickname}</p>

        <div className="starWrap">{utils.etc.renderStarPotint(data.score)}</div>
      </div>

      <div className="reviewBottom">
        {data?.images?.length > 0 && (
          <ul className="photoListWrap">
            {data?.images?.split(",")?.map((item, index) => {
              return (
                <li className="photoReviewList" key={"photoReviewList" + index}>
                  <img src={item} alt="photoReview" />
                </li>
              );
            })}
          </ul>
        )}

        <p className="reviewDetail">{data.description}</p>

        <div className="dateWrap">
          <div className="date">{utils.date.getDateType(data.created_at,'.')}</div>

          {console.log(data.write_yn)}
          {data?.write_yn === "N" && data?.report_yn === "N" && authInfo.isLogin === true &&
            <button
              type="button"
              className="reportButton"
              onClick={() => {
                if (authInfo.certification()) {
                  navigate("/report", { state: { type: 5, id: data.id } });
                }
              }}
            >
              신고
            </button>
          }
        </div>
      </div>
    </Styles.Container>
  );
}

export default ReviewList;
