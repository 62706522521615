import Styles from "./styles";
import ScrollContainer from "react-indiana-drag-scroll";
import { useCategory } from "hooks";
import { NavLink, Link } from "react-router-dom";

function CategoryNavigation({ mainHomeText, url, introduction }) {
  const pageType = () => {
    switch (url) {
      case "/program": return { type : 2 , id : 2};
      case "/magazine": return { type : 1 , id : 1};
      case "/introduction": return { type : 3 , id : 3};
    }
  }

  const categoryInfo = useCategory({
    listOption: {
      type: pageType().type, 
      parent_info_id:pageType().id,
    },
  });

  const data = introduction
    ? [...categoryInfo.list, introduction]
    : categoryInfo.list

  return (
    <Styles.Container>
      <ScrollContainer className="scroll-container">
        <ul>
          <li className="categoryList">
            <Link to={url}>
              <div>{mainHomeText}</div>
            </Link>
          </li>

          {data?.map(
            (item, index) => {
              return (
                <li className="categoryList" key={"categoryList" + index}>
                  <NavLink
                    to={url + "/category/" + item.id}
                    onClick={item.onClick && item.onClick}
                  >
                    <div>{item.title}</div>
                  </NavLink>
                </li>
              );
            }
          )}
        </ul>
      </ScrollContainer>
    </Styles.Container>
  );
}

export default CategoryNavigation;
