import styled from "styled-components";

const Input = styled.input`
  display: none;

  &:checked + label {
    background-color: var(--purple);
    border-color: var(--purple);
  }

  &:disabled + label {
    color: var(--gray02);
    background-color: var(--gray03);
    border-color: var(--gray03);

    & .people {
      color: var(--gray02);
    }
  }
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 224px;
  height: 90px;
  background-color: #fff;
  border: 1px solid var(--gray02);
  border-radius: 10px;
  cursor: pointer;

  & .time {
    display: inline-block;
    font-size: 22px;
    font-weight: 600;
  }

  & .people {
    margin-top: 5px;
    font-size: 18px;
    color: var(--gray01);
  }

  @media (max-width: 520px) {
    width: calc(100vw * (224 / 500));
    height: calc(100vw * (90 / 500));
    border-radius: calc(100vw * (10 / 500));

    & .time {
      font-size: calc(100vw * (22 / 500));
    }

    & .people {
      margin-top: calc(100vw * (5 / 500));
      font-size: calc(100vw * (18 / 500));
    }
  }
`;

export { Input, Label };

export default { Input, Label };
