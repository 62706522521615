import styled from "styled-components";

const Container = styled.main`
  min-height: 100vh;
  min-height: 100dvh;
  padding-bottom: 100px;

  & .mainBannerSection {
    margin-bottom: 40px;
  }

  & .storySection {
    & .useLink {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 90px;
      margin-bottom: 40px;
      color: #fff;
      background-color: var(--green);
      border-radius: 10px;

      & p:first-child {
        margin-bottom: 8px;
        font-size: 22px;
        font-weight: bold;
        white-space: pre-wrap;
      }

      & p:last-child {
        font-size: 18px;
        font-weight: 400;
        white-space: pre-wrap;
      }
    }

    & .storyContainer {
      &:not(:last-child) {
        margin-bottom: 40px;
        padding-bottom: 40px;
        border-bottom: 10px solid var(--gray03);
      }
    }

    & .emptyText {
      padding: 176px 0 175px;
      color: var(--gray01);
      text-align: center;
    }
  }

  @media (max-width: 520px) {
    padding-bottom: calc(100vw * (100 / 500));

    & .mainBannerSection {
      margin-bottom: calc(100vw * (40 / 500));
    }

    & .storySection {
      & .useLink {
        height: calc(100vw * (90 / 500));
        margin-bottom: calc(100vw * (40 / 500));
        border-radius: calc(100vw * (10 / 500));

        & p:first-child {
          margin-bottom: calc(100vw * (8 / 500));
          font-size: calc(100vw * (22 / 500));
        }

        & p:last-child {
          font-size: calc(100vw * (18 / 500));
        }
      }

      & .storyContainer {
        &:not(:last-child) {
          margin-bottom: calc(100vw * (40 / 500));
          padding-bottom: calc(100vw * (40 / 500));
          border-bottom: calc(100vw * (10 / 500)) solid var(--gray03);
        }
      }

      & .emptyText {
        padding: calc(100vw * (176 / 500)) 0 calc(100vw * (175 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
