import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90px;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  background-color: var(--green);

  & p:first-child {
    margin-bottom: 8px;
    font-size: 22px;
    font-weight: bold;
  }

  & p:last-child {
    font-size: 18px;
  }

  &.time {
    background-color: var(--main);
  }

  @media (max-width: 520px) {
    height: calc(100vw * (90 / 500));
    border-radius: calc(100vw * (10 / 500));

    & p:first-child {
      margin-bottom: calc(100vw * (8 / 500));
      font-size: calc(100vw * (22 / 500));
    }

    & p:last-child {
      font-size: calc(100vw * (18 / 500));
    }
  }
`;

export { Container };

export default { Container };
