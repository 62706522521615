import Styles from "./styles";
import { useEffect, useState } from "react";
import { common, ui } from "components";
import { useAuth, useModals } from "hooks";

function DeleteAccountPage() {
  const modalOption = useModals();
  const authInfo = useAuth({});

  const [inputs, setInputs] = useState({
    reason: "",
    textarea: "",
  });
  const [check, setCheck] = useState(false);

  const onRadioChange = (e) => {
    const { name, id } = e.target;
    setInputs((e) => {
      return { ...e, [name]: id, textarea: "" };
    });
  };
  const onTextareaChange = (e) => {
    const { name, value } = e.target;
    setInputs((e) => {
      return { ...e, [name]: value };
    });
  };

  const reasonList = [
    "흥미로운 콘텐츠가 부족해요.",
    "프로그램이 만족스럽지 않아요.",
    "매거진 정보가 만족스럽지 않아요.",
    "커뮤니티 기능이 만족스럽지 않아요",
    "고객 상담에 불만이 있어요.",
    "가격이 너무 비싸요.",
    "거리가 너무 멀어요.",
    "기타",
  ];
  const textareaData = {
    id: "textarea",
    value: inputs.textarea,
    name: "textarea",
    placeholder: "직접 입력",
    errorText: false,
  };
  const guideList = [
    <>
      회원 탈퇴 시 현재 로그인된 아이디는 즉시 탈퇴 처리되며 <br />
      서비스 탈퇴 후 전자상거래법에 의거. <br />
      거래 기록은 5년간 보관 후 폐기 합니다.
    </>,
    <>
      탈퇴 후 보유하고 있는 각종 포인트와 쿠폰은 자동 소멸되며 <br />
      재가입할 경우에도 복원되지 않습니다. 또한, 오뉴가 제공한 <br />
      이벤트 혜택과 당첨 이벤트도 취소 됩니다.
    </>,
    "결제한 프로그램에 대해서는 자동 취소되지 않습니다.",
    "탈퇴 후 회원 정보 및 관심 서비스, 등록한 게시물의 이용 기록은 모두 삭제되며 이때 삭제된 데이터는 복구되지 않습니다.",
  ];

  const validate = () => {
    if (inputs.reason === "") {
      modalOption.setToastModal((e) => { e.show = true; e.text = "탈퇴 사유를 선택해 주세요."; return { ...e }; });
      return false;
    }
    if (inputs.reason === "기타" && inputs.textarea === "") {
      modalOption.setToastModal((e) => { e.show = true; e.text = "기타사유를 입력해 주세요."; return { ...e }; });
      return false;
    }
    if (!check) {
      modalOption.setToastModal((e) => { e.show = true; e.text = "탈퇴 안내를 확인해 주세요."; return { ...e }; });
      return false;
    }
    return true;
  }
  
  const onNext = () => {
    if (validate()) {
      authInfo.resign(inputs);
    }
  }

  useEffect(()=>{
    setInputs((e) => { return { ...e, textarea: "" } })
  },[]);

  return (
    <div className="wrap">
      <common.SubHeader pageName={"탈퇴하기"} />

      <Styles.Container>
        <section className="reasonSection">
          <div className="inner">
            <h2>탈퇴 사유를 선택해 주세요.</h2>

            <ul>
              {reasonList.map((item, index) => {
                return (
                  <li className="reasonList" key={"reasonList" + index}>
                    <ui.input.ReasonRadio
                      id={item}
                      labelText={item}
                      checked={inputs.reason}
                      name={"reason"}
                      onChange={onRadioChange}
                    />
                  </li>
                );
              })}
            </ul>

            <div className="textarea">
              <ui.input.BasicTextarea
                data={textareaData}
                disabled={inputs.reason !== "기타"}
                onChange={onTextareaChange}
              />
            </div>
          </div>
        </section>

        <section className="guideSection">
          <div className="inner">
            <p className="title">오뉴를 떠나는 회원님에게</p>

            <ul>
              {guideList.map((item, index) => {
                return (
                  <li className="guideList" key={"guideList" + index}>
                    {item}
                  </li>
                );
              })}
            </ul>
          </div>
        </section>

        <div className="inner">
          <div className="check">
            <ui.input.BasicCheckbox
              id={"check"}
              labelText={"탈퇴 안내를 확인했습니다."}
              checked={check}
              onChange={(e) => {
                setCheck(e.target.checked);
              }}
            />
          </div>

          <div className="buttonWrap">
            <ui.button.BasicButton
              buttonText={"탈퇴하기"}
              onClick={onNext}
            />
          </div>
        </div>
      </Styles.Container>
    </div>
  );
}

export default DeleteAccountPage;
