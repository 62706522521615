import Styles from "./styles";
import { common, layout, ui } from "components";
import { useNavigate } from "react-router-dom";
import { api } from "service";
import utils from "utils";
import { useTransaction } from "hooks";

function MyPaymentHistoryPage() {
  const navigate = useNavigate();
  const transactionInfo = useTransaction({
    transactionDetailOption: {
      id: utils.etc.getSearchParam().id,
    },
  });

  const detail = transactionInfo.detailQuery?.data?.data;
  const optionList = detail?.group_list[0]?.option_list?.filter(
    (value) => value.type != "2"
  );

  const renderButton = (state) => {
    switch (String(state)) {
      case "21":
        return (
          <>
            <div className="buttonWrap">
              <ui.button.BasicButton
                buttonText={"환불 신청"}
                buttonType={"green"}
                onClick={() => {
                  navigate("/mypage/refund?id=" + detail.id);
                }}
              />
            </div>
            <div className="buttonWrap">
              <ui.button.BasicButton
                buttonText={"리뷰 작성"}
                onClick={() => {
                  navigate("/review/writing?id=" + detail.id);
                }}
              />
            </div>
          </>
        );
      case "22":
        return (
          <ui.button.BasicButton
            buttonText={"리뷰 작성"}
            onClick={() => {
              navigate("/review/writing?id=" + detail.id);
            }}
          />
        );
      case "30":
        return (
          <ui.button.BasicButton
            buttonText={"리뷰 수정"}
            onClick={() => {
              navigate("/review/edit?id=" + detail.id);
            }}
          />
        );
      case "20":
        return (
          <ui.button.BasicButton
            buttonText={"결제 취소"}
            buttonType={"green"}
            onClick={() => {
              navigate("/mypage/payment-cancel?id=" + detail.id);
            }}
          />
        );
    }
  };

  return (
    <div className="wrap">
      <common.SubHeader pageName={"내역 상세"} />

      <Styles.Container>
        <section className="programInfoSection">
          <layout.ProgramInfoSection detail={detail} />
        </section>

        <section className="detailSection">
          <layout.ProgramDetailSection detail={detail} />
        </section>

        {detail?.type != "2" ? (
          optionList?.length > 0 && (
            <section className="optionSection">
              <layout.ProgramOptionSection detail={detail} />
            </section>
          )
        ) : (
          <section className="scheduleSection">
            <div className="inner">
              <div className="title">프로그램 일정</div>

              <ul className="time">
                {detail?.group_list[0]?.option_list?.map((item, index) => {
                  return (
                    <li className="timeList" key={"timeList" + index}>
                      <p className="number">{index + 1}</p>
                      <p className="optionTitle">{utils.date.getDateType(item.started_at,'.')}</p>
                      <p className="optionTime">{item.title}</p>
                    </li>
                  );
                })}
              </ul>
            </div>
          </section>
        )}

        <section className="peopleSection">
          <div className="inner">
            <p className="title">수강 회원 정보</p>

            <ul className="people">
              {(detail?.member_list || []).map((item, index) => {
                return (
                  <li className="peopleList" key={"peopleList" + index}>
                    <div className="number">{index + 1}</div>
                    <p className="name">{item.name}</p>
                    <p className="phone">{item?.phone?.substr(0, 3) +
                      (item?.phone?.length > 3
                        ? "-" +
                        item?.phone?.substr(3, 4) +
                          (item?.phone?.length > 7
                            ? "-" + item?.phone?.substr(7, 4)
                            : "")
                        : "")}</p>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>

        <section className="methodSection">
          <div className="inner">
            <div className="title">결제 방법</div>

            <div className="method">
              {detail?.method == "CARD" ? (
                <>
                  신용카드{" "}
                  {detail?.receipt_url && (
                    <a href={detail?.receipt_url}>결제 영수증</a>
                  )}
                </>
              ) : (
                "가상계좌"
              )}
            </div>
          </div>
        </section>

        <section className="priceSection">
          <layout.ProgramPriceSection
            data={{
              point: detail?.total_point,
              coupon: 0,
              price: detail?.total_product_price,
            }}
            totalPrice={utils.etc.numberWithCommas(
              detail?.total_price
            )}
            detailPage
            // fee는 클래스 시작 일 기준 D-2~D-1 일 때만
            fee
            // ~ D-3 : 전액 취소, D-2~D-1 : 10% 차감 후 취소
            state={detail?.state}
            cancelPrice={detail?.state == -1 || detail?.state == 89 ? utils.etc.numberWithCommas(detail?.canceled_price) : false}
          />
        </section>

        {/* {data.state < 4 && ( */}
        <div className="buttonContainer">
          <div className="inner">{renderButton(detail?.state)}</div>
        </div>
        {/* // )} */}
      </Styles.Container>
    </div>
  );
}

export default MyPaymentHistoryPage;
