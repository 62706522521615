import { useAtom } from "jotai";
import { useQuery } from "react-query";
import { api } from "service";
import { modalAtom } from "store/atoms";

export default function useCategory({
  listOption,
  bannerOption
}) {
  
  const listQuery = useQuery(["category",listOption], 
    ()=>{
      return api.category.getList(listOption);
    }, {
    enabled:!!listOption,
    refetchOnWindowFocus: false,
  });
  
  const bannerListQuery = useQuery(["categoryBanner",bannerOption], 
    ()=>{
      return api.category.getCategoryBannerList(bannerOption?.id)
    }, {
    enabled:!!bannerOption,
    refetchOnWindowFocus: false,
  });

  return {
    listQuery,
    bannerListQuery,
    list : listQuery.data?.data ? listQuery.data?.data : [] 
  };
}
