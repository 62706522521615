import Styles from "./styles";
import ui from "components/ui";

// img
import IconClose from "resources/image/icon/icon_close.svg";

function CouponModal({ modalOption, modalClose }) {
  return (
    <Styles.Container>
      <div className="modalContainer">
        <button
          type="button"
          className="closeButton"
          onClick={() => {
            modalClose();
          }}
        >
          <img src={IconClose} alt="IconClose" />
        </button>

        <p className="title">쿠폰 선택</p>

        <p className="text">적용 가능한 쿠폰이 없습니다.</p>

        <div className="button">
          <ui.button.BasicButton
            buttonText={"쿠폰 적용하기"}
            onClick={() => {}}
          />
        </div>
      </div>
    </Styles.Container>
  );
}

export default CouponModal;
