import Styles from "./styles";

function FirstOptionCheckbox({ id, name, data, checked, onChange, amount }) {
  return (
    <>
      <Styles.Input
        id={id}
        name={name}
        type="checkbox"
        disabled={
          data?.amount - data?.current_amount == 0 ||
          data?.amount - data?.current_amount - amount < 0
        }
        checked={checked}
        onChange={onChange}
      />

      <Styles.Label htmlFor={id}>
        <span className="time">{data?.title}</span>

        {/* <span className="people">
          {data?.current_amount}/{data?.amount}
        </span> */}
      </Styles.Label>
    </>
  );
}

export default FirstOptionCheckbox;
