import Styles from "./styles";
import { common, layout, ui } from "components";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useBoard, useLike, useModals, useReply, useSearch } from "hooks";
import utils from "utils";
import { useEffect, useState } from "react";

//img
import ImgDummy from "resources/image/img_dummy_list.png";
import IconShare from "resources/image/icon/icon_share.svg";

function MagazineDetailPage() {
  const params = useParams();
  const modalOption = useModals();
  const navigate = useNavigate();
  const [filter, setFilter] = useState(null);
  const location = useLocation();

  const searchInfo = useSearch({
    relateListOption: {
      id: params?.id,
      type: 1,
    },
  });
  const replyInfo = useReply({
    listOption: filter,
  });
  const likeInfo = useLike({
    queryName: "board_detail",
    callFilter: { id: params?.id || 1 },
  });
  const boardInfo = useBoard({
    detailOption: {
      id: params?.id || 1,
    },
  });

  useEffect(() => {
    let param = utils.etc.getSearchParam();
    setFilter({
      page: param.page || 1,
      recordSize: param.recordSize || 5,
      pageSize: param.pageSize || 5,
      relate_info_id: (params?.id).replace(/[^0-9]/gi, ""),
      type: param.type || 1,
    });
  }, [location]);
  const detail = boardInfo.detailQuery.data?.data || {};

  return (
    <div className="wrap">
      <common.SubHeader pageName={"매거진"} />

      <Styles.Container>
        <section className="programSection">
          <div className="thumbnail">
            <div className="img">
              <img
                src={detail.image ? detail.image : ImgDummy}
                alt="programImg"
              />
            </div>

            <div className="textWrap">
              <div className="inner">
                <p className="title">
                  {detail.category_subtitle}{" "}
                  <Link
                    to={"/magazine/category/" + detail?.category_info_id}
                    className="categoryName"
                  >
                    {detail.category_title}
                  </Link>
                </p>

                <p className="mainTitle">{detail.title}</p>
                <p className="description">{detail.subtitle}</p>
              </div>
            </div>
          </div>

          <div className="editorWrap">
            <div className="inner">
              <div
                className="editor ck-content"
                dangerouslySetInnerHTML={{ __html: detail.description }}
              ></div>

              <ul className="reaction">
                <li className="reactionList">
                  <ui.button.LikeButton
                    like={detail?.like_yn == "Y" ? true : false}
                    likeNumber={detail?.like_count}
                    likeInfo={{ page: likeInfo, id: detail.id, type: 1 }}
                  />
                </li>

                <li className="reactionList">
                  <button
                    type="button"
                    className="shareButton"
                    onClick={() => {
                      if (utils.url.getUrlCopy())
                        modalOption.setToastModal((e) => {
                          e.show = true;
                          e.text = "주소가 복사되었습니다.";
                          return { ...e };
                        });
                    }}
                  >
                    <img src={IconShare} alt="IconShare" />
                  </button>
                </li>
              </ul>

              <ul className="keyword">
                {detail.keyword?.split(",")?.map((item, index) => {
                  return (
                    <li className="keywordList" key={"keywordList" + index}>
                      <ui.button.Keyword data={item} />
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          <div className="commentWrap">
            <div className="inner">
              <layout.Comment replyInfo={replyInfo} />
            </div>
          </div>

          {searchInfo?.relateQuery?.data?.data && (
            <div className="relatedContents">
              <p className="contentsTitle inner">관련 콘텐츠</p>
              <layout.ContentsSlider
                slider={"program"}
                data={searchInfo?.relateQuery?.data?.data}
                isHideMore={true}
                urlCallback={(e) => {
                  e.type == 2
                    ? navigate("/program/detail/" + e.id)
                    : navigate("/magazine/detail/" + e.id);
                }}
              />
            </div>
          )}
        </section>
      </Styles.Container>
      <common.QuickMenu />
      <common.NavigationBar />
    </div>
  );
}

export default MagazineDetailPage;
