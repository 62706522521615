import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 100vh;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10;

  & .modalContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 420px;
    padding: 70px 14px 0;
    background-color: #fff;
    border-radius: 10px;

    & .closeButton {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 34px;
      height: 34px;
    }

    & .modalInner {
      width: 100%;
      max-height: 60vh;
      max-height: 60dvh;
      padding-bottom: 30px;
      overflow: auto;
    }

    & .todayButton {
      position: absolute;
      top: calc(100% + 20px);
      left: 50%;
      transform: translateX(-50%);
      font-size: 20px;
      font-weight: 500;
      color: #fff;
    }
  }

  @media (max-width: 520px) {
    width: 100%;

    & .modalContainer {
      width: calc(100vw * (420 / 500));
      padding: calc(100vw * (70 / 500)) calc(100vw * (40 / 500)) 0;
      border-radius: calc(100vw * (10 / 500));

      & .closeButton {
        top: calc(100vw * (20 / 500));
        right: calc(100vw * (20 / 500));
        width: calc(100vw * (34 / 500));
        height: calc(100vw * (34 / 500));
      }

      & .modalInner {
        padding-bottom: calc(100vw * (30 / 500));
      }

      & .todayButton {
        top: calc(100% + calc(100vw * (20 / 500)));
        font-size: calc(100vw * (20 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
