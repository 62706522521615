import styled from "styled-components";

//img
import IconArrowCurved from "resources/image/icon/icon_arrow_curved.svg";

const Container = styled.div`
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--gray03);

  &.reply {
    padding-left: 45px;

    &::before {
      position: absolute;
      top: 0;
      left: 15px;
      width: 15px;
      height: 19px;
      background: url(${IconArrowCurved}) no-repeat center / cover;
      content: "";
    }

    & .profileContainer .userName {
      max-width: 282px;
    }
  }

  & .moreButtonContainer {
    position: absolute;
    top: 6px;
    right: 0;
    width: 24px;
    height: 24px;

    & .moreListWrap {
      position: absolute;
      top: 0;
      right: 100%;
      background: #fff;
      border-radius: 6px;
      box-shadow: 3px 5px 15px 0px rgba(27, 10, 51, 0.2);
      overflow: hidden;

      & .moreList:not(:last-child) {
        border-bottom: 1px solid var(--gray03);
      }

      & button {
        width: 90px;
        height: 40px;
        font-size: 20px;
        font-weight: 500;
      }
    }
  }

  & .profileContainer {
    display: flex;
    align-items: center;

    & .profileImgWrap {
      width: 37px;
      height: 37px;
      margin-right: 8px;
      border-radius: 50%;
      overflow: hidden;
    }

    & .userName {
      max-width: 324px;
      font-size: 22px;
      font-weight: 600;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  & .comment {
    margin: 10px 0 20px;
    padding: 0 24px 0 45px;
    font-size: 22px;
    font-weight: 400;
  }

  & .bottomContainer {
    display: flex;
    gap: 16px;
    padding-left: 45px;
    font-weight: 400;

    & .date,
    .replyButton {
      font-size: 18px;
      color: var(--gray01);
    }
  }

  & .editCommentContainer {
    margin-top: 20px;
  }

  @media (max-width: 499px) {
    margin-bottom: calc(100vw * (20 / 500));
    padding-bottom: calc(100vw * (20 / 500));

    &.reply {
      padding-left: calc(100vw * (45 / 500));

      &::before {
        left: calc(100vw * (18 / 500));
        width: calc(100vw * (16 / 500));
        height: calc(100vw * (23 / 500));
      }

      & .profileContainer .userName {
        max-width: calc(100vw * (282 / 500));
      }
    }

    & .moreButtonContainer {
      top: calc(100vw * (6 / 500));
      width: calc(100vw * (24 / 500));
      height: calc(100vw * (24 / 500));

      & .moreListWrap {
        border-radius: calc(100vw * (6 / 500));
        box-shadow: calc(100vw * (3 / 500)) calc(100vw * (5 / 500))
          calc(100vw * (15 / 500)) 0 rgba(27, 10, 51, 0.2);

        & button {
          width: calc(100vw * (90 / 500));
          height: calc(100vw * (40 / 500));
          font-size: calc(100vw * (20 / 500));
        }
      }
    }

    & .profileContainer {
      & .profileImgWrap {
        width: calc(100vw * (37 / 500));
        height: calc(100vw * (37 / 500));
        margin-right: calc(100vw * (8 / 500));
      }

      & .userName {
        max-width: calc(100vw * (324 / 500));
        font-size: calc(100vw * (22 / 500));
      }
    }

    & .comment {
      margin: calc(100vw * (10 / 500)) 0 calc(100vw * (20 / 500));
      padding: 0 calc(100vw * (24 / 500)) 0 calc(100vw * (45 / 500));
      font-size: calc(100vw * (22 / 500));
    }

    & .bottomContainer {
      gap: calc(100vw * (16 / 500));
      padding-left: calc(100vw * (45 / 500));

      & .date,
      .replyButton {
        font-size: calc(100vw * (18 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
