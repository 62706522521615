import utils from "utils";
import Styles from "./styles";

function ProgramInfoSection({ productInfo, transactionList, detail }) {
  const optionList = detail?.group_list[0]?.option_list?.filter(
    (value) => value.type == "0"
  );

  return (
    <Styles.Container>
      <div className="inner">
        <div className="title">프로그램 정보</div>

        <div className="right">
          <p className="date">
            {optionList && optionList[0]?.started_at
              ? utils.date.getDateType(optionList[0]?.started_at, ".")
              : null}
            {transactionList && transactionList[0]?.date
              ? utils.date.getDateType(transactionList[0]?.date, ".")
              : null}
            {/* {!data?.type == 0 && "~"} */}
          </p>
          <p className="people">
            수강 인원
            <b>
              {detail && detail?.group_list[0]
                ? detail?.group_list[0].amount
                : transactionList && transactionList[0]?.amount}
              명
            </b>
          </p>
        </div>
      </div>
    </Styles.Container>
  );
}

export default ProgramInfoSection;
