import Styles from "./styles";
import { date } from "utils";

// img
import IconArrowRight from "resources/image/icon/icon_arrow_right.svg";
import IconArrowLeft from "resources/image/icon/icon_arrow_left.svg";

export default function Calendar({ 
  calendar, 
  activeAll, //activeAll 일시 day.enable에 해당 되는 날짜들은 전부 active
}) {

  function getDayRender(day, i) {
    return (
      <div
        // 1. 시간권 일 때
        // 일정이 있는 날짜에 schedule 클래스를 넣어주세요!
        // 클릭 시 클릭한 날짜에만 active 넣어주세요!
        // 2. 정기권 / 원데이 일 때
        // 유저가 일정 선택 불가, 정해진 schedule 날짜(복수)에 schedule, active 클래스 전부 넣어주세요!
        className={`date ${day.enable ? "schedule" : null} ${day.enable && activeAll && !day.isBefore && !day.isAfter && "active"} ${
          calendar.value && date.equalTwoDate(calendar.value, day.date)
            ? "active"
            : ""
        }`}
        key={"day_info_" + "_" + i}
        onClick={() => {
          if((!day.isBefore && !day.isAfter) == false)return;
          if(activeAll)return;
          if (!day.isBefore && !day.isAfter) calendar.onClickDate(day.date);
        }}
      >
        <>
          {!day.isBefore && !day.isAfter ? (
            <p>{day.day}</p>
          ) : (
            <p className="emptyDate"></p>
          )}
        </>
      </div>
    );
  }

  function renderCalendar(dateObject) {
    let days = dateObject.days;
    let resultTag = [];
    let dayTags = [];

    resultTag.push(
      <div key={"calendar_tag_00"} className="dayContainer">
        <p className="day sunday">일</p>
        <p className="day">월</p>
        <p className="day">화</p>
        <p className="day">수</p>
        <p className="day">목</p>
        <p className="day">금</p>
        <p className="day saturday">토</p>
      </div>
    );

    for (let i = 0; i < days.length; i++) {
      //개행
      if (i != 0 && i % 7 == 0) {
        resultTag.push(
          <div key={"calendar_tag_" + i} className="row">
            {dayTags}
          </div>
        );
        dayTags = [];
      }
      //element 요소
      dayTags.push(getDayRender(days[i], i));

      //개행
      if (days.length === i + 1) {
        resultTag.push(
          // 마지막줄이 비어있으면 꼭 지워주세요!! 레이아웃이 달라집니다 ㅠㅠ
          <div className="row" key={"calendar_tag_" + i}>
            {dayTags}
          </div>
        );
      }
    }

    return resultTag;
  }

  return (
    <Styles.Container>
      <div className="dateTopContainer">
        <button
          type="button"
          onClick={() => {
            calendar.prevMonthEvent();
          }}
        >
          <img src={IconArrowLeft} alt="prev" />
        </button>

        <div className="dateWrap">
          <span className="year">{calendar.currentDate.getFullYear()}</span>.
          <span className="month">
            {calendar.currentDate.getMonth() + 1 < 10
              ? "0" + (calendar.currentDate.getMonth() + 1)
              : calendar.currentDate.getMonth() + 1}
          </span>
        </div>

        <button
          type="button"
          onClick={() => {
            calendar.nextMonthEvent();
          }}
        >
          <img src={IconArrowRight} alt="next" />
        </button>
      </div>

      <div className="dateBottomContainer">
        {renderCalendar(calendar.getDaysObject())}
      </div>
    </Styles.Container>
  );
}
