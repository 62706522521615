import styled from "styled-components";

const Container = styled.div`
  overflow: auto;

  & ul {
    display: flex;
    background-color: #fff;

    & li {
      flex: 1 0;
      color: var(--gray01);
      border-top: 1px solid var(--gray02);

      & a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 60px;
        padding: 0 16px;

        & > div {
          width: max-content;
        }

        &.active {
          position: relative;
          font-weight: 600;
          color: var(--main);

          &::before {
            position: absolute;
            top: -1px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: var(--main);
            content: "";
          }
        }
      }
    }
  }

  @media (max-width: 520px) {
    & ul li a {
      height: calc(100vw * (60 / 500));
    }
  }
`;

export { Container };

export default { Container };
