import styled from "styled-components";

const Container = styled.div`
  padding-bottom: 50px;

  & .totalPointWrap {
    margin-bottom: 20px;
    padding: 20px 0;
    font-weight: 600;
    border-top: 1px solid var(--gray02);
    border-bottom: 1px solid var(--gray02);

    & .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    & .text {
      color: var(--gray01);
    }

    & .totalPoint {
      font-size: 22px;
    }
  }

  & .select {
    width: 150px;
    margin: 0 0 20px auto;
  }

  & .pointList {
    padding-bottom: 20px;
    border-bottom: 1px solid var(--gray03);

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    & .top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      font-size: 22px;
      font-weight: 600;

      & .point.on {
        color: var(--green);
      }
    }

    & .bottom {
      font-size: 18px;
      font-weight: 400;
      color: var(--gray01);

      & .date {
        margin-right: 15px;
      }
    }
  }

  & .pagination {
    margin-top: 40px;
  }

  & .emptyText {
    padding-top: 184px;
    font-weight: 400;
    color: var(--gray01);
    text-align: center;
  }

  @media (max-width: 499px) {
    padding-bottom: calc(100vw * (50 / 500));

    & .totalPointWrap {
      margin-bottom: calc(100vw * (20 / 500));
      padding: calc(100vw * (20 / 500)) 0;

      & .totalPoint {
        font-size: calc(100vw * (22 / 500));
      }
    }

    & .select {
      width: calc(100vw * (150 / 500));
      margin: 0 0 calc(100vw * (20 / 500)) auto;
    }

    & .pointList {
      padding-bottom: calc(100vw * (20 / 500));

      &:not(:last-child) {
        margin-bottom: calc(100vw * (20 / 500));
      }

      & .top {
        margin-bottom: calc(100vw * (10 / 500));
        font-size: calc(100vw * (22 / 500));
      }

      & .bottom {
        font-size: calc(100vw * (18 / 500));

        & .date {
          margin-right: calc(100vw * (15 / 500));
        }
      }
    }

    & .pagination {
      margin-top: calc(100vw * (40 / 500));
    }

    & .emptyText {
      padding-top: calc(100vw * (184 / 500));
    }
  }
`;

export { Container };

export default { Container };
