import connector from "service/connector"
import utils from "utils";


const getList = async (data) => {
  return await connector.authConnector(`search?${utils.etc.getFilterStr(data)}`, "GET");
}

const getRelateList = async (data) => {
  return await connector.authConnector(`search/relate?${utils.etc.getFilterStr(data)}`, "GET");
}

export {
  getList,
  getRelateList,
};

export default {
  getList,
  getRelateList,
};