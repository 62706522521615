import { atom } from "jotai";

const defaultModalAtom = atom({ show: false, zIndex: 20 });
const toastModalAtom = atom({ show: false, zIndex: 20 });
const couponModalAtom = atom({ show: false, zIndex: 20 });
const termsModalAtom = atom({ show: false, zIndex: 20 });
const addressModalAtom = atom({ show: false, zIndex: 20 });
const guideModalAtom = atom({ show: false, zIndex: 20 });
const eventModalAtom = atom({ show: false, zIndex: 20 });

export {
  defaultModalAtom,
  toastModalAtom,
  couponModalAtom,
  termsModalAtom,
  addressModalAtom,
  guideModalAtom,
  eventModalAtom,
};

export default {
  defaultModalAtom,
  toastModalAtom,
  couponModalAtom,
  termsModalAtom,
  addressModalAtom,
  guideModalAtom,
  eventModalAtom,
};
