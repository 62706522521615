import Styles from "./styles";
import { Link } from "react-router-dom";
import { common } from "components";
import { useDirection } from "hooks";

// img
import Logo from "resources/image/icon/logo.svg";
import IconLocation from "resources/image/icon/icon_location02.svg";

function DirectionPage() {
  const directionInfo = useDirection({ locationList: true });
  const data = directionInfo.getLocation?.data?.data;

  return (
    <div className="wrap">
      <common.SubHeader pageName={"오시는 길"} />

      <Styles.Container>
        <div className="inner">
          <div className="logo">
            <img src={Logo} alt="logo" />
          </div>

          <ul>
            {data?.map((item, index) => {
              return (
                <li className="locaitonList" key={"locaitonList" + index}>
                  <Link to={"/direction/detail/" + item.id}>
                    <div className="icon">
                      <img src={IconLocation} alt="IconLocation" />
                    </div>

                    <div className="text">
                      <p className="location">{"오뉴 " + item.title}</p>
                      <p className="address">{item.address}</p>
                    </div>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </Styles.Container>

      <common.QuickMenu />
      <common.Footer />
      <common.NavigationBar />
    </div>
  );
}

export default DirectionPage;
