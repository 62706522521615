import Styles from "./styles";

function ReasonRadio({ id, name, labelText, checked, onChange }) {
  return (
    <>
      <Styles.Input
        id={id}
        name={name}
        type="radio"
        checked={checked === id}
        onChange={onChange}
      />

      <Styles.Label htmlFor={id}>
        {labelText}
        <span className="checkIcon"></span>
      </Styles.Label>
    </>
  );
}

export default ReasonRadio;
