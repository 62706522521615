import { useState, useCallback } from "react";
import { useDaumPostcodePopup } from "react-daum-postcode";
import DaumPostcodeEmbed from "react-daum-postcode";
import { AddressModal } from "components/modal";

export default function useAddress() {
  const CURRENT_URL =
    "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
  const open = useDaumPostcodePopup(CURRENT_URL);
  const [show, setShow] = useState(false);
  const [address, setAddress] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [addressDetail, setAddressDetail] = useState("");

  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    setAddress(fullAddress);
    setZipcode(data.zonecode);
    setShow(false);
  };

  const handleClick = () => {
    // open({ onComplete: handleComplete });
    setShow(true);
  };

  const RenderAddress = useCallback(() => {
    if (show)
      return (
        <AddressModal
          modalClose={() => {
            setShow(false);
          }}
          
        >
          <DaumPostcodeEmbed 
            onComplete={handleComplete} 
            style={{ 
              height:"70vh"
            }}
          />
        </AddressModal>
      );
    else return <div></div>;
  },[show]);

  return {
    handleClick,

    address,
    addressDetail,
    zipcode,

    setAddressDetail,

    RenderAddress,
  };
}
