import styled from "styled-components";

const Container = styled.section`
  & .searchContainer {
    position: relative;
    margin-bottom: 40px;

    & input {
      padding-right: 74px;
    }

    & .searchButton {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      width: 34px;
      height: 34px;
      filter: var(--main-filter);
    }
  }

  & .program {
    & .contentsTitle {
      margin-bottom: 20px;
      font-size: 22px;
      font-weight: bold;
    }

    & ul {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
    }

    & .emptyText {
      padding-top: 180px;
      color: var(--gray01);
      text-align: center;
    }
  }

  & .buttonContainer {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 500px;
    padding: 12px 0;
    border-top: 1px solid var(--gray02);
    background-color: #fff;
    z-index: 3;

    & .inner {
      display: flex;
      gap: 10px;

      & .buttonWrap {
        flex: 1;
      }
    }
  }

  @media (max-width: 520px) {
    & .searchContainer {
      margin-bottom: calc(100vw * (40 / 500));

      & input {
        padding-right: calc(100vw * (74 / 500));
      }

      & .searchButton {
        right: calc(100vw * (20 / 500));
        width: calc(100vw * (34 / 500));
        height: calc(100vw * (34 / 500));
      }
    }

    & .program {
      & .contentsTitle {
        margin-bottom: calc(100vw * (20 / 500));
        font-size: calc(100vw * (22 / 500));
      }

      & ul {
        gap: calc(100vw * (5 / 500));
      }

      & .emptyText {
        padding-top: calc(100vw * (180 / 500));
      }
    }

    & .buttonContainer {
      width: 100%;
      padding: calc(100vw * (12 / 500)) 0;

      & .inner {
        gap: calc(100vw * (10 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
