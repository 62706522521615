import Styles from "./styles";
import { common, layout, ui } from "components";
import { useNavigate, useLocation } from "react-router-dom";
import { api } from "service";
import utils from "utils";

// img
import IconCheck from "resources/image/icon/icon_check.svg";
import { useTransaction } from "hooks";

function PaymentCompletePage() {
  const navigate = useNavigate();
  const transactionInfo = useTransaction({
    transactionDetailOption: {
      id: utils.etc.getSearchParam().id,
    },
  });

  const detail = transactionInfo.detailQuery?.data?.data;
  const optionList = detail?.group_list[0]?.option_list?.filter(
    (value) => value.type != "2"
  );

  return (
    <div className="wrap">
      <common.SubHeader noPrevButton pageName={"결제완료"} />

      <Styles.Container>
        <div className="completeText">
          <div className="icon">
            <img src={IconCheck} alt="IconCheck" />
          </div>
          <p>
            결제가 <span>완료</span> 되었습니다!
          </p>
        </div>

        <section className="programInfoSection">
          <layout.ProgramInfoSection detail={detail} />
        </section>

        <section className="detailSection">
          <layout.ProgramDetailSection 
            detail={detail} 
            hideState={true}
          />
        </section>

        {detail?.type != "2" && optionList?.length > 0 && (
          <section className="optionSection">
            <layout.ProgramOptionSection detail={detail} />
          </section>
        )}

        {detail?.type == "2" && (
          <section className="optionSection">
            <layout.ProgramJungiOptionSection detail={detail} />
          </section>
        )}

        <section className="methodSection">
          <div className="inner">
            <div className="title">결제 방법</div>

            <div className="method">
              {detail?.method == "CARD" ? "신용카드" : "가상계좌"}
            </div>
          </div>
        </section>

        <section className="priceSection">
          <layout.ProgramPriceSection
            data={{
              point: detail?.total_point,
              coupon: 0,
              price: detail?.total_product_price,
            }}
            totalPrice={utils.etc.numberWithCommas(
              detail?.total_price
            )}
          />
        </section>

        <div className="buttonContainer">
          <div className="inner">
            <div className="buttonWrap">
              <ui.button.BasicButton
                buttonType={"green"}
                buttonText={"마이페이지 이동"}
                onClick={() => {
                  navigate("/mypage");
                }}
              />
            </div>

            <div className="buttonWrap">
              <ui.button.BasicButton
                buttonText={"프로그램 홈으로"}
                onClick={() => {
                  navigate("/program");
                }}
              />
            </div>
          </div>
        </div>
      </Styles.Container>
    </div>
  );
}

export default PaymentCompletePage;
