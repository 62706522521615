import Styles from "./styles";
import { ui } from "components";
import { Link } from "react-router-dom";
import { useSupport } from "hooks";
import { getDateType } from "utils/date";

function Notice() {
  const supportInfo = useSupport({
    options: { noticeList: true }
  });
  const data = supportInfo.getNoticeList.data || {};
  const total = data.pagination?.totalRecordCountAll

  return (
    <Styles.Container>
      {data.list?.length > 0 ? (
        <>
          <ul>
            {data.list.map((item, index) => {
              return (
                <li className="noticeList" key={"noticeList" + index}>
                  <Link to={"/contact/notice/detail/" + item.id}>
                    <p className="title">{item.title}</p>
                    <p className="bottom">
                      <span className="number">{total - index}</span>
                      <span className="date">{getDateType(item.created_at)}</span>
                    </p>
                  </Link>
                </li>
              );
            })}
          </ul>

          <div className="pagination">
            <ui.Pagination
              page={data.pagination?.page}
              list={data.pagination?.page_list}
              maxPage={data.pagination?.totalPageCount}
            />
          </div>
        </>
      ) : (
        <p className="emptyText">등록된 게시글이 없습니다.</p>
      )}
    </Styles.Container>
  );
}

export default Notice;
