import styled from "styled-components";

const Container = styled.div`
  padding: 20px 15px;
  font-size: 22px;
  border: 1px solid var(--gray02);

  & .top,
  & .location,
  & .bottom,
  & .button {
    display: flex;
    align-items: center;
  }

  & .top {
    gap: 10px;
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: 600;

    & .location {
      color: var(--main);

      & .icon {
        width: 30px;
        height: 30px;
      }
    }

    & .state {
      margin-left: auto;
      color: var(--gray01);
    }
  }

  & .date {
    margin-bottom: 15px;
    color: var(--gray01);

    & .people {
      margin-left: 15px;
    }
  }

  & .title {
    margin-bottom: 20px;
    padding-bottom: 20px;
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    border-bottom: 1px solid var(--gray02);
  }

  & .bottom {
    color: var(--gray01);

    & .icon {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }

    & .room {
      margin-left: 20px;
    }
  }

  & .button {
    gap: 10px;
    justify-content: center;
    margin-top: 20px;

    & button {
      width: 120px;
    }
  }

  @media (max-width: 520px) {
    padding: calc(100vw * (20 / 500)) calc(100vw * (15 / 500));
    font-size: calc(100vw * (22 / 500));

    & .top {
      gap: calc(100vw * (10 / 500));
      margin-bottom: calc(100vw * (30 / 500));
      font-size: calc(100vw * (20 / 500));

      & .location {
        & .icon {
          width: calc(100vw * (30 / 500));
          height: calc(100vw * (30 / 500));
        }
      }
    }

    & .date {
      margin-bottom: calc(100vw * (15 / 500));

      & .people {
        margin-left: calc(100vw * (15 / 500));
      }
    }

    & .title {
      margin-bottom: calc(100vw * (20 / 500));
      padding-bottom: calc(100vw * (20 / 500));
    }

    & .bottom {
      & .icon {
        width: calc(100vw * (24 / 500));
        height: calc(100vw * (24 / 500));
        margin-right: calc(100vw * (10 / 500));
      }

      & .room {
        margin-left: calc(100vw * (20 / 500));
      }
    }

    & .button {
      gap: calc(100vw * (10 / 500));
      margin-top: calc(100vw * (20 / 500));

      & button {
        width: calc(100vw * (120 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
