import styled from "styled-components";

// img
import IconArrowBottom from "resources/image/icon/icon_arrow_bottom.svg";
import IconArrowTop from "resources/image/icon/icon_arrow_top.svg";

const Container = styled.div`
  position: relative;
  font-size: 22px;
  font-weight: 600;
  cursor: pointer;

  & .nowSelect {
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0 15px;
    background: url(${IconArrowBottom}) no-repeat calc(100% - 15px) center /
      24px;
    border: 1px solid var(--gray01);
    border-radius: 5px;

    &.open {
      background-image: url(${IconArrowTop});
    }
  }

  & .optionListWrap {
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    width: 100%;
    max-height: 300px;
    background-color: #fff;
    border: 1px solid var(--gray01);
    border-radius: 5px;
    overflow: auto;
    z-index: 2;

    & .optionList {
      display: flex;
      align-items: center;
      height: 60px;
      padding: 0 15px;
    }
  }

  @media (max-width: 520px) {
    font-size: calc(100vw * (22 / 500));

    & .nowSelect {
      height: calc(100vw * (60 / 500));
      padding: 0 calc(100vw * (15 / 500));
      background: url(${IconArrowBottom}) no-repeat
        calc(100% - calc(100vw * (15 / 500))) center / calc(100vw * (24 / 500));
      border-radius: calc(100vw * (5 / 500));
    }

    & .optionListWrap {
      top: calc(100% + calc(100vw * (2 / 500)));
      max-height: calc(100vw * (300 / 500));
      border-radius: calc(100vw * (5 / 500));

      & .optionList {
        height: calc(100vw * (60 / 500));
        padding: 0 calc(100vw * (15 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
