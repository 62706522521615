import Styles from "./styles";
import { api } from "service";
import { useBoard, useInfiniteScroll, useProduct, useSearch } from "hooks";
import { common, layout } from "components";
import { useParams, useSearchParams } from "react-router-dom";
import { search } from "service/api";

function SearchDetailPage() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const searchInfo = useSearch({ listOption: {
    keyword : searchParams.get("keyword")
  } });

  const productInfo = useProduct({ infinitListOption: {
    page: 1,
    recordSize: 20,
    pageSize: 1,
    keyword : searchParams.get("keyword")
  }});

  const magazineInfo = useBoard({ infinitListOption: {
    page: 1,
    recordSize: 20,
    pageSize: 1,
    type:1,
    keyword : searchParams.get("keyword")
  }});

  const query = id == "magazine" ? magazineInfo.infiniteListQuery : productInfo.infiniteListQuery;
  const data = id == "magazine" ? magazineInfo.infiniteListQuery?.data : productInfo.infiniteListQuery?.data;

  const scrollInfo = useInfiniteScroll({
    query : query,
  });


  return (
    <div className="wrap">
      <common.SubHeader pageName={"검색하기"} />

      <Styles.Container>
        <section className="section">
          <div className="inner">
            <layout.SectionTitle
              title={
                id === "program" ? (
                  <>
                    프로그램 <span>({data?.pages ? data?.pages[0].totalCount : 0})</span>
                  </>
                ) : (
                  <>
                    매거진 <span>({data?.pages ? data?.pages[0].totalCount : 0})</span>
                  </>
                )
              }
            />

            <ul>
              {data?.pages?.map(v=> v == null ? [] : v.list || [])?.flat()?.map((item, index) => {
                return (
                  <li className="detailList" key={"detailList" + index}>
                    <layout.ContentsList
                      data={item}
                      big
                      url={`/${id}/detail/${item.id}`}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </section>
      </Styles.Container>
    </div>
  );
}

export default SearchDetailPage;
