import { useQuery } from "react-query";
import { api } from "service";

export default function useBanner({
  type,
  introduce
}) {

  const getBanner = useQuery(["banner", type], () => {
    return api.banner.getBanner(type);
  }, {
    enabled:!!type,
    refetchOnWindowFocus: false,
  });
  const clickBanner = (id, type) => {
    return api.banner.clickBanner(id, type);
  };
  const getIntroduceBanner = useQuery(["introduce"], () => {
    return api.banner.getIntroduceBanner();
  }, {
    enabled: !!introduce,
    refetchOnWindowFocus: false,
  });
  

  return { getBanner, clickBanner, getIntroduceBanner }
}