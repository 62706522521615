import { api } from "service";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useAuth from "hooks/useAuth";

export default function useLike({
  queryName,
  callFilter,

  programList,
  magazineList,
}) {
  const queryClient = useQueryClient();
  const authInfo = useAuth({})

  
  const likeProgramList = useQuery(["like_program"], () => {
    return api.like.likeProgramList();
  }, {
    refetchOnWindowFocus: false,
    enabled: !!programList
  });
  const likeMagazineList = useQuery(["like_magazine"], () => {
    return api.like.likeMagazineList();
  }, {
    refetchOnWindowFocus: false,
    enabled: !!magazineList
  });


  const toggleLike = async (relateInfoId, type) => {
    if (authInfo.certification()) {
      doLike({
        relate_info_id: relateInfoId,
        type: type
      });
    }
  }

  const { mutate: doLike } = useMutation(
    (e) => {
      return api.like.likeToggle(JSON.stringify(e)) },
    {
      async onMutate() {
        queryClient.setQueryData([queryName, callFilter],
          e => {
            if (e?.data) {
              e.data.like_yn = e.data.like_yn === "Y" ? "N" : "Y"
              e.data.like_count = parseInt(e.data.like_count);
              e.data.like_count += e.data.like_yn === "Y" ? 1 : -1;
              return { ...e };
            }
          }
        )
      },
      onError(error, variables, context) {
        context && context();
      },
    }
  );
  
  return {
    likeProgramList,
    likeMagazineList,
    toggleLike
  }
}