import Styles from "./styles";
import { Link } from "react-router-dom";

// img
import IconArrowRight from "resources/image/icon/icon_arrow_right_mini.svg";

function SectionTitle({ title, url }) {
  return (
    <Styles.Container className="inner">
      <p>{title}</p>

      {url && (
        <Link to={url}>
          전체보기{" "}
          <div className="icon">
            <img src={IconArrowRight} alt="IconArrowRight" />
          </div>
        </Link>
      )}
    </Styles.Container>
  );
}

export default SectionTitle;
