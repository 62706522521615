import styled from "styled-components";

const Container = styled.div`
  padding: 35px 0;
  color: #fff;
  background-color: var(--main);

  & .inner {
    display: flex;
    justify-content: space-between;
  }

  & .title {
    margin: 0;
  }

  & .right {
    font-size: 22px;
    text-align: right;

    & .date {
      margin-bottom: 10px;
    }

    & .people b {
      margin-left: 10px;
    }
  }

  @media (max-width: 520px) {
    padding: calc(100vw * (35 / 500)) 0;

    & .right {
      font-size: calc(100vw * (22 / 500));

      & .date {
        margin-bottom: calc(100vw * (10 / 500));
      }

      & .people b {
        margin-left: calc(100vw * (10 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
