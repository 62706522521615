import Styles from "./styles";
import { layout, ui } from "components";
import { useCalendar, useQuickProduct } from "hooks";
import { useOutletContext } from "react-router-dom";
import utils from "utils";
import { useEffect } from "react";

function DateReservation() {
  const calendar = useCalendar();
  const quickProductInfo = useQuickProduct({
    groupDateListOption: {
      date: utils.date.getDateType(calendar.currentDate, "-"),
    },
    groupListOption: {
      date: utils.date.getDateType(calendar.value, "-"),
    },
  });
  const { check, setCheck, searchParam, setSearchParam } = useOutletContext();

  useEffect(() => {
    calendar.updateValidMap(quickProductInfo.groupDateQuery?.data?.data);
    calendar.onClickDate(null);
  }, [quickProductInfo.groupDateQuery?.data]);

  return (
    <Styles.Container>
      <div className="inner">
        <div className="calendar">
          <layout.Calendar calendar={calendar} />
        </div>

        <div className="guideText">
          <p>날짜를 선택해주세요!</p>
          <p>원하는 날짜를 선택하면 프로그램을 확인할 수 있어요.</p>
        </div>

        {/* 날짜 선택 시에만 프로그램이 보이게 해주세요! */}
        <div className="program">
          <ul>
            {quickProductInfo?.groupListQuery?.data?.data?.map(
              (item, index) => {
                return (
                  <li className="programList" key={"programList" + index}>
                    <ui.input.ProgramRadio
                      big
                      data={item}
                      name={"program"}
                      checked={check === item.id ? true : false}
                      onClick={(e) => {
                        setSearchParam(
                          utils.etc.getFilterStr({
                            date: utils.date.getDateType(calendar.value, "-"),
                          })
                        );
                        setCheck(check == item.id ? "" : item.id);
                      }}
                    />
                  </li>
                );
              }
            )}
          </ul>
        </div>
      </div>
    </Styles.Container>
  );
}
export default DateReservation;
