import Styles from "./styles";

function SecondOptionCheckbox({ id, name, data, checked, onChange, amount }) {
  return (
    <Styles.Container>
      <label htmlFor={id}></label>

      <input
        id={id}
        name={name}
        type="checkbox"
        checked={checked}
        disabled={
          data?.amount - data?.current_amount == 0 ||
          data?.amount - data?.current_amount - amount < 0
        }
        onChange={onChange}
      />

      <div className="label">
        <div className="textWrap">
          <p className="time">{data?.title}</p>

          <div className="secondOptionInfo">
            <p className="optionTitle">{data?.product_title}</p>

            {/* <div className="people">
              {data?.current_amount}/{data?.amount}
            </div> */}
          </div>
        </div>
      </div>
    </Styles.Container>
  );
}

export default SecondOptionCheckbox;
