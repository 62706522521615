import useModals from "hooks/useModals";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { api } from "service";
import { modalAtom } from "store/atoms";

export default function useInterest({
  listOption,
  detailListOption,
}) {
  const modalOption = useModals();
  const [list,setList] = useState([]);

  const listQuery = useQuery(["interest_list",listOption], 
    ()=>{
      return api.interest.getList(listOption);
    }, {
    enabled:!!listOption,
    refetchOnWindowFocus: false,
    onSuccess : (e)=>{ 

      let result = [];
      e?.data.map((value)=>{
        let data = {
          id: value.id,
          description : value.description,
          multiple_yn: value.multiple_yn,
          required_yn: value.required_yn,
          type: value.type,
          title: value.title,
        };
        
        if(value.type == "4"){
          data.group_list = [];
          let groupObject = {};
          for(let i = 0;i<value.item_list.length;i++){
            if(!groupObject[value.item_list[i].group_title]){
              groupObject[value.item_list[i].group_title] = [];
            }

            // if(value.item_list[i].type == "2"){
            //   data.write_relate_id = value.item_list[i].id;
            //   data.placeholder = value.item_list[i].placeholder;
            //   data.write_obj = value.item_list[i];
            // }

            groupObject[value.item_list[i].group_title].push(value.item_list[i]);
          }
          for(let key in groupObject){
            data.group_list.push({
              group_title : key,
              item_list : groupObject[key]
            });
          }
        }else{
          value.item_list.map((value)=>{
            if(value.type == "2"){
              data.write_relate_id = value.id;
              data.placeholder = value.placeholder;
              data.write_obj = value;
            }
          })
          data.group_list = [ { group_title : "", item_list : value.item_list } ];
        }

        result.push(data);
      })
      setList([...result]); 
    }
  });

  const myListQuery = useQuery(["interest_my_detail",detailListOption], 
    ()=>{
      return api.interest.getMyList(detailListOption);
    }, {
    enabled:!!detailListOption && list.length > 0,
    refetchOnWindowFocus: false,
  });

  useEffect(()=>{
    let checkObj = {};
    myListQuery?.data?.data?.map((value)=>{
      checkObj[value.interest_item_info_id] = value;
    });

    list.map((group)=>{
      group.group_list.map((value)=>{
        value.item_list.map((value)=>{
          if(checkObj[value.id]){
            value.checked = true;
            if(value.type == "2" && checkObj[value.id].content)
              group.content = checkObj[value.id].content
          }
        })
      })
    })
    setList([...list])

  },[myListQuery?.data])

  const updateInterestInfo = async () => {
    
    if(validate() == false){
      return false;
    }
    
    let result = [];
    list.map((value)=>{
      for(let j = 0;j<value.group_list.length;j++){
        let itemList = value.group_list[j].item_list;
        itemList.map((v)=>{
          if(v.checked){
            let resultData = {
              interest_info_id : value.id,
              type : value.type,
              title : value.title,
            };
            resultData.content = (value.content || v.title);
            resultData.interest_item_info_id = v.id;
            result.push(resultData);
          }
        })
      }
    });

    return await api.interest.updateInterview(JSON.stringify(result));
  }

  const validate = () => {
    let isCount = 0;
    list.map((value)=>{
      if(value.required_yn == 'Y'){
        for(let j = 0;j<value.group_list.length;j++){
          let isIn = false;
          let itemList = value.group_list[j].item_list;
          for(let i = 0;i<itemList.length;i++){
            if(itemList[i].checked){
              isIn = true;
              break;
            }
          }
          if(isIn){
            isCount++;
            break;
          }
        }
      }else{
        isCount++;
      }
      
    });

    if(isCount != list.length){
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "모든 항목에 답변해 주세요.";
        // 기타 내용을 입력해 주세요.
        return { ...e };
      });
    }

    
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (emailRegex.test(list.filter((value)=> value.title == "이메일")[0]?.content) === false) {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "이메일 형식으로 입력해주세요.";
        // 기타 내용을 입력해 주세요.
        return { ...e };
      });
    }

    return isCount == list.length;
  }

  return {
    list,
    setList,
    listQuery,
    updateInterestInfo,
    validate,
  };
}
