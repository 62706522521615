import { useAtom } from "jotai";
import { useState } from "react";
import { useInfiniteQuery, useQuery } from "react-query";
import { api } from "service";
import { modalAtom } from "store/atoms";

export default function useProduct({
  listOption,
  infinitListOption,
  optionListOption,
  groupListOption,
  detailOption,
  transactionDetailOption,
}) {
  const [optionGroupList,setOptionGroupList] = useState([]);

  const listQuery = useQuery(["product",listOption], 
    ()=>{
      return api.product.getList(listOption);
    }, {
    enabled:!!listOption,
    refetchOnWindowFocus: false,
  });

  const infiniteListQuery = useInfiniteQuery(["product_in_list",infinitListOption], 
    ({pageParam = 1})=>{
    return api.product.getInitinteList({...infinitListOption, page : pageParam });
    }, {
    enabled:!!infinitListOption,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage) => {
      return lastPage?.list?.length == 0 ? false :  lastPage?.page + 1;
    },
  });

  const groupListQuery = useQuery(["productGroup",groupListOption], 
    ()=>{
      return api.product.getGroupList(groupListOption);
    }, {
    enabled:!!groupListOption,
    refetchOnWindowFocus: false,
  });


  const detailQuery = useQuery(["productDetail",detailOption], 
    ()=>{
      return api.product.getDetail(detailOption?.id);
    }, {
    enabled:!!detailOption,
    refetchOnWindowFocus: false,
  });

  const transactionDetailQuery = useQuery(["productTranSactionDetail",transactionDetailOption], 
    ()=>{
      return api.product.getTransactionDetail(transactionDetailOption?.id);
    }, {
    enabled:!!transactionDetailOption,
    refetchOnWindowFocus: false,
  });

  const optionListQuery = useQuery(["option",optionListOption], 
    ()=>{
      return api.product.getOptionList(optionListOption);
    }, {
    enabled:!!optionListOption,
    refetchOnWindowFocus: false,
    onSuccess:(e)=>{
      if(e.success){
        let resultList = [];
        let groupKey = {};
        e.data?.map((value,index)=>{
          if(transactionDetailQuery?.data?.data.type == "2"){
            value.checked = true;
          }

          groupKey[value.group_title] = groupKey[value.group_title] ? groupKey[value.group_title] : [];
          groupKey[value.group_title] = groupKey[value.group_title].concat([value]);
        })
        
        for(let key in groupKey){
          resultList.push({
            title : key,
            list : groupKey[key]
          })
        }

        resultList.map((value)=>{
          value.list.sort((a,b) => a.id - b.id);
        });


        setOptionGroupList(resultList);
      }
    }
  });

  const onChangeOption = (item,parentList) => {

    if(parentList == null){
      optionGroupList.filter((value)=> value.title == item.group_title).map((value)=>{
        value.list?.map((option)=>{
          if(option.id == item.id){
            option.checked = !item.checked;
            option.option_relate_list.map((v)=>{
              v.checked = false;
            })
          }else{
            option.checked = false;
            option.option_relate_list.map((v)=>{
              v.checked = false;
            })
          }
        })
      });
    }else{
      parentList.filter((v)=>v.checked)[0]?.option_relate_list?.map((value)=>{
        if(value.id == item.id){
          value.checked = !item.checked;
        }else{
          value.checked = false;
        }
      })
    }

    setOptionGroupList(e => [...e]);
  }


  return {
    listQuery,
    infiniteListQuery,
    groupListQuery,
    detailQuery,
    transactionDetailQuery,
    optionListQuery,
    optionGroupList,
    setOptionGroupList,
    
    onChangeOption,
  };
}
