import styled from "styled-components";

const Container = styled.main`
  min-height: calc(100vh - 161px);
  min-height: calc(100dvh - 161px);
  padding-bottom: 100px;

  & section {
    padding: 40px 0;
  }

  & section:not(:last-of-type) {
    border-bottom: 10px solid var(--gray03);
  }

  & .label {
    margin-bottom: 15px;
    font-size: 22px;
    font-weight: 600;

    & span {
      color: var(--main);
    }
  }

  & .insideButton {
    width: 120px;
  }

  & .guideText {
    color: var(--gray01);
    font-weight: 600;

    & .icon {
      display: inline-block;
      width: 28px;
      height: 28px;
      filter: var(--gray01-filter);
    }
  }

  & .title {
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: 600;
  }

  & .profileSection {
    & .profileImg {
      position: relative;
      width: fit-content;
      margin: 0 auto 40px;

      & .imgWrap {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        overflow: hidden;
      }

      & .changeButtonWrap {
        position: absolute;
        bottom: -2px;
        right: -2px;

        & .changeButton {
          width: 44px;
          height: 44px;
        }

        & .changeListWrap {
          position: absolute;
          top: 18px;
          left: 15px;
          background-color: var(--gray03);
          border-radius: 5px;
          filter: drop-shadow(10px 10px 15px rgba(68, 74, 102, 0.2));

          & .changeList {
            & input {
              display: none;
            }

            & label,
            & button {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 191px;
              height: 60px;
              font-size: 22px;
              font-weight: 500;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  & .infoSection {
    & .list {
      &:not(:last-child) {
        margin-bottom: 40px;
      }

      & .inputContainer:not(:last-child) {
        margin-bottom: 15px;
      }

      & .inputWrap {
        position: relative;

        & .time {
          position: absolute;
          top: 50%;
          right: 145px;
          transform: translateY(-50%);
          font-size: 22px;
          font-weight: 400;
          color: var(--red);
        }
      }

      &.gender ul {
        display: flex;
        gap: 10px;

        & li {
          flex: 1;
        }
      }

      &.phone .guideText {
        display: flex;
        gap: 7px;
        margin-top: 15px;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

  & .familySection,
  & .termsSection {
    & .guideText {
      margin-bottom: 20px;
    }

    & ul {
      display: flex;
      flex-wrap: wrap;
      gap: 20px 40px;
    }
  }

  & .termsSection {
    & .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;

      & .icon {
        width: 16px;
        height: 16px;
        filter: var(--gray01-filter);
      }
    }
  }

  & .deleteButton {
    display: block;
    width: fit-content;
    margin: 20px auto 0;
    font-weight: 600;
    color: var(--gray01);
  }

  @media (max-width: 520px) {
    min-height: calc(100vh - calc(100vw * (161 / 500)));
    min-height: calc(100dvh - calc(100vw * (161 / 500)));
    padding-bottom: calc(100vw * (100 / 500));

    & section {
      padding: calc(100vw * (40 / 500)) 0;
    }

    & section:not(:last-of-type) {
      border-bottom: calc(100vw * (10 / 500)) solid var(--gray03);
    }

    & .label {
      margin-bottom: calc(100vw * (15 / 500));
      font-size: calc(100vw * (22 / 500));
    }

    & .insideButton {
      width: calc(100vw * (120 / 500));
    }

    & .guideText {
      & .icon {
        width: calc(100vw * (28 / 500));
        height: calc(100vw * (28 / 500));
      }
    }

    & .title {
      margin-bottom: calc(100vw * (10 / 500));
      font-size: calc(100vw * (22 / 500));
    }

    & .profileSection {
      & .profileImg {
        margin: 0 auto calc(100vw * (40 / 500));

        & .imgWrap {
          width: calc(100vw * (120 / 500));
          height: calc(100vw * (120 / 500));
        }

        & .changeButtonWrap {
          bottom: calc(100vw * (-2 / 500));
          right: calc(100vw * (-2 / 500));

          & .changeButton {
            width: calc(100vw * (44 / 500));
            height: calc(100vw * (44 / 500));
          }

          & .changeListWrap {
            top: calc(100vw * (18 / 500));
            left: calc(100vw * (15 / 500));
            border-radius: calc(100vw * (5 / 500));
            filter: drop-shadow(
              calc(100vw * (10 / 500)) calc(100vw * (10 / 500))
                calc(100vw * (15 / 500)) rgba(68, 74, 102, 0.2)
            );

            & .changeList {
              & label,
              & button {
                width: calc(100vw * (191 / 500));
                height: calc(100vw * (60 / 500));
                font-size: calc(100vw * (22 / 500));
              }
            }
          }
        }
      }
    }

    & .infoSection {
      & .list {
        &:not(:last-child) {
          margin-bottom: calc(100vw * (40 / 500));
        }

        & .inputContainer:not(:last-child) {
          margin-bottom: calc(100vw * (15 / 500));
        }

        & .inputWrap {
          & .time {
            right: calc(100vw * (145 / 500));
            font-size: calc(100vw * (22 / 500));
          }
        }

        &.gender ul {
          gap: calc(100vw * (10 / 500));
        }

        & .guideText {
          gap: calc(100vw * (7 / 500));
          margin-top: calc(100vw * (15 / 500));
          font-size: calc(100vw * (18 / 500));
        }
      }
    }

    & .familySection,
    & .termsSection {
      & .guideText {
        margin-bottom: calc(100vw * (20 / 500));
      }

      & ul {
        gap: calc(100vw * (20 / 500)) calc(100vw * (40 / 500));
      }
    }

    & .termsSection {
      & .title {
        & .icon {
          width: calc(100vw * (16 / 500));
          height: calc(100vw * (16 / 500));
        }
      }
    }

    & .deleteButton {
      margin: calc(100vw * (20 / 500)) auto 0;
    }
  }
`;

export { Container };

export default { Container };
