import Styles from "./styles";

function BasicInput({
  data,
  setInputs,
  onKeyUp,
  value,
  setValue,
  children,
  disabled,
  noRequied,
}) {
  const {
    id,
    labelText,
    essential,
    type,
    name,
    placeholder,
    errorText,
    readOnly,
  } = data;

  const onChange = (e) => {
    if (setValue) {
      setValue(e);
      return;
    }
    const { name, value } = e.target;
    setInputs((e) => {
      return { ...e, [name]: value };
    });
  };

  return (
    <>
      {labelText && (
        <Styles.Label htmlFor={id ? id : name}>
          {noRequied ? <span>[선택]</span> : essential && <span>[필수]</span>}{" "}
          {labelText}
        </Styles.Label>
      )}

      <Styles.Container>
        <input
          className={errorText ? "error" : ""}
          type={type ? type : "text"}
          id={id ? id : name}
          name={name}
          placeholder={placeholder}
          value={value ? value : data?.value}
          onChange={onChange}
          onKeyUp={onKeyUp}
          readOnly={readOnly}
          disabled={disabled}
        />

        {children}
      </Styles.Container>
    </>
  );
}

export default BasicInput;
