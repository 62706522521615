import styled from "styled-components";

const Container = styled.ul`
  display: flex;
  border-top: 1px solid var(--gray02);
  background-color: #fff;

  & li {
    width: calc(100% / 5);
    color: var(--gray01);

    & a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;

      &.active {
        position: relative;
        font-weight: 600;
        color: var(--main);

        &::before {
          position: absolute;
          top: -1px;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: var(--main);
          content: "";
        }
      }
    }
  }

  @media (max-width: 520px) {
    & li {
      & a {
        height: calc(100vw * (60 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
