import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  min-height: 70px;
  border: 1px solid var(--gray01);
  border-radius: 6px;

  & textarea {
    display: block;
    flex-grow: 1;
    margin: 10px 0;
    padding: 12px 6px 12px 15px;
    word-break: break-all;

    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: var(--purple01);
      border-radius: 999px;
      background-clip: padding-box;
      border: 4px solid transparent;
    }
  }

  & .submitButton {
    flex-shrink: 0;
    width: 82px;
    height: 70px;
    font-weight: 500;
    color: var(--main);
  }

  @media (max-width: 499px) {
    min-height: calc(100vw * (70 / 500));
    border-radius: calc(100vw * (6 / 500));

    & textarea {
      margin: calc(100vw * (10 / 500)) 0;
      padding: calc(100vw * (12 / 500)) calc(100vw * (6 / 500))
        calc(100vw * (12 / 500)) calc(100vw * (15 / 500));

      &::-webkit-scrollbar {
        width: calc(100vw * (8 / 500));
      }
      &::-webkit-scrollbar-thumb {
        border-radius: calc(100vw * (999 / 500));
        border: calc(100vw * (4 / 500)) solid transparent;
      }
    }

    & .submitButton {
      width: calc(100vw * (82 / 500));
      height: calc(100vw * (70 / 500));
    }
  }
`;

export { Container };

export default { Container };
