import connector from "service/connector";

// 인증번호 전송(가입 유저)
const postNUserSms = async (data) => {
  return await connector.authConnector(`identify/user`, "POST", data);
};

// 인증번호 전송(미가입 유저)
const sendSms = async (data) => {
  return await connector.authConnector(`identify`, "POST", data);
};
// const sendSms = async (data) => {
//   return await connector.authConnector(`identify/n-user`, "POST", data);
// };

// 인증번호 확인
const identifyEmail = async (data) => {
  return await connector.connectFetchController(`identify/n-user/pw`, "POST", data);
};

// 인증번호 확인
const checkSms = async (data) => {
  return await connector.authConnector(`identify/check`, "POST", data);
};

export { checkSms, sendSms, postNUserSms,identifyEmail };

export default { checkSms, sendSms, postNUserSms,identifyEmail };
