import styled from "styled-components";

const Container = styled.main`
  min-height: 100vh;
  min-height: 100dvh;
  padding-bottom: 100px;

  & .mainBannerSection {
    margin-bottom: 40px;
  }

  & .programSection {
    margin-bottom: 60px;
  }

  & .subBannerSection {
    margin-bottom: 60px;

    & .subBannerList {
      width: 460px;

      & a {
        height: 130px;
        border-radius: 10px;
        overflow: hidden;
      }
    }
  }

  @media (max-width: 520px) {
    padding-bottom: calc(100vw * (100 / 500));

    & .mainBannerSection {
      margin-bottom: calc(100vw * (40 / 500));
    }

    & .programSection {
      margin-bottom: calc(100vw * (60 / 500));
    }

    & .subBannerSection {
      margin-bottom: calc(100vw * (60 / 500));

      & .subBannerList {
        width: calc(100vw * (460 / 500));

        & a {
          height: calc(100vw * (130 / 500));
          border-radius: calc(100vw * (10 / 500));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
