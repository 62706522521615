import { useEffect, useRef, useState } from "react";
import utils from "utils";


export default function useInfiniteScroll({
  query,
}) {

  const isLoading = useRef(false);
  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, offsetHeight } = document.documentElement;
      if (query.status == 'success' && isLoading.current == false && window.innerHeight + scrollTop + 200 >= offsetHeight) {
        if (query.hasNextPage){ 
          console.log(query)
          query.fetchNextPage();
          isLoading.current = true;
        }
        else if (!query.hasNextPage) return;
      }
    }
    window.addEventListener('scroll', handleScroll)

    if(query.isFetching == false && query.hasNextPage){
      isLoading.current = false;
    }

    return () => window.removeEventListener('scroll', handleScroll)
  }, [query])


  return {
   
  };
}
