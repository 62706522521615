import Styles from "./styles";
import { ui } from "components";
import { api } from "service";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";

// img
import IconSearch from "resources/image/icon/icon_search.svg";
import { useQuickProduct } from "hooks";
import utils from "utils";

function QuickReservation() {
  const location = useLocation();
  const navigate = useNavigate();
  const [filter, setFilter] = useState(null);

  //무한스크롤 작업 필요
  const quickProductInfo = useQuickProduct({
    listOption: filter
  });

  const list = quickProductInfo.listQuery?.data?.list;
  const { check, setCheck, searchParam, setSearchParam } = useOutletContext();

  const [inputs, setInputs] = useState({
    search: "",
  });
  const [search, setSearch] = useState(false);

  const inputData = {
    name: "search",
    value: inputs.search,
    placeholder: "원하는 프로그램 검색하기",
  };

  useEffect(()=>{
    let param = utils.etc.getSearchParam();
    let f = { 
      page : param.page ? param.page : 1,
      recordSize : param.recordSize ? param.recordSize : 15,
      pageSize: 30,
      type : 4,
    };
    if(param.keyword){
      f.keyword = param.keyword;
      setInputs({search : param.keyword });
    }
    setFilter({...f});
  },[location])

  return (
    <Styles.Container>
      <div className="inner">
        <div className="searchContainer">
          <ui.input.BasicInput 
            data={inputData} 
            setInputs={setInputs} 
            onKeyUp={e=>{
              if(e.key == "Enter"){
                navigate(utils.etc.getQueryParamUrl({ keyword : inputs.search, page: 1, }, false));
                setSearch(true);
              }
            }}
          />

          <button
            type="button"
            className="searchButton"
            onClick={() => {
              navigate(utils.etc.getQueryParamUrl({ keyword : inputs.search, page: 1, }, false));
              setSearch(true);
            }}
          >
            <img src={IconSearch} alt="IconSearch" />
          </button>
        </div>

        <div className="program">
          <p className="contentsTitle">
            {search ? "검색 결과" : "인기 프로그램"}
          </p>

          {list?.length > 0 ? (
            <ul>
              {list?.map((item, index) => {
                return (
                  <li className="programList" key={"programList" + index}>
                    <ui.input.ProgramRadio
                      data={item}
                      name={"program"}
                      checked={check === item.id ? true : false}
                      onClick={(e) => {
                        setSearchParam(utils.etc.getFilterStr(filter));
                        setCheck(check == item.id ? "" : item.id);
                      }}
                    />
                  </li>
                );
              })}
            </ul>
          ) : (
            search && <p className="emptyText">검색결과가 없습니다.</p>
          )}
        </div>
      </div>

      {check !== "" && (
        <div className="buttonContainer">
          <div className="inner">
            <div className="buttonWrap">
              <ui.button.BasicButton
                buttonText={"자세히 보기"}
                buttonType={"green"}
              />
            </div>

            <div className="buttonWrap">
              <ui.button.BasicButton buttonText={"선택하기"} />
            </div>
          </div>
        </div>
      )}
    </Styles.Container>
  );
}
export default QuickReservation;
