import styled from "styled-components";

const Container = styled.main`
  position: relative;
  min-height: calc(100vh - 61px);
  min-height: calc(100dvh - 61px);

  & .couponEmptyText {
    padding-top: 350px;
    font-weight: 400;
    color: var(--gray01);
    text-align: center;
  }

  @media (max-width: 520px) {
    min-height: calc(100vh - calc(100vw * (61 / 500)));
    min-height: calc(100dvh - calc(100vw * (61 / 500)));

    & .couponEmptyText {
      padding-top: calc(100vw * (350 / 500));
    }
  }
`;

export { Container };

export default { Container };
