import styled from "styled-components";

const Container = styled.button`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 18px;
  font-weight: 500;

  & .likeIcon {
    display: inline-block;
    width: 34px;
    height: 34px;
  }

  @media (max-width: 520px) {
    gap: calc(100vw * (5 / 500));
    font-size: calc(100vw * (18 / 500));

    & .likeIcon {
      width: calc(100vw * (34 / 500));
      height: calc(100vw * (34 / 500));
    }
  }
`;

export { Container };

export default { Container };
