import utils from "utils";
import Styles from "./styles";

function ProgramJungiOptionSection({ productInfo, transactionList, detail }) {
  const optionList = detail?.group_list[0]?.option_list;

  return (
    <Styles.Container>
      <div className="inner">
        <div className="title">정기권 옵션</div>

        <ul className="time">
          {optionList &&
            optionList?.map((item, index) => {
              return (
                <li className="timeList" key={"timeList" + index}>
                  <p className="number">{index + 1}</p>
                  <p className="optionTitle">{utils.date.getDateType(item.started_at,'.')}</p>
                  <p className="optionTime">{item.title}</p>
                </li>
              );
            })}
        </ul>
      </div>
    </Styles.Container>
  );
}

export default ProgramJungiOptionSection;
