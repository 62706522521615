import styled from "styled-components";

const Container = styled.section`
  & .calendar {
    padding-top: 40px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--gray02);
  }

  & .guideText {
    padding: 18px;
    color: #fff;
    text-align: center;
    background-color: var(--green);
    border-radius: 10px;

    & p:first-child {
      margin-bottom: 8px;
      font-size: 22px;
      font-weight: bold;
    }

    & p:last-child {
      font-size: 18px;
    }
  }

  & .program {
    margin-top: 20px;

    & ul {
      display: flex;
      flex-wrap: wrap;
      gap: 14px;
    }
  }

  @media (max-width: 520px) {
    & .calendar {
      padding-top: calc(100vw * (40 / 500));
      margin-bottom: calc(100vw * (20 / 500));
    }

    & .guideText {
      padding: calc(100vw * (18 / 500));
      border-radius: calc(100vw * (10 / 500));

      & p:first-child {
        margin-bottom: calc(100vw * (8 / 500));
        font-size: calc(100vw * (22 / 500));
      }

      & p:last-child {
        font-size: calc(100vw * (18 / 500));
      }
    }

    & .program {
      margin-top: calc(100vw * (20 / 500));

      & ul {
        gap: calc(100vw * (14 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
