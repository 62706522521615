import Styles from "./styles";
import { common, layout } from "components";
import { api } from "service";
import { Link } from "react-router-dom";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper";
import { useIamport, useProduct } from "hooks";

function TestPay() {
  
  const payInfo = useIamport();

  return (
    <div className="wrap">
      <common.Header />

      <Styles.Container>
        <layout.Menu />
        <input type="text" value={"결제 진행"} onClick={payInfo.onClickPayment}/>
        
      </Styles.Container>

      <common.Footer />

      <common.QuickMenu />
      <common.NavigationBar />
    </div>
  );
}

export default TestPay;
