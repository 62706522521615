import Styles from "./styles";
import layout from "..";
import ui from "components/ui";
import { useNavigate } from "react-router-dom";
import utils from "utils";

// img
import IconLocation from "resources/image/icon/icon_location.svg";
import IconBox from "resources/image/icon/icon_box.svg";

function MyProgram({ data, hideStateBar }) {
  const navigate = useNavigate();

  // 수강일 기준 기획(내부용 최종 12-5-1, 12-5-2 사이 상태값 시안) 참고 부탁드립니다!
  const renderState = (state) => {
    switch (String(state)) {
      case "-1":
        return "결제 취소";
      case "10":
        return "결제 대기";
      case "80":
        return "환불 신청";
      case "83":
        return "환불 완료";
      case "81":
        return "환불 반려";
      default:
        return "결제 완료";
    }
  };

  const renderButton = (state) => {
    switch (String(state)) {
      case "21":
        return (
          <>
            <div className="buttonWrap">
              <ui.button.BasicButton
                buttonText={"환불 신청"}
                size={"smallText"}
                buttonType={"black"}
                onClick={() => {
                  navigate("/mypage/refund?id=" + data.id);
                }}
              />
            </div>
            <div className="buttonWrap">
              <ui.button.BasicButton
                buttonText={"리뷰 작성"}
                size={"smallText"}
                buttonType={"black"}
                onClick={() => {
                  navigate("/review/writing?id=" + data.id);
                }}
              />
            </div>
          </>
        );
      case "22":
        return (
          <ui.button.BasicButton
            buttonText={"리뷰 작성"}
            size={"smallText"}
            buttonType={"black"}
            onClick={() => {
              navigate("/review/writing?id=" + data.id);
            }}
          />
        );
      case "30":
        return (
          <ui.button.BasicButton
            buttonText={"리뷰 수정"}
            size={"smallText"}
            buttonType={"black"}
            onClick={() => {
              navigate("/review/edit?id=" + data.id);
            }}
          />
        );
      case "20":
        return (
          <ui.button.BasicButton
            buttonText={"결제 취소"}
            size={"smallText"}
            buttonType={"black"}
            onClick={() => {
              navigate("/mypage/payment-cancel?id=" + data.id);
            }}
          />
        );
    }
  };

  return (
    <Styles.Container>
      <div className="top">
        <div className="tag">
          <layout.Tag type={data?.type} />
        </div>

        <div className="location">
          <div className="icon">
            <img src={IconLocation} alt="IconLocation" />
          </div>
          {data?.location}
        </div>

        <div className="state">{renderState(data.state)}</div>
      </div>

      <div className="date">
        {data.date || utils.date.getDateType(data.started_at, ".")}{" "}
        <span className="people">{data.amount || 0}명</span>
      </div>

      <p className="title">{data.title}</p>

      <div className="bottom">
        <div className="icon">
          <img src={IconBox} alt="IconBox" />
        </div>
        강의실
        <span className="room">
          {data.product_room_title || data.room_title || data.room}
        </span>
      </div>

      {!hideStateBar && (
        <div className="button">{renderButton(data.state)}</div>
      )}
    </Styles.Container>
  );
}

export default MyProgram;
