import connector from "service/connector"
import utils from "utils";


const getReply = async (filter) => {
  return await connector.authConnector("reply" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
}
const postReply = async (data) => {
  return await connector.authConnector("reply", "POST", data);
}
const deleteReply = async (data) => {
  return await connector.authConnector("reply/del", "POST", data);
}
const editReply = async (data) => {
  return await connector.authConnector("reply/update", "POST", data)
}


export {
  getReply,
  postReply,
  deleteReply,
  editReply
};

export default {
  getReply,
  postReply,
  deleteReply,
  editReply
};