import styled from "styled-components";

const Label = styled.label`
  display: inline-block;
  margin-bottom: 15px;
  font-size: 22px;
  font-weight: 600;

  & span {
    color: var(--main);
  }

  @media (max-width: 520px) {
    margin-bottom: calc(100vw * (15 / 500));
    font-size: calc(100vw * (22 / 500));
  }
`;

const Container = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;

  & input {
    width: 100%;
    height: 70px;
    padding: 0 15px;
    font-size: 22px;
    font-weight: 400;
    background-color: var(--gray03);
    border-radius: 5px;

    &::placeholder {
      color: var(--gray01);
    }

    &.error {
      border: 1px solid var(--red);
    }
  }

  @media (max-width: 520px) {
    gap: calc(100vw * (10 / 500));

    & input {
      height: calc(100vw * (70 / 500));
      padding: 0 calc(100vw * (15 / 500));
      font-size: calc(100vw * (22 / 500));
      border-radius: calc(100vw * (5 / 500));
    }
  }
`;

export { Label, Container };

export default {
  Label,
  Container,
};
