import Styles from "./styles";
import { common, ui } from "components";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import {
  useAddress,
  useAuth,
  useImagePreview,
  useModals,
  useSupport,
  useTimer,
  useUpload,
} from "hooks";
import { api } from "service";
import utils from "utils";

// img
import ImgDefaultProfile from "resources/image/img_default_profile.svg";
import ImgLoadProfile from "resources/image/icon/icon_naver.svg";
import IconCamera from "resources/image/icon/icon_camera.svg";
import IconWarning from "resources/image/icon/icon_warning.svg";
import IconArrowRight from "resources/image/icon/icon_arrow_right_mini.svg";

function MyInfoPage() {
  const modalOption = useModals();
  const navigate = useNavigate();
  const authInfo = useAuth({ detail: true });
  const supportInfo = useSupport({
    options: { policy: true },
  });
  const uploadInfo = useUpload();
  const address = useAddress();

  const [isOpen, setIsOpen] = useState(false);
  const [profileImg, setProfileImg] = useState("");
  const [inputs, setInputs] = useState({
    nickname: "",
    name: "",
    gender: "",
    phone: "",
    confirmCode: "",
    postcode: "",
    address: "",
    address_detail: "",
  });
  const [myDate, setMyDate] = useState("");
  const [confirmCodeSend, setConfirmCodeSend] = useState(false);
  const [confirmComplete, setConfirmComplete] = useState(false);
  const [nameCheck, setNameCheck] = useState(false);
  const [check, setCheck] = useState([]);
  const [termsCheck, setTermsCheck] = useState([]);
  const [isBlock, setIsBlock] = useState(0);
  const timer = useTimer();
  const isLoad = useRef(false);
  const detail = authInfo.userDetail?.data;

  const sendIdentify = async () => {
    if (inputs.phone.replace(/-/gi, "").replace(/[^0-9]/g, "").length != 11) {
      return;
    }
    if (isBlock >= 2) return;
    let result = await authInfo.sendIdentifyCode(inputs.phone, "phone");
    modalOption.setToastModal((e) => {
      e.show = true;
      e.text = "인증번호가 발송되었습니다.";
      return { ...e };
    });

    timer.clickTimer();
    setConfirmCodeSend(true);
    setIsBlock((e) => e + 1);
  };

  const identifyCode = async () => {
    if (inputs.confirmCode == "") {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "휴대폰 번호를 입력해 주세요.";
        return { ...e };
      });
      return;
    }

    if (timer.min == "00" && timer.sec == "00") {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "인증 시간이 만료되었습니다.";
        return { ...e };
      });
      return;
    }

    let resultData = {
      identify_number: inputs.phone,
      code: inputs.confirmCode,
      type: 1,
    };
    let result = await api.sms.checkSms(JSON.stringify(resultData));
    if (result.success == true) {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "인증이 완료되었습니다.";
        return { ...e };
      });
      setConfirmComplete(true);
    } else {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "인증번호가 일치하지 않습니다.";
        return { ...e };
      });
      setConfirmComplete(false);
    }
  };

  // 가족 구성원 체크
  const handleFamilyCheck = (checked, id) => {
    if (checked) {
      setCheck(check.concat(id));
    } else {
      setCheck(check.filter((el) => el !== id));
    }
  };

  // 약관 체크
  const handleTermsCheck = (checked, id) => {
    if (checked) {
      setTermsCheck(termsCheck.concat(id));
    } else {
      setTermsCheck(termsCheck.filter((el) => el !== id));
    }
  };

  const onNameCheck = async () => {
    const result = await authInfo.findNickName(inputs.nickname);
    result?.success ? setNameCheck(true) : setNameCheck(false);
    if (result?.success) {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "사용 가능한 닉네임 입니다.";
        return { ...e };
      });
    } else {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "사용 불가능한 닉네임 입니다.";
        return { ...e };
      });
    }
  };

  const doEditInfo = async () => {
    let result = {
      nickname: inputs.nickname,
      name: inputs.name,

      address: address.address || inputs.address,
      postcode: address.zipcode || inputs.postcode,
      address_detail: inputs.address_detail || inputs.address_detail,
      birthday: utils.date.getDateType(myDate?.birthday, "-"),
      gender: inputs.gender,
      phone: inputs.phone,

      mail_yn: termsCheck.filter((v) => v == "optional03")[0] ? "Y" : "N",
      message_yn: termsCheck.filter((v) => v == "optional02")[0] ? "Y" : "N",
      push_yn: termsCheck.filter((v) => v == "optional01")[0] ? "Y" : "N",

      spouse_yn: check.filter((v) => v == "family01")[0] ? "Y" : "N",
      son_yn: check.filter((v) => v == "family02")[0] ? "Y" : "N",
      daughter_yn: check.filter((v) => v == "family03")[0] ? "Y" : "N",
      parent_yn: check.filter((v) => v == "family04")[0] ? "Y" : "N",
      grandchildren_yn: check.filter((v) => v == "family05")[0] ? "Y" : "N",
      pet_yn: check.filter((v) => v == "family06")[0] ? "Y" : "N",
      image: profileImg,
    };
    if (validate()) {
      await authInfo.userUpdate(result);
    }
    if (isLoad.current) return;
    isLoad.current = true;
  };

  const validate = () => {
    if (!inputs.nickname) {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "닉네임을 입력해 주세요.";
        return { ...e };
      });
      return false;
    }
    if (!nameCheck && inputs.nickname != detail?.nickname) {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "닉네임 중복확인을 해주세요.";
        return { ...e };
      });
      return false;
    }
    if (inputs.nickname < 2 || inputs.nickname.length > 10) {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "닉네임을 입력해 주세요.";
        return { ...e };
      });
      return false;
    }
    if (utils.etc.iOS() == false) {
      if (!inputs.name) {
        modalOption.setToastModal((e) => {
          e.show = true;
          e.text = "이름을 입력해 주세요.";
          return { ...e };
        });
        return false;
      }
      if (!inputs.gender) {
        modalOption.setToastModal((e) => {
          e.show = true;
          e.text = "성별을 선택해 주세요.";
          return { ...e };
        });
        return false;
      }
      if (!myDate || !myDate.birthday) {
        modalOption.setToastModal((e) => {
          e.show = true;
          e.text = "생년월일을 선택해 주세요.";
          return { ...e };
        });
        return false;
      }
    }
    if (
      !inputs.phone ||
      (inputs.phone.replace(/-/gi, "").replace(/[^0-9]/g, "").length != 11 &&
        inputs.phone != authInfo?.userData?.phone)
    ) {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "휴대폰 번호를 입력해 주세요.";
        return { ...e };
      });
      return false;
    }
    if (!confirmCodeSend && inputs.phone != detail?.phone) {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "휴대폰 인증을 진행해 주세요.";
        return { ...e };
      });
      return false;
    }
    if (!confirmComplete && inputs.phone != detail?.phone) {
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "휴대폰 인증을 진행해 주세요.";
        return { ...e };
      });
      return false;
    }
    return true;
  };

  useEffect(() => {
    setNameCheck(false);
  }, [inputs.nickname]);

  useEffect(() => {
    setConfirmCodeSend(false);
  }, [inputs.phone]);

  // data
  const inputList = [
    {
      essential: true,
      name: "nickname",
      value: inputs.nickname,
      labelText: "닉네임",
      placeholder: "최대 10자, 한글, 영문, 숫자만 입력",
    },
    {
      essential: true,
      value: inputs.name,
      name: "name",
      labelText: "이름",
      placeholder: "정확한 예약 확인을 위해 실명을 적어주세요.",
    },
    {
      essential: true,
      value: inputs.gender,
      name: "gender",
      labelText: "성별",
    },
    {
      essential: true,
      value: inputs.phone,
      name: "phone",
      type: "phone",
      labelText: "휴대폰",
      placeholder: "숫자만 입력",
    },
    {
      value: inputs.confirmCode,
      name: "confirmCode",
      type: "number",
      placeholder: "인증번호 입력",
      readOnly: confirmComplete,
    },
    {
      name: "postcode",
      value: address.zipcode || inputs.postcode,
      labelText: "거주지",
      placeholder: "우편번호",
      readOnly: true,
    },
    {
      name: "address",
      value: address.address || inputs.address,
      placeholder: "본주소",
      readOnly: true,
    },
    {
      name: "address_detail",
      value: inputs.address_detail,
      placeholder: "상세주소",
    },
  ];
  const genderList = [
    { id: "W", text: "여성" },
    { id: "M", text: "남성" },
  ];
  const familyList = [
    {
      id: "family01",
      labelText: "배우자",
    },
    {
      id: "family02",
      labelText: "아들",
    },
    {
      id: "family03",
      labelText: "딸",
    },
    {
      id: "family04",
      labelText: "부모님",
    },
    {
      id: "family05",
      labelText: "손주",
    },
    {
      id: "family06",
      labelText: "반려동물",
    },
  ];

  const optionalCheckList = [
    {
      id: "optional01",
      labelText: "앱푸시",
    },
    {
      id: "optional02",
      labelText: "문자",
    },
    {
      id: "optional03",
      labelText: "이메일",
    },
  ];

  const inputKey = [
    "nickname",
    "name",
    "gender",
    "phone",
    "confirmCode",
    "postcode",
    "address",
    "address_detail",
  ];

  useEffect(() => {
    if (detail) {
      setInputs(
        Object.assign(
          {},
          ...inputKey.map((i) => {
            return { [i]: detail[i] };
          })
        )
      );
      setProfileImg(authInfo.userData?.image);
      setMyDate({ birthday: new Date(detail.birthday) });

      let backupData = {
        spouse_yn: "family01",
        son_yn: "family02",
        daughter_yn: "family03",
        parent_yn: "family04",
        grandchildren_yn: "family05",
        pet_yn: "family06",

        push_yn: "optional01",
        message_yn: "optional02",
        mail_yn: "optional03",
      };

      let resultChecks = [];
      let resultTerms = [];
      for (let key in backupData) {
        if (detail[key] == "Y") {
          (key == "push_yn" || key == "message_yn" || key == "mail_yn"
            ? resultTerms
            : resultChecks
          ).push(backupData[key]);
        }
      }

      setCheck(resultChecks);
      setTermsCheck(resultTerms);
    }
  }, [detail]);

  return (
    <div className="wrap">
      <common.SubHeader pageName={"나의 정보"} />

      <Styles.Container>
        <section className="profileSection">
          <div className="inner">
            <div className="profileImg">
              <div className="imgWrap">
                <img
                  src={profileImg ? profileImg : ImgDefaultProfile}
                  alt="userImg"
                />
              </div>

              <div className="changeButtonWrap">
                <button
                  type="button"
                  className="changeButton"
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                >
                  <img src={IconCamera} alt="IconCamera" />
                </button>

                {isOpen && (
                  <ul className="changeListWrap">
                    <li className="changeList">
                      <input
                        type="file"
                        id="imgUpload"
                        accept=".png, .jpeg, .jpg"
                        onChange={async (e) => {
                          setProfileImg(ImgLoadProfile);
                          const maxSize = 20971520; // 20MB
                          if (e.target.files[0].size < maxSize) {
                            const result = await uploadInfo.uploadImage(
                              e.target.files[0]
                            );
                            setProfileImg(result?.data.thumbnail);
                            setIsOpen(false);
                          }
                        }}
                      />
                      <label htmlFor="imgUpload">사진 변경</label>
                    </li>

                    <li className="changeList">
                      <button
                        type="button"
                        onClick={() => {
                          setProfileImg("");
                          setIsOpen(false);
                        }}
                      >
                        기본 이미지로 변경
                      </button>
                    </li>
                  </ul>
                )}
              </div>
            </div>
            <div className="nickname">
              <ui.input.BasicInput
                data={inputList[0]}
                setValue={(e) => {
                  setInputs((input) => {
                    return {
                      ...input,
                      [inputList[0].name]: e.target.value
                        .replace(/[^가-힣ㆍᆢㄱ-ㅎㅏ-ㅣa-zA-Z0-9]/g, "")
                        .slice(0, 10),
                    };
                  });
                }}
              >
                <div className="confirmButton insideButton">
                  <ui.button.BasicButton
                    size={"smallText"}
                    buttonText={"중복확인"}
                    buttonType={"black"}
                    onClick={onNameCheck}
                  />
                </div>
              </ui.input.BasicInput>
            </div>
          </div>
        </section>

        <section className="infoSection">
          <div className="inner">
            <ul className="listWrap">
              <li className="list">
                <div className="inputContainer">
                  <ui.input.BasicInput
                    data={inputList[1]}
                    setInputs={setInputs}
                    noRequied={utils.etc.iOS()}
                  />
                </div>
              </li>

              <li className="list gender">
                <p className="label">
                  <span>{utils.etc.iOS() ? "[선택]" : "[필수]"}</span> 성별
                </p>

                <ul>
                  {genderList.map((item, index) => {
                    return (
                      <li
                        className="radioInputContainer"
                        key={"radioInputContainer" + index}
                      >
                        <ui.input.BasicRadio
                          id={item.id}
                          checked={inputs.gender}
                          name={"gender"}
                          labelText={item.text}
                          setInputs={setInputs}
                        />
                      </li>
                    );
                  })}
                </ul>
              </li>

              <li className="list">
                <ui.input.DateInput
                  labelText={"생년월일"}
                  name={"birthday"}
                  startDate={myDate.birthday}
                  setStartDate={setMyDate}
                  // readOnly={true}
                />
              </li>

              <li className="list phone">
                <div className="inputContainer">
                  <ui.input.BasicInput
                    data={{
                      ...inputList[3],
                      value:
                        inputs.phone.substr(0, 3) +
                        (inputs.phone.length > 3
                          ? "-" +
                            inputs.phone.substr(3, 4) +
                            (inputs.phone.length > 7
                              ? "-" + inputs.phone.substr(7, 4)
                              : "")
                          : ""),
                    }}
                    setValue={(e) => {
                      setConfirmCodeSend(false);
                      setConfirmComplete(false);
                      setInputs((input) => {
                        return {
                          ...input,
                          [inputList[3].name]: e.target.value
                            .replace(/-/gi, "")
                            .replace(/[^0-9]/gi, "")
                            .slice(0, 11),
                        };
                      });
                    }}
                  >
                    <div className="confirmButton insideButton">
                      <ui.button.BasicButton
                        size={"smallText"}
                        buttonText={"재인증"}
                        buttonType={"black"}
                        disabled={confirmCodeSend}
                        onClick={sendIdentify}
                      />
                    </div>
                  </ui.input.BasicInput>
                </div>

                {confirmCodeSend && (
                  <div className="inputContainer">
                    <div className="inputWrap">
                      <ui.input.BasicInput
                        data={inputList[4]}
                        setInputs={setInputs}
                      >
                        <div className="confirmButton insideButton">
                          <ui.button.BasicButton
                            size={"smallText"}
                            buttonText={
                              confirmComplete ? "인증완료" : "인증하기"
                            }
                            buttonType={"black"}
                            disabled={confirmComplete}
                            onClick={identifyCode}
                          />
                        </div>
                        {!confirmComplete && (
                          <span className="time">
                            {timer.min}:{timer.sec}
                          </span>
                        )}
                      </ui.input.BasicInput>
                    </div>

                    <p className="guideText">
                      <span className="icon">
                        <img src={IconWarning} alt="IconWarning" />
                      </span>
                      인증번호가 오지 않을경우 정보를 다시 확인 후 <br />
                      인증번호 재발송 해주세요.
                    </p>
                  </div>
                )}
              </li>

              <li className="list address">
                <div className="inputContainer">
                  <ui.input.BasicInput
                    data={inputList[5]}
                    setInputs={setInputs}
                  >
                    <div className="confirmButton insideButton">
                      <ui.button.BasicButton
                        size={"smallText"}
                        buttonText={"검색"}
                        buttonType={"black"}
                        onClick={address.handleClick}
                      />
                    </div>
                  </ui.input.BasicInput>
                </div>
                <div className="inputContainer">
                  <ui.input.BasicInput
                    data={inputList[6]}
                    setInputs={setInputs}
                  />
                </div>
                <div className="inputContainer">
                  <ui.input.BasicInput
                    data={inputList[7]}
                    setInputs={setInputs}
                  />
                </div>
              </li>
            </ul>
          </div>
        </section>

        <section className="familySection">
          <div className="inner">
            <p className="title">가족 구성원</p>
            <p className="guideText">중복 선택이 가능합니다.</p>

            <ul>
              {familyList?.map((item, index) => {
                return (
                  <li className="familyList" key={"familyList" + index}>
                    <ui.input.BasicCheckbox
                      id={item.id}
                      labelText={item.labelText}
                      checked={check.includes(item.id) ? true : false}
                      onChange={(e) =>
                        handleFamilyCheck(e.target.checked, e.target.id)
                      }
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </section>

        <section className="termsSection">
          <div className="inner">
            <p className="title">
              선택 이용 약관
              {/* //* 퍼블 디자인 깨짐 우려 주석. 원본 코드 */}
              {/* <Link to={"/contact/terms"} className="icon">
                <img src={IconArrowRight} alt="IconArrowRight" />
              </Link> */}
              <button
                className="icon"
                type="button"
                onClick={() => {
                  supportInfo.getModal(0);
                }}
              >
                <img src={IconArrowRight} alt="IconArrowRight" />
              </button>
            </p>

            <p className="guideText">
              할인 소식 혜택 등 마케팅 수신에 동의합니다.
            </p>

            <ul>
              {optionalCheckList?.map((item, index) => {
                return (
                  <li
                    className="optionalCheckList"
                    key={"optionalCheckList" + index}
                  >
                    <ui.input.BasicCheckbox
                      id={item.id}
                      labelText={item.labelText}
                      checked={termsCheck.includes(item.id) ? true : false}
                      onChange={(e) =>
                        handleTermsCheck(e.target.checked, e.target.id)
                      }
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </section>

        <div className="buttonContainer inner">
          <ui.button.BasicButton buttonText={"수정완료"} onClick={doEditInfo} />

          <button
            type="button"
            className="deleteButton"
            onClick={() => {
              navigate("/delete-account");
            }}
          >
            회원탈퇴
          </button>
        </div>
        <address.RenderAddress></address.RenderAddress>
      </Styles.Container>

      <common.QuickMenu />
      <common.NavigationBar />
    </div>
  );
}

export default MyInfoPage;
