import Styles from "./styles";
import { api } from "service";
import ui from "components/ui";
import { CommentList } from "./components";
import { useState } from "react";
import { useAuth, useModals } from "hooks";
import { useNavigate } from "react-router-dom";
import modal from "components/modal";

function Comment({ replyInfo }) {
  const modalOption = useModals();
  const authInfo = useAuth({});
  const [content,setContent]=useState("");
  const [subWrite, setSubWrite] = useState(-1);
  const data = api.dummy.commentList;

  const onReplyEdit = (index) => {
    setSubWrite(index);
  }

  const validate = (text) =>{ 
    if(!text || text.length == 0){
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = "댓글을 작성해주세요.";
        return { ...e };
      });
      return false;
    }
    return true;
  }
  return (
    <Styles.Container>
      <p className="total">
        댓글 <span>{replyInfo?.listQuery?.data?.pagination?.totalRecordCount || 0}</span>
      </p>

      <div className="commentInputContainer">
        <ui.input.CommentInput
          value={content}
          setValue={setContent}
          onChange={(e)=>{
            setContent(e.target.value);
          }}
          onClick={() => {
            if (authInfo.certification() && validate(content)) {
              replyInfo.createReply(JSON.stringify({
                reply_info_id : 0,
                relate_info_id : replyInfo?.listOption.relate_info_id,
                type : replyInfo?.listOption.type,
                content : content,
              }));
            }
          }}
        />
      </div>

      {( replyInfo?.listQuery?.data?.list )?.length > 0 ? (
        <>
          <ul className="listWrap">
            {(replyInfo?.listQuery?.data?.list || data)?.map((item, index) => {
              return (
                <li className="list" key={"commentList" + index}>
                  <CommentList 
                    data={item} 
                    onCreateReply={(text) => {
                      if (authInfo.certification() && validate(text)) {
                        replyInfo.createReply(JSON.stringify({
                          reply_info_id: item.id,
                          relate_info_id: replyInfo?.listOption.relate_info_id,
                          type: replyInfo?.listOption.type,
                          content: text,
                        }));
                      }
                    }}
                    onDeleteReply={()=>{ replyInfo.deleteReply(item.id); }}
                    onEditReply={(text)=>{ if(validate(text))replyInfo.editReply(item.id, text); }}
                    onReplyEdit={()=> { onReplyEdit(subWrite == index ? -1 : index); }}
                  />
                  {
                    subWrite == index && 
                    <ul className="replyListWrap">
                      <li className="replyList" key={"replyList" + index + " " + -1}>
                        <CommentList
                          data={item?.nickname === "익명의 수발러" ? {...authInfo.userData, nickname: "익명의 수발러"} : authInfo.userData}
                          onCreateReply={(text) => {
                            if (authInfo.certification() && validate(text)) {
                              replyInfo.createReply(JSON.stringify({
                                reply_info_id: item.id,
                                relate_info_id: replyInfo?.listOption.relate_info_id,
                                type: replyInfo?.listOption.type,
                                content: text,
                              }));
                            }
                          }}
                          reply
                          replyMode={true}
                        />
                      </li>
                    </ul>
                  }

                  {item.reply_list && (
                    <ul className="replyListWrap">
                      {item.reply_list.map((current, idx) => {
                        return (
                          <li className="replyList" key={"replyList" + idx}>
                            <CommentList 
                              data={current} 
                              reply
                              onDeleteReply={()=>{ replyInfo.deleteReply(current.id); }}
                              onEditReply={(text)=>{ if(validate(text))replyInfo.editReply(current.id, text); }}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
          <div className="pagination">
            <ui.Pagination
              list={replyInfo?.listQuery?.data?.pagination?.page_list}
              page={replyInfo?.listQuery?.data?.pagination?.page}
              maxPage={replyInfo?.listQuery?.data?.pagination?.totalPageCount}
              disableScrollTop={true}
            />
          </div>
        </>
      ) : (
        <p className="emptyText">
          댓글이 없습니다. <br />첫 댓글을 작성해 주세요
        </p>
      )}
    </Styles.Container>
  );
}

export default Comment;
