import styled from "styled-components";

const Input = styled.input`
  display: none;

  &:checked + label {
    color: #fff;
    background-color: var(--main);
  }
`;

const Label = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  font-size: 22px;
  font-weight: 600;
  color: var(--gray01);
  background-color: var(--gray03);
  border-radius: 5px;
  cursor: pointer;

  &.error {
    border: 1px solid var(--red);
  }

  @media (max-width: 520px) {
    height: calc(100vw * (70 / 500));
    font-size: calc(100vw * (22 / 500));
    border-radius: calc(100vw * (5 / 500));
  }
`;

export { Input, Label };

export default { Input, Label };
