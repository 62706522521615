import styled from "styled-components";

const Container = styled.main`
  position: relative;
  min-height: calc(100vh - 61px);
  min-height: calc(100dvh - 61px);
  padding: 40px 0 50px;

  & .program {
    margin-bottom: 40px;
  }

  & .textarea {
    margin-bottom: 40px;
  }

  & .buttonContainer {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding-top: 50px;

    & .buttonWrap {
      flex: 1;
    }
  }

  @media (max-width: 520px) {
    min-height: calc(100vh - calc(100vw * (61 / 500)));
    min-height: calc(100dvh - calc(100vw * (61 / 500)));
    padding: calc(100vw * (40 / 500)) 0 calc(100vw * (50 / 500));

    & .program {
      margin-bottom: calc(100vw * (40 / 500));
    }

    & .textarea {
      margin-bottom: calc(100vw * (40 / 500));
    }

    & .buttonContainer {
      gap: calc(100vw * (10 / 500));
      padding-top: calc(100vw * (50 / 500));
    }
  }
`;

export { Container };

export default { Container };
