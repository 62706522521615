import { api } from "service";
import { useState } from "react";
import { useQuery } from "react-query";

export default function usePoint({
  isTotalPoint,
  listOption
}) {

  const [maxPoint, setMaxPoint] = useState(0);
  const totalPointQuery = useQuery(["totalPoint", isTotalPoint],
    () => {
      return api.point.getTotalPoint();
    }, {
    enabled: !!isTotalPoint,
    refetchOnWindowFocus: false,
    onSuccess: (e) => {
      setMaxPoint(e?.data || 0);
    }
  });
  const getPointList = useQuery(["point_list", listOption], () => {
    return listOption && api.point.getPointList(listOption);
  }, {
    enabled: !!listOption,
    refetchOnWindowFocus: false,
  });

  return {
    maxPoint,
    totalPoint : (totalPointQuery?.data?.data || 0),
    getPointList,
  };
}
