import utils from "utils";
import connector from "service/connector";

const getTotalPoint = async () => {
  return await connector.authConnector(`point/info`,'GET');
}

const getPointList = async (filter) => {
  return await connector.authConnector("point" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
}


const getAttendInfo = async () => {
  return await connector.authConnector(`point/attend`,'GET');
}

const doAttend = async () => {
  return await connector.authConnector(`point/attend`,'POST');
}

export {
  getTotalPoint,
  getPointList,
  getAttendInfo,
  doAttend,
};


export default {
  getTotalPoint,
  getPointList,
  getAttendInfo,
  doAttend,
}