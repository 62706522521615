import styled from "styled-components";

const Container = styled.div`
  padding: 5px;
  font-size: 16px;
  color: var(--main);
  background-color: var(--purple);
  border-radius: 3px;
  cursor: pointer;

  @media (max-width: 520px) {
    padding: calc(100vw * (5 / 500));
    font-size: calc(100vw * (16 / 500));
    border-radius: calc(100vw * (3 / 500));
  }
`;

export { Container };

export default { Container };
