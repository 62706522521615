import styled from "styled-components";

const Container = styled.main`
  min-height: 100vh;
  min-height: 100dvh;
  padding-bottom: 100px;

  & .mainBannerSection {
    margin-bottom: 20px;
  }

  & .magazineSection {
    & .magazine {
      display: flex;
      flex-wrap: wrap;
      gap: 40px 16px;
    }
  }

  @media (max-width: 520px) {
    padding-bottom: calc(100vw * (100 / 500));

    & .mainBannerSection {
      margin-bottom: calc(100vw * (20 / 500));
    }

    & .magazineSection {
      & .magazine {
        gap: calc(100vw * (40 / 500)) calc(100vw * (16 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
