import { api } from "service";
import { useInfiniteQuery, useQuery } from "react-query";

export default function useBoard({
  listOption,
  infinitListOption,
  groupListOption,
  detailOption,
  introduce,
}) {

  const listQuery = useQuery(["board_list", listOption], () => {
    return listOption && api.board.getList(listOption);
  }, {
    enabled: !!listOption,
    refetchOnWindowFocus: false,
  });

  const infiniteListQuery = useInfiniteQuery(["board_in_list",infinitListOption], 
    ({pageParam = 1})=>{
    return api.board.getInitinteList({...infinitListOption, page : pageParam });
    }, {
    enabled:!!infinitListOption,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage) => {
      return lastPage?.list?.length == 0 ? false :  lastPage?.page + 1;
    },
  });

  const groupListQuery = useQuery(["board_group", groupListOption], () => {
    return api.board.getGroupList(groupListOption);
  }, {
    enabled: !!groupListOption,
    refetchOnWindowFocus: false,
  });
  const detailQuery = useQuery(["board_detail", detailOption], () => {
    return api.board.getDetail(detailOption?.id);
  }, {
    enabled: !!detailOption,
    refetchOnWindowFocus: false,
  });
  const introduceQuery = useQuery(["board_introduce"], () => {
    return api.board.getIntroduce();
  }, {
    enabled: !!introduce,
    refetchOnWindowFocus: false,
  });


  return {
    listQuery,
    infiniteListQuery,
    groupListQuery,
    detailQuery,
    introduceQuery,
  };
}
