import ModalPage from "./ModalPage";
import MainPage from "./MainPage";
import LoginPage from "./LoginPage";
import SignupStepAPage from "./SignupStepAPage";
import SignupStepBPage from "./SignupStepBPage";
import SignupCompletePage from "./SignupCompletePage";
import ProgramPage from "./ProgramPage";
import ProgramCategoryPage from "./ProgramCategoryPage";
import ProgramDetailPage from "./ProgramDetailPage";
import ApplyPage from "./ApplyPage";
import PaymentPage from "./PaymentPage";
import PaymentCompletePage from "./PaymentCompletePage";
import ReservationPage from "./ReservationPage";
import ReservationDetailPage from "./ReservationDetailPage";
import MyPage from "./MyPage";
import MyProgramPage from "./MyProgramPage";
import MyPaymentHistoryPage from "./MyPaymentHistoryPage";
import MyPaymentCancelPage from "./MyPaymentCancelPage";
import MyRefundPage from "./MyRefundPage";
import ReviewWritingPage from "./ReviewWritingPage";
import ReviewEditPage from "./ReviewEditPage";
import SearchPage from "./SearchPage";
import SearchDetailPage from "./SearchDetailPage";
import AskPage from "./AskPage";
import ContactPage from "./ContactPage";
import NoticeDetailPage from "./NoticeDetailPage";
import MyLikePage from "./MyLikePage";
import MyBenefitPage from "./MyBenefitPage";
import MyInfoPage from "./MyInfoPage";
import DirectionPage from "./DirectionPage";
import DirectionDetailPage from "./DirectionDetailPage";
import DeleteAccountPage from "./DeleteAccountPage";
import InterestPage from "./InterestPage";
import InterestDetailPage from "./InterestDetailPage";
import InterestCompletePage from "./InterestCompletePage";
import IntroductionPage from "./IntroductionPage";
import IntroductionStoryPage from "./IntroductionStoryPage";
import IntroductionDetailPage from "./IntroductionDetailPage";
import ReportPage from "./ReportPage";
import CategoryPage from "./CategoryPage";
import MagazinePage from "./MagazinePage";
import MagazineCategoryPage from "./MagazineCategoryPage";
import MagazineDetailPage from "./MagazineDetailPage";
import AttendancePage from "./AttendancePage";
import TestPay from "./TestPay";

export default {
  ModalPage,
  MainPage,
  LoginPage,
  SignupStepAPage,
  SignupStepBPage,
  SignupCompletePage,
  ProgramPage,
  ProgramCategoryPage,
  ProgramDetailPage,
  ApplyPage,
  PaymentPage,
  PaymentCompletePage,
  ReservationPage,
  ReservationDetailPage,
  MyPage,
  MyProgramPage,
  MyPaymentHistoryPage,
  MyPaymentCancelPage,
  MyRefundPage,
  ReviewWritingPage,
  ReviewEditPage,
  SearchPage,
  SearchDetailPage,
  AskPage,
  ContactPage,
  NoticeDetailPage,
  MyLikePage,
  MyBenefitPage,
  MyInfoPage,
  DirectionPage,
  DirectionDetailPage,
  DeleteAccountPage,
  InterestPage,
  InterestDetailPage,
  InterestCompletePage,
  IntroductionPage,
  IntroductionStoryPage,
  IntroductionDetailPage,
  ReportPage,
  CategoryPage,
  MagazinePage,
  MagazineCategoryPage,
  MagazineDetailPage,
  AttendancePage,
  TestPay,
};

export {
  ModalPage,
  MainPage,
  LoginPage,
  SignupStepAPage,
  SignupStepBPage,
  SignupCompletePage,
  ProgramPage,
  ProgramCategoryPage,
  ProgramDetailPage,
  ApplyPage,
  PaymentPage,
  PaymentCompletePage,
  ReservationPage,
  ReservationDetailPage,
  MyPage,
  MyProgramPage,
  MyPaymentHistoryPage,
  MyPaymentCancelPage,
  MyRefundPage,
  ReviewWritingPage,
  ReviewEditPage,
  SearchPage,
  SearchDetailPage,
  AskPage,
  ContactPage,
  NoticeDetailPage,
  MyLikePage,
  MyBenefitPage,
  MyInfoPage,
  DirectionPage,
  DirectionDetailPage,
  DeleteAccountPage,
  InterestPage,
  InterestDetailPage,
  InterestCompletePage,
  IntroductionPage,
  IntroductionStoryPage,
  IntroductionDetailPage,
  ReportPage,
  CategoryPage,
  MagazinePage,
  MagazineCategoryPage,
  MagazineDetailPage,
  AttendancePage,
  TestPay,
};
