import Styles from "./styles";
import utils from "utils";

function ProgramPriceSection({
  data,
  totalPrice,
  fee,
  cancelPrice,
  detailPage,
  state,
}) {
  const priceList = [
    {
      title: "교재/재료비 포함",
      text: utils.etc.numberWithCommas(data.price),
    },
    {
      title: "쿠폰 할인",
      text: utils.etc.numberWithCommas(data.coupon),
    },
    {
      title: "포인트 사용",
      text: <>-{utils.etc.numberWithCommas(data.point)}</>,
    },
  ];

  let per = Math.floor((cancelPrice / totalPrice) * 100);

  return (
    <Styles.Container>
      <div className="inner">
        <div className="title">결제 금액 {detailPage && " 확인"}</div>

        <ul className="prcieListWrap">
          {priceList.map((item, index) => {
            return (
              <li className="priceList" key={"priceList" + index}>
                <div className="priceTitle">{item.title}</div>

                <div className="priceText">{item.text}원</div>
              </li>
            );
          })}
        </ul>

        <div className="totalPriceWrap">
          <div className="title">최종 결제금액</div>

          <p className="totalPrice">{totalPrice}원</p>
        </div>

        {cancelPrice && (
          <div className="cancelPriceWrap">
            <div className="title">결제 {state == -1 ? "취소" : "환불"} 금액</div>

            <p className="cancelPrice">
              {/* {fee && <span className="fee">{totalPrice == 0 || (totalPrice == cancelPrice && per == 100) ? "" : `-${per}%`}</span>} */}
              {cancelPrice}원
            </p>
          </div>
        )}
      </div>
    </Styles.Container>
  );
}

export default ProgramPriceSection;
