import styled from "styled-components";

const Container = styled.main`
  position: relative;
  min-height: calc(100vh - 672px);
  min-height: calc(100dvh - 672px);
  padding-bottom: 100px;

  & .contactSection {
    & .contactWrap {
      display: flex;

      & .contactList {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 160px;

        & .icon {
          width: 34px;
          height: 34px;
          margin-bottom: 10px;
        }

        & .text {
          margin-bottom: 9px;
          font-size: 22px;
          font-weight: 600;
        }

        & .colorText {
          color: var(--main);

          & a {
            display: flex;
            align-items: center;
            gap: 5px;

            & span {
              text-decoration: underline;
              text-underline-offset: 3px;
            }

            & .arrowIcon {
              width: 12px;
              height: 12px;
              filter: var(--main-filter);
            }
          }
        }
      }
    }

    & .time {
      padding: 20px 0;
      font-size: 18px;
      color: var(--gray01);
      text-align: center;
      border-top: 1px solid var(--gray02);
      border-bottom: 1px solid var(--gray02);
    }
  }

  & .contentsSection {
    & .tab {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 520px) {
    min-height: calc(100vh - calc(100vw * (672 / 500)));
    min-height: calc(100dvh - calc(100vw * (672 / 500)));
    padding-bottom: calc(100vw * (100 / 500));

    & .contactSection {
      & .contactWrap {
        & .contactList {
          height: calc(100vw * (160 / 500));

          & .icon {
            width: calc(100vw * (34 / 500));
            height: calc(100vw * (34 / 500));
            margin-bottom: calc(100vw * (10 / 500));
          }

          & .text {
            margin-bottom: calc(100vw * (9 / 500));
            font-size: calc(100vw * (22 / 500));
          }

          & .colorText {
            & a {
              gap: calc(100vw * (5 / 500));

              & span {
                text-underline-offset: calc(100vw * (3 / 500));
              }

              & .arrowIcon {
                width: calc(100vw * (12 / 500));
                height: calc(100vw * (12 / 500));
              }
            }
          }
        }
      }

      & .time {
        padding: calc(100vw * (20 / 500)) 0;
        font-size: calc(100vw * (18 / 500));
      }
    }
    & .contentsSection {
      & .tab {
        margin-bottom: calc(100vw * (20 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
