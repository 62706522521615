import { api } from "service";
import { useQuery } from "react-query";

export default function useSearch({ listOption, relateListOption }) {

  const listQuery = useQuery(["search_list", listOption], () => {
    return api.search.getList(listOption);
  }, { 
    refetchOnWindowFocus: true,
    enabled: !!listOption
  });


  const relateQuery = useQuery(["productRelate",relateListOption], 
    ()=>{
      return api.search.getRelateList(relateListOption);
    }, {
    enabled:!!relateListOption,
    refetchOnWindowFocus: false,
  });


  return {
    listQuery,
    relateQuery,
  }
}