import Styles from "./styles";
import { useState } from "react";

// img
import IconQuestion from "resources/image/icon/icon_question.svg";
import IconAnswer from "resources/image/icon/icon_answer.svg";

function FaqList({ data }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Styles.Container
      className={isOpen ? "on" : ""}
      onClick={() => {
        setIsOpen(!isOpen);
      }}
    >
      <div className="questionWrap">
        <div className="question">
          <div className="icon">
            <img src={IconQuestion} alt="IconQuestion" />
          </div>

          <p>{data.title}</p>
        </div>
      </div>

      {isOpen && (
        <div className="answerWrap">
          <div className="answer">
            <div className="icon">
              <img src={IconAnswer} alt="IconAnswer" />
            </div>
            <p dangerouslySetInnerHTML={{ __html: data.description }}></p>
          </div>
        </div>
      )}
    </Styles.Container>
  );
}

export default FaqList;
