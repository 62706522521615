import connector from "service/connector"
import utils from "utils";


const getInquiry = async (filter) => {
  return await connector.jsonConnector("inquiry" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
}
const postInquiry = async (data) => {
  return await connector.jsonConnector("inquiry", "POST", data);
}
const getInquiryDetail = async (path) => {
  return await connector.authConnector("inquiry/detail?id=" + path, "GET");
}

export {
  getInquiry,
  postInquiry,
  getInquiryDetail
};

export default {
  getInquiry,
  postInquiry,
  getInquiryDetail
};