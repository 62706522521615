import Styles from "./styles";
import { common, layout, ui } from "components";
import { api } from "service";
import { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import utils from "utils";
import { useProduct, useQuickProduct } from "hooks";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

// img
import IconLocation from "resources/image/icon/icon_location.svg";
import IconCard from "resources/image/icon/icon_card.svg";
import IconPeople from "resources/image/icon/icon_people.svg";
import IconBox from "resources/image/icon/icon_box.svg";
import IconClock from "resources/image/icon/icon_clock.svg";
import IconCalendar from "resources/image/icon/icon_calendar.svg";
import ImgDummy from "resources/image/img_dummy_list.png";

function ReservationDetailPage() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [filter, setFilter] = useState(null);

  const quickProductInfo = useQuickProduct({
    groupDateListOption: utils.etc.getSearchParam().date && {
      date: utils.etc.getSearchParam().date,
    },
    groupListOption: utils.etc.getSearchParam().date && {
      date: utils.etc.getSearchParam().date,
    },
    relateListOption: {
      id: params?.id,
    },
    listOption: filter,
  });

  const quickList =
    quickProductInfo?.listQuery?.data?.list ||
    quickProductInfo?.groupListQuery?.data?.data;
  const [swiper, setSwiper] = useState(null);

  const productInfo = useProduct({
    detailOption: {
      id: params?.id || 1,
    },
  });
  const detail = productInfo.detailQuery.data?.data || {};

  const [tabIndex, setTabIndex] = useState(location.pathname.split("/")[2]);
  const [active, setActive] = useState(0);

  const tabList = [
    {
      id: "quick",
      url: "/reservation/quick",
      text: "빠른 예약하기",
    },
    {
      id: "date",
      url: "/reservation/date",
      text: "일자별 예약하기",
    },
  ];
  const infoList = [
    {
      icon: IconPeople,
      title: "수강 인원",
      text: detail.member_title, // data.people,
    },
    {
      icon: IconBox,
      title: "강의실",
      text: detail.product_room_title || detail.room_title, // data.room,
    },
    {
      icon: IconClock,
      title: "강의 시간",
      text: detail.class_title, // data.time,
    },
    {
      icon: IconCalendar,
      title: "수강 일정",
      text: detail.date_title, // data.schedule,
    },
  ];

  useEffect(() => {
    if (!utils.etc.getSearchParam().date) {
      let param = utils.etc.getSearchParam();
      setFilter({
        page: param.page,
        recordSize: param.recordSize,
        pageSize: param.pageSize,
        type: param.type,
      });
    }
  }, []);

  useEffect(() => {
    if (quickList?.length > 0) {
      quickList?.map((value, index) => {
        if (value.id == params?.id) {
          swiper?.slideTo(index, 0);
          setActive(value.id);
        }
      });
    }
  }, [swiper, quickList]);

  return (
    <div className="wrap">
      <common.SubHeader
        pageName={"프로그램 예약"}
        backEvent={() => {
          navigate("../..", { relative: "path" });
        }}
      />

      <Styles.Container>
        <layout.Tab
          tabList={tabList}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
        />

        <section className="slideSection">
          {tabIndex === "date" && (
            <div className="nowProgramDate inner">
              {utils.date.getDateType(utils.etc.getSearchParam().date)} 진행
              프로그램
            </div>
          )}

          <Swiper
            onSwiper={setSwiper}
            slidesPerView={"auto"}
            spaceBetween={-12}
            loop={quickList?.length > 4 ? true : false}
            className="slider"
          >
            {quickList?.map((item, index) => {
              return (
                <SwiperSlide
                  className={
                    "slideList" + (active === item.id ? " active" : "")
                  }
                  onClick={() => {
                    setActive(item.id);
                    navigate(
                      `/reservation/${
                        utils.etc.getSearchParam().date ? "date" : "quick"
                      }/detail/${item.id}` + location.search
                    );
                    swiper?.slideTo(index, 0);
                  }}
                  key={"slideList" + index}
                >
                  <div className="img">
                    <img
                      src={item.thumbnail ? item.thumbnail : ImgDummy}
                      alt="slideImg"
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </section>

        <section className="priceSection">
          <div className="inner">
            <div className="top">
              <div className="tag">
                <layout.Tag type={detail.type} />
              </div>

              <div className="location">
                <div className="icon">
                  <img src={IconLocation} alt="IconLocation" />
                </div>
                {detail?.location}
              </div>
            </div>

            <p className="title">{detail?.title}</p>

            <div className="bottom">
              <div className="priceText">
                <div className="icon">
                  <img src={IconCard} alt="IconCard" />
                </div>
                교재/재료비 포함가
              </div>

              <div className="priceWrap">
                <p className="discount">
                  <span className="percent">{detail?.sale}%</span>
                  <del>{utils.etc.numberWithCommas(detail?.origin_price)}</del>
                </p>
                <p className="price">
                  {utils.etc.numberWithCommas(detail?.price)}원
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="infoSection">
          <div className="inner">
            <ul className="infoWrap">
              {infoList?.map((item, index) => {
                return (
                  <li className="infoList" key={"infoList" + index}>
                    <div className="listTitle">
                      <div className="icon">
                        <img src={item.icon} alt="listIcon" />
                      </div>
                      {item.title}
                    </div>

                    <p className="text">{item.text}</p>
                  </li>
                );
              })}
            </ul>

            <ul className="keyword">
              {detail.keyword?.split(",")?.map((item, index) => {
                return (
                  <li className="keywordList" key={"keywordList" + index}>
                    <ui.button.Keyword data={item} />
                  </li>
                );
              })}
            </ul>
            {detail?.banner_title && (
              <layout.ProgramBanner
                type={detail?.type}
                title={detail?.banner_title}
                subtitle={detail?.banner_subtitle}
              />
            )}
          </div>
        </section>

        {detail?.type === "1" &&
          quickProductInfo?.relateListQuery?.data?.data &&
          quickProductInfo?.relateListQuery?.data?.data?.length > 0 && (
            <section className="withProgramSection">
              <p className="contentsTitle inner">함께하는 프로그램</p>

              <layout.ContentsSlider
                slider={"program"}
                isHideMore
                data={quickProductInfo?.relateListQuery?.data?.data}
                urlCallback={(e) => {
                  navigate("/program/detail/" + e.id);
                }}
              />
            </section>
          )}

        <div className="application">
          <div className="inner">
            <div className="priceWrap">
              <p className="discount">
                <span className="percent">{detail?.sale}%</span>
                <del>{utils.etc.numberWithCommas(detail?.origin_price)}</del>
              </p>
              <p className="price">
                {utils.etc.numberWithCommas(detail?.price)}원
              </p>
            </div>

            <div className="applicationButton">
              <ui.button.BasicButton
                buttonText={detail?.soldout_yn == "Y" ? "마감" : "신청하기"}
                buttonType={detail?.soldout_yn == "Y" && "gray"}
                disabled={detail?.soldout_yn == "Y"}
                onClick={() => {
                  navigate("/apply/" + (params?.id ? params?.id : 1));
                }}
              />
            </div>
          </div>
        </div>
      </Styles.Container>
    </div>
  );
}

export default ReservationDetailPage;
