import utils from "utils";
import Styles from "./styles";
import components from "./components";
import ScrollContainer from "react-indiana-drag-scroll";
import { useLocation, useParams } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { common, layout, ui } from "components";
import {
  useAuth,
  useLike,
  useModals,
  useProduct,
  useReview,
  useSearch,
} from "hooks";

// img
import IconShare from "resources/image/icon/icon_share.svg";
import IconCard from "resources/image/icon/icon_card.svg";
import IconPeople from "resources/image/icon/icon_people.svg";
import IconBox from "resources/image/icon/icon_box.svg";
import IconClock from "resources/image/icon/icon_clock.svg";
import IconCalendar from "resources/image/icon/icon_calendar.svg";
import ImgDummy from "resources/image/img_dummy_list.png";

function ProgramDetailPage() {
  const navigate = useNavigate();
  const modalOption = useModals();
  const params = useParams();
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState(0);

  const authInfo = useAuth({});
  const searchInfo = useSearch({
    relateListOption: {
      id: params?.id,
      type: 2,
    },
  });
  const [filter, setFilter] = useState({
    page: 1,
    recordSize: 5,
    pageSize: 1,
    relate_info_id: params?.id || 1,
    search_type: 0,
  });

  const likeInfo = useLike({
    queryName: "productDetail",
    callFilter: { id: params?.id || 1 },
  });
  const productInfo = useProduct({
    detailOption: { id: params?.id || 1 },
  });
  const reviewInfo = useReview({
    listOption: filter,
    infoOption: {
      relate_info_id: params?.id || 1,
      serach_type: 0,
    },
  });
  const reviewImageInfo = useReview({
    listOption: {
      page: 1,
      recordSize: 5,
      pageSize: 1,
      relate_info_id: params?.id || 1,
      search_type: 1,
    },
  });

  const reviewList = reviewInfo.listQuery?.data?.list || [];
  const imageList = reviewImageInfo.listQuery?.data?.list || [];
  const info = reviewInfo?.infoQuery?.data?.data;
  const detail = productInfo.detailQuery.data?.data || {};

  useEffect(() => {
    let f = {
      relate_info_id: params?.id || 1,
      page: utils.etc.getSearchParam().page || 1,
      recordSize: 5,
      serach_type: 0,
      pageSize: 5,
    };
    setFilter({ ...f });
  }, [location]);

  useEffect(() => {
    const scrollEvent = utils.etc.throttle(handleScroll, 100);
    window.addEventListener("scroll", scrollEvent);
    return () => {
      // return a cleanup function to unregister our function since it will run multiple times
      window.removeEventListener("scroll", scrollEvent);
    };
  }, []);

  // 탭 클릭 시 해당 영역으로 스크롤
  const refs = useRef([]);
  const handleClick = (idx) => {
    refs.current[idx].scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const infoList = [
    {
      icon: IconPeople,
      title: "수강 인원",
      text: detail.member_title, // data.people,
    },
    {
      icon: IconBox,
      title: "강의실",
      text: detail.room_title, // data.room,
    },
    {
      icon: IconClock,
      title: "강의 시간",
      text: detail.class_title, // data.time,
    },
    {
      icon: IconCalendar,
      title: "수강 일정",
      text: detail.date_title, // data.schedule,
    },
  ];
  const tabList = [
    {
      text: "프로그램",
    },
    {
      text: "강사정보",
    },
    {
      text: (
        <>
          리뷰(
          {reviewInfo.listQuery?.data?.pagination?.totalRecordCountAll || 0})
        </>
      ),
    },
    {
      text: "Q&A",
      onClick: () => {
        navigate("/ask");
      },
    },
  ];

  function handleScroll() {
    for (let i = 0; i < refs.current.length; i++) {
      var bodyRect = document.body?.getBoundingClientRect(),
        elemRect = refs.current[i]?.getBoundingClientRect(),
        offset = elemRect?.top - bodyRect?.top;
      if (window.scrollY <= offset) {
        setTabIndex(i);
        break;
      }
    }
  }

  return (
    <div className="wrap">
      <common.SubHeader pageName={"프로그램"} />

      <Styles.Container>
        <section className="programSection">
          <div className="thumbnail">
            <div className="img">
              <img
                src={detail.image ? detail.image : ImgDummy}
                alt="programImg"
              />
            </div>

            <div className="textWrap">
              <div className="inner">
                <p className="title">
                  {/* {detail.category_subtitle}{" "} */}
                  <Link
                    to={`/program/category/${detail.category_info_id}`}
                    className="categoryName"
                  >
                    {detail.category_title}
                  </Link>
                </p>

                <p className="mainTitle">{detail.title}</p>
                <p className="description">{detail.subtitle}</p>
              </div>
            </div>
          </div>

          <div className="priceContainer">
            <div className="inner">
              <div className="top">
                <layout.Tag type={detail.type} />

                <ul className="reaction">
                  <li className="reactionList starWrap">
                    {info?.score}
                    {utils.etc.renderStarPotint(info?.score)}
                  </li>

                  <li className="reactionList">
                    <ui.button.LikeButton
                      like={detail?.like_yn == "Y" ? true : false}
                      likeNumber={detail?.like_count}
                      likeInfo={{ page: likeInfo, id: detail.id, type: 2 }}
                    />
                  </li>

                  <li className="reactionList">
                    <button
                      type="button"
                      className="shareButton"
                      onClick={() => {
                        if (utils.url.getUrlCopy())
                          modalOption.setToastModal((e) => {
                            e.show = true;
                            e.text = "주소가 복사되었습니다.";
                            return { ...e };
                          });
                      }}
                    >
                      <img src={IconShare} alt="IconShare" />
                    </button>
                  </li>
                </ul>
              </div>

              <div className="bottom">
                <div className="priceText">
                  <div className="icon">
                    <img src={IconCard} alt="IconCard" />
                  </div>
                  교재/재료비 포함가
                </div>

                <div className="priceWrap">
                  <p className="discount">
                    <span className="percent">{detail.sale}%</span>
                    <del>{utils.etc.numberWithCommas(detail.origin_price)}</del>
                  </p>
                  <p className="price">
                    {utils.etc.numberWithCommas(detail.price)}원
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="info">
            <div className="inner">
              <ul className="infoWrap">
                {infoList?.map((item, index) => {
                  return (
                    <li className="infoList" key={"infoList" + index}>
                      <div className="listTitle">
                        <div className="icon">
                          <img src={item.icon} alt="listIcon" />
                        </div>
                        {item.title}
                      </div>

                      <p className="text">{item.text}</p>
                    </li>
                  );
                })}
              </ul>

              <ul className="keyword">
                {detail.keyword?.split(",")?.map((item, index) => {
                  return (
                    <li className="keywordList" key={"keywordList" + index}>
                      <ui.button.Keyword data={item} />
                    </li>
                  );
                })}
              </ul>

              {detail?.banner_title && (
                <layout.ProgramBanner
                  type={detail?.type}
                  title={detail?.banner_title}
                  subtitle={detail?.banner_subtitle}
                />
              )}
            </div>
          </div>

          <ul className="tab">
            {tabList?.map((item, index) => {
              return (
                <li
                  className={"tabList" + (tabIndex === index ? " on" : "")}
                  key={"tabList" + index}
                  onClick={() => {
                    if (index == 3) {
                      navigate("/ask?type=2&title=" + detail?.title);
                      return;
                    }
                    tabIndex === index && setTabIndex(index);
                    {
                      item.onClick ? item.onClick() : handleClick(index);
                    }
                  }}
                >
                  {item.text}
                </li>
              );
            })}
          </ul>

          <div
            className="program"
            ref={(ref) => {
              refs.current[0] = ref;
            }}
          >
            <div className="inner">
              <div
                className="editor ck-content"
                dangerouslySetInnerHTML={{ __html: detail.description }}
              ></div>
            </div>
          </div>

          <div
            className="instructor"
            ref={(ref) => {
              refs.current[1] = ref;
            }}
          >
            <div className="inner">
              <div
                className="editor ck-content"
                dangerouslySetInnerHTML={{ __html: detail.teacher_description }}
              ></div>

              {detail.type === 0 &&
                detail.subProgramList &&
                detail.subProgramList.length > 0 && (
                  <div className="withProgram">
                    <p className="contentsTitle">함께하는 프로그램</p>

                    <ul className="withProgramWrap">
                      {detail.subProgramList?.map((item, index) => {
                        return (
                          <li
                            className="subProgramList"
                            key={"subProgramList" + index}
                          >
                            <layout.ContentsList big data={item} />
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
            </div>
          </div>

          <div
            className="review"
            ref={(ref) => {
              refs.current[2] = ref;
            }}
          >
            <div className="totalStarPoint">
              {info?.score}
              {utils.etc.renderStarPotint(info?.score)}
            </div>

            {imageList?.length > 0 && (
              <div className="recentPhotoReview">
                <p className="contentsTitle inner">사진 리뷰</p>

                <ScrollContainer className="scroll-container">
                  {imageList
                    ?.map((value) => {
                      return value.images;
                    })
                    ?.join(",")
                    ?.split(",")
                    ?.map((item, index) => {
                      return (
                        <div
                          className="recentphotoReviewList"
                          key={"recentphotoReviewList" + index}
                        >
                          <img src={item} alt="photoReview" />
                        </div>
                      );
                    })}
                </ScrollContainer>
              </div>
            )}

            <div className="allReview">
              <p className="reviewTitle inner">
                전체 리뷰{" "}
                <span>
                  {reviewInfo.listQuery?.data?.pagination
                    ?.totalRecordCountAll || 0}
                </span>
              </p>

              <div className="reviewContainer">
                {reviewList?.length > 0 ? (
                  <>
                    <ul className="reviewWrap">
                      {reviewList?.map((item, index) => {
                        return (
                          <li className="reviewList" key={"reviewList" + index}>
                            <components.ReviewList data={item} />
                          </li>
                        );
                      })}
                    </ul>

                    <div className="pagination">
                      <ui.Pagination
                        list={reviewInfo.listQuery?.data?.pagination?.page_list}
                        page={reviewInfo.listQuery?.data?.pagination?.page}
                        maxPage={
                          reviewInfo.listQuery?.data?.pagination?.totalPageCount
                        }
                        disableScrollTop={true}
                      />
                    </div>
                  </>
                ) : (
                  <p className="emptyText">등록된 리뷰가 없습니다.</p>
                )}
              </div>
            </div>
          </div>

          {searchInfo?.relateQuery?.data?.data && (
            <div className="relatedContents">
              <p className="contentsTitle inner">관련 콘텐츠</p>
              <layout.ContentsSlider
                slider={"program"}
                isHideMore={true}
                data={searchInfo?.relateQuery?.data?.data}
                urlCallback={(e) => {
                  if (e.type == 2) {
                    navigate("/program/detail/" + e.id);
                  } else navigate("/magazine/detail/" + e.id);
                }}
              />
            </div>
          )}
        </section>

        <div className="application">
          <div className="inner">
            <div className="priceWrap">
              <p className="discount">
                <span className="percent">{detail?.sale}%</span>
                <del>{utils.etc.numberWithCommas(detail?.origin_price)}</del>
              </p>
              <p className="price">
                {utils.etc.numberWithCommas(detail?.price)}원
              </p>
            </div>

            <div className="applicationButton">
              <ui.button.BasicButton
                buttonText={detail.soldout_yn == "Y" ? "마감" : "신청하기"}
                buttonType={detail.soldout_yn == "Y" && "gray"}
                disabled={detail.soldout_yn == "Y"}
                onClick={() => {
                  // if (authInfo.certification()) {
                    navigate("/apply/" + (params?.id ? params?.id : 1));
                  // }
                }}
              />
            </div>
          </div>
        </div>
      </Styles.Container>
    </div>
  );
}

export default ProgramDetailPage;
