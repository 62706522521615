import utils from "utils";
import { api } from "service";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import useModals from "hooks/useModals";

export default function useSupport({
  options = {
    noticeList: false,
    noticeDetail: false,
    faqList: false,
    policy: false,
  }
}) {
  const { id } = useParams();
  const location = useLocation();
  const [filter, setFilter] = useState(null);
  const modalOption = useModals();

  useEffect(() => {
    let param = utils.etc.getSearchParam();
    let f = {
      page: param.page ? param.page : 1,
      pageSize: 8
    };
    setFilter({ ...f });
  }, [location])

  const getModal = (type) => {
    let data = {};
    if (typeof(type) == "number") {
      data = getPolicy.data?.data.filter((v)=>v.type == type)[0] || {};
    }

    modalOption.setTermsModal((e) => {
      e.show = true;
      e.title = data.title;
      e.description = data.description

      return { ...e };
    });
  }

  const getModalDetail = (data) => {

    modalOption.setTermsModal((e) => {
      e.show = true;
      e.title = data.title;
      e.description = data.description

      return { ...e };
    });
  }

  const getNoticeList = useQuery(["notice_list", filter], () => {
    return filter && api.support.getNoticeList(filter);
  }, {
    refetchOnWindowFocus: false,
    enabled: !!options.noticeList
  });
  const getNoticeDetail = useQuery(["notice_detail", id], () => {
    return api.support.getNoticeDetail(id);
  }, {
    refetchOnWindowFocus: false, 
    enabled: !!options.noticeDetail
  });
  const getFaqList = useQuery(["faq_list", filter], () => {
    return filter && api.support.getFaqList(filter);
  }, {
    refetchOnWindowFocus: false, 
    enabled: !!options.faqList
  });
  const getPolicy = useQuery(["policy", filter], () => {
    return filter && api.support.getPolicy(filter);
  }, {
    refetchOnWindowFocus: false, 
    enabled: !!options.policy
  });
  
  return {
    filter, setFilter,
    getModal,
    getModalDetail,

    getNoticeList,
    getNoticeDetail,
    getFaqList,
    getPolicy,
  }
}