import styled from "styled-components";

// img
import IconDisabled from "resources/image/icon/icon_check_disabled.svg";
import IconCheckOn from "resources/image/icon/icon_check_on.svg";
import IconCheckOff from "resources/image/icon/icon_check_off.svg";

const Container = styled.div`
  position: relative;

  & label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    cursor: pointer;
  }

  & input {
    display: none;

    &:checked + div {
      background-image: url(${IconCheckOn});
      background-color: var(--purple);
      border-color: var(--purple);
    }

    &:disabled + div {
      color: var(--gray02);
      background-image: url(${IconDisabled});
      background-color: var(--gray03);
      border-color: var(--gray03);

      & .secondOptionInfo {
        color: var(--gray02);
      }
    }
  }

  & .label {
    display: flex;
    align-items: center;
    height: 90px;
    padding: 0 20px 0 74px;
    background: url(${IconCheckOff}) no-repeat 20px center / 34px #fff;
    border: 1px solid var(--gray02);
    border-radius: 10px;

    & .textWrap {
      width: 100%;
    }

    & .time {
      margin-bottom: 5px;
      font-size: 22px;
      font-weight: 600;
    }

    & .secondOptionInfo {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      color: var(--gray01);
    }
  }

  @media (max-width: 520px) {
    & .label {
      height: calc(100vw * (90 / 500));
      padding: 0 calc(100vw * (20 / 500)) 0 calc(100vw * (74 / 500));
      background: url(${IconCheckOff}) no-repeat calc(100vw * (20 / 500)) center /
        calc(100vw * (34 / 500)) #fff;
      border-radius: calc(100vw * (10 / 500));

      & .time {
        margin-bottom: calc(100vw * (5 / 500));
        font-size: calc(100vw * (22 / 500));
      }

      & .secondOptionInfo {
        font-size: calc(100vw * (18 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
