import Styles from "./styles";

// img
import ImgDummyList from "resources/image/img_dummy_list.png";

function ProgramRadio({ data, name, big, checked, onChange, onClick }) {
  const { id, thumbnail, title } = data;

  return (
    <Styles.Container className={big ? " big" : ""}>
      <input
        type="radio"
        id={data?.id}
        checked={checked}
        name={name}
        onChange={onChange}
        onClick={onClick}
      />

      <div className="label">
        <div className="img">
          <img src={thumbnail ? thumbnail : ImgDummyList} alt="listImg" />
        </div>

        <div className="textWrap">
          <p className="title">{title}</p>
        </div>
      </div>

      <label htmlFor={id}></label>
    </Styles.Container>
  );
}

export default ProgramRadio;
