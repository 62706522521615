import styled from "styled-components";

const Container = styled.main`
  position: relative;

  & section {
    padding: 40px 0;
    border-bottom: 10px solid var(--gray03);
  }

  & .refundSection {
    & .program {
      margin-bottom: 40px;
    }
  }

  & .termsSection {
    & .termsLinkList {
      font-size: 22px;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      & a {
        display: flex;
        justify-content: space-between;

        & .icon {
          width: 16px;
          height: 16px;
          filter: var(--gray01-filter);
        }
      }
    }
  }

  & .check {
    width: fit-content;
    margin: 40px auto;
  }

  & .buttonContainer {
    padding-bottom: 50px;

    & .inner {
      display: flex;
      justify-content: center;
      gap: 10px;

      & .buttonWrap {
        flex: 1;
      }
    }
  }

  @media (max-width: 520px) {
    & section {
      padding: calc(100vw * (40 / 500)) 0;
      border-bottom: calc(100vw * (10 / 500)) solid var(--gray03);
    }

    & .refundSection {
      & .program {
        margin-bottom: calc(100vw * (40 / 500));
      }
    }

    & .termsSection {
      & .termsLinkList {
        font-size: calc(100vw * (22 / 500));

        &:not(:last-child) {
          margin-bottom: calc(100vw * (20 / 500));
        }

        & a {
          & .icon {
            width: calc(100vw * (16 / 500));
            height: calc(100vw * (16 / 500));
          }
        }
      }
    }

    & .check {
      margin: calc(100vw * (40 / 500)) auto;
    }

    & .buttonContainer {
      padding-bottom: calc(100vw * (50 / 500));

      & .inner {
        gap: calc(100vw * (10 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
