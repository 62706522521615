import connector from "service/connector"


const getLocationList = async () => {
  return await connector.connectFetchController("location", "GET");
}
const getLocationDetail = async (path) => {
  return await connector.connectFetchController("location/detail?id=" + path, "GET");
}


export {
  getLocationList,
  getLocationDetail,
};


export default {
  getLocationList,
  getLocationDetail,
}