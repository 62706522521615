import styled from "styled-components";

const Container = styled.main`
  & .inner {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 61px);
    min-height: calc(100dvh - 61px);
    padding: 50px 0 100px;

    & h2 {
      margin-bottom: 30px;
      font-size: 30px;
      font-weight: 400;
      line-height: 1.4;

      & b {
        font-weight: 800;
      }
    }

    & .logo {
      width: 228px;
      height: 65px;
    }

    & ul {
      margin-top: 141px;

      & li:not(:last-child) {
        margin-bottom: 20px;
      }

      & button {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 70px;
        font-size: 22px;
        font-weight: 600;
        text-transform: capitalize;
        border-radius: 5px;

        &.anotherButton {
          color: var(--main);
          background-color: var(--purple);
        }

        &.google {
          background-color: #f7f7f7;
        }

        &.kakao {
          background-color: #fee500;
        }

        &.naver {
          color: #fff;
          background-color: #0ccc61;
        }

        &.apple {
          color: #fff;
          background-color: var(--black);
        }

        & .icon {
          position: absolute;
          top: 50%;
          left: 20px;
          transform: translateY(-50%);
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  @media (max-width: 520px) {
    & .inner {
      min-height: calc(100vh - calc(100vw * (61 / 500)));
      padding: calc(100vw * (50 / 500)) 0 calc(100vw * (100 / 500));

      & h2 {
        margin-bottom: calc(100vw * (30 / 500));
        font-size: calc(100vw * (30 / 500));
      }

      & .logo {
        width: calc(100vw * (228 / 500));
        height: calc(100vw * (65 / 500));
      }

      & ul {
        margin-top: calc(100vw * (141 / 500));

        & li:not(:last-child) {
          margin-bottom: calc(100vw * (20 / 500));
        }

        & button {
          height: calc(100vw * (70 / 500));
          font-size: calc(100vw * (22 / 500));
          border-radius: calc(100vw * (5 / 500));

          & .icon {
            left: calc(100vw * (20 / 500));
            width: calc(100vw * (24 / 500));
            height: calc(100vw * (24 / 500));
          }
        }
      }
    }
  }
`;

export { Container };

export default { Container };
