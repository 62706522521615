import IconStarOn from "resources/image/icon/icon_star_on.svg";
import IconStarOff from "resources/image/icon/icon_star_off.svg";
import React from "react";

//숫자 3자리수 콤마 코드
function numberWithCommas(x) {
  try {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } catch (e) {
    return "0";
  }
}

// 검색어 하이라이팅
const highlighting = (v, keyword) => {
  if (v == null) return "";

  const text = v.split(keyword);

  return text.map((item, index) => {
    return (
      <React.Fragment key={"text" + index}>
        {index > 0 && <span className="highlighting">{keyword}</span>}
        {item}
      </React.Fragment>
    );
  });
};

function getFilterStr(parameter) {
  let filter = new URLSearchParams(parameter).toString();
  return decodeURIComponent(filter);
}

function getSearchParam() {
  try {
    return JSON.parse(
      '{"' +
        decodeURI(
          window.location.search
            .substring(1)
            .replace(/&/g, '","')
            .replace(/=/g, '":"')
        ) +
        '"}'
    );
  } catch (e) {
    return {};
  }
}

//keyArray = { key : page, value : 1 };
function getQueryParamUrl(obj, isClear, isParamOnly) {
  try {
    obj = obj ? obj : {};
    let searchParam = isClear ? {} : getSearchParam();
    for (let key in obj) {
      if (obj[key] == null) {
        delete searchParam[key];
        continue;
      }
      searchParam[key] = obj[key];
    }
    if (isParamOnly) {
      return getFilterStr(searchParam);
    }
    return `${window.location.pathname}?${getFilterStr(searchParam)}`;
  } catch (e) {
    return {};
  }
}

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

// 별점 표기
const renderStarPotint = (starPoint) => {
  starPoint = starPoint ? parseInt(starPoint) : 0;
  return (
    <ul className="star">
      {[...Array(starPoint)].map((_, index) => {
        return (
          <li className="starList" key={"starList" + index}>
            <img src={IconStarOn} alt="IconStarOn" />
          </li>
        );
      })}
      {[...Array(5 - starPoint)].map((_, index) => {
        return (
          <li className="starList" key={"starList" + index}>
            <img src={IconStarOff} alt="IconStarOff" />
          </li>
        );
      })}
    </ul>
  );
};

// object 타입의 데이터를 form 타입으로 바꾸는 함수
const objectToForm = (jsonData) => {
  const postData = new FormData();
  Object.keys(jsonData).map((item) => {
    postData.append(`${item}`, jsonData[item]);
  });
  return postData;
};

const throttle = (fn, delay) => {
  let time = Date.now();
  return () => {
    if (time + delay - Date.now() <= 0) {
      fn();
      time = Date.now();
    }
  };
};

function openUrl(url) {
  if (
    window.ReactNativeWebView &&
    window.ReactNativeWebView.postMessage &&
    url.includes("onew.life") == -1
  ) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ type: "OPENLINK", url: url })
    );
  } else {
    //기존 이동 페이지 or navigate(url);
    window.location.href = url;
  }
}

function callNative(type, url) {
  if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ type: type, url: url })
    );
  } else {
    //기존 이동 페이지 or navigate(url);
    window.location.href = url;
  }
}

function iOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    navigator.userAgent.includes("Mac")
  );
}

export {
  numberWithCommas,
  highlighting,
  getFilterStr,
  getSearchParam,
  getQueryParamUrl,
  getCookie,
  renderStarPotint,
  objectToForm,
  throttle,
  openUrl,
  callNative,
  iOS,
};

export default {
  numberWithCommas,
  highlighting,
  getFilterStr,
  getSearchParam,
  getQueryParamUrl,
  getCookie,
  renderStarPotint,
  objectToForm,
  throttle,
  openUrl,
  callNative,
  iOS,
};
