import Styles from "./styles";
import { useNavigate } from "react-router-dom";

export default function Keyword({ data }) {

  const navigate = useNavigate();

  return (
    <Styles.Container
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        navigate("/search?keyword=" + data);
      }}
    >
      {data?.title ? data?.title : data.keyword != null ? data.keyword : String(data)}
    </Styles.Container>
  );
}
