import styled from "styled-components";

const Container = styled.main`
  position: relative;
  min-height: 100vh;
  min-height: 100dvh;

  & .programSection {
    padding-bottom: 100px;
  }

  & .star {
    display: flex;
  }

  & .contentsTitle {
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: bold;
  }

  & .priceWrap {
    & .percent {
      margin-right: 10px;
      color: var(--green);
    }

    & del {
      font-weight: 400;
      color: var(--gray01);
    }

    & .price {
      font-weight: bold;
    }
  }

  & .thumbnail {
    position: relative;

    & .img {
      height: 625px;
    }

    & .textWrap {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;
      height: 312px;
      padding: 40px 0;
      color: #fff;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 135.68%);

      & .title {
        font-size: 22px;

        & .categoryName {
          display: inline-block;
          color: #00ffac;
          text-decoration: underline; 
        }
      }

      & .mainTitle {
        margin: 12px 0 15px;
        font-size: 32px;
        font-weight: 600;
        line-height: 1.37;
      }

      & .description {
        font-size: 18px;
        font-weight: 400;
        line-height: 1.66;
        white-space: pre-wrap;
      }
    }
  }

  & .priceContainer {
    padding: 20px 0;
    border-bottom: 10px solid var(--gray03);

    & .top,
    & .reaction,
    & .reactionList,
    & .bottom,
    & .priceText {
      display: flex;
      align-items: center;
    }

    & .top {
      justify-content: space-between;
      margin-bottom: 20px;

      & .reaction {
        gap: 15px;

        & .reactionList {
          gap: 5px;
        }
      }

      & .starWrap {
        font-size: 18px;
        color: var(--gray01);

        & .starList {
          width: 24px;
          height: 24px;
        }
      }

      & .shareButton {
        width: 34px;
        height: 34px;
      }
    }

    & .bottom {
      align-items: flex-start;
      justify-content: space-between;
      font-size: 22px;

      & .priceText {
        gap: 10px;
        color: var(--gray01);

        & .icon {
          width: 24px;
          height: 24px;
        }
      }

      & .priceWrap {
        text-align: right;

        & .discount {
          margin-bottom: 10px;
        }

        & .price {
          font-size: 36px;
        }
      }
    }
  }

  & .info {
    padding: 20px 0 40px;

    & .infoList,
    & .listTitle,
    & .keyword {
      display: flex;
      align-items: center;
    }

    & .infoWrap {
      margin-bottom: 20px;
    }

    & .infoList {
      justify-content: space-between;
      font-size: 22px;

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      & .listTitle {
        color: var(--gray01);

        & .icon {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
      }

      & .text {
        width: 282px;
      }
    }

    & .keyword {
      flex-wrap: wrap;
      gap: 8px;
      margin-bottom: 40px;
    }
  }

  & .tab {
    position: sticky;
    top: 60px;
    display: flex;
    background-color: #fff;
    z-index: 5;

    & .tabList {
      position: relative;
      flex: 1;
      padding: 18px 0;
      color: var(--gray01);
      text-align: center;
      border-top: 1px solid var(--gray02);
      cursor: pointer;

      &.on {
        font-weight: 600;
        color: var(--main);

        &::before {
          position: absolute;
          top: -1px;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: var(--main);
          content: "";
        }
      }
    }
  }

  & .program,
  & .instructor,
  & .review {
    scroll-margin: 121px;
  }

  & .program,
  & .instructor {
    padding: 40px 0;
  }

  & .editor {
    word-break: break-all;
  }

  & .instructor {
    border-top: 10px solid var(--gray03);

    & .withProgram {
      margin-top: 40px;

      & .withProgramWrap {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
      }
    }
  }

  & .review {
    & .totalStarPoint {
      padding: 40px;
      font-size: 30px;
      font-weight: bold;
      text-align: center;
      border-bottom: 10px solid var(--gray03);

      & .star {
        justify-content: center;
        margin-top: 10px;

        & .starList {
          width: 44px;
          height: 44px;
        }
      }
    }

    & .recentPhotoReview {
      padding-top: 40px;

      & .scroll-container {
        display: flex;
        gap: 8px;
        padding: 0 20px;
        overflow: auto;

        & .recentphotoReviewList {
          flex-shrink: 0;
          width: 150px;
          height: 150px;
        }
      }
    }

    & .allReview {
      padding-top: 40px;

      & .reviewTitle {
        padding-bottom: 20px;
        font-weight: bold;

        & span {
          color: var(--main);
        }
      }

      & .reviewContainer {
        padding-bottom: 20px;
        border-top: 1px solid var(--gray02);
        border-bottom: 1px solid var(--gray02);

        & .emptyText {
          padding: 100px 0;
          color: var(--gray01);
          text-align: center;
        }

        & .reviewList:not(:last-child) {
          border-bottom: 1px solid var(--gray03);
        }

        & .pagination {
          margin: 20px 0;
        }
      }
    }
  }

  & .relatedContents {
    padding-top: 40px;
  }

  & .application {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 500px;
    padding: 12px 0;
    background-color: #fff;
    border-top: 1px solid var(--gray02);
    z-index: 5;

    & .inner {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & .discount {
        margin-bottom: 5px;
        font-size: 18px;
      }

      & .price {
        font-size: 30px;
      }

      & .applicationButton {
        width: 225px;
      }
    }
  }

  @media (max-width: 520px) {
    & .programSection {
      padding-bottom: calc(100vw * (100 / 500));
    }

    & .contentsTitle {
      margin-bottom: calc(100vw * (20 / 500));
      font-size: calc(100vw * (22 / 500));
    }

    & .thumbnail {
      & .img {
        height: calc(100vw * (625 / 500));
      }

      & .textWrap {
        height: calc(100vw * (312 / 500));
        padding: calc(100vw * (40 / 500)) 0;

        & .title {
          font-size: calc(100vw * (22 / 500));
        }

        & .mainTitle {
          margin: calc(100vw * (12 / 500)) 0 calc(100vw * (15 / 500));
          font-size: calc(100vw * (32 / 500));
        }

        & .description {
          font-size: calc(100vw * (18 / 500));
          white-space: pre-wrap;
        }
      }
    }

    & .priceContainer {
      padding: calc(100vw * (20 / 500)) 0;
      border-bottom: calc(100vw * (10 / 500)) solid var(--gray03);

      & .top {
        margin-bottom: calc(100vw * (20 / 500));

        & .reaction {
          gap: calc(100vw * (15 / 500));

          & .reactionList {
            gap: calc(100vw * (5 / 500));
          }
        }

        & .starWrap {
          font-size: calc(100vw * (18 / 500));

          & .starList {
            width: calc(100vw * (24 / 500));
            height: calc(100vw * (24 / 500));
          }
        }

        & .shareButton {
          width: calc(100vw * (34 / 500));
          height: calc(100vw * (34 / 500));
        }
      }

      & .bottom {
        font-size: calc(100vw * (22 / 500));

        & .priceText {
          gap: calc(100vw * (10 / 500));

          & .icon {
            width: calc(100vw * (24 / 500));
            height: calc(100vw * (24 / 500));
          }
        }

        & .priceWrap {
          & .discount {
            margin-bottom: calc(100vw * (10 / 500));

            & .percent {
              margin-right: calc(100vw * (10 / 500));
            }
          }

          & .price {
            font-size: calc(100vw * (36 / 500));
          }
        }
      }
    }

    & .info {
      padding: calc(100vw * (20 / 500)) 0 calc(100vw * (40 / 500));

      & .infoWrap {
        margin-bottom: calc(100vw * (20 / 500));
      }

      & .infoList {
        font-size: calc(100vw * (22 / 500));

        &:not(:last-child) {
          margin-bottom: calc(100vw * (15 / 500));
        }

        & .listTitle {
          & .icon {
            width: calc(100vw * (24 / 500));
            height: calc(100vw * (24 / 500));
            margin-right: calc(100vw * (10 / 500));
          }
        }

        & .text {
          width: calc(100vw * (282 / 500));
        }
      }

      & .keyword {
        gap: calc(100vw * (8 / 500));
        margin-bottom: calc(100vw * (40 / 500));
      }
    }

    & .tab {
      top: calc(100vw * (60 / 500));

      & .tabList {
        padding: calc(100vw * (18 / 500)) 0;

        &.on {
          &::before {
            height: calc(100vw * (2 / 500));
          }
        }
      }
    }

    & .program,
    & .instructor,
    & .review {
      scroll-margin: calc(100vw * (121 / 500));
    }

    & .program,
    & .instructor {
      padding: calc(100vw * (40 / 500)) 0;
    }

    & .instructor {
      border-top: calc(100vw * (10 / 500)) solid var(--gray03);

      & .withProgram {
        margin-top: calc(100vw * (40 / 500));

        & .withProgramWrap {
          gap: calc(100vw * (16 / 500));
        }
      }
    }

    & .review {
      & .totalStarPoint {
        padding: calc(100vw * (40 / 500));
        font-size: calc(100vw * (30 / 500));
        border-bottom: calc(100vw * (10 / 500)) solid var(--gray03);

        & .star {
          margin-top: calc(100vw * (10 / 500));

          & .starList {
            width: calc(100vw * (44 / 500));
            height: calc(100vw * (44 / 500));
          }
        }
      }

      & .recentPhotoReview {
        padding-top: calc(100vw * (40 / 500));

        & .scroll-container {
          gap: calc(100vw * (8 / 500));
          padding: 0 calc(100vw * (20 / 500));

          & .recentphotoReviewList {
            width: calc(100vw * (150 / 500));
            height: calc(100vw * (150 / 500));
          }
        }
      }

      & .allReview {
        padding-top: calc(100vw * (40 / 500));

        & .reviewTitle {
          padding-bottom: calc(100vw * (20 / 500));
        }

        & .reviewContainer {
          padding-bottom: calc(100vw * (20 / 500));

          & .emptyText {
            padding: calc(100vw * (100 / 500)) 0;
          }

          & .pagination {
            margin: calc(100vw * (20 / 500)) 0;
          }
        }
      }
    }

    & .relatedContents {
      padding-top: calc(100vw * (40 / 500));
    }

    & .application {
      width: 100%;
      padding: calc(100vw * (12 / 500)) 0;

      & .inner {
        & .discount {
          margin-bottom: calc(100vw * (5 / 500));
          font-size: calc(100vw * (18 / 500));
        }

        & .price {
          font-size: calc(100vw * (30 / 500));
        }

        & .applicationButton {
          width: calc(100vw * (225 / 500));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
