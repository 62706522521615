import Styles from "./styles";
import { NavLink } from "react-router-dom";

function Tab({ tabList, tabIndex, setTabIndex }) {
  return (
    <Styles.Container>
      {tabList?.map((item, index) => {
        return (
          <li
            className={"tabList" + (tabIndex === item.id ? " on" : "")}
            key={"tabList" + index}
            onClick={() => {
              setTabIndex(item.id);
            }}
          >
            {item.url ? (
              <NavLink to={item.url}>{item.text}</NavLink>
            ) : (
              <div>{item.text}</div>
            )}
          </li>
        );
      })}
    </Styles.Container>
  );
}

export default Tab;
