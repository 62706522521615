import Styles from "./styles";
import { common, layout, ui } from "components";
import { useNavigate } from "react-router-dom";
import { useModals, useReview, useUpload } from "hooks";
import { useEffect, useRef, useState } from "react";
import { api } from "service";
import useOrder from "hooks/useOrder";
import utils from "utils";

function ReviewEditPage() {
  const navigate = useNavigate();
  const modalOption = useModals();
  const uploadInfo = useUpload();
  const orderInfo = useOrder({
    detailOption: {
      id: utils.etc.getSearchParam().id,
    },
  });
  const reviewInfo = useReview({
    detailOption:{
      id: utils.etc.getSearchParam().id,
    }
  });
  const [imageList, setImageList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [inputs, setInputs] = useState({
    review: "리뷰를 작성하는 칸",
  });
  const [star, setStar] = useState(3);
  const loadRef = useRef(false);
  useEffect(()=>{
    if(reviewInfo.detailQuery.data?.data){
      let detail = reviewInfo.detailQuery.data?.data;
      setStar(detail?.score);
      setInputs({review:detail?.description});
      setImageList(detail?.images?.split(",") || []);
    }
  },[reviewInfo.detailQuery.data])
  
  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs((e) => {
      return { ...e, [name]: value };
    });
  };

  const doValidate = async () => {

    if(star == 0 || inputs.review.length == 0){
      modalOption.setToastModal((e) => {
        e.show = true;
        e.text = star == 0 ? "별점을 선택해 주세요." : "리뷰를 작성해 주세요.";
        // 별점을 선택해 주세요.
        // 사진은 최대 5개까지 첨부 가능합니다.
        return { ...e };
      });
      return;
    }else{

      if(loadRef.current) return;
      loadRef.current = true;
      
      let data = {
        transaction_info_id : utils.etc.getSearchParam().id,
        type : 2,
        description : inputs.review,
        score : star
      };
      
      if(fileList.length > 0){
          let temp = await uploadInfo.uploadReviewImageList(fileList);
          data.images = temp?.data ? imageList.concat(temp?.data?.map((value)=> value.thumbnail)).join(",") : "";
      }

      let result = await api.review.updateReview(JSON.stringify(data));
      if(result.success)
        modalOption.setDefaultModal((e) => {
          e.show = true;
          e.closeButton = false;
          e.icon = false;
          e.title = "리뷰가 수정되었습니다.";
          e.text = false;
          e.confirmButtonText = "확인";
          e.confirmButtonClick = () => {
            navigate("/mypage/program");
            modalOption.clearModals();
          };

          return { ...e };
        });
    }
  }

  // map
  const reviewData = {
    essential: true,
    labelText: "리뷰 작성",
    name: "review",
    id: "review",
    value: inputs.review,
    placeholder: "직접 입력",
  };

  return (
    <div className="wrap">
      <common.SubHeader pageName={"리뷰 수정"} />

      <Styles.Container>
        <section className="reviewSection">
          <div className="inner">
            <div className="program">
              {
                orderInfo?.detailQuery?.data?.data &&
                <layout.MyProgram 
                  data={orderInfo?.detailQuery?.data?.data} 
                  hideStateBar={true}
                />
              }
            </div>

            <div className="starPoint">
              <layout.ReviewStarPoint star={star} setStar={setStar} />
            </div>

            <div className="textarea">
              <ui.input.BasicTextarea data={reviewData} onChange={onChange} />
            </div>

            <div className="imgUpload">
              <layout.ReviewImageUpload 
                imageList={imageList}
                setImageList={setImageList}
                fileList={fileList}
                setFileList={setFileList}
              />
            </div>

            <div className="buttonContainer">
              <div className="buttonWrap">
                <ui.button.BasicButton
                  buttonText={"취소"}
                  buttonType={"green"}
                  onClick={() => {
                    navigate(-1);
                  }}
                />
              </div>

              <div className="buttonWrap">
                <ui.button.BasicButton
                  buttonText={"리뷰 수정"}
                  onClick={() => {
                    doValidate();
                    // modalOption.setToastModal((e) => {
                    //   e.show = true;
                    //   e.text = "리뷰를 작성해 주세요.";
                    //   // 별점을 선택해 주세요.
                    //   // 사진은 최대 5개까지 첨부 가능합니다.

                    //   return { ...e };
                    // });
                  }}
                />
              </div>
            </div>
          </div>
        </section>
      </Styles.Container>
    </div>
  );
}

export default ReviewEditPage;
