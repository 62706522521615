import styled from "styled-components";

const Container = styled.div`
  padding: 0 13px;
  border-bottom: 1px solid var(--gray02);
  cursor: pointer;

  &.on {
    background-color: var(--gray03);
    border-color: var(--gray03);
  }

  & .questionWrap,
  .question,
  .answer {
    display: flex;
  }

  & .question,
  & .answer {
    align-items: flex-start;
    gap: 10px;

    & .icon {
      flex-shrink: 0;
      width: 28px;
      height: 28px;
    }
  }

  & .questionWrap {
    align-items: center;
    height: 90px;

    & p {
      padding-top: 2px;
    }
  }

  & .answerWrap {
    padding-bottom: 70px;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.44;
  }

  @media (max-width: 520px) {
    padding: 0 calc(100vw * (13 / 500));

    & .question,
    & .answer {
      gap: calc(100vw * (10 / 500));

      & .icon {
        width: calc(100vw * (28 / 500));
        height: calc(100vw * (28 / 500));
      }
    }

    & .questionWrap {
      height: calc(100vw * (90 / 500));

      & p {
        padding-top: calc(100vw * (2 / 500));
      }
    }

    & .answerWrap {
      padding-bottom: calc(100vw * (70 / 500));
      font-size: calc(100vw * (18 / 500));
    }
  }
`;

export { Container };

export default { Container };
