import connector from "service/connector"


const likeToggle = async (data) => {
  return await connector.authConnector("like", "POST", data);
}
const likeProgramList = async () => {
  return await connector.authConnector("product/like/list", "GET");
}
const likeMagazineList = async () => {
  return await connector.authConnector("board/like/list?type=1", "GET");
}


export {
  likeToggle,
  likeProgramList,
  likeMagazineList,
};
export default {
  likeToggle,
  likeProgramList,
  likeMagazineList,
}