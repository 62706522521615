import Styles from "./styles";
import { common, layout, ui } from "components";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

function ReservationPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const [tabIndex, setTabIndex] = useState(location.pathname.split("/")[2]);
  const [check, setCheck] = useState("");
  const [searchParam, setSearchParam] = useState({});

  const tabList = [
    {
      id: "quick",
      url: "/reservation/quick",
      text: "빠른 예약하기",
    },
    {
      id: "date",
      url: "/reservation/date",
      text: "일자별 예약하기",
    },
  ];

  useEffect(()=>{
    setCheck("");
  },[tabIndex])

  return (
    <div className="wrap">
      <common.SubHeader
        pageName={"프로그램 예약"}
        backEvent={() => { navigate("/program") }}
      />

      <Styles.Container>
        <layout.Tab
          tabList={tabList}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
        />

        <Outlet 
          context={{ check, setCheck , searchParam, setSearchParam }} 
        />

        {check !== "" && (
          <div className="buttonContainer">
            <div className="inner">
              <div className="buttonWrap">
                <ui.button.BasicButton
                  buttonText={"자세히 보기"}
                  buttonType={"green"}
                  onClick={()=>{
                    navigate(`/program/detail/${check}`);
                  }}
                />
              </div>

              <div className="buttonWrap">
                <ui.button.BasicButton
                  buttonText={"선택하기"}
                  onClick={() => {
                    navigate(`/reservation/${tabIndex}/detail/${check}?${searchParam}`);
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </Styles.Container>
    </div>
  );
}
export default ReservationPage;
