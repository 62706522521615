import Styles from "./styles";
import { NavLink } from "react-router-dom";
import { useModals } from "hooks";

// img
import IconWarning from "resources/image/icon/icon_warning.svg";

function Menu() {
  const modalOption = useModals();

  const list = [
    {
      url: "/program",
      text: "프로그램",
    },
    {
      url: "/magazine",
      text: "매거진",
    },
    {
      url: "/community",
      onClick: (e) => {
        e.preventDefault();
        modalOption.setDefaultModal((e) => {
          e.show = true;
          e.icon = IconWarning;
          e.title = (
            <>
              오뉴 커뮤니티 <span className="color">단장중</span>입니다!
            </>
          );
          e.text = "조금만 기다려 주세요 :)";
          e.confirmButtonText = "확인";
          return { ...e };
        });
      },
      text: "커뮤니티",
    },
    {
      url: "/introduction",
      text: "오뉴소개",
    },
    {
      url: "/ask",
      text: "문의하기",
    },
  ];

  return (
    <Styles.Container>
      {list.map((item, index) => {
        return (
          <li className="navList" key={"navList" + index}>
            <NavLink to={item.url} onClick={item.onClick && item.onClick}>
              {item.text}
            </NavLink>
          </li>
        );
      })}
    </Styles.Container>
  );
}

export default Menu;
