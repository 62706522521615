import styled from "styled-components";

const Container = styled.div`
  padding: 20px;

  & .reviewTop {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    & .profileImg {
      flex-shrink: 0;
      width: 37px;
      height: 37px;
      margin-right: 8px;
      border-radius: 50%;
      overflow: hidden;
    }

    & .nickname {
      font-size: 22px;
      font-weight: 600;
    }

    & .starWrap {
      margin-left: auto;

      & .starList {
        width: 24px;
        height: 24px;
      }
    }
  }

  & .reviewBottom {
    padding-left: 45px;

    & .photoListWrap {
      display: flex;
      gap: 8px;
      margin-bottom: 10px;

      & .photoReviewList {
        width: 76px;
        height: 76px;
      }
    }

    & .reviewDetail {
      margin-bottom: 20px;
      font-size: 22px;
      font-weight: 400;
    }

    & .dateWrap {
      display: flex;
      align-items: center;
      gap: 20px;

      & .date,
      & .reportButton {
        font-size: 18px;
        font-weight: 400;
        color: var(--gray01);
      }
    }
  }

  @media (max-width: 520px) {
    padding: calc(100vw * (20 / 500));

    & .reviewTop {
      margin-bottom: calc(100vw * (10 / 500));

      & .profileImg {
        width: calc(100vw * (37 / 500));
        height: calc(100vw * (37 / 500));
        margin-right: calc(100vw * (8 / 500));
      }

      & .nickname {
        font-size: calc(100vw * (22 / 500));
      }

      & .starWrap {
        & .starList {
          width: calc(100vw * (24 / 500));
          height: calc(100vw * (24 / 500));
        }
      }
    }

    & .reviewBottom {
      padding-left: calc(100vw * (45 / 500));

      & .photoListWrap {
        gap: calc(100vw * (8 / 500));
        margin-bottom: calc(100vw * (10 / 500));

        & .photoReviewList {
          width: calc(100vw * (76 / 500));
          height: calc(100vw * (76 / 500));
        }
      }

      & .reviewDetail {
        margin-bottom: calc(100vw * (20 / 500));
        font-size: calc(100vw * (22 / 500));
      }

      & .dateWrap {
        gap: calc(100vw * (20 / 500));

        & .date,
        & .reportButton {
          font-size: calc(100vw * (18 / 500));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
