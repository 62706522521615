import Styles from "./styles";
import { etc } from "utils";

function ReviewStarPoint({ star, setStar }) {
  return (
    <Styles.Container>
      <p className="starText">프로그램은 어떠셨나요?</p>

      <p className="starPointText">{star}점</p>

      <div className="inputWrap">
        {etc.renderStarPotint(parseInt(star))}

        <input
          type="range"
          id="star"
          name="star"
          min="0"
          max="5"
          value={star}
          step="1"
          onChange={(e) => {
            setStar(e.target.value);
          }}
        />
      </div>
    </Styles.Container>
  );
}

export default ReviewStarPoint;
