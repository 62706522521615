import styled from "styled-components";

const Container = styled.main`
  position: relative;

  & section:not(:first-of-type, :last-of-type) {
    padding: 40px 0;
    border-bottom: 10px solid var(--gray03);
  }

  & .title {
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: bold;
  }

  & .titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    & .title {
      margin-bottom: 0;
    }
  }

  & .applicable,
  .available {
    font-size: 18px;
    color: var(--gray01);

    & b {
      margin-left: 10px;
      font-size: 22px;
      font-weight: 600;
      color: var(--main);
    }
  }

  & .userInfoSection {
    & .title span {
      color: var(--main);
    }

    & .labelText {
      font-size: 18px;
    }

    & .inputLabel,
    & .inputList {
      display: flex;
      gap: 14px;

      & input {
        width: 100%;
      }
    }

    & .name {
      width: 126px;
    }

    & .tel {
      width: 320px;
    }

    & .inputLabel {
      margin-bottom: 15px;
      font-size: 22px;
      font-weight: 600;
    }

    & .inputList:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  & .couponSection {
    & .guideText {
      display: flex;
      gap: 7px;
      margin-top: 15px;
      font-size: 18px;
      color: var(--gray01);

      & .icon {
        display: inline-block;
        width: 28px;
        height: 28px;
        filter: var(--gray01-filter);
      }
    }
  }

  & .pointSection {
    & .allPointCheck .labelText {
      font-size: 18px;
    }

    & .inputWrap {
      margin-bottom: 15px;

      & button {
        width: 120px;
      }
    }

    & .available {
      text-align: right;
    }
  }

  & .payButtonSection {
    & .payButton {
      display: flex;
      gap: 20px;

      & .payButtonList {
        & input {
          display: none;

          &:checked + label {
            color: #fff;
            background-color: var(--main);

            & .icon {
              filter: var(--white-filter);
            }
          }
        }

        & label {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 220px;
          height: 160px;
          font-size: 22px;
          font-weight: bold;
          color: var(--main);
          border: 1px solid var(--main);
          border-radius: 5px;
          cursor: pointer;

          & .icon {
            display: inline-block;
            width: 50px;
            height: 50px;
            filter: var(--main-filter);
          }
        }
      }
    }
  }

  & .termsSection {
    padding: 40px 0 195px;

    & .allCheck,
    & .essentialCheck {
      margin-bottom: 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid var(--gray02);
    }

    & .essentialCheckList,
    .termsLink .termsLinkList {
      display: flex;
      justify-content: space-between;

      & .icon {
        width: 16px;
        height: 16px;
        filter: var(--gray01-filter);
      }
    }

    & .essentialCheckList:not(:last-child),
    .termsLinkList:not(:last-child) {
      margin-bottom: 20px;
    }

    & .termsLinkList {
      font-size: 22px;
    }
  }

  & .application {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 500px;
    padding: 12px 0;
    background-color: #fff;
    border-top: 1px solid var(--gray02);
    z-index: 5;

    & .inner {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & .price {
        font-size: 30px;
        font-weight: bold;
      }

      & .applicationButton {
        width: 225px;
      }
    }
  }

  @media (max-width: 520px) {
    & section:not(:first-of-type, :last-of-type) {
      padding: calc(100vw * (40 / 500)) 0;
      border-bottom: calc(100vw * (10 / 500)) solid var(--gray03);
    }

    & .title {
      margin-bottom: calc(100vw * (20 / 500));
      font-size: calc(100vw * (22 / 500));
    }

    & .titleContainer {
      margin-bottom: calc(100vw * (20 / 500));
    }

    & .applicable,
    .available {
      font-size: calc(100vw * (18 / 500));

      & b {
        margin-left: calc(100vw * (10 / 500));
        font-size: calc(100vw * (22 / 500));
      }
    }

    & .userInfoSection {
      & .labelText {
        font-size: calc(100vw * (18 / 500));
      }

      & .inputLabel,
      & .inputList {
        gap: calc(100vw * (14 / 500));
      }

      & .name {
        width: calc(100vw * (126 / 500));
      }

      & .tel {
        width: calc(100vw * (320 / 500));
      }

      & .inputLabel {
        margin-bottom: calc(100vw * (15 / 500));
        font-size: calc(100vw * (22 / 500));
      }

      & .inputList:not(:last-child) {
        margin-bottom: calc(100vw * (15 / 500));
      }
    }

    & .couponSection {
      & .guideText {
        gap: calc(100vw * (7 / 500));
        margin-top: calc(100vw * (15 / 500));
        font-size: calc(100vw * (18 / 500));

        & .icon {
          width: calc(100vw * (28 / 500));
          height: calc(100vw * (28 / 500));
        }
      }
    }

    & .pointSection {
      & .allPointCheck .labelText {
        font-size: calc(100vw * (18 / 500));
      }

      & .inputWrap {
        margin-bottom: calc(100vw * (15 / 500));

        & button {
          width: calc(100vw * (120 / 500));
        }
      }
    }

    & .payButtonSection {
      & .payButton {
        gap: calc(100vw * (20 / 500));

        & .payButtonList {
          & label {
            width: calc(100vw * (220 / 500));
            height: calc(100vw * (160 / 500));
            font-size: calc(100vw * (22 / 500));
            border-radius: calc(100vw * (5 / 500));

            & .icon {
              width: calc(100vw * (50 / 500));
              height: calc(100vw * (50 / 500));
            }
          }
        }
      }
    }

    & .termsSection {
      padding: calc(100vw * (40 / 500)) 0 calc(100vw * (195 / 500));

      & .allCheck,
      & .essentialCheck {
        margin-bottom: calc(100vw * (30 / 500));
        padding-bottom: calc(100vw * (30 / 500));
      }

      & .essentialCheckList,
      .termsLink .termsLinkList {
        & .icon {
          width: calc(100vw * (16 / 500));
          height: calc(100vw * (16 / 500));
        }
      }

      & .essentialCheckList:not(:last-child),
      .termsLinkList:not(:last-child) {
        margin-bottom: calc(100vw * (20 / 500));
      }

      & .termsLinkList {
        font-size: calc(100vw * (22 / 500));
      }
    }

    & .application {
      width: 100%;
      padding: calc(100vw * (12 / 500)) 0;

      & .inner {
        & .price {
          font-size: calc(100vw * (30 / 500));
        }

        & .applicationButton {
          width: calc(100vw * (225 / 500));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
