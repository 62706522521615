import { useAtom } from "jotai";
import { useState } from "react";
import { useQuery } from "react-query";
import { api } from "service";
import { modalAtom } from "store/atoms";

export default function useOrder({
  listOption,
  detailOption,
}) {

  const listQuery = useQuery(["order",listOption], 
    ()=>{
      return api.order.getList(listOption);
    }, {
    enabled:!!listOption,
    refetchOnWindowFocus: false,
  });

  const detailQuery = useQuery(["order",detailOption], 
    ()=>{
      return api.order.getDetail(detailOption.id);
    }, {
    enabled:!!detailOption,
    refetchOnWindowFocus: false,
  });

  return {
    listQuery,
    detailQuery,
  };
}
