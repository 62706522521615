import Styles from "./styles";
import { common } from "components";
import { useDirection } from "hooks";

// img
import IconLocation from "resources/image/icon/icon_location02.svg";
import IconHeadset from "resources/image/icon/icon_headset.svg";
import IconEmail from "resources/image/icon/icon_email.svg";

function DirectionDetailPage() {
  const directionInfo = useDirection({ locationDetail: true });
  const data = directionInfo.getLocationDetail?.data?.data;

  const locaitonList = [
    {
      icon: IconLocation,
      address: data?.address,
      detail: data?.address_detail,
      postcode: data?.postcode,
    },
    {
      icon: IconHeadset,
      text: <a href={`tel:${data?.contact}`}>{data?.contact}</a>,
    },
    {
      icon: IconEmail,
      text: data?.email,
    },
  ];

  return (
    <div className="wrap">
      <common.SubHeader pageName={"오시는 길"} />

      <Styles.Container>
        <div className="inner">
          <h2>{"오뉴 " + data?.title}</h2>

          <div
            className="editor ck-content"
            dangerouslySetInnerHTML={{ __html: data?.description }}
          ></div>

          <ul className="location">
            {locaitonList.map((item, index) => {
              return (
                <li className="locaitonList" key={"locaitonList" + index}>
                  <div className="icon">
                    <img src={item.icon} alt="locationIcon" />
                  </div>

                  {/* //* 퍼블 깨짐 우려 주석 */}
                  {item.postcode && <div className="text">{"우편번호: " + item.postcode}</div>}
                  <a href={data?.url}>
                    {item.address && <div className="text">{item.address}</div>}
                    {item.detail && <div className="text">{item.detail}</div>}
                  </a>

                  {/* //* 퍼블 원래는 아래의 text만 존재 */}
                  <div className="text">{item.text}</div>
                </li>
              );
            })}
          </ul>
        </div>
      </Styles.Container>

      <common.QuickMenu />
      <common.Footer />
      <common.NavigationBar />
    </div>
  );
}

export default DirectionDetailPage;
