import styled from "styled-components";

const Container = styled.main`
  position: relative;
  min-height: calc(100vh - 61px);
  min-height: calc(100dvh - 61px);
  padding: 40px 0 100px;

  & ul {
    display: flex;
    flex-wrap: wrap;
    gap: 40px 16px;
  }

  @media (max-width: 520px) {
    min-height: calc(100vh - calc(100vw * (61 / 500)));
    min-height: calc(100dvh - calc(100vw * (61 / 500)));
    padding: calc(100vw * (40 / 500)) 0 calc(100vw * (100 / 500));

    & ul {
      gap: calc(100vw * (40 / 500)) calc(100vw * (16 / 500));
    }
  }
`;

export { Container };

export default { Container };
