import Styles from "./styles";

function ProgramOptionSection({ productInfo, transactionList, detail }) {
  const optionList = detail?.group_list[0]?.option_list?.filter(
    (value) => value.type != "2"
  );

  return (
    <Styles.Container>
      <div className="inner">
        <div className="title">
          {productInfo?.detail?.type == 1 || detail?.type == 1
            ? "시간권 "
            : "모임 "}
          옵션
        </div>

        <ul className="time">
          {transactionList &&
            transactionList[0]?.option_relate_list?.map((item, index) => {
              return (
                <li className="timeList" key={"timeList" + index}>
                  <p className="number">{index + 1}</p>
                  <p className="optionTitle">{item.product_title}</p>
                  <p className="optionTime">{item.title}</p>
                </li>
              );
            })}
          {optionList &&
            optionList?.map((item, index) => {
              return (
                <li className="timeList" key={"timeList" + index}>
                  <p className="number">{index + 1}</p>
                  <p className="optionTitle">{item.product_title}</p>
                  <p className="optionTime">{item.title}</p>
                </li>
              );
            })}
        </ul>
      </div>
    </Styles.Container>
  );
}

export default ProgramOptionSection;
