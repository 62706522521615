import connector from "service/connector";
import utils from "utils";


const getDetail = async (id) => {
  return await connector.authConnector(`order/detail?id=${id}`,'GET');
}

const getList = async (data) => {
  return await connector.authConnector(`order?${utils.etc.getFilterStr(data)}`,'GET');
}



export {
  getDetail,
  getList,
};


export default {
  getDetail,
  getList,
}