import Styles from "./styles";
import { useParams } from "react-router-dom";
import { common, layout } from "components";
import { useBoard, useCategory } from "hooks";

function MagazineCategoryPage() {
  const params = useParams();

  const categoryInfo = useCategory({
    bannerOption: {
      id : params?.id ? params.id : 0,
    },
  });
  const boardInfo = useBoard({
    listOption: {
      page: 1,
      category_info_id: params?.id ? params.id : 0,
      recordSize: 60,
      pageSize: 1,
      type : 1,
    },
  });

  return (
    <div className="wrap">
      <common.Header />

      <Styles.Container>
        <layout.CategoryNavigation
          mainHomeText={"매거진 홈"}
          url={"/magazine"}
        />

        {
          categoryInfo?.bannerListQuery?.data?.data && 
          <section className="mainBannerSection">
            <layout.MainBanner 
              data={categoryInfo?.bannerListQuery?.data?.data} 
              type={2}
            />
          </section>
        }

        <section className="magazineSection">
          <div className="inner">
            <ul className="magazine">
              {boardInfo.listQuery?.data?.list?.map((item, index) => {
                return (
                  <li className="magazineList" key={"magazineList" + index}>
                    <layout.ContentsList
                      data={item}
                      big
                      url={"/magazine/detail/" + item.id}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </section>
      </Styles.Container>

      <common.QuickMenu />
      <common.NavigationBar />
    </div>
  );
}

export default MagazineCategoryPage;
