import Header from "./Header";
import Footer from "./Footer";
import QuickMenu from "./QuickMenu";
import NavigationBar from "./NavigationBar";
import SubHeader from "./SubHeader";
import CustomCkEditor from "./CustomCkEditor";

export {
  Header,
  Footer,
  QuickMenu,
  NavigationBar,
  SubHeader,
  CustomCkEditor,
};

export default {
  Header,
  Footer,
  QuickMenu,
  NavigationBar,
  SubHeader,
  CustomCkEditor,
};
