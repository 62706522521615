import { useEffect } from "react";
import AuthRoute from "routes/AuthRoute";
import { Route, Routes, useLocation } from "react-router-dom";
import Pages from "pages";
import {
  QuickReservation,
  DateReservation,
} from "pages/ReservationPage/components";
import { Notice, Terms, Faq } from "pages/ContactPage/components";
import utils from "utils";
import { getCookie } from "utils/etc";

export default function CustomRoute() {
  let location = useLocation();

  useEffect(() => {
    if (!utils.etc.getSearchParam().disableScrollTop) {
      window.scrollTo(0, 0);
      document.body.scrollTo(0, 0);
    }

    if(window.ReactNativeWebView && window.ReactNativeWebView.postMessage){
      window.ReactNativeWebView.postMessage(JSON.stringify({ type:"ISBACK", isBack: location.pathname == "" || location.pathname == "/" ? "true" : false }));
    }

    if(window.ReactNativeWebView && window.ReactNativeWebView.postMessage && getCookie('jwt')){
      window.ReactNativeWebView.postMessage(JSON.stringify({ type:"LOGIN", token: getCookie('jwt') }));
    }

  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Pages.MainPage />} />
      {/* 로그인 */}
      <Route path="/login" element={<Pages.LoginPage />} />
      <Route
        path="/signup/stepA"
        element={
          // <NonAuthRoute>
          <Pages.SignupStepAPage />
          // </NonAuthRoute>
        }
      />
      <Route
        path="/signup/stepB"
        element={
          // <NonAuthRoute>
          <Pages.SignupStepBPage />
          // </NonAuthRoute>
        }
      />
      <Route path="/signup/complete" element={<Pages.SignupCompletePage />} />
      {/* 출석체크 */}
      <Route path="/attendance" element={<Pages.AttendancePage />} />
      {/* 프로그램 */}
      <Route path="/program" element={<Pages.ProgramPage />} />
      <Route path="/test-pay" element={<Pages.TestPay />} />
      <Route
        path="/program/category/:id"
        element={<Pages.ProgramCategoryPage />}
      />
      <Route path="/program/detail" element={<Pages.ProgramDetailPage />} />
      <Route path="/program/detail/:id" element={<Pages.ProgramDetailPage />} />
      {/* 신청하기 */}
      <Route
        path="/apply/:id"
        element={
            <Pages.ApplyPage />
        }
      />
      {/* 결제하기 */}
      <Route
        path="/payment"
        element={
          <AuthRoute>
            <Pages.PaymentPage />
          </AuthRoute>
        }
      />
      <Route
        path="/payment/complete"
        element={
          <AuthRoute>
            <Pages.PaymentCompletePage />
          </AuthRoute>
        }
      />
      {/* 빠른 예약하기 */}
      <Route
        path="/reservation"
        element={
            <Pages.ReservationPage />
        }
      >
        <Route path="quick" element={<QuickReservation />} />
        <Route path="date" element={<DateReservation />} />
      </Route>
      <Route
        path="/reservation/:type/detail/:id"
        element={
            <Pages.ReservationDetailPage />
        }
      />
      {/* 매거진 */}
      <Route path="/magazine" element={<Pages.MagazinePage />} />
      <Route
        path="/magazine/category/:id"
        element={<Pages.MagazineCategoryPage />}
      />
      <Route
        path="/magazine/detail/:id"
        element={<Pages.MagazineDetailPage />}
      />
      {/* 오뉴소개 */}
      <Route path="/introduction" element={<Pages.IntroductionPage />} />
      <Route
        path="/introduction/category/:id"
        element={<Pages.IntroductionStoryPage />}
      />
      <Route
        path="/introduction/detail/:id"
        element={<Pages.IntroductionDetailPage />}
      />
      {/* 문의하기 */}
      <Route path="/ask" element={<Pages.AskPage />} />
      <Route path="/contact" element={<Pages.ContactPage />}>
        <Route path="notice" element={<Notice />} />
        <Route path="faq" element={<Faq />} />
        <Route path="terms" element={<Terms />} />
      </Route>
      <Route
        path="/contact/notice/detail/:id"
        element={<Pages.NoticeDetailPage />}
      />
      {/* 검색 */}
      <Route path="/search" element={<Pages.SearchPage />} />
      <Route path="/search/detail/:id" element={<Pages.SearchDetailPage />} />
      {/* 마이페이지 */}
      <Route
        path="/mypage"
        element={
          <AuthRoute>
            <Pages.MyPage />
          </AuthRoute>
        }
      />
      <Route
        path="/mypage/program"
        element={
          <AuthRoute>
            <Pages.MyProgramPage />
          </AuthRoute>
        }
      />
      <Route
        path="/mypage/payment-history"
        element={
          <AuthRoute>
            <Pages.MyPaymentHistoryPage />
          </AuthRoute>
        }
      />
      <Route
        path="/mypage/payment-cancel"
        element={
          <AuthRoute>
            <Pages.MyPaymentCancelPage />
          </AuthRoute>
        }
      />
      <Route
        path="/mypage/refund"
        element={
          <AuthRoute>
            <Pages.MyRefundPage />
          </AuthRoute>
        }
      />
      <Route
        path="/mypage/like"
        element={
          <AuthRoute>
            <Pages.MyLikePage />
          </AuthRoute>
        }
      />
      <Route
        path="/mypage/benefit"
        element={
          <AuthRoute>
            <Pages.MyBenefitPage />
          </AuthRoute>
        }
      />
      <Route
        path="/mypage/info"
        element={
          <AuthRoute>
            <Pages.MyInfoPage />
          </AuthRoute>
        }
      />

      <Route
        path="/review/writing"
        element={
          <AuthRoute>
            <Pages.ReviewWritingPage />
          </AuthRoute>
        }
      />
      <Route
        path="/review/edit"
        element={
          <AuthRoute>
            <Pages.ReviewEditPage />
          </AuthRoute>
        }
      />
      <Route
        path="/delete-account"
        element={
          <AuthRoute>
            <Pages.DeleteAccountPage />
          </AuthRoute>
        }
      />
      <Route
        path="/interest"
        element={
          <AuthRoute>
            <Pages.InterestPage />
          </AuthRoute>
        }
      />
      {/* 관리자단 예외처리 */}
      <Route path="/interest/webview" element={<Pages.InterestDetailPage />} />
      <Route
        path="/interest/complete"
        element={<Pages.InterestCompletePage />}
      />
      {/* 오시는길 */}
      <Route path="/direction" element={<Pages.DirectionPage />} />
      <Route
        path="/direction/detail/:id"
        element={<Pages.DirectionDetailPage />}
      />
      {/* 신고하기 */}
      <Route
        path="/report"
        element={
          <AuthRoute>
            <Pages.ReportPage />
          </AuthRoute>
        }
      />
      {/* 카테고리 */}
      <Route path="/category" element={<Pages.CategoryPage />} />
    </Routes>
  );
}
