import connector from "service/connector";
import utils from "utils";

const getList = async (data) => {
  return await connector.authConnector(`interest?${utils.etc.getFilterStr(data)}`,'GET');
}

const getMyList = async (data) => {
  return await connector.authConnector(`interest/user/detail?${utils.etc.getFilterStr(data)}`,'GET');
}

const updateInterview = async (data) => {
  return await connector.authConnector(`interest/insert`,'POST',data);
}


export {
  getList,
  getMyList,
  updateInterview,
};


export default {
  getList,
  getMyList,
  updateInterview,
}