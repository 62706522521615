import connector from "service/connector";
import utils from "utils";



const getList = async (data) => {
  return await connector.authConnector(`review?${utils.etc.getFilterStr(data)}`,'GET');
}

const getInfo = async (data) => {
  return await connector.authConnector(`review/info?${utils.etc.getFilterStr(data)}`,'GET');
}

const getDetail = async (id) => {
  return await connector.authConnector(`review/detail?relate_info_id=${id}`,'GET');
}

const updateReview = async (data) => {
  return await connector.authConnector(`review/update`,'POST',data);
}

const insertReview = async (data) => {
  return await connector.authConnector(`review`,'POST',data);
}


export {
  getList,
  getInfo,
  getDetail,
  updateReview,
  insertReview,
};


export default {
  getList,
  getInfo,
  getDetail,
  updateReview,
  insertReview,
}