import styled from "styled-components";

// img
import IconArrowRight from "resources/image/icon/icon_arrow_right_gray01.svg";

const Container = styled.main`
  min-height: calc(100vh - 96px);
  min-height: calc(100dvh - 96px);
  padding: 56px 0 100px;

  & .profileContainer {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    & .profileImgWrap {
      width: 50px;
      height: 50px;
      margin-right: 15px;
      border-radius: 50%;
      overflow: hidden;
    }

    & .userName {
      font-size: 22px;
      font-weight: 600;
    }

    & a {
      margin-left: auto;
      padding: 5px;
      font-size: 18px;
      font-weight: 600;
      color: #fff;
      background-color: var(--black);
      border-radius: 3px;
    }
  }

  & .conttact {
    display: flex;
    margin-bottom: 60px;
    font-size: 22px;
    font-weight: 600;
    background-color: var(--gray03);
    border-radius: 5px;

    & .contactList {
      position: relative;
      flex: 1;

      &:not(:last-child)::after {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 1px;
        height: 86px;
        background-color: var(--gray02);
        content: "";
      }

      & a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 120px;

        & .icon {
          width: 34px;
          height: 34px;
          margin-bottom: 10px;
        }
      }
    }
  }

  & .menuList {
    &:not(:last-child) {
      margin-bottom: 50px;
    }

    & a {
      display: flex;
      align-items: center;
      font-size: 26px;
      background: url(${IconArrowRight}) no-repeat right center / 16px;

      & .icon {
        width: 43px;
        height: 43px;
        margin-right: 20px;
      }
    }
  }

  @media (max-width: 520px) {
    min-height: calc(100vh - calc(100vw * (96 / 500)));
    min-height: calc(100dvh - calc(100vw * (96 / 500)));
    padding: calc(100vw * (56 / 500)) 0 calc(100vw * (100 / 500));

    & .profileContainer {
      margin-bottom: calc(100vw * (40 / 500));

      & .profileImgWrap {
        width: calc(100vw * (50 / 500));
        height: calc(100vw * (50 / 500));
        margin-right: calc(100vw * (15 / 500));
      }

      & .userName {
        font-size: calc(100vw * (22 / 500));
      }

      & a {
        padding: calc(100vw * (5 / 500));
        font-size: calc(100vw * (18 / 500));
        border-radius: calc(100vw * (3 / 500));
      }
    }

    & .conttact {
      margin-bottom: calc(100vw * (60 / 500));
      font-size: calc(100vw * (22 / 500));
      border-radius: calc(100vw * (5 / 500));

      & .contactList {
        &:not(:last-child)::after {
          height: calc(100vw * (86 / 500));
        }

        & a {
          height: calc(100vw * (120 / 500));

          & .icon {
            width: calc(100vw * (34 / 500));
            height: calc(100vw * (34 / 500));
            margin-bottom: calc(100vw * (10 / 500));
          }
        }
      }
    }

    & .menuList {
      &:not(:last-child) {
        margin-bottom: calc(100vw * (50 / 500));
      }

      & a {
        font-size: calc(100vw * (26 / 500));
        background: url(${IconArrowRight}) no-repeat right center /
          calc(100vw * (16 / 500));

        & .icon {
          width: calc(100vw * (43 / 500));
          height: calc(100vw * (43 / 500));
          margin-right: calc(100vw * (20 / 500));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
