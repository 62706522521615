import styled from "styled-components";

const Label = styled.label`
  display: inline-block;
  margin-bottom: 15px;
  font-size: 22px;
  font-weight: 600;

  & span {
    color: var(--main);
  }

  @media (max-width: 520px) {
    margin-bottom: calc(100vw * (15 / 500));
    font-size: calc(100vw * (22 / 500));
  }
`;

const Textarea = styled.textarea`
  display: block;
  width: 100%;
  height: 300px;
  padding: 20px 15px;
  font-size: 22px;
  font-weight: 400;
  background-color: var(--gray03);
  border-radius: 5px;

  &::placeholder {
    color: var(--gray01);
  }

  &.error {
    border: 1px solid var(--red);
  }

  @media (max-width: 520px) {
    height: calc(100vw * (300 / 500));
    padding: calc(100vw * (20 / 500)) calc(100vw * (15 / 500));
    font-size: calc(100vw * (22 / 500));
    border-radius: calc(100vw * (5 / 500));
  }
`;

export { Label, Textarea };

export default {
  Label,
  Textarea,
};
