import styled from "styled-components";

const Container = styled.main`
  position: relative;
  min-height: calc(100vh - 157px);
  min-height: calc(100dvh - 157px);
  padding: 40px 0 100px;

  & .searchContainer {
    position: relative;

    & input {
      padding-right: 74px;
    }

    & .searchButton {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      width: 34px;
      height: 34px;
      filter: var(--main-filter);
    }
  }

  & .program,
  .magazine {
    padding-top: 40px;

    & .emptyText {
      padding-top: 16px;
      color: var(--gray01);
    }
  }

  & .program {
    padding-bottom: 40px;
    border-bottom: 10px solid var(--gray03);
  }

  & .subBanner {
    margin-top: 20px;

    & .keywordWrap {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;
      margin-top: 20px;
    }

    & .logo {
      width: 111px;
      height: 32px;
      margin: 162px auto 0;
    }
  }

  @media (max-width: 520px) {
    min-height: calc(100vh - calc(100vw * (157 / 500)));
    min-height: calc(100dvh - calc(100vw * (157 / 500)));
    padding: calc(100vw * (40 / 500)) 0 calc(100vw * (100 / 500));

    & .searchContainer {
      & input {
        padding-right: calc(100vw * (74 / 500));
      }

      & .searchButton {
        right: calc(100vw * (20 / 500));
        width: calc(100vw * (34 / 500));
        height: calc(100vw * (34 / 500));
      }
    }

    & .program,
    .magazine {
      padding-top: calc(100vw * (40 / 500));

      & .emptyText {
        padding-top: calc(100vw * (16 / 500));
      }
    }

    & .program {
      padding-bottom: calc(100vw * (40 / 500));
      border-bottom: calc(100vw * (10 / 500)) solid var(--gray03);
    }

    & .subBanner {
      margin-top: calc(100vw * (20 / 500));

      & .keywordWrap {
        gap: calc(100vw * (10 / 500));
        margin-top: calc(100vw * (20 / 500));
      }

      & .logo {
        width: calc(100vw * (111 / 500));
        height: calc(100vw * (32 / 500));
        margin: calc(100vw * (250 / 500)) auto 0;
      }
    }
  }
`;

export { Container };

export default { Container };
