import Styles from "./styles";

// img
import IconClose from "resources/image/icon/icon_close.svg";

function AddressModal({ children, modalClose }) {
  return (
    <Styles.Container>
      <div className="modalContainer">
        <button
          type="button"
          className="closeButton"
          onClick={() => {
            modalClose();
          }}
        >
          <img src={IconClose} alt="IconClose" />
        </button>

        {children}
      </div>
    </Styles.Container>
  );
}

export default AddressModal;
