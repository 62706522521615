import Styles from "./styles";
import { openUrl } from "utils/etc";
import { Link, useNavigate } from "react-router-dom";


// img
import Logo from "resources/image/icon/logo.svg";
import IconArrowRight from "resources/image/icon/icon_arrow_right_mini.svg";
import IconInsta from "resources/image/icon/icon_insta.svg";
import IconYoutube from "resources/image/icon/icon_youtube.svg";

export default function Footer() {
  const navigate = useNavigate();

  // window.open openUrl로 변경 혹은 : openUrl = 아이폰 고려 실행
  // openUrl을 window.open 변경 혹은 : window.open = 새창에서 실행
  // window.open을 onClickHandler로 변경 : 테스트 안된 아이폰 새창 실행

  const onClickHandler = async (url) => {
    const windowReference = window.open(url, '_blank');
    return windowReference && windowReference.location;
  }

  const linkList = [
    {
      text: "고객센터",
      link: () => { navigate("/contact/notice") },
      target: null,
    },
    {
      text: "오뉴 파트너쉽 문의",
      link: () => { onClickHandler("https://www.notion.so/568f117a619d4d21b433e1dc4375c687?pvs=4") },
      // target: "_blank",
    },
  ];
  const snsList = [
    {
      icon: IconInsta,
      link: () => { onClickHandler("https://www.instagram.com/onew.lifestyle/") },
    },
    {
      icon: IconYoutube,
      link: () => { onClickHandler("https://www.youtube.com/@onew.lifestyle") },
    },
  ];
  const contactList = [
    <>
      고객센터 : <a href="tel:02-2038-2636">02-2038-2636</a>
    </>,
    "월~토요일 오전 9시 - 오후 6시",
    <>
      카카오톡{" "}
      <button
        type="button"
        onClick={() => {
          onClickHandler("http://pf.kakao.com/_xnIyqb")
        }}
      >
        @오뉴
      </button>{" "}
      친구 추가하고 다양한 혜택을 받아보세요.
    </>,
  ];

  const infoList = [
    "주식회사 로쉬코리아 ㅣ 대표자 : 현준엽",
    "사업자 등록번호 : 280-87-02148",
    "통신판매업 신고번호 : 제2023-서울종로-1305호",
    "주소 : 서울 종로구 팔판길 39",
    "이메일 : hello@losh.co.kr",
  ];

  return (
    <Styles.Container>
      <div className="top">
        <div className="inner">
          <ul className="linkWrap">
            {linkList.map((item, index) => {
              return (
                <li className="linkList" key={"linkList" + index}>

                  {/* //* 퍼블 깨짐 우려 주석. 원본 코드 */}
                  {/* <Link to={item.link} target={item.target}>
                    {item.text}{" "}
                    <div className="icon">
                      <img src={IconArrowRight} alt="IconArrowRight" />
                    </div>
                  </Link> */}

                  <a onClick={item.link} target="_blank">
                    {item.text}{" "}
                    <div className="icon">
                      <img src={IconArrowRight} alt="IconArrowRight" />
                    </div>
                  </a>
                </li>
              );
            })}
          </ul>

          <ul className="snsWrap">
            {snsList.map((item, index) => {
              return (
                <li className="snsList" key={"snsList" + index}>
                  <button
                    type="button"
                    onClick={item.link}
                  >
                    <img src={item.icon} alt="snsIcon" />
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      <div className="bottom">
        <div className="inner">
          <div className="logo">
            <img src={Logo} alt="logo" />
          </div>

          <ul className="contact">
            {contactList.map((item, index) => {
              return (
                <li className="contactList" key={"contactList" + index}>
                  {item}
                </li>
              );
            })}
          </ul>

          <ul className="info">
            {infoList.map((item, index) => {
              return (
                <li className="infoList" key={"infoList" + index}>
                  {item}
                </li>
              );
            })}
          </ul>

          <p className="copyright">
            © 2024 LOSH KOREA. LTD. All rights reserved.
          </p>
        </div>
      </div>
    </Styles.Container>
  );
}
