import styled from "styled-components";

const Container = styled.ul`
  display: flex;

  & li {
    position: relative;
    flex: 1;

    & a,
    & div {
      padding: 18px 0;
      color: var(--gray01);
      text-align: center;
      cursor: pointer;
    }

    & a.active,
    &.on div {
      font-weight: 600;
      color: var(--main);

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: var(--main);
        content: "";
      }
    }
  }

  @media (max-width: 520px) {
    & li a,
    & li div {
      padding: calc(100vw * (18 / 500)) 0;

      & a.active,
      &.on div {
        &::before {
          height: calc(100vw * (2 / 500));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
