import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  left: calc(50% + 160px);
  bottom: 125px;
  z-index: 6;

  & .buttonList {
    position: relative;
    z-index: 2;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 14px;
    }

    & .buttonText {
      position: absolute;
      top: 50%;
      right: calc(100% + 20px);
      transform: translateY(-50%);
      width: max-content;
      font-size: 20px;
      font-weight: 600;
      color: #fff;
    }

    & a,
    & button {
      width: 70px;
      height: 70px;
    }

    & button {
      display: block;
      filter: drop-shadow(0 5px 10px rgba(57, 57, 57, 0.2));
    }
  }

  & .overlay {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 500px;
    height: 100vh;
    height: 100dvh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }

  @media (max-width: 520px) {
    left: auto;
    right: calc(100vw * (20 / 500));
    bottom: calc(100vw * (125 / 500));

    & .buttonList {
      &:not(:last-child) {
        margin-bottom: calc(100vw * (14 / 500));
      }

      & .buttonText {
        right: calc(100% + calc(100vw * (20 / 500)));
        font-size: calc(100vw * (20 / 500));
      }

      & a,
      & button {
        width: calc(100vw * (70 / 500));
        height: calc(100vw * (70 / 500));
      }

      & button {
        filter: drop-shadow(
          0 calc(100vw * (8 / 500)) calc(100vw * (10 / 500))
            rgba(57, 57, 57, 0.2)
        );
      }
    }

    & .overlay {
      width: 100%;
    }
  }
`;

export { Container };

export default { Container };
