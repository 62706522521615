import styled from "styled-components";

const Container = styled.div`
  position: relative;
  width: 150px;
  border-radius: 5px;
  overflow: hidden;

  &.big {
    width: 223px;

    & .img {
      height: 278px;
    }

    & .textWrap {
      padding: 20px 15px;
      font-size: 22px;
      line-height: 1.45;

      &::after {
        height: 138px;
      }
    }
  }

  & input {
    display: none;

    &:checked + div .textWrap::after {
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(99, 5, 238, 0) -35.29%,
        #6305ee 105.88%
      );
      content: "";
    }
  }

  & .img {
    width: 100%;
    height: 187px;
  }

  & .textWrap {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    padding: 15px 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.37;
    color: #fff;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 93px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 204.84%);
      content: "";
    }

    & .title {
      position: relative;
      display: -webkit-box;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      z-index: 1;
    }
  }

  & label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 2;
  }

  @media (max-width: 520px) {
    width: calc(100vw * (150 / 500));
    border-radius: calc(100vw * (5 / 500));

    &.big {
      width: calc(100vw * (223 / 500));

      & .img {
        height: calc(100vw * (278 / 500));
      }

      & .textWrap {
        padding: calc(100vw * (20 / 500)) calc(100vw * (15 / 500));
        font-size: calc(100vw * (22 / 500));

        &::after {
          height: calc(100vw * (138 / 500));
        }
      }
    }

    & .img {
      height: calc(100vw * (187 / 500));
    }

    & .textWrap {
      padding: calc(100vw * (15 / 500)) calc(100vw * (10 / 500));
      font-size: calc(100vw * (16 / 500));

      &::after {
        height: calc(100vw * (93 / 500));
      }
    }
  }
`;

export { Container };

export default { Container };
