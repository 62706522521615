import Styles from "./styles";

function Tag({ type }) {
  const renderTag = (type) => {
    switch (String(type)) {
      case "1":
        return <Styles.Container className="tag time">시간권</Styles.Container>;

      case "2":
        return <Styles.Container className="tag">정기권</Styles.Container>;

      case "3":
        return <Styles.Container className="tag">원데이</Styles.Container>;

      case "4":
        return <Styles.Container className="tag time">모임</Styles.Container>;

      default:
        return <Styles.Container className="tag time">시간권</Styles.Container>;
    }
  };

  return <>{renderTag(type)}</>;
}

export default Tag;
