import useModals from "hooks/useModals";
import { api } from "service";
import { useNavigate } from "react-router-dom";

export default function useInquiry() {
  const modalOption = useModals();
  const navigate = useNavigate();

  const postInquiry = async (data) => {
    const result = await api.inquiry.postInquiry(JSON.stringify(data));
    if (result.success) {
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.closeButton = false;
        e.icon = false;
        e.title = "문의가 완료되었습니다.";
        e.text = (
          <>
            영업일 기준 2~3일 내로 적어주신 연락처로 <br />
            답변드리겠습니다.
          </>
        );
        e.confirmButtonText = "메인으로";
        e.confirmButtonClick = () => {
          navigate("/");
          modalOption.clearModals();
        };

        return { ...e };
      });
    }
  };

  return {
    postInquiry,
  };
}
