import connector from "service/connector";

const postImage = async (data) => {
  return await connector.connectFetchController("upload/image/profile", "POST", data);
}


const postReviewImage = async (data) => {
  return await connector.connectFetchController("upload/image/reviews", "POST", data);
}


export {
  postImage,
  postReviewImage,
};

export default {
  postImage,
  postReviewImage,
}