import styled from "styled-components";

const Container = styled.main`
  min-height: calc(100vh - 61px);
  min-height: calc(100dvh - 61px);
  padding: 40px 0 50px;

  & h2 {
    margin-bottom: 25px;
    font-size: 30px;
    font-weight: bold;
  }

  & .reasonList {
    padding: 15px 0;
    border-bottom: 1px solid var(--gray03);
  }

  & .textarea {
    margin-top: 15px;

    & textarea {
      height: 170px;
    }
  }

  & .buttonWrap {
    margin-top: 110px;
  }

  @media (max-width: 520px) {
    min-height: calc(100vh - calc(100vw * (61 / 500)));
    min-height: calc(100dvh - calc(100vw * (61 / 500)));
    padding: calc(100vw * (40 / 500)) 0 calc(100vw * (50 / 500));

    & h2 {
      margin-bottom: calc(100vw * (25 / 500));
      font-size: calc(100vw * (30 / 500));
    }

    & .reasonList {
      padding: calc(100vw * (15 / 500)) 0;
    }

    & .textarea {
      margin-top: calc(100vw * (15 / 500));

      & textarea {
        height: calc(100vw * (170 / 500));
      }
    }

    & .buttonWrap {
      margin-top: calc(100vw * (110 / 500));
    }
  }
`;

export { Container };

export default { Container };
