import styled from "styled-components";

const Container = styled.div`
  & .swiper {
    padding: 0 20px;

    & .swiper-slide {
      width: fit-content;
    }

    & .moreList {
      align-self: center;

      & a {
        padding: 0 4px 0 8px;
        font-size: 18px;
        font-weight: 600;
        color: var(--gray01);
        text-align: center;

        & .icon {
          width: 63px;
          height: 63px;
          margin-bottom: 5px;
        }
      }
    }
  }

  @media (max-width: 520px) {
    & .swiper {
      padding: 0 calc(100vw * (20 / 500));

      & .moreList {
        & a {
          padding: 0 calc(100vw * (4 / 500)) 0 calc(100vw * (8 / 500));
          font-size: calc(100vw * (18 / 500));

          & .icon {
            width: calc(100vw * (63 / 500));
            height: calc(100vw * (63 / 500));
            margin-bottom: calc(100vw * (5 / 500));
          }
        }
      }
    }
  }
`;

export { Container };

export default { Container };
